import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProfileSettings from './ProfileSettings';
import ProfileGameType from './ProfileGameType';
import ProfileStraight from './ProfileStraight';
import ProfileParlay from './ProfileParlay';
import ProfileTeaser from './ProfileTeaser';
import OddsDefault from './OddsDefault';
import JuiceRebate from './JuiceRebate';
import ProfileTeaserButtons from './ProfileTeaserButtons';
import './ProfileTabs.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProfileTabs(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //const user = useContext(UserContext);
  console.log('In Tabs ===>', props);
  if (!props.Profile) {
  } else {
    return (
      <React.Fragment>
        <br />
        <Tabs value={value} onChange={handleChange} className="tabs-class">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Game Type" {...a11yProps(1)} />
          <Tab label="Straight" {...a11yProps(2)} />
          <Tab label="Parlay" {...a11yProps(3)} />
          <Tab label="Teaser" {...a11yProps(4)} />
          <Tab to="/" label="Teaser Buttons" {...a11yProps(5)} />
          <Tab label="Odds Default" {...a11yProps(6)} />
          <Tab label="Juice Rebate" {...a11yProps(7)} />
        </Tabs>
        <TabPanel className="tab-content" value={value} index={0}>
          <ProfileSettings
            Profile={props.Profile}
            UpdateProfile={props.UpdateProfile}
          ></ProfileSettings>
        </TabPanel>
        <TabPanel className="tab-content" value={value} index={1}>
          <ProfileGameType Profile={props.Profile}></ProfileGameType>
        </TabPanel>
        <TabPanel className="tab-content" value={value} index={2}>
          <ProfileStraight
            Profile={props.Profile}
            UpdateProfile={props.UpdateProfile}
          >
            {' '}
          </ProfileStraight>
        </TabPanel>

        <TabPanel className="tab-content" value={value} index={3}>
          <ProfileParlay
            Profile={props.Profile}
            UpdateProfile={props.UpdateProfile}
          ></ProfileParlay>
        </TabPanel>
        <TabPanel className="tab-content" value={value} index={4}>
          <ProfileTeaser Profile={props.Profile}></ProfileTeaser>
        </TabPanel>
        <TabPanel className="tab-content" value={value} index={5}>
          <ProfileTeaserButtons Profile={props.Profile}></ProfileTeaserButtons>
        </TabPanel>
        <TabPanel className="tab-content" value={value} index={6}>
          <OddsDefault Profile={props.Profile}></OddsDefault>
        </TabPanel>
        <TabPanel className="tab-content" value={value} index={7}>
          <JuiceRebate Profile={props.Profile}></JuiceRebate>
        </TabPanel>
      </React.Fragment>
    );
  }
}
