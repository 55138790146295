import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../../components/common/InputSelect';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import * as legoData from '../../common/animations/legoLoader.json';
import './LineTypeSettings.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
export default function ProfileSettings(props) {
  const classes = useStyles();
  const [gameLinkedValues, setGameLinkedValues] = React.useState([]);
  const [idSport, setIdSport] = React.useState('CBB');
  const [sports, setSports] = useState();
  const [period, setPeriod] = React.useState(['0']);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    // Change for server api address
    GetValues(idSport);
  }, [setGameLinkedValues]);

  useEffect(() => {
    //Change for server api address
    const url = process.env.REACT_APP_API_URL + '/SPORT';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let groupingInfo = data.records.map((v) => {
          v.IdSport = v.IdSport;
          v.SportName = v.SportName;
          return v;
        });
        setSports(groupingInfo);
      });
  }, [setSports]);  

  const GetValues = (idSport) => {
    const url =
      process.env.REACT_APP_API_URL +
      '/LINETYPELINKS' +
      '?filter=IdLineTypeOut,eq,' +
      props.idLineTypeOut +
      '&filter=IdLineTypeIn,eq,' +
      props.idLineTypeIn +
      '&filter=IdSport,eq,' +
      idSport;
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      setGameLinkedValues(response.data.records);
    });

    setIsLoading(false);
  };

  function onAfterSaveCell(row, cellName, cellValue) {
    UpdateLineType(row);
  }
  function UpdateLineType(row) {
    let request = {
      IdLineTypeOut: props.idLineTypeOut,
      IdLineTypeIn: props.idLineTypeIn,
      IdSport: idSport,
      SpreadSync: row.SpreadSync,
      SpreadOddsSync: row.SpreadOddsSync,
      TotalSync: row.TotalSync,
      TotalOddsSync: row.TotalOddsSync,
      MoneyLineSync: row.TotalOddsSync,
      LinkType: row.LinkType,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/LineType/LineTypeLinks_Update',      
      data: request,
    }).then((response) => {
      if (response.data === '0') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  function changeSportID(value) {
    setIdSport(value);
    GetValues(value);
  }

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  console.log('PROPS====== Settings setPeriodOnMem>', isLoading);
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <Table>
          <TableRow>
            <TableCell>
              <TableCell>
                <InputSelect
                  variant="outlined"
                  name="name"
                  data={sports}
                  placeholder="Sports"
                  setSelectValue={changeSportID}
                  startValue={idSport}
                />
              </TableCell>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="3">
              <Table>
                <TableCell>
                  {' '}
                  {/* */}
                  <TableHeaderColumn>Link Syncronization</TableHeaderColumn>
                  <BootstrapTable
                    data={gameLinkedValues}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdLineTypeOut"
                    >
                      IdLineType
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="SpreadSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      SpreadSync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="SpreadOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      Spread Odds Sync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="TotalSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      Total Sync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="TotalOddsSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      Total Odds Sync
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="MoneyLineSync"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      Money Line Sync
                    </TableHeaderColumn>
                  </BootstrapTable>
                </TableCell>
              </Table>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}
