import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../common/InputSelect';

/*Other Components*/
import Sports from '../../common/Sports';
import Periods from '../../common/Periods';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const priceFormatter = (cell, row) => {
  let isSelected = '';
  let isSelected0 = '';
  let isSelected1 = '';
  let isSelected2 = '';
  let isSelected3 = '';
  let isSelected4 = '';
  console.log('Formatting cell', cell);
  switch (cell) {
    case 0:
      isSelected0 = 'checked';
      break;
    case 1:
      isSelected1 = 'checked';
      break;
    case 2:
      isSelected2 = 'checked';
      break;
    case 3:
      isSelected3 = 'checked';
      break;
    case 4:
      isSelected4 = 'checked';
      break;
    default:
      isSelected = 'checked';
  }
  console.log('Formatting cell isSelected0', isSelected0);
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>

 `;
};
export default function UserBooks(props) {
  const classes = useStyles();
  const [sysUser, setSysUser] = React.useState([]);
  const [sysUserBooks, setSysUserBooks] = React.useState([]);
  const [books, setBooks] = React.useState([]);

  const [idSport, setIdSport] = React.useState(['CBB']);
  const [period, setPeriod] = React.useState(['0']);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  let IdUser = props.sysUser.IdUser;

  useEffect(() => {
    GetBooks();
    GetBooksSelected();
  }, []);
  // const [selectedIdGame, setselectedIdGame] = useState();
  const GetBooks = () => {
    // Change for server api address
    const url = process.env.REACT_APP_API_URL + '/BOOK';
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS UserBook ====== REST THEN>', response.data.records);
      console.log('PROPS UserBook ====== REST THEN>', response.data.records[0]);
      setBooks(response.data.records);
    });
  };
  const GetBooksSelected = () => {
    console.log('PROPS User Settings period======>', props);
    console.log('PROPS User Settings period======>', period);
    const url =
      process.env.REACT_APP_API_URL +
      '/USERSBOOK' +
      '?filter=IdUser,eq,' +
      props.sysUser.IdUser +
      '&join=BOOK';
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      let mappedArr = response.data.records.map((x) => {
        return {
          Id: x.IdBook.IdBook,
        };
      });
      let array = [];
      mappedArr.forEach((element) => array.push(element.Id));
      setSysUserBooks(array);
    });
  };
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const onRowSelect = (row, isSelected, e) => {
    if (isSelected) {
      axios({
        method: 'post',
        //url:'https://localhost:44337/api/User/UsersBook_Insert?idBook=' + row.IdBook + '&idUser='+ props.sysUser.IdUser,
        url:
          process.env.REACT_APP_API2_URL +
          '/User/UsersBook_Insert?idBook=' +
          row.IdBook +
          '&idUser=' +
          props.sysUser.IdUser,
        /*  REPLACE WITH SELECT DATA */
      }).then((response) => {
        if (response.data === 0) {
          GetBooks();
        }
      });
    } else if (!isSelected) {
      axios({
        method: 'post',
        //url:'http://192.168.3.22:4587/api/User/UsersBook_Delete?idBook=' + row.IdBook + '&idUser='+ props.sysUser.IdUser,
        url:
        process.env.REACT_APP_API2_URL + '/User/UsersBook_Delete?idBook=' +
          row.IdBook +
          '&idUser=' +
          props.sysUser.IdUser,
      }).then((response) => {
        if (response.data === 0) {
        }
      });
    }
  };

  const onSelectAll = (isSelected, rows) => {
    if (isSelected) {
      axios({
        method: 'post',
        //url:'http://192.168.3.22:4587/api/User/UsersBook_Insert?idBook=' + rows.IdBook + '&idUser='+ props.sysUser.idUser,
        url:
        process.env.REACT_APP_API2_URL + '/User/UsersBook_Insert?idBook=' +
          rows.IdBook +
          '&idUser=' +
          props.sysUser.idUser,
      }).then((response) => {
        if (response.data === 0) {
        }
      });
    } else if (!isSelected) {
      axios({
        method: 'post',
        //url:'http://192.168.3.22:4587/api/User/UsersBook_Delete?idBook=' + row.IdBook + '&idUser='+ props.sysUser.idUser,
        url:
        process.env.REACT_APP_API2_URL + '/User/UsersBook_Delete?idBook=' +
          rows.IdBook +
          '&idUser=' +
          props.sysUser.idUser,
      }).then((response) => {
        if (response.data === 0) {
        }
      });
    }
  };

  const selectRowProp = {
    mode: 'checkbox',
    selected: sysUserBooks,
    clickToSelect: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  console.log('PROPS====== Settings setPeriodOnMem>', isLoading);
  return (
    <React.Fragment>
      <div class="main-div">
        <Table>
          <TableRow>
            <TableCell colSpan="2">
              <b>Profiles</b>
              <br></br>
              <BootstrapTable
                data={books}
                selectRow={selectRowProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdBook"
                >
                  IdBook
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Description">
                  Description
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}
