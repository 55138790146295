import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import GameTypes from '../../common/GameTypes';
import Sports from '../../common/Sports';
import WagerTypes from '../../common/WagerTypes';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import LineTypeLinked from './LineTypeLinked';

export default function LineTypeLinks(props) {
  // const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  console.log('PROPS====== PLAYERLineTypeLinkSPORT url props', props);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [wagerType, setWagerType] = React.useState(['1']);
  const [gameType, setGameType] = React.useState(['1']);
  const [notLinkedLineType, setNotLinkedLineType] = React.useState();
  const [linkedLineType, setLinkedLineType] = React.useState();
  const [lineTypes, setLineTypes] = React.useState([]);

  const GetLinkedLineTypes = () => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/LineType/LineTypeLinks_GetNotLinked?idLineType=' +
        props.LineType.IdLineType +
        '&idUser=' +
        userInfo.UserId,
      //url:'https://localhost:44337/api/LineType/LineTypeLinks_GetNotLinked?idLineType=' +props.LineType.IdLineType +'&idUser=' +userInfo.UserId,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('NOT LINKED LINETYPES =======>', response.data);
      setNotLinkedLineType(response.data);
    });
  };

  const GetUnlinkedLineTypes = () => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/LineType/LineTypeLinks_GetLinked?idLineType=' +
        props.LineType.IdLineType +
        '&idUser=' +
        userInfo.UserId,
      //url:'https://localhost:44337/api/LineType/LineTypeLinks_GetLinked?idLineType=' +props.LineType.IdLineType +'&idUser=' + userInfo.UserId,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('LINKED LINETYPES =======>', response.data);
      setLinkedLineType(response.data);
    });
  };

  useEffect(() => {
    GetLinkedLineTypes();    
    GetUnlinkedLineTypes();
  }, [props.LineType.IdLineType]);
  

  /*function idToName(cell, row) {
    let rowIdLineType = row.IdLineTypeOut.IdLineType;
    console.log('LineType formatting row', rowIdLineType);
    console.log('LineType formatting lineTypes', lineTypes);
    let foundLL = lineTypes.filter((e) => e.IdLineType === rowIdLineType);
    console.log('LineType formatting foundLL', foundLL[0]);
    console.log('LineType formatting foundLL', foundLL[0].Description);
    return foundLL[0].Description;
  }*/
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const isExpandableRow = (row) => {
    console.log('isExpandableRow ', row);
    return true;
  };
  const expandComponent = (row) => {
    console.log('expandComponent ', row);
    return <BSTable data={row} />;
  };

  const onRowSelect = (row, isSelected, e) => {
    let request = {
      IdLineTypeOut: row.IdLineType,
      IdLineTypeIn: props.LineType.IdLineType,
      IdUser: userInfo.UserId,
    };
    if (isSelected) {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/LineType/LineTypeLinks_Insert',
        //url: 'https://localhost:44337/api/LineType/LineTypeLinks_Insert',
        /*  REPLACE WITH SELECT DATA */
        data: request,
      }).then((response) => {
        if (response.data === 0) {
          GetLinkedLineTypes();
          GetUnlinkedLineTypes();
        }
      });
    }
  };

  const onSelectAll = (isSelected, rows) => {
    if (isSelected) {
      let request = {
        IdLineTypeOut: -1,
        IdLineTypeIn: props.LineType.IdLineType,
        IdUser: userInfo.UserId,
      };
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/LineType/LineTypeLinks_Insert',
        //url: 'https://localhost:44337/api/LineType/LineTypeLinks_Insert',
        /*  REPLACE WITH SELECT DATA */
        data: request,
      }).then((response) => {
        if (response.data === 0) {
          GetLinkedLineTypes();
          GetUnlinkedLineTypes();
        }
      });
    }
  };

  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  const onRowUnlinkedSelect = (row, isSelected, e) => {
    if(window.confirm("You are about to unlink a Line Type, Would you like to proceed?") === true){      
      if (isSelected) {
        let request = {
          IdLineTypeOut: row.IdLineType,
          IdLineTypeIn: props.LineType.IdLineType,
          IdUser: userInfo.UserId,
        };
        axios({
          method: 'post',
          url: process.env.REACT_APP_API2_URL + '/LineType/LineTypeLinks_Delete',
          //url: 'https://localhost:44337/api/LineType/LineTypeLinks_Delete',
          /*  REPLACE WITH SELECT DATA */
          data: request,
        }).then((response) => {
          if (response.data === 0) {
            GetLinkedLineTypes();
            GetUnlinkedLineTypes();
          }
        });
      }
    }
  };

  const onUnlinkedSelectAll = (isSelected, rows) => {
    if (isSelected) {
      let request = {
        IdLineTypeOut: -1,
        IdLineTypeIn: props.LineType.IdLineType,
        IdUser: userInfo.UserId,
      };
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/LineType/LineTypeLinks_Delete',
        //url: 'https://localhost:44337/api/LineType/LineTypeLinks_Delete',
        /*  REPLACE WITH SELECT DATA */
        data: request,
      }).then((response) => {
        if (response.data === 0) {
          GetLinkedLineTypes();
          GetUnlinkedLineTypes();
        }
      });
    }
  };

  const selectRowPropUnlinked = {
    mode: 'checkbox',
    //clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    onSelect: onRowUnlinkedSelect,
    onSelectAll: onUnlinkedSelectAll,
  };

  class BSTable extends React.Component {
    render() {
      if (this.props.data) {
        console.log('response this.props.data', this.props.data);
        return (
          <Table className="table-open-bets">
            <TableRow>
              <LineTypeLinked
                idLineTypeOut={this.props.data.IdLineType}
                idLineTypeIn={props.LineType.IdLineType}
              ></LineTypeLinked>
            </TableRow>
          </Table>
        );
      } else {
        return <p>?</p>;
      }
    }
  }

  return (
    <React.Fragment>
      <Table>
        <TableRow>
          <TableCell>
            Not Linked Line Types
            <TableRow></TableRow>
            {console.log('LineType before rendiriung', notLinkedLineType)}
            <BootstrapTable
              data={notLinkedLineType}
              selectRow={selectRowProp}
              options={options}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdLineType"
              >
                IdLineType
              </TableHeaderColumn>
              <TableHeaderColumn
                filter={{ type: 'TextFilter', delay: 1000 }}
                dataField="Description"
              >
                Description
              </TableHeaderColumn>
            </BootstrapTable>
          </TableCell>
          <TableCell>
            Linked Line Types
            {console.log('LineType before rendiriung', linkedLineType)}
            <BootstrapTable
              data={linkedLineType}
              options={options}
              selectRow={selectRowPropUnlinked}
              expandableRow={isExpandableRow}
              expandComponent={expandComponent}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdLineType"
              >
                IdLineType
              </TableHeaderColumn>
              <TableHeaderColumn
                filter={{ type: 'TextFilter', delay: 1000 }}
                dataField="Description"
              >
                Description
              </TableHeaderColumn>
            </BootstrapTable>
          </TableCell>
        </TableRow>
      </Table>
    </React.Fragment>
  );
}
