import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import './WebConfiguratorList.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Loader from '../../common/loader/Loader';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DataGrid } from '@material-ui/data-grid'; 
import Button from "@material-ui/core/Button";
import WebCongigurationRowNew from '../webConfiguratorComponents/WebConfigurationRowNew'
import TextField from "@material-ui/core/TextField";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function WebList(props) {
  const [webRows, setWebRows] = useState();
  const [record, setRecord] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [selectedWebRow,setSelectedWebRow] = useState(false);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [isNewWebRow, setIsNewWebRow] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIdWeb, setSelectedIdWeb] = useState(props.initialValue);
  const [filter, setFilter] = useState("");

  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 230,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();


  useEffect(() => {
    GetWebRows();
  }, []);

  const GetWebRows = () => {
    setIsLoading(true);
    console.log('WEBROW');
    const url = process.env.REACT_APP_API_URL + '/WEBROW?order=IdWebRow';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setWebRows(data.records);

        setIsLoading(false);
      });
  };


  function selectedValues(row) {
    console.log(`You click row id: ${row.IdWebRow}`);
    props.loadWebRow(row);
  }

  const handleCloseCreate = function () {
    setIsNewWebRow(false); 
  };

  const handleIdWebChange = (event, newValue) => {
    props.setWebOnMem(event.target.value, props.row);
    setSelectedIdWeb(newValue.props.value);
  };

  function onRowSelect(row, isSelected, e) {
    setSelectedWebRow(webRows.filter(x => x.IdWebRow == row[0])[0])    
  }

  const deleteWebRow = () => {
    if(window.confirm("Are you sure you want to delete the selected item? ("+selectedWebRow.IdWebRow+")")){
      let RequestDTO = {
        idWebRow: selectedWebRow.IdWebRow,
        idUser: userInfo.UserId,
      };
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/WebConfigurator/WebRow_Delete',
        data: RequestDTO,
      }).then((response) => {
        if (response.data === true) {
          alert('Data Successfully Deleted');
        }
        GetWebRows()
        setSelectedWebRow(false)
      });      
    }
  }

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  }

  function renderManage(params) { 
    return( 
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          props.loadWebRow(params.row)
        }}
      >
        Manage
      </Button>
    )
  }

  const printInputList = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>          
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedIdWeb}
            onChange={handleIdWebChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {webRows &&
              webRows.map((row) => (
                <MenuItem value={row.IdWebRow}>{row.Description}</MenuItem>
              ))}
          </Select>
          <FormHelperText>Webs</FormHelperText>
        </FormControl>
      </div>
    );
  };

  

  const printList = () => {
    return (
      <div id="main-div Web-list" class="Web-list">
        <Paper className={classes.root}>
          <h2>Web Rows</h2>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{setIsNewWebRow(true)}}
          >
            New Web Row
          </Button>
          {selectedWebRow ? (
            <Button
                style={{ margin: 8 }}
                variant="contained"
                color="secondary"
                onClick={deleteWebRow}
              >
                Delete Web Row
              </Button>
            ) : (
              ""
            )}

          {isLoading ? <Loader></Loader> : ''}
          <Table className={classes.table} aria-label="simple table">
            <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
              {webRows && (
                <div>
                  <TextField      
                    style={{padding:"8px"}}                          
                    variant="outlined"                            
                    label="Search by Description"
                    type="text"
                    value={filter}
                    onChange={handleChangeFilter}                                                             
                  />
                  <Box sx={{ width: '750px' }}>
                    <DataGrid
                        rows={webRows.length > 0 ? filter != "" ? webRows.filter(x => x.Description.toUpperCase().includes(filter.toUpperCase())) : webRows : []}
                        getRowId={(row) => Object.values(row)[0]}
                        columns={[ 
                          {
                            field: "",
                            headerName: "",
                            align: "right",
                            headerAlign: "center",
                            width: 100,
                            editable: false,
                            sortable: false,
                            filterable: false,
                            renderCell: renderManage,
                          },                    
                          {field: 'Description' , headerName: 'Description', width: 450, editable: false, sortable: false, filterable: false},
                        ]}
                        pageSize={100}
                        rowsPerPageOptions={[5]}            
                        disableColumnSelector
                        disableColumnMenu
                        disableMultipleSelection={true}  
                        onSelectionModelChange={onRowSelect}   
                        autoHeight                               
                    />
                  </Box>
                </div>
              )}
            </TableBody>
          </Table>
        </Paper>
        {isNewWebRow?(        
            <WebCongigurationRowNew
              onClose={handleCloseCreate}
              GetWebRows={GetWebRows}
            />          
        ):(
          "" 
        )} 
      </div>
    );
  };

  if (redirect) {
    let pushURL = '/Web/' + redirect.IdWeb;
    console.log('Web Info props redirect', redirect);
    props.setWeb(redirect);
    printList();
    setRedirect(null);
    return (
      <div>
        <Redirect
          to={{
            pathname: pushURL,
          }}
        />

        {}
      </div>
    );

  }

  if (props.isInput) {
    return printInputList();
  } else {
    return printList();
  }
}

export default WebList;


