import React, { useState, setState, useEffect, useContext } from 'react';
import { forwardRef, useRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { UserContext } from '../../contexts/UserContext';
import Checkbox from '@material-ui/core/Checkbox';

import KeyboardEventHandler from 'react-keyboard-event-handler';
import LoadingOverlay from 'react-loading-overlay';
/*user Components*/
import UserList from './userComponents/UserList';
import UserInfo from './userComponents/UserInfo';
import UserTabs from './userComponents/UserTabs';
import './User.css';

const User = forwardRef((props) => {
  const user = useContext(UserContext);

  const [sysUser, setSysUser] = React.useState(0);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      useState,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  let history = useHistory();

  useEffect(() => {
    //Change for server api address
    const url =
      process.env.REACT_APP_API_URL +
      '/USERS?filter=IdUser,eq,' +
      props.match.params.id;
    const timeout = setTimeout(() => {
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setSysUser(data.records[0]);

          console.log('User data props', data);
        }, 500);

      return () => clearTimeout(timeout);
    });
  }, []);
  //subscribeToTimer((err, timestamp) => settimestamp(timestamp));

  const UpdateSysUser = (row) => {
    let userRequest = {
      UserID: row.IdUser,
      UserName: row.UserName,
      IdUserProfile: row.IdUserProfile,
      IdDepartment: row.IdDepartment,
      LoginName: row.LoginName,
      Name: row.Name,
      Status: row.Status,
      IdLanguage: row.IdLanguage,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/api/User/Users_Update',
      //url: 'https://localhost:44337/api/User/Users_Update',
      data: userRequest,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  };

  const setCurrentUser = (user) => {
    console.log('in user props', props);
    console.log('in user props', user);
    setSysUser(user);
    let anothervar = user.IdUser.toString();
    //let anothervar = 'user.Iduser';
    history.push(anothervar);
    console.log('in user props after anothervar', anothervar);
  };
  return (
    <React.Fragment>
      {console.log('in user props', props)}
      {user && (
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="user-list cell-top-align" id="usersCell">
                  <div className="users-left-div">
                    <UserList
                      showLoading={props.showLoading}
                      setUser={setCurrentUser}
                    ></UserList>
                  </div>
                </TableCell>
                <TableCell className="users-right-div cell-top-align">
                  <div className="users-right-div">
                    <Paper className={classes.root}>
                      <div
                        id="inputs"
                        style={{ display: 'flex', maxHeight: 45 }}
                      >
                        {true ? (
                          <UserInfo
                            sysUser={sysUser}
                            UpdateSysUser={UpdateSysUser}
                          ></UserInfo>
                        ) : (
                          <UserInfo
                            sysUser={sysUser}
                            UpdateSysUser={UpdateSysUser}
                          ></UserInfo>
                        )}
                      </div>

                      <UserTabs
                        AgentInfo={props.location}
                        sysUser={sysUser}
                        UpdateSysUser={UpdateSysUser}
                        showLoading={props.showLoading}
                      ></UserTabs>
                    </Paper>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
});

export default User;
