import React, { useContext, useState, setState } from 'react';
/*Loader */
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReactLoading from "react-loading";

/*animation*/
import * as legoData from "../animations/legoLoader.json"



export default function Loader(props) {
 
  const [showLoader, setShowLoader] = useState(false);
  //subscribeToChanges((err, change) => console.log(change));

/*Loader*/
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
  preserveAspectRatio: "xMidYMid slice"
  }
  }

  

  return(
    <FadeIn>
    <div class="d-flex justify-content-center align-items-center">
            <Lottie options={defaultOptions} height={props.height} width={props.width} />                      
    </div>
  </FadeIn>

  );
}

