import React, { useState, setState, useEffect, useContext } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import InputSelect from "../../components/common/InputSelect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Axios from "axios";
import { width } from 'dom-helpers';
import Loader from "../../components/common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import { SettingsInputSvideoRounded } from '@material-ui/icons';
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";

function CreateLeagueMapping(props) {    
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },            
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "600px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
              justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [IdSport, setIdSport] = useState(0);
    const [lineTypeID, setlineTypeID] = useState(0);
    const [leagues, setLeagues] = useState();
    const [leagueID, setLeagueID] = useState(-1);
    const [gameTypeID, setGameTypeId] = useState(0);
    const [dbLeague, setDbLeague] = useState("");
    const [dbLeagueId, setDbLeagueId] = useState("");
    const [isLoading, setIsLoading] = useState(false);  
    const [isRed, setIsRed] = useState(false);
    const [isInserted, setIsInserted] = useState(false);
    
    useEffect(() => {
        getLeagues(IdSport)
    }, []);

    function changeSportID(value) {
        setIdSport(value);       
        getLeagues(value);        
    }

    function changeGameTypeId(value) {        
        setGameTypeId(value);             
    }

    function changeLineTypeID(value) {        
        setlineTypeID(value)      
    }

    const handleClose = () => {
        if (props.onClose) {
          props.onClose();
        }
        setOpen(false);
    };

    const getLeagues = (idSport) => {        
        const url =
          process.env.REACT_APP_API_URL + "/LEAGUE?filter=IdSport,eq," + idSport;
        Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            let leagueInfo = data.records.map((v) => {
              v.IdLineType = v.IdLeague;
              v.Description = v.Description;
              return v;
            });
            setLeagues(leagueInfo);
          });
    };

    const handleChangeDbLeague = (event) => {
        setDbLeague(event.target.value);
    };
    const handleChangeDbLeagueId = (event) => {
        if(event.target.value.match(/^[0-9]+$/)!=null || event.target.value===""){
            setDbLeagueId(event.target.value);    
        }       
    };    

    const insertLeagueMapping = () => {
        let leagueMappingRequest = {
            idDonBestLeague: dbLeagueId,
            nameDonBestLeague: dbLeague,
            idDGSidSport: IdSport,
            idDGSidLeague: leagueID,            
            idDGSidGameType: gameTypeID,
            idDGSLineType: lineTypeID,
            idASIidStore: null,
            idASIidSport: null,
            idASIidSportSubType: null,
            idDBHouse: 364,
            time: null,
            pointSpd: null,
            moneyline: null,
            offsetTotals: null,
            minRangePointSpd: null,
            minRangeMoneyline: null,
            minRangeTotals: null,
            maxRangePointSpd: null,
            maxRangeMoneyline: null,
            maxRangeTotals: null,
            roundUp: null,
        };
        setIsLoading(true);      
        Axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/AddOns_ALMLeagueReference',
            data: leagueMappingRequest,
        }).then((response) => {
            console.log(response)
            setIsInserted(true);
            alert("The League mapping was successfully created Reference ID: "+response.data)            
        })
        setIsLoading(false)       
    }

    const handleOk = () => {        
        if(IdSport===0||leagueID===-1||gameTypeID===0||lineTypeID===0||dbLeagueId===""||dbLeague===""){
            alert("Please provide the required fields");     
            setIsRed(true);                        
        }else{
            insertLeagueMapping();            
        }
    } 
       
    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>            
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        New Mapping
                        </Typography>
                    </Toolbar>
                </AppBar>  
                    <form className={classes.center}>
                        <Divider />
                            <Typography variant="h6" className={classes.title}>
                                Don Best Information
                            </Typography>
                            <FormGroup row>                                
                                <TextField                                
                                variant="outlined"                            
                                label="League Name"
                                type="text"
                                value={dbLeague}
                                onChange={handleChangeDbLeague}
                                fullWidth   
                                {...isRed&&dbLeague===""?(
                                    {error:true}
                                ):("")}                                                
                                />
                                <TextField                                
                                variant="outlined"                            
                                label="Id League"
                                type="text"                                
                                value={dbLeagueId}
                                onChange={handleChangeDbLeagueId}
                                fullWidth    
                                {...isRed&&dbLeagueId===""?(
                                    {error:true}
                                ):("")}                                              
                                />
                            </FormGroup>
                        <Divider />
                            <Typography variant="h6" className={classes.title}>
                                DGS Information
                            </Typography>
                            <FormGroup row>
                                <InputSelect
                                    variant="outlined"
                                    name="name"
                                    data={props.sports}
                                    placeholder="Sports"
                                    setSelectValue={changeSportID}
                                    startValue={IdSport}
                                    {...isRed&&IdSport===0?(
                                        {isError:true}
                                    ):("")} 
                                />
                                <InputSelect
                                    variant="outlined"
                                    name="name"
                                    data={props.lineTypes}
                                    placeholder="Line Type"
                                    setSelectValue={changeLineTypeID}
                                    startValue={lineTypeID}
                                    {...isRed&&lineTypeID===0?(
                                        {isError:true}
                                    ):("")} 
                                />                                
                            </FormGroup>
                        <Divider/> 
                            <FormGroup row>
                            <Autocomplete                                    
                                        disablePortal
                                        id="League"
                                        options={leagues}              
                                        getOptionLabel={(option) => option.Description ? option.Description : "" } 
                                        disabled = {IdSport===0?(true):(false)}
                                        onChange={(event, newValue) => {   
                                            if(newValue===null){
                                                setLeagueID(-1)                                            
                                            }else{
                                                setLeagueID(newValue.IdLeague)
                                            }                                           
                                        }}                        
                                        renderInput={(params) => 
                                            <TextField 
                                            {...isRed&&leagueID===-1?(
                                                {error:true}
                                            ):("")} 
                                            {...params} 
                                            label="League Name" 
                                            fullWidth 
                                            />}
                                        />
                            </FormGroup> 
                            <Divider/> 
                            <FormGroup row>
                                <InputSelect                                    
                                    variant="outlined"
                                    name="name"
                                    data={props.gameTypes}
                                    placeholder="Type"
                                    setSelectValue={changeGameTypeId}
                                    startValue={gameTypeID}  
                                    {...isRed&&gameTypeID===0?(
                                        {isError:true}
                                    ):("")}                                   
                                />
                            </FormGroup>                                              
                    </form>
                    <React.Fragment>
                        <DialogActions>
                            {isInserted ? (                                
                                <FormControl className={classes.formControl}>
                                    <InputBase
                                    style={{
                                        color: "red",
                                        fontSize: 35,
                                        fontWeight: "bold",
                                    }}
                                    className={classes.margin}
                                    defaultValue="Done!"
                                    inputProps={{ "aria-label": "naked" }}
                                    />
                                </FormControl>                                
                            ) : (
                                ""
                            )}
                            {isLoading ? (
                            <Loader height={100} width={100}></Loader>
                            ) : (
                            <div>                                
                                <Button
                                startIcon={<SaveIcon />}
                                color="inherit"
                                onClick={handleOk}
                                >
                                Ok
                                </Button>
                            </div>
                            )}                            
                        </DialogActions>   
                                             
                    </React.Fragment>   
            </Dialog>
        </React.Fragment>
    );
}
export default CreateLeagueMapping;

