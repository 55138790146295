import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as moment from 'moment';
import InputSelect from '../../common/InputSelect';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LineTypeList from '../../lineTypes/lineTypesComponents/LineTypeList';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import * as legoData from '../../common/animations/legoLoader.json';
import './ChangesByGame.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FinalStatsByAgent(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [gameScore, setGameScore] = React.useState([]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[0]);

  const HistoryValues = () => {
    setIsLoading(true);
    let request = {
      logIdUser: userInfo.UserId,
      StartDate: startDate,
      EndDate: endDate,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Reports/Report_Game_Score?logIdUser=' +
        userInfo.UserId +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate,
    }).then((response) => {
      console.log('response', response.data);
      setGameScore(response.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    console.log("gameScore",gameScore)
    formatGameScore(gameScore);
  }, [gameScore]);

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
    },
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function rowClassNameFormat(row, rowIdx) {
    return rowIdx % 2 === 0
      ? 'tr-function-example'
      : 'td-column-function-odd-example';
  }

  const formatGameScore = (gameScore) => {
    let aux = []
    let visitorNameTemp = '';
    gameScore.forEach(
      x => {
        if(x.GHomeScore == null && x.GVisitorScore == null){
          x.GHomeScore = 'Cancelled';    
          x.GVisitorScore = 'Cancelled';
        }
        if(x.IdSport.trim() == 'TNT' || x.IdSport.trim() == 'PROP'){
          if(x.Result === 0){
            x.GHomeScore = 'Loss';    
            x.GVisitorScore = 'Loss';
          }else{
            x.GHomeScore = 'Win';    
            x.GVisitorScore = 'Win';
          }
        }
      }
    )
    for(let i = 0 ; i < gameScore.length ; i++){
      if(gameScore[i].IdSport.trim() != 'TNT'){
        let visitorGame = {
          idSport: gameScore[i].IdSport,
          gameDate: gameScore[i].GameDateTime,
          name: gameScore[i].VisitorName,
          gameScore: gameScore[i].GVisitorScore,
          firstHalf: gameScore[i].VisitorScore1H,
          secondHalf: gameScore[i].VisitorScore2H,
          firstQ: gameScore[i].VisitorScore1Q,
          secondQ: gameScore[i].VisitorScore2Q,
          thirdQ: gameScore[i].VisitorScore3Q,
          fourthQ: gameScore[i].VisitorScore4Q,
          gradedDate: gameScore[i].GradedDate
        }
        let homeGame = {
          idSport: gameScore[i].IdSport,
          gameDate: gameScore[i].GameDateTime,
          name: gameScore[i].HomeName,
          gameScore: gameScore[i].GHomeScore,
          firstHalf: gameScore[i].HomeScore1H,
          secondHalf: gameScore[i].HomeScore2H,
          firstQ: gameScore[i].HomeScore1Q,
          secondQ: gameScore[i].HomeScore2Q,
          thirdQ: gameScore[i].HomeScore3Q,
          fourthQ: gameScore[i].HomeScore4Q,
          gradedDate: gameScore[i].GradedDate
        }
        aux.push(visitorGame,homeGame)
      }else{
        const gameDateAux = new Date(gameScore[i].GameDateTime);
        if(visitorNameTemp === '' || visitorNameTemp != gameScore[i].VisitorName){          
          let visitorGame = {
            idSport: gameScore[i].IdSport,
            gameDate: gameDateAux.getFullYear() + '-' +
            (gameDateAux.getMonth()+1) + '-' +
            gameDateAux.getDate(),
            name: gameScore[i].VisitorName,
            gameScore: gameScore[i].GVisitorScore,
            firstHalf: gameScore[i].VisitorScore1H,
            secondHalf: gameScore[i].VisitorScore2H,
            firstQ: gameScore[i].VisitorScore1Q,
            secondQ: gameScore[i].VisitorScore2Q,
            thirdQ: gameScore[i].VisitorScore3Q,
            fourthQ: gameScore[i].VisitorScore4Q,
            gradedDate: gameScore[i].GradedDate
          }
          let homeGame = {
            idSport: gameScore[i].IdSport,
            gameDate: gameDateAux.getHours() + ':' +
            gameDateAux.getMinutes() + ':' +
            gameDateAux.getSeconds(),
            name: gameScore[i].HomeName,
            gameScore: gameScore[i].GHomeScore,
            firstHalf: gameScore[i].HomeScore1H,
            secondHalf: gameScore[i].HomeScore2H,
            firstQ: gameScore[i].HomeScore1Q,
            secondQ: gameScore[i].HomeScore2Q,
            thirdQ: gameScore[i].HomeScore3Q,
            fourthQ: gameScore[i].HomeScore4Q,
            gradedDate: gameScore[i].GradedDate
          }
          aux.push(visitorGame,homeGame)
          visitorNameTemp = gameScore[i].VisitorName
        }else{
          let homeGame = {
            idSport: gameScore[i].IdSport,gameDate: gameDateAux.getHours() + ':' +
            gameDateAux.getMinutes() + ':' +
            gameDateAux.getSeconds(),
            name: gameScore[i].HomeName,
            gameScore: gameScore[i].GHomeScore,
            firstHalf: gameScore[i].HomeScore1H,
            secondHalf: gameScore[i].HomeScore2H,
            firstQ: gameScore[i].HomeScore1Q,
            secondQ: gameScore[i].HomeScore2Q,
            thirdQ: gameScore[i].HomeScore3Q,
            fourthQ: gameScore[i].HomeScore4Q,
            gradedDate: gameScore[i].GradedDate
          }
          aux.push(homeGame)
        }
      }
    }
    console.log("aux",aux)
    return aux
  }

  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <Table>
              <TableRow>
                <TableCell>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    className={classes.textField}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    defaultValue={endDate}
                    className={classes.textField}
                    onChange={handleChangeEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Button color="inherit" onClick={HistoryValues}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            </Table>
          </form>
          <BootstrapTable
            data={formatGameScore(gameScore)}
            trClassName={rowClassNameFormat}
            pagination
            striped
            tableStyle={{fontSize:10}}
            exportCSV
            hover
          >            
            <TableHeaderColumn 
              dataField="idSport"
              thStyle={{width:"4%"}}
              tdStyle={{width:"4%"}}
            >
              Sport
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="gameDate"
              thStyle={{width:"11%"}}
              tdStyle={{width:"11%"}}
            >
              Game Date
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="name"
              isKey={true}
            >
              Visitor 
              <br></br>
              Home
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="gameScore"
              thStyle={{width:"6%"}}
              tdStyle={{width:"6%"}}
            >
              Game
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="firstHalf"
              thStyle={{width:"6%"}}
              tdStyle={{width:"6%"}}
            >
              1H
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="secondHalf"
              thStyle={{width:"6%"}}
              tdStyle={{width:"6%"}}
            >
              2H
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="firstQ"
              thStyle={{width:"6%"}}
              tdStyle={{width:"6%"}}
            >
              1P
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="secondQ"
              thStyle={{width:"6%"}}
              tdStyle={{width:"6%"}}
            >
              2P
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="thirdQ"
              thStyle={{width:"6%"}}
              tdStyle={{width:"6%"}}
            >
              3P
            </TableHeaderColumn>
            <TableHeaderColumn 
              dataField="fourthQ"
              thStyle={{width:"6%"}}
              tdStyle={{width:"6%"}}
            >
              4P
            </TableHeaderColumn>     
          </BootstrapTable>
        </div>
      </div>
    </React.Fragment>
  );
}
