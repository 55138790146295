import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as moment from 'moment';
import InputSelect from '../../common/InputSelect';
/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LineTypeList from '../../lineTypes/lineTypesComponents/LineTypeList';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './ChangesByGame.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FinalStatsByAgent(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [gameScore, setGameScore] = React.useState([]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[0]);
  // let IdPeriod = '0';

  const HistoryValues = () => {
    setIsLoading(true);
    let request = {
      logIdUser: userInfo.UserId,
      StartDate: startDate,
      EndDate: endDate,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Reports/Report_Game_Started?logIdUser=' +
        userInfo.UserId +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate,
    }).then((response) => {
      console.log('response', response.data);
      setGameScore(response.data);
      setIsLoading(false);
    });
  };
  // const [selectedIdGame, setselectedIdGame] = useState();

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  function rowClassNameFormat(row, rowIdx) {
    // row is whole row object
    // rowIdx is index of row
    return rowIdx % 2 === 0
      ? 'tr-function-example'
      : 'td-column-function-odd-example';
  }

  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <Table>
              <TableRow>
                <TableCell>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    className={classes.textField}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    defaultValue={endDate}
                    className={classes.textField}
                    onChange={handleChangeEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Button color="inherit" onClick={HistoryValues}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            </Table>
          </form>
          <BootstrapTable
            data={gameScore}
            //   cellEdit={cellEditProp}
            trClassName={rowClassNameFormat}
            pagination
          >
            <TableHeaderColumn isKey={true} hidden dataField="IdGame">
              IdGame
            </TableHeaderColumn>
            <TableHeaderColumn dataField="IdSport">Sport</TableHeaderColumn>
            <TableHeaderColumn dataField="GameDateTime">
              Game Date Time
            </TableHeaderColumn>
            <TableHeaderColumn dataField="VisitorName">
              VisitorTeam
            </TableHeaderColumn>
            <TableHeaderColumn dataField="HomeName">
              Home Team
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PeriodDescription">
              Period
            </TableHeaderColumn>
            <TableHeaderColumn dataField="GradedDate">Graded</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </React.Fragment>
  );
}
