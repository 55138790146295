import React, {
  useState,
  useRef,
  setState,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import { makeStyles } from "@material-ui/core/styles";
import "./Schedule.css";
import { DelayInput } from "react-delay-input";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import AddBoxIcon from "@material-ui/icons/AddBox";
import axios from "axios";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GameModify from "../GameModify";
import ModifyBanner from "../ModifyBanner";
import GameStatus from "../GameStatus";
import CreateGame from "../CreateGame";
import CreateBanner from "../CreateBanner";
import DeleteGame from "../DeleteGame";
import ChartingInfo from "../ChartingInfo";
import GamePeriods from "../games/gameComponents/GamePeriod";
import HideGames from "../games/gameComponents/HideGames";
import CreateEvents from "../events/createEvents";
import GameSettingsByLt from "../games/gameComponents/GameSettingsByLt";
import DescriptionIcon from "@material-ui/icons/Description";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardEventHandler from "react-keyboard-event-handler";
import clsx from "clsx";
import {
  updateML,
  updateTO,
  updatePROPTNT,
  updateDraw,
  updateSP,
} from "../../api/SportsBookUtils";
import { useSelector } from "react-redux";
import { DataGrid } from '@material-ui/data-grid'
import Typography from "@material-ui/core/Typography";
import Box from '@mui/material/Box';
import { isNull } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  headerCell:{
    textAlign:'center !important'
  },
  headerCellAS:{
    textAlign:'center !important',    
  },
  cellWithGridLines:{
    borderBottom: '1px solid rgba(68, 67, 67, 0.75) !important',    
  },
  cellWithGridLinesEnd:{
    textAlign:'end !important',
    padding: '5px !important',
    borderBottom: '1px solid rgba(68, 67, 67, 0.75) !important'
  },
  cell: {
    "& .MuiDataGrid-cell ": {
      padding: 0,
      justifyContent:"center !important",   
       
    },"& .MuiDataGrid-cell MuiDataGrid-cell--withRenderer ": {
      backgroundColor:"red",  
      tabIndex: -1
    }
  },
  caption: {
    display: 'none',
  },
}));

const useStylesCheck = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 24,
    height: 24,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "white",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "1px auto rgba(19,124,189,.6)",
      outlineOffset: 1,
    },
    "input:hover ~ &": {
      backgroundColor: "#137cbd",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

export default function Schedule(props) {
  

  useEffect(() => {
    setTimeout(() => {
      const headerCells = document.querySelectorAll('MuiDataGrid-cell');
  
      if (headerCells && headerCells.length > 0) {
        headerCells.forEach(cell => {
          cell.tabIndex = "-1";
        });
      }
    }, 1000);
  }, []);

  
  
  

  const classes = useStyles();
  const classCheck = useStylesCheck();

  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;
  const lineManagerGames = useSelector((state) => state.lineManagerGames);
  const [selectedIdGame, setselectedIdGame] = useState();
  const [rotation, setRotation] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedPlay, setselectedPlay] = useState();
  const [selectedIdLeague, setselectedIdLeague] = useState();
  const [isModify, setIsModify] = useState(false);
  const [isModifyBanner, setIsModifyBanner] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isPeriod, setIsPeriod] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isCharting, setIsCharting] = useState(false);
  const [selectedGame, setSelectedGame] = useState();
  const [isStatus, setIsStatus] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isNewEvent, setIsNewEvent] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [newBanner, setNewBanner] = useState(false);
  const [parentGame, setParentGame] = useState();
  const [homeNumber, setHomeNumber] = useState();
  const [visitorNumber, setVisitorNumber] = useState();
  const [homeTeam, setHomeTeam] = useState();
  const [visitorTeam, setVisitorTeam] = useState();
  const [gameDateTime, setGameDateTime] = useState();
  const [recentUpdatedGame, setRecentUpdatedGame] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [MLVisitorAction, setMLVisitorAction] = useState("");
  const [MLVisitorCount, setMLVisitorCount] = useState("");
  const [MLHomeAction, setMLHomeAction] = useState("");
  const [MLHomeCount, setMLHomeCount] = useState("");
  const [valueHasChanged, setValueHasChanged] = useState(1);
  const [isChecked, setisChecked] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);

  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [tntPropAction, setTntPropAction] = useState([]);


  const setEventKey = function (key, e) {
    if (key === "alt+d") {
      if (isChecked) {
        setIsDelete(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+i") {
      if (isChecked) {
        setIsPeriod(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+m") {
      if (isChecked) {
        setIsModify(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+b") {
      if (isChecked) {
        setNewBanner(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+t") {
      if (isChecked) {
        setIsSettings(true);
      } else {
        alert("You must select a game");
      }
    } else if (key === "alt+n") {
      setIsNew(true);
    } else if (key === "alt+s") {
      if (isChecked) {
        setIsStatus(true);
      } else {
        alert("You must select a game");
      }
    }
  };


  useEffect(() => {
    setMLActions(props.selectedGame[0],props.IdLinetype);
  }, [props.selectedGame]);    

  useEffect(() => {    
    //getGameTNTPROPAction(); este acula
  }, []); 

   useEffect(() => {    
    //getGameTNTPROPAction(); este acula
  }, [props.filterStr]); 


  const getGameTNTPROPAction = async () => {
    const url = process.env.REACT_APP_API_URL + "/GAMETNTPROPACTION";
    await axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setTntPropAction(data.records);          
      });
  }
  
 

  const handleCloseModify = function () {
    setIsModify(false);
  };
  const handleCloseBannerModify = function () {
    setIsModifyBanner(false);
  };
  const handleCloseCharting = function () {
    setIsCharting(false);
  };
  const handleCloseHideGames = function () {
    setIsHidden(false);
  };
  const handleCloseIsNewEvent = function () {
    setIsNewEvent(false);
  };
  const handleCloseStatus = function () {
    setIsStatus(false);
  };
  const handleCloseCreate = function () {
    setIsNew(false);
  };
  const handleDelete = function () {
    setIsDelete(false);
  };
  const handleTemplates = function () {
    setIsTemplate(false);
  };
  const handlePeriods = function () {
    setIsPeriod(false);
  };
  const handleSettings = function () {
    setIsSettings(false);
  };
  const handleCloseNewBanner = function () {
    setNewBanner(false);
  };

  const pageNumbers = [];
  
  if (lineManagerGames) {
    for (
      let i = 1;
      i <= Math.ceil(lineManagerGames.length / rowsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  function handleClick(event, newPage) {
    if(newPage > -1){
      props.handleChangePageNumber(newPage);    
      setCurrentPage(newPage);
    }
    
  }

  const handleChange = (event) => {
    props.handleChangeRecordsPerPage(event.target.value);
    setRowsPerPage(event.target.value);
  };


  const getLastGameId = () => {
    let lastSystemChangedLinesId = JSON.parse(
      localStorage.getItem("lastSystemChangedLinesId")
    );
    return lastSystemChangedLinesId;
  };

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li
        key={number}
        id={number}
        style={
          number == currentPage
            ? {
                color: "green",
                display: "inline",
                paddingRight: 8,
                cursor: "pointer",
              }
            : { display: "inline", paddingRight: 8, cursor: "pointer" }
        }
        onClick={handleClick}
      >
        {number}
      </li>
    );
  });

  const handler = useCallback(
    ({ clientX, clientY }) => {
      // Update coordinates
      setCoords({ x: clientX, y: clientY });
    },
    [setCoords]
  );

  const handleClickCheckEvent = (
    event,
    name,
    idLeagueSelected,
    parentGame,
    homeNumber,
    visitorNumber,
    visitorOdds,
    game
  ) => {
    props.handleClickCheck(name, visitorOdds);
    setselectedIdLeague(idLeagueSelected);
    setParentGame(parentGame);
    setHomeNumber(homeNumber);
    setVisitorNumber(visitorNumber);
    setRotation(visitorOdds);
    setSelectedGame(game);
  };

  const isSelected = (row, name, name2) => {
    if (props.IdSport == "TNT") {
      if (
        props.selectedGame.length > 0 &&
        props.selectedGame.includes(row.IdGame) 
      ) {
        return true;
      } else {
        return false;
      }
    } else return props.selectedGame.indexOf(name) !== -1;
  };

  const useEventListener = (event) => {
    event.preventDefault();
  };

  const deleteBanner = (banner) => {
    setSelectedGame(banner.id);
    setIsDelete(true);
  };

  const handleContextMenu = useCallback((e) => {
  });

  document.addEventListener("contextmenu", handleContextMenu);
  const useEventListener1 = (eventName, handler, element = window) => {
    const savedHandler = useRef();

    useMemo(() => {
      savedHandler.current = handler;
    }, [handler]);

    useMemo(
      () => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        const eventListener = (event) => savedHandler.current(event);

        // Add event listener
        element.addEventListener(eventName, eventListener);

        // Remove event listener on cleanup
        return () => {
          element.removeEventListener(eventName, eventListener);
        };
      },
      [eventName, element] // Re-run if eventName or element changes
    );
  };

  function StyledCheckbox(props) {
    const classesCk = useStylesCheck();

    return (
      <Checkbox
        tabIndex={-1}
        className={classesCk.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classesCk.icon, classesCk.checkedIcon)} />
        }
        icon={<span className={classesCk.icon} />}
        inputProps={{ "aria-label": "decorative checkbox" }}
        {...props}
      />
    );
  }

  function setMLActions(idGame,idLineType) {   
    if(props.selectedGame && props.selectedGame.length > 0 ){
      axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL + "/LMGetWagersAction.php",
        data: {
          idGame : idGame,
          idLineType : idLineType
        },
      })
        .then((response) => response.data)
        .then((data) => {
          setMLHomeAction(data.data[4]);
          setMLVisitorAction(data.data[5]);
          console.log('data.data',data.data)
        })   
        
      axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL + "/LMGetWagersCount.php",
        data: {
          idGame : idGame,
          idLineType : idLineType
        },
      })
        .then((response) => response.data)
        .then((data) => {
          setMLHomeCount(data.data[4]);
          setMLVisitorCount(data.data[5]);
          console.log('data.data',data.data)
        })
    }
  }

  const getBannerSuffix = (row) => {
    let suffix = "";
    switch (row.Period) {
      case 1:
        suffix = " - First Half Lines ";
        break;
      case 2:
        suffix = " - Second Half Lines ";
        break;
      case 3:
        suffix = " - First Quarters ";
        break;
      case 4:
        suffix = " - Second Quarters ";
        break;
      case 5:
        suffix = " - Third Quarters ";
        break;
      case 6:
        suffix = " - Forth Quarters ";
        break;
      case 7:
        suffix = " - First Periods ";
        break;
      case 8:
        suffix = " - Second Periods ";
        break;
      case 9:
        suffix = " - Third Periods ";
        break;
      default:
        suffix = "";
        break;
    }
    return suffix;
  };

  const leagueDescriptionContainsPeriod = (row) => {
    const periodsWords = [
      "1ST HALVES",
      "2ND HALVES",
      "1H",
      "2H",
      "FIRST HALF LINES",
      "SECOND HALF LINES",
      "1ST HALF",
      "2ND HALF",
      "FIRST HALF",
      "SECOND HALF",
      "FIRST HALVE",
      "SECOND HALVE",
    ];

    let cont = 0;
    for (let i = 0; i < periodsWords.length; i++) {
      if (row.LeagueDescription.includes(periodsWords[i])) {
        cont++;
      }
    }
    return cont;
  };

  let currentCombination = [];

  const arethereMultiplePeriods = (row, games) => {
    if (currentCombination.length === 0) {
      for (let i = 0; i < games.length; i++) {
        if (
          games[i].Period === row.Period &&
          games[i].GameDateTime.date.substr(0, 10) ===
            row.GameDateTime.date.substr(0, 10) &&
          games[i].LeagueDescription === row.LeagueDescription &&
          leagueDescriptionContainsPeriod(row) === 0
        ) {
          currentCombination.push(row.Period);
          currentCombination.push(row.GameDateTime.date.substr(0, 10));
          currentCombination.push(row.LeagueDescription);
          return true;
        }
      }
    } else if (
      currentCombination[0] != row.Period ||
      currentCombination[1] != row.GameDateTime.date.substr(0, 10) ||
      currentCombination[2] != row.LeagueDescription
    ) {
      for (let i = 0; i < games.length; i++) {
        if (
          games[i].Period === row.Period &&
          games[i].GameDateTime.date.substr(0, 10) ===
            row.GameDateTime.date.substr(0, 10) &&
          games[i].LeagueDescription === row.LeagueDescription &&
          leagueDescriptionContainsPeriod(row) === 0
        ) {
          currentCombination.pop();
          currentCombination.pop();
          currentCombination.pop();
          currentCombination.push(row.Period);
          currentCombination.push(row.GameDateTime.date.substr(0, 10));
          currentCombination.push(row.LeagueDescription);
          return true;
        }
      }
    }
  };

  

  const colorToHexString = (dColor) => {
    return '#' + ("000000" + (((dColor & 0xFF) << 16) + (dColor & 0xFF00) + ((dColor >> 16) & 0xFF)).toString(16)).slice(-6);
  } 

  function calculateNotOpenGameColor (row){
    if(!row.id.toString().includes("B")){
      let bgColor=''; 
    
      switch (row.GameStat) {
        case "F":
          bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'F').BkColor) : "white" ;
          break;
  
        case "C":
          bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'C').BkColor) : "white";
          break;
  
        case "D":
          bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'D').BkColor) : "white";
          break;
  
        case "H":
          bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'H').BkColor) : "white";
          break;
  
        case "V":
          bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'V').BkColor) : "white";
          break;     
  
        case "B":
          bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'F').BkColor) : "white";
          break;
          
        default:
          bgColor = colorToHexString(row.GameStat);
          break;
        
      }
      return bgColor 
    }
  }

  function calculateBgColor (GameStat) {
    
    let bgColor=''; 
    
    switch (GameStat) {
      case "F":
        bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'F').BkColor) : "white" ;
        break;

      case "C":
        bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'C').BkColor) : "white";
        break;

      case "D":
        bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'D').BkColor) : "white";
        break;

      case "H":
        bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'H').BkColor) : "white";
        break;

      case "V":
        bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'V').BkColor) : "white";
        break;     

      case "B":
        bgColor = props.systemColors.length > 0 ? colorToHexString(props.systemColors.find(x => x.GameStat === 'F').BkColor) : "white";
        break;
        
      default:
        bgColor = colorToHexString(GameStat);
        break;
      
    }
    return bgColor
  };

  function getNextAlphanumeric(input) {
    console.log(input, "input")
    const lastChar = input[input.length - 1]
    let aux =""
    switch(lastChar) {
      case "a":
        aux = input.slice(0, -1) + "b"
        break;
      case "b":
        aux = input.slice(0, -1) + "c"
        break;
      case "c":
        aux = input.slice(0, -1) + "d"
        break;
      case "d":
        aux = input.slice(0, -1) + "e"
        break;
      case "e":
        aux = input.slice(0, -1) + "f"
        break;
      case "f":
        aux = input.slice(0, -1) + "g"
        break;
      case "g":
        aux = input.slice(0, -1) + "h"
        break;
      case "h":
        aux = input.slice(0, -1) + "i"
        break;
      case "i":
        aux = input.slice(0, -1) + "j"
        break;
      case "j":
        aux = (parseInt(input.slice(0, -1)) + 1) + "a"
        break;
    }
    return aux
  }

  function handleTabNavigation(event, api, rowId) {
    console.log("props.isLoading",props.isLoading)
    if (event.key === 'Tab' && !props.isLoading) {
      event.preventDefault();
      const nextRowId = getNextAlphanumeric(rowId); // Implement this function based on your logic
      const nextFocusElement = document.getElementById(nextRowId);
      if (nextFocusElement) {
        nextFocusElement.focus();
      }
    }
  }

  const drawMoneyLineVisitor = (params) => {
    const row = params.row
    if(!isBanner(row)){
      const valueML = (isMLBSOCNHL() || props.IdSport == 'MU') ? row.VisitorOdds : row.VisitorSpread
      return(
        <DelayInput
          style={{
            fontWeight: row.GameValues ? row.GameValues.BoldML ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className={` ${
            props.IdSport != "TNT" &&
            props.IdSport != "PROP"
              ? " schedule-text-box-input"
              : "schedule-text-box-input-tnt"
          } `}
          value={
            isMLBSOCNHL() || props.IdSport == 'MU' ? row.VisitorOdds : (row.VisitorSpread === 0 ? "PK" : row.VisitorSpread)
          }
          onBlur={(event) => {
            if(
              (event.target.value && (valueML === undefined || valueML === null)) || 
              (
                event.target.value && (valueML !== undefined && valueML !== null) && 
                (valueML && event.target.value !== valueML.toString()) 
              ) 
              // ||
              // (event.target.value === '' && (row.HomeOdds !== undefined && row.HomeOdds !== null))
            ){
              if(isMLBSOCNHL() || props.IdSport == 'MU'){
                updateML(
                  row.id,
                  row.OverOdds,
                  props.IdLinetype,
                  event.target.value,
                  props.IdSport,
                  row.VisitorNumber,
                  4,
                  8,
                  1,
                  0,
                  row.HomeNumber,
                  row.IdGameType,
                  8,
                  5,
                  valueHasChanged,
                  setRecentUpdatedGame,
                  setValueHasChanged,
                  props
                )
              }else{
                updateSP(
                  row.id,
                  event.target.value,
                  props.IdLinetype,
                  event.target.value,
                  props.IdSport,
                  row.VisitorNumber,
                  0,
                  8,
                  1,
                  0,
                  row.HomeNumber,
                  row.IdGameType,
                  9,
                  1,
                  2,
                  valueHasChanged,
                  setValueHasChanged,
                  setRecentUpdatedGame,
                  props
                );
              }
              
              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            } 
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };

  const drawMoneyLineHome = (params) => {
    const row = params.row
    if(!isBanner(row)){
      const valueML = (isMLBSOCNHL() || props.IdSport == 'MU') ? row.HomeOdds : row.HomeSpread
      return(
        <DelayInput
          style={{
            fontWeight: row.GameValues ? row.GameValues.BoldML ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className={` ${
            props.IdSport == "TNT" ||
            props.IdSport == "PROP"
              ? "schedule-text-box-input-tnt"
              : " schedule-text-box-input"
          } `}
          value={
            isMLBSOCNHL() || props.IdSport == 'MU' ? row.HomeOdds : (row.HomeSpread === 0 ? "PK" : row.HomeSpread)
          }
          onBlur={(event) => {
            if((event.target.value && (valueML === undefined || valueML === null)) || 
              (
                event.target.value && (valueML !== undefined && valueML !== null) && 
                (valueML && event.target.value !== valueML.toString()) 
              )
            // ||
            // (event.target.value === '' && (row.VisitorOdds !== undefined && row.VisitorOdds !== null))
            ){ 
              if (props.IdSport != "PROP") {
                if(isMLBSOCNHL() || props.IdSport == 'MU'){
                  updateML(
                    row.id,
                    row.OverOdds,
                    props.IdLinetype,
                    event.target.value,
                    props.IdSport,
                    row.VisitorNumber,
                    5,
                    8,
                    1,
                    0,
                    row.HomeNumber,
                    row.IdGameType,
                    8,
                    4,
                    valueHasChanged,
                    setRecentUpdatedGame,
                    setValueHasChanged,
                    props
                  )
              }else{
                updateSP(
                  row.id,
                  event.target.value,
                  props.IdLinetype,
                  event.target.value,
                  props.IdSport,
                  row.VisitorNumber,
                  0,
                  8,
                  1,
                  0,
                  row.HomeNumber,
                  row.IdGameType,
                  9,
                  1,
                  2,
                  valueHasChanged,
                  setValueHasChanged,
                  setRecentUpdatedGame,
                  props
                );
              }
              } else {           
                updatePROPTNT(
                  row.IdGame,//este si
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                  event.target.value,
                  row.HomeNumber,//este si
                  row.TeamName,//este si
                  props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].DetailResult,
                  props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].Graded,
                  valueHasChanged,
                  setValueHasChanged,
                  row.IdSport,
                  1,
                  4,
                  props.lineTypes
                );            
              }

              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
              }
            }  
          }
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };

  const drawMoneyLineDraw = (row) => {
    if(!isBanner(row) && props.IdSport == "SOC"){
      return(
        <DelayInput
          style={{                                   
            fontWeight: row.GameValues ? row.GameValues.BoldML ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={
            row.VisitorSpecialOdds == 0 ||
            row.VisitorSpecialOdds == null
              ? ""
              : row.VisitorSpecialOdds
          }
            onBlur={(event) => {
              if((event.target.value && (row.VisitorSpecialOdds === undefined || row.VisitorSpecialOdds === null)) || 
                (
                  event.target.value && (row.VisitorSpecialOdds !== undefined && row.VisitorSpecialOdds !== null) && 
                  (event.target.value !== row.VisitorSpecialOdds.toString())
                )
              ){  
                updateDraw(
                  row.id,
                  event.target.value,
                  props.IdLinetype,
                  valueHasChanged,
                  setValueHasChanged
                  );
                  props.announce(
                    row.id,
                    props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
                  );
                }
              }
            }
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }    
  };

  const drawActionFirst = (row) => {    
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
            textAlign: 'center !important'          
          }} 
          className={classes.headerCellAS}
        >
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(4);
              setIsCharting(true);
            }}
          >
              {isMLBSOCNHL() || props.IdSport == 'MU' ? 
              (
                row.GameAction ? calculateActionML(row.GameAction.VWin,row.GameAction.VRisk,row.GameAction.VCount):""
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.VSWin,
                      row.GameAction.VSRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.VSCount) || "")
                )
              )
            }
          </Link>
  
          <br></br>
          <br></br>
  
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(4); 
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() || props.IdSport == 'MU' ? 
              (
                row.GameAction ? calculateActionML(row.GameAction.HWin,row.GameAction.HRisk,row.GameAction.HCount) : ""
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.HSWin,
                      row.GameAction.HSRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.HSCount) || "")
                )
              )
            }
          </Link>
        </TableCell>
      )
    }    
  };

  const drawSumThird = (row) => {
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
          }}
          className={classes.headerCellAS}
        >
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() || props.IdSport == 'MU' ? 
              (
                row.GameAction ? calculateActionML(row.GameAction.SVSWin,row.GameAction.SVSRisk,row.GameAction.SVSCount) : ""
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.SVWin,
                      row.GameAction.SVRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.SVCount) || "")
                )
              )
            }            
          </Link>
          <br></br>
          <br></br>
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() || props.IdSport == 'MU' ? 
              (
                row.GameAction ? calculateActionML(row.GameAction.SHSWin,row.GameAction.SHSRisk,row.GameAction.SHSCount) : ""
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.SHWin,
                      row.GameAction.SHRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.SHCount) || "")
                )
              )
            } 
          </Link>
        </TableCell>
      )
    }    
  };

  const drawSpreadAction = (row) => {
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
          }}
          className={classes.headerCellAS}
        >
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            { 
              (row.GameAction && (
                parseInt(
                  getMayor(
                    row.GameAction.VSPWin,
                    row.GameAction.VSPRisk
                  )
                ) || "") +
                "-" +
                (parseInt(row.GameAction.VSPCount) || "")
              )
            }            
          </Link>
          <br></br>
          <br></br>
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {
              (row.GameAction && (
                parseInt(
                  getMayor(
                    row.GameAction.HSPWin,
                    row.GameAction.HSPRisk
                  )
                ) || "") +
                "-" +
                (parseInt(row.GameAction.HSPCount) || "")
              )
            } 
          </Link>
        </TableCell>
      )
    }    
  };

  const drawSpreadSum = (row) => {
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
          }}
          className={classes.headerCellAS}
        >
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            { 
              (row.GameAction && (
                parseInt(
                  getMayor(
                    row.GameAction.SVSPWin,
                    row.GameAction.SVSPRisk
                  )
                ) || "") +
                "-" +
                (parseInt(row.GameAction.SVSPCount) || "")
              )
            }            
          </Link>
          <br></br>
          <br></br>
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {
              (row.GameAction && (
                parseInt(
                  getMayor(
                    row.GameAction.SHSPWin,
                    row.GameAction.SHSPRisk
                  )
                ) || "") +
                "-" +
                (parseInt(row.GameAction.SHSPCount) || "")
              )
            } 
          </Link>
        </TableCell>
      )
    }    
  };

  const drawTotalOverMLB_SOC_NHL = (params) => {
    const row = params.row
    if(!isBanner(row)){
      return(
        <div> 
          <span>O</span>
          <DelayInput
            style={{         
              fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
              marginRight:'5%'
            }}
            minLength={1}
            type="number"
            delayTimeout={2000}
            className="schedule-text-box-input"
            value={
              row.TotalOver == 0 ||
              row.TotalOver == null
                ? ""
                : row.TotalOver
            }
            onBlur={(event) => {     
              if((event.target.value && (row.TotalOver === undefined || row.TotalOver === null)) || 
                (
                  event.target.value && (row.TotalOver !== undefined && row.TotalOver !== null) && 
                  (event.target.value !== row.TotalOver.toString())
                )
              ){  
                updateTO(
                  row.id,
                  row.OverOdds,
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                  event.target.value,
                  props.IdSport,
                  row.VisitorNumber,
                  2,
                  8,
                  1,
                  0,
                  row.HomeNumber,
                  row.IdGameType,
                  9,
                  3,
                  1,
                  valueHasChanged,
                  setValueHasChanged,
                  setRecentUpdatedGame,
                  props
                );
                props.announce(
                  row.IdGame,
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
                );
              }
            }}
            onChange={() => {
              setValueHasChanged(true);
            }}
          />  
        </div>   
      )
    }
  };

  const drawOverOddsMLB_SOC_NHL = (params) => {
    const row = params.row
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{                 
            fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={
            row.OverOdds == 0 || row.OverOdds == null
              ? ""
              : row.OverOdds
          }
          onBlur={(event) => {
            if((event.target.value && (row.OverOdds === undefined || row.OverOdds === null)) || 
              (
                event.target.value && (row.OverOdds !== undefined && row.OverOdds !== null) && 
                (event.target.value !== row.OverOdds.toString())
              )
            ){    
              updateTO(
                row.id,
                event.target.value,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                row.OverOdds,
                props.IdSport,
                row.VisitorNumber,
                2,
                9,
                0,
                1,
                row.HomeNumber,
                row.IdGameType,
                9,
                3,
                2,
                valueHasChanged,
                setValueHasChanged,
                setRecentUpdatedGame,
                props
              );
              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            }
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />  
      )
    }
  };

  const drawTotalUnderMLB_SOC_NHL = (params) => {
    const row = params.row
    if(!isBanner(row)){
      return(     
        <div >
          <span>U</span>   
          <DelayInput
            style={{                     
              fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
              marginRight:'5%'
            }}
            minLength={1}
            type="number"
            delayTimeout={2000}
            className={` ${
              row.GameStat != "TNT" &&
              row.GameStat != "PROP"
                ? " schedule-text-box-input"
                : "schedule-text-box-input-tnt"
            } `}
            value={
              row.TotalUnder == 0 ||
              row.TotalUnder == null
                ? ""
                : row.TotalUnder
              }
            onBlur={(event) => {
              {
                if((event.target.value && (row.TotalUnder === undefined || row.TotalUnder === null)) || 
                  (
                    event.target.value && (row.TotalUnder !== undefined && row.TotalUnder !== null) && 
                    (event.target.value !== row.TotalUnder.toString())
                  )
                ){  
                  props.IdSport != "TNT" &&
                  props.IdSport != "PROP"
                    ? updateTO(
                        row.id,
                        row.UnderOdds,
                        props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                        event.target.value,
                        props.IdSport,
                        row.HomeNumber,
                        3,
                        8,
                        1,
                        0,
                        row.VisitorNumber,
                        row.IdGameType,
                        9,
                        2,
                        1,
                        valueHasChanged,
                        setValueHasChanged,
                        setRecentUpdatedGame,
                        props
                      )
                    : updatePROPTNT(
                      row.IdGame,//este si
                      props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                      event.target.value,
                      row.HomeNumber,//este si
                      row.TeamName,//este si
                      props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].DetailResult,
                      props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].Graded,
                      valueHasChanged,
                      setValueHasChanged,
                      row.IdSport,
                      1,
                      4,
                      props.lineTypes
                    );
                      props.announce(
                        row.id,
                        props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
                      ); 
                } 
                
              }

              
            }}
            onChange={() => {
              setValueHasChanged(true);
            }}
          />  
        </div> 
      )
    }
  };

  const drawUnderOddsMLB_SOC_NHL = (params) => {
    const row = params.row
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{           
              fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
              marginRight:'5%'
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={
            row.UnderOdds == 0 ||
            row.UnderOdds == null
              ? ""
              : row.UnderOdds
          }
          onBlur={(event) => {
            if((event.target.value && (row.UnderOdds === undefined || row.UnderOdds === null)) || 
              (
                event.target.value && (row.UnderOdds !== undefined && row.UnderOdds !== null) && 
                (event.target.value !== row.UnderOdds.toString())
              )
            ){   
                updateTO(
                row.id,
                event.target.value,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                event.target.value,
                props.IdSport,
                row.HomeNumber,
                3,
                9,
                0,
                1,
                row.VisitorNumber,
                row.IdGameType,
                9,
                2,
                2,
                valueHasChanged,
                setValueHasChanged,
                setRecentUpdatedGame,
                props
              );
              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            }
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };

  const drawVisitorSpecial = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{           
            fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={row.VisitorSpecial}
          onBlur={(event) => {
              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };

  const drawHomeSpecial = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{           
              fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={row.HomeSpecial}
          onBlur={(event) => {
              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };

  const drawVisitorSpecialOdds = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{           
            fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={row.VisitorSpecialOdds}
          onBlur={(event) => {
              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };

  const drawHomeSpecialOdds = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{           
              fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={row.HomeSpecialOdds}
          onBlur={(event) => {
            //todo
            props.announce(
              row.id,
              props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
            );
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };

  const drawPropsOdds = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{           
              fontWeight: row.GameValues ? row.GameValues.BoldTotal ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input"
          value={row.Odds}
          onBlur={(event) => {
            props.IdLinetype === 55?
            (
              multipleUpdatePROPTNT(event.target.value,row)
            ):
            (
              updatePROPTNT(
                row.IdGame,//este si
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                event.target.value,
                row.HomeNumber,//este si
                row.TeamName,//este si
                props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].DetailResult,
                props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].Graded,
                valueHasChanged,
                setValueHasChanged,
                row.IdSport,
                1,
                4,
                props.lineTypes
              )                     
            )
            props.announce(
              row.id,
              props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
            );
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  };


  const drawActionSecond= (row) => {
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
            textAlign: 'center !important'          
          }} 
          className={classes.headerCellAS}
        >
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(2);
              setIsCharting(true);
            }}
          >
              {isMLBSOCNHL() ? 
                (
                  row.GameAction ? calculateActionML(row.GameAction.TOWin,row.GameAction.TORisk,row.GameAction.TOCount) : ""
                ):
                (
                  (row.GameAction && (
                    parseInt(
                      getMayor(
                        row.GameAction.TOWin,
                        row.GameAction.TORisk
                      )
                    ) || "") +
                    "-" +
                    (parseInt(row.GameAction.TOCount) || "")
                  )
                )
              }
          </Link>
  
          <br></br>
          <br></br>
  
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(2); 
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() ? 
              (
                row.GameAction ? calculateActionML(row.GameAction.TUWin,row.GameAction.TURisk,row.GameAction.TUCount) : ""
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.TUWin,
                      row.GameAction.TURisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.TUCount) || "")
                )
              )
            }
          </Link>
        </TableCell>
      )
    }    
  };

  const drawSumSecond = (row) => {
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
            textAlign: 'center !important'
          }}
          className={classes.headerCellAS}
        >
          <Link          
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(2);
              setIsCharting(true);
            }}
          >
            {row.GameAction ? calculateActionML(row.GameAction.STOWin,row.GameAction.STORisk,row.GameAction.STOCount) : ""}
          </Link>
  
          <br></br>
  
          <br></br>
  
          <Link          
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(2);
              setIsCharting(true);
            }}
          >
            {row.GameAction ? calculateActionML(row.GameAction.STUWin,row.GameAction.STURisk,row.GameAction.STUCount) : ""}
          </Link>
        </TableCell>
      )
    }    
  };

  const drawActionThird = (row) => {
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
            textAlign: 'center !important'          
          }} 
          className={classes.headerCellAS}
        >
          <Link          
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() || props.IdSport == 'MU'  ? 
              (
                row.GameAction ? calculateActionML(row.GameAction.VSWin,row.GameAction.VSRisk,row.GameAction.VSCount) : ""
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.VWin,
                      row.GameAction.VRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.VCount) || "")
                )
              )
            }  
          </Link>
          <br></br>
          <br></br>
          <Link          
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() || props.IdSport == 'MU'  ? 
              (
                row.GameAction ? calculateActionML(row.GameAction.HSWin,row.GameAction.HSRisk,row.GameAction.HSCount) : ""
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.HWin,
                      row.GameAction.HRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.HCount) || "")
                )
              )
            }
          </Link>
        </TableCell>
      )
    }    
  };

  const drawSumProps = (row) => {
    if(!isBanner(row)){
      return(
        <TableCell        
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
            textAlign: 'center !important'          
          }} 
          className={classes.headerCellAS}
        >
          <Link            
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {
              (row.GameAction && (
                parseInt(
                  getMayor(
                    row.GameAction.SWin,
                    row.GameAction.SRisk
                  )
                ) || "") +
                "-" +
                (parseInt(row.GameAction.SCount) || "")
              )
            }  
          </Link>          
        </TableCell>
      )
    }    
  };

  const drawActionProps = (row) => {
    if(!isBanner(row)){
      return(
        <TableCell        
          tabIndex={-1}   
          style={{
            backgroundColor: 'white',
            textAlign: 'center !important'          
          }} 
          className={classes.headerCellAS}
        >
          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setselectedPlay(0);
              setIsCharting(true);
            }}
          >
            {
              (row.GameAction && (
                parseInt(
                  getMayor(
                    row.GameAction.AWin,
                    row.GameAction.ARisk
                  )
                ) || "") +
                "-" +
                (parseInt(row.GameAction.ACount) || "")
              )
            }  
          </Link>          
        </TableCell>
      )
    }    
  };

  const drawSpreadOdds = (params) => {
    const row = params.row
    if(!isBanner(row)){
      return(
        <div
          style={{
            backgroundColor : row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width:'100%',
            height:'100%',
            lineHeight:'200%'
          }}
        >
          <DelayInput
            style={{                                                
              fontWeight: row.GameValues ? row.GameValues.BoldSpread ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
              marginRight:'5%'
            }}
            minLength={1}
            delayTimeout={2000}
            type="number"
            className="schedule-text-box-input"
            value={
              row.VisitorSpreadOdds == 0 ||
              row.VisitorSpreadOdds == null
                ? ""
                : row.VisitorSpreadOdds
            }
            onBlur={(event) => {
              if(
                (event.target.value && (row.VisitorSpreadOdds === undefined || row.VisitorSpreadOdds === null)) || 
                (
                  event.target.value && (row.VisitorSpreadOdds !== undefined && row.VisitorSpreadOdds !== null) && 
                  (row.VisitorSpreadOdds && event.target.value !== row.VisitorSpreadOdds.toString()) 
                )                
              ){  
                updateSP(
                  row.id,
                  event.target.value,
                  props.IdLinetype,
                  row.OverOdds,
                  props.IdSport,
                  row.VisitorNumber,
                  0,
                  8,
                  0,
                  1,
                  row.HomeNumber,
                  row.IdGameType,
                  9,
                  1,
                  2,
                  valueHasChanged,
                  setValueHasChanged,
                  setRecentUpdatedGame,
                  props
                );

                props.announce(
                  row.id,
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
                );
              }
            }}
            onChange={() => {
              setValueHasChanged(true);
            }}
          />
          <DelayInput
            style={{                                  
              fontWeight: row.GameValues ? row.GameValues.BoldSpread ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
              marginRight:'5%'
            }}
            minLength={1}
            delayTimeout={2000}
            type="number"
            className="schedule-text-box-input"
            value={
              row.HomeSpreadOdds == 0 ||
              row.HomeSpreadOdds == null
                ? ""
                : row.HomeSpreadOdds
            }
            onBlur={(event) => {
              if(
                (event.target.value && (row.HomeSpreadOdds === undefined || row.HomeSpreadOdds === null)) || 
                (
                  event.target.value && (row.HomeSpreadOdds !== undefined && row.HomeSpreadOdds !== null) && 
                  (row.HomeSpreadOdds && event.target.value !== row.HomeSpreadOdds.toString()) 
                )
              ){  
                  updateSP(
                  row.id,
                  event.target.value,
                  props.IdLinetype,
                  event.target.value,
                  props.IdSport,
                  row.HomeNumber,
                  1,
                  9,
                  0,
                  1,
                  row.VisitorNumber,
                  row.IdGameType,
                  8,
                  0,
                  2,
                  valueHasChanged,
                  setValueHasChanged,
                  setRecentUpdatedGame,
                  props
                );
                props.announce(
                  row.id,
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
                );
              }
            }}
            onChange={() => {
              setValueHasChanged(true);
            }}
          />
        </div>
      )
    }
  }

  const drawSpread = (params) => {
    const row = params.row
    if(!isBanner(row)){
      return(
        <div
          style={{
            backgroundColor : row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width:'100%',
            height:'100%',
            lineHeight:'200%'
          }}
        >
          <DelayInput
            style={{                       
              fontWeight: row.GameValues ? row.GameValues.BoldSpread ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
              marginRight:'5%'
            }}
            minLength={1}
            type={row.VisitorSpread === 0 ? "text" : "number"}
            delayTimeout={2000}
            className="schedule-text-box-input"
            value={
              row.VisitorSpread === null
                ? ""
                : row.VisitorSpread === 0 ? "PK" : row.VisitorSpread
            }
            onBlur={(event) => {
              if((event.target.value && (row.VisitorSpread === undefined || row.VisitorSpread === null)) || 
                (
                  event.target.value && (row.VisitorSpread !== undefined && row.VisitorSpread !== null) && 
                  (event.target.value !== row.VisitorSpread.toString())
                )
              ){  
                  updateSP(
                  row.id,
                  row.OverOdds,
                  props.IdLinetype,
                  event.target.value,
                  props.IdSport,
                  row.VisitorNumber,
                  0,
                  8,
                  1,
                  0,
                  row.HomeNumber,
                  row.IdGameType,
                  9,
                  1,
                  1,
                  valueHasChanged,
                  setValueHasChanged,
                  setRecentUpdatedGame,
                  props
                );
                props.announce(
                  row.id,
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
                );
              }
            }}
            onChange={() => {
              setValueHasChanged(true);
            }}
          /> 
          <DelayInput
            style={{                                        
              fontWeight: row.GameValues ? row.GameValues.BoldSpread ? "bold" : "normal" :"",
              backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row),
              marginRight:'5%'
            }}
            minLength={1}
            delayTimeout={2000}
            type={row.HomeSpread === 0 ? "text" : "number"}
            className="schedule-text-box-input"
            value={
              row.HomeSpread === null
                ? ""
                : row.HomeSpread === 0 ? "PK" : row.HomeSpread
            }
            onBlur={(event) => {
              
              if((event.target.value && (row.HomeSpread === undefined || row.HomeSpread === null)) || 
                (
                  event.target.value && (row.HomeSpread !== undefined && row.HomeSpread !== null) && 
                  (event.target.value !== row.HomeSpread.toString())
                )
              ){  
                  updateSP(
                  row.id,
                  row.UnderOdds,
                  props.IdLinetype,
                  event.target.value,
                  props.IdSport,
                  row.HomeNumber,
                  1,
                  9,
                  1,
                  0,
                  row.VisitorNumber,
                  row.IdGameType,
                  8,
                  0,
                  1,
                  valueHasChanged,
                  setValueHasChanged,
                  setRecentUpdatedGame,
                  props
                );
                props.announce(
                  row.id,
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
                );
              }
            }}
            onChange={() => {
              setValueHasChanged(true);
            }}
          />          
        </div>
      )
    }    
  }

  const multipleUpdatePROPTNT = (event,row) => {
    for(let i = 0 ; i < props.lineTypes.length ; i++){      
      updatePROPTNT(
        row.id,
        props.lineTypes[i].IdLineType,
        event,
        row.HomeNumber,//este si
        row.TeamName,//este si
        props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].DetailResult,
        props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].Graded,
        valueHasChanged,
        setValueHasChanged,
        row.IdSport,
        1,
        4,
        props.lineTypes
      )
      
    }
  }

  const calculateVisitorPitcherName = (row) => {
    if(row.VisitorPitcher === 'UNDECIDED' || isNull(row.VisitorPitcher)){
      return ""
    }else{
      return row.VisitorPitcher
    }
  }

  const calculateHomePitcherName = (row) => {
    if(row.HomePitcher === 'UNDECIDED' || isNull(row.HomePitcher)){
      return ""
    }else{
      return row.HomePitcher
    }
  }

  const drawVisitorOdds = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{                                        
            fontWeight: row.GameValues ? row.GameValues.BoldSpread ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className={` ${
            props.IdSport != "TNT" &&
            props.IdSport != "PROP"
              ? " schedule-text-box-input"
              : "schedule-text-box-input-tnt"
          } `}
          value={
            row.VisitorOdds == 0 ||
            row.VisitorOdds == null
              ? ""
              : row.VisitorOdds
          }
          onBlur={(event) => {
            if((event.target.value && (row.VisitorOdds === undefined || row.VisitorOdds === null)) || 
              (
                event.target.value && (row.VisitorOdds !== undefined && row.VisitorOdds !== null) && 
                (event.target.value !== row.VisitorOdds.toString())
              )
            ){  
              updateML(
                row.id,
                row.OverOdds,
                props.IdLinetype,
                event.target.value,
                props.IdSport,
                row.VisitorNumber,
                4,
                8,
                1,
                0,
                row.HomeNumber,
                row.IdGameType,
                8,
                5,
                valueHasChanged,
                setRecentUpdatedGame,
                setValueHasChanged,
                props
              );
              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            }
            
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      )
    }
  }  

  const drawHomeOdds = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{                                         
            fontWeight: row.GameValues ? row.GameValues.BoldSpread ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className={` ${
            props.IdSport != "TNT" &&
            props.IdSport != "PROP"
              ? " schedule-text-box-input"
              : "schedule-text-box-input-tnt"
          } `}
          value={
            props.IdSport === 'PROP' ? 
              tntPropAction.length > 0 && row.gameAction && tntPropAction.filter(x => x.IdLineType == props.IdLinetype && x.TeamNumber == row.gameAction.TeamNumber)[0].Odds
            :
              row.HomeOdds == 0 || row.HomeOdds == null
                ? ""
                : row.HomeOdds
          }
          onBlur={(event) => {
              if (props.IdSport != "PROP") {
                if((event.target.value && (row.HomeOdds === undefined || row.HomeOdds === null)) || 
                  (
                    event.target.value && (row.HomeOdds !== undefined && row.HomeOdds !== null) && 
                    (event.target.value !== row.HomeOdds.toString())
                  )
                ){  
                  updateML(
                    row.id,
                    row.OverOdds,
                    props.IdLinetype,
                    event.target.value,
                    props.IdSport,
                    row.VisitorNumber,
                    5,
                    8,
                    1,
                    0,
                    row.HomeNumber,
                    row.IdGameType,
                    8,
                    4,
                    valueHasChanged,
                    setRecentUpdatedGame,
                    setValueHasChanged,
                    props
                  );
                }
              } else {
                updatePROPTNT(
                  row.IdGame,//este si
                  props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
                  event.target.value,
                  row.TeamNumber,//este si
                  row.TeamName,//este si
                  props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].DetailResult,
                  props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].Graded,
                  valueHasChanged,
                  setValueHasChanged,
                  row.IdSport,
                  1,
                  4,
                  props.lineTypes
                );
              }

              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            
          }}
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      ) 
    }
  }  

  const drawSumFirst = (row,bgColor) => {
    if(!isBanner(row)){
      return(
        <TableCell
          tabIndex={-1}
          style={{
            backgroundColor: 'white',
          }}
          className={classes.headerCellAS}
        >
          <Link            
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() || props.IdSport == 'MU' ? 
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.SVWin,
                      row.GameAction.SVRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.SVCount) || "")
                )
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.SVSWin,
                      row.GameAction.SVSRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.SVSCount) || "")
                )
              )
            }
          </Link>

          <br></br>

          <br></br>

          <Link
            tabIndex={-1}
            component="button"
            variant="body2"
            onClick={() => {
              setHomeTeam(row.HomeTeam);
              setVisitorTeam(row.VisitorTeam);
              setselectedIdGame(row.id);
              setIsCharting(true);
            }}
          >
            {isMLBSOCNHL() || props.IdSport == 'MU' ? 
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.SHWin,
                      row.GameAction.SHRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.SHCount) || "")
                )
              ):
              (
                (row.GameAction && (
                  parseInt(
                    getMayor(
                      row.GameAction.HSWin,
                      row.GameAction.HSRisk
                    )
                  ) || "") +
                  "-" +
                  (parseInt(row.GameAction.HSCount) || "")
                )
              )
            }   
          </Link>
        </TableCell>
      )
    }
  }  

  const drawTNTOdds = (row) => {
    if(!isBanner(row)){
      return(
        <DelayInput
          style={{                                         
            fontWeight: row.GameValues ? row.GameValues.BoldSpread ? "bold" : "normal" :"",
            backgroundColor: row.GameStat == 'O' ? calculateOpenGameBC(row):calculateNotOpenGameColor(row)
          }}
          minLength={1}
          type="number"
          delayTimeout={2000}
          className="schedule-text-box-input-tnt"
          value={row.Odds}
          onBlur={(event) => {         
            updatePROPTNT(
              row.IdGame,//este si
              props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype,
              event.target.value,
              row.TeamNumber,//este si
              row.TeamName,//este si
              props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].DetailResult,
              props.schedulePagination.filter(item => item.IdGame === row.IdGame && item.HomeNumber === row.HomeNumber)[0].Graded,
              valueHasChanged,
              setValueHasChanged,
              row.IdSport,
              1,
              4,
              props.lineTypes
            );
            

              props.announce(
                row.id,
                props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype
              );
            }
          }
          onChange={() => {
            setValueHasChanged(true);
          }}
        />
      ) 
    }
  }

  const getMayor = (win, risk) => {
    if (Math.round(win) <= Math.round(risk)) {
      return win;
    } else {
      return risk;
    }
  };

  const calculateActionML = (win,risk,count) =>{
    if(win > 0 || risk > 0){
      return parseInt(getMayor(win,risk))+"-"+ count
    }else{
      return "-"
    }
  }

  function handleOnClickRow (e) {
    if(e.row && !e.row.id.toString().includes("B")){
      handleClickCheckEvent(
        e,
        props.IdSport.trim() !== 'TNT' ? e.row.id : e.row.IdGame,
        e.row.IdLeague,
        e.row.ParentGame,
        e.row.HomeNumber,
        e.row.VisitorNumber,
        e.row.VisitorOdds,
        e.row
      )
    }    
    
  }

  function handleOnDoubleClickRow (e) {
    if(e.row && e.row.GameStat == 'B'){
      setselectedIdGame(e.id)
      setIsModifyBanner(true)      
    }
  }

  function getTeamNames(params){
    if(!isBanner(params.row)){
      return (             
        props.IdSport == 'TNT' ?
        (
          <div
            style={{
              backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
              display:'flex',
              flexDirection:'row',
              justifyContent:'center',
              width:'100%',
              height:'100%',
              alignItems:'center'
            }}
          >        
            <Typography variant="subtitle2" >
              {params.row.TeamName}
            </Typography>                 
          </div>
        ):
        (
          <div 
            style={{
              backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              width:'100%',
              height:'100%'
            }}>
            <Typography variant="subtitle2" >
              {props.IdSport === 'MLB' ? params.row.VisitorTeam + " " + calculateVisitorPitcherName(params.row) : params.row.VisitorTeam}
            </Typography>
            <Typography variant="subtitle2" >
              {props.IdSport === 'MLB' ? params.row.HomeTeam + " " + calculateHomePitcherName(params.row) : params.row.HomeTeam}
            </Typography>
            {props.IdSport == 'SOC' ? 
              (
                <Typography variant="subtitle2">
                  DRAW
                </Typography>
              ):
              (
                ""
              )
            }        
          </div>
        )
      )
    }    
  }

  function getTeamRotations(params){
    if(!isBanner(params.row)){
      return (
        props.IdSport == 'TNT' || props.IdSport == 'PROP'?
        (
          <div style={{
            backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width:'100%',
            height:'100%'
            }}
          >        
            <Typography variant="subtitle2">
              {params.row.TeamNumber}
            </Typography>                 
          </div>
        ):
        (
        <div
          style={{
            backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width:'100%',
            height:'100%'
          }}
        >
          <Typography variant="subtitle2">
            {params.row.VisitorNumber}
          </Typography>
          <Typography variant="subtitle2">
            {params.row.HomeNumber }
          </Typography>
        </div>
        )
      )
    }
  }

  function getTeamMoneyLines(params){
    
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),       
          lineHeight:'200%'
        }}
      >
        {drawMoneyLineVisitor(params)}
        <br></br>
        {drawMoneyLineHome(params)}
        <br></br>
        {drawMoneyLineDraw(params)}
      </div>      
    )
       
  }

  function getActionTNT(params){
    return (
      <div>
        {drawActionProps(params.row)}
      </div>
    )
  }

  function getSumTNT(params){
    return (
      <div>
        {drawSumProps(params.row)}
      </div>
    )
  }

  function getTeamActionFirst(params){    
    return (
      <div>
        {drawActionFirst(params.row)}
      </div>       
    )      
  }

  function getTeamSumFirst(params){
    return (
      <div>
        {drawSumFirst(params.row)}
      </div>  
    )   
  }

  function getTotalUnderOversOdds(params){
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          width:'100%',
          lineHeight:'200%' ,         
        }}
        tabIndex={-1}
      >
        {drawTotalOverMLB_SOC_NHL(params)}
        {drawTotalUnderMLB_SOC_NHL(params)}
      </div>
    )
  }

  function getUnderOverOdds(params){
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          width:'100%',
          lineHeight:'200%'
        }}
      >
        {drawOverOddsMLB_SOC_NHL(params)}
        {drawUnderOddsMLB_SOC_NHL(params)}
      </div>
    )
  }

  function getSpreadOdds(params){
    return (      
      drawSpreadOdds(params)
    )
  }

  function getTeamActionSecond(params){
    return (
      <div>        
        {drawActionSecond(params.row)}       
      </div>
    )
  }

  function getTeamSumSecond(params){
    return (
      <div>
        {drawSumSecond(params.row)}
      </div>
    )
  }

  function getSpread(params){
    return (      
      drawSpread(params)
    )
  }

  function getOdds(params){
    return (
      props.IdSport == 'TNT' ?
      (
        <div
          style={{
            backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            width:'100%',
            height:'100%',
            lineHeight:'200%'
          }}
        >        
          {drawTNTOdds(params.row)}                 
        </div>
      ):
      (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          width:'100%',
          height:'100%',
          lineHeight:'200%',
          borderBottom:'1px solid white',
          alignItems: 'center'
        }}
      >
        {drawVisitorOdds(params.row)}
        {drawHomeOdds(params.row)}
      </div>
      )
    )
  }

  function getSpecial(params){
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          lineHeight:'200%'
        }}
      >
        {drawVisitorSpecial(params.row)}
        {drawHomeSpecial(params.row)}
      </div>
    )
  }

  function getSpecialOdds(params){
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          lineHeight:'200%'
        }}
      >
        {drawVisitorSpecialOdds(params.row)}
        {drawHomeSpecialOdds(params.row)}
      </div>
    )
  }
  

  function getTeamActionThird(params){
    return (
      <div>
        {drawActionThird(params.row)}        
      </div>
    )
  }

  function getTeamSumThird(params){
    return (
      <div>
        {drawSumThird(params.row)}        
      </div>
    )
  }

  function getSpreadSum(params){
    return (
      <div>
        {drawSpreadSum(params.row)}         
      </div>
    )
  }

  function getSpreadAction(params){
    return (
      <div>
        {drawSpreadAction(params.row)} 
      </div>
    )
  }

  function getEventDate(params){
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          lineHeight:'200%',
          width:'100%',
          height:'100%',
          justifyContent:'center'
        }}
      >
        <Typography variant="subtitle2">
          {params.row.FormattedEventDate}
        </Typography>
      </div>
    )
  }

  function getPropType(params){
    if(!isBanner(params.row)){
      return (
        <div
          style={{
            backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            width:'100%',
            height:'100%',
            alignItems:'center'
          }}
        >
          <Typography variant="subtitle2">
            {params.row.VisitorTeam}
          </Typography>
        </div>
      )
    }
  }

  function getPropDescription(params){
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'row',
          justifyContent:'center',
          width:'100%',
          height:'100%',
          alignItems:'center'
        }}
      >
        <Typography variant="subtitle2">
          {params.row.HomeTeam}
        </Typography>
      </div>
    )
  }

  function getPropOdds(params){
    return (
      <div
        style={{
          backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row):calculateNotOpenGameColor(params.row),
          display:'flex',
          flexDirection:'row',
          justifyContent:'center',
          width:'100%',
          height:'100%',
          alignItems:'center'
        }}
      >
        {drawPropsOdds(params.row)}
      </div>
    )
  }  

  function getDateTime (params){
    if(params.row.GameStat == "B"){
      return(
        <div>        
          <Typography variant="subtitle2">
            {params.row.VisitorTeam}
          </Typography>
          <Typography variant="subtitle2">
            {params.row.HomeTeam }
            <DeleteIcon
              onClick={() => deleteBanner(params.row)}
          />
          <EditIcon
            onClick={() => {
              setselectedIdGame(params.row.id);
              setIsModifyBanner(true);
            }}
          />
          </Typography>
          
        </div>
      )
    }else{
      return (      
        <div 
          style={{
            backgroundColor : params.row.GameStat == 'O' ? calculateOpenGameBC(params.row): calculateNotOpenGameColor(params.row),
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            width:'100%',
            height:'100%',
            alignItems:'center',
            paddingLeft: '0.1%'
            
          }}
        >
          {
            isBanner(params.row) ? "" : (
              <StyledCheckbox
                checked={
                  isSelected(params.row,
                  params.row.id,
                  params.row.VisitorTeam)
                }
              />
            )
          }
          <Typography variant="subtitle2" style={{color: params.row.DateChanged && !isBanner(params.row)? 'red' : ''}}>
            {isBanner(params.row) ? params.row.GameDateTime + " " + params.row.LeagueDescription: params.row.GameDateTime.substring(0,8)}
          </Typography>            
          <Typography variant="subtitle2" style={{color: params.row.TimeChanged && !isBanner(params.row)? 'red' : ''}}>
            {isBanner(params.row) ? "" : params.row.GameDateTime.substring(9,params.row.GameDateTime.length)}
          </Typography>
        </div>
      )
    }
  }

  function isBanner(row){
    if (row.id.toString().includes("B") || row.GameStat == 'B') 
      return true
    else 
      return false
  }

  function calculateOpenGameBC (row){
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();    
    today.setTime(today.getTime()+today.getTimezoneOffset()*60*1000)
    var offset = -300
    today = new Date(today.getTime() + offset*60*1000)
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    var dateTime = date + ' ' + time;
    if(new Date(row.DateTime.date) > new Date(dateTime)){                          
      if(props.systemColors.length > 0 && row.GameValues && row.GameValues.HideGame){
        return calculateBgColor('H')
      }else{
        return colorToHexString(row.GameType.BkColor)
      }
    }else{
      return calculateBgColor('V')
    }
  }


  function otherSportsColumns () {
    return(
      [
        { field: 'id', headerName: 'gameId', width: 100, editable: false, sortable: false, filterable: false, hide:true},
        { 
          field: 'GameDateTime', 
          headerName: 'Date', 
          width: 150, 
          editable: false, 
          sortable: false, 
          filterable: false,  
          headerAlign: 'center',
          renderCell: getDateTime,
          cellClassName: (params) => {
            if (isBanner(params.row)) {
              return 'BannerCell';
            }
          },
        },
        { field: 'RotationNumbers', align: 'center',headerAlign: 'center', headerName: 'Rotation', width: 150, editable: false, sortable: false, filterable: false,  renderCell: getTeamRotations},
        { field: 'TeamNames', headerName: 'Match', headerAlign: 'center', width: 250, editable: false, sortable: false, filterable: false,renderCell: getTeamNames},
        { field: 'Spread', headerName: 'Spread', align: 'right',headerAlign: 'center', width: 120, editable: false, sortable: false, filterable: false,renderCell: getTeamMoneyLines},
        { field: 'Spread Odds', headerName: 'Odds', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpreadOdds},
        { field: 'ActionFirst', headerName: 'Action', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionFirst},
        { field: 'SumFirst', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumFirst},
        { field: 'Totals', headerName: 'Totals', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTotalUnderOversOdds},
        { field: 'Odds', headerName: 'Odds', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getUnderOverOdds},
        { field: 'ActionSecond', headerName: 'Action ', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionSecond},
        { field: 'SumSecond', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumSecond},
        { field: 'MoneyLine', headerName: '$-Line', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getOdds},        
        { field: 'ActionThird', headerName: 'Action ', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionThird},
        { field: 'SumThird', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumThird},
      ]
    )
  }

  function mlbColumns () {
    return(
      [
        { field: 'id', headerName: 'gameId', width: 100, editable: false, sortable: false, filterable: false, hide:true},
        { 
          field: 'GameDateTime', 
          headerName: 'Date', 
          width: 150, 
          editable: false, 
          sortable: false, 
          filterable: false,
          headerAlign: 'center',
          renderCell: getDateTime,
          cellClassName: (params) => {
            if (isBanner(params.row)) {
              return 'BannerCell';
            }
          },
        },
        { field: 'RotationNumbers', align: 'center',headerAlign: 'center', headerName: 'Rotation', width: 150, editable: false, sortable: false, filterable: false,  renderCell: getTeamRotations},
        { field: 'TeamNames', headerName: 'Match', headerAlign: 'center', width: 250, editable: false, sortable: false, filterable: false,renderCell: getTeamNames},
        { field: 'MoneLine', headerName: 'Money Line',align: 'right', headerAlign: 'center', width: 120, editable: false, sortable: false, filterable: false,renderCell: getTeamMoneyLines},
        { field: 'ActionFirst', headerName: 'Action', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionFirst},
        { field: 'SumFirst', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumFirst},
        { field: 'Totals', headerName: 'Totals', align: 'right',headerAlign: 'center', width: 120, editable: false, sortable: false, filterable: false,renderCell:getTotalUnderOversOdds},
        { field: 'Odds', headerName: 'Odds', align: 'right',headerAlign: 'center', width: 120, editable: false, sortable: false, filterable: false, renderCell:getUnderOverOdds},
        { field: 'ActionSecond', headerName: 'Action ', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionSecond},
        { field: 'SumSecond', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumSecond},
        { field: 'RunLine', headerName: 'Runline', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpread},
        { field: 'RunLineOdds', headerName: 'Odds', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpreadOdds},
        { field: 'ActionThird', headerName: 'Action ', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionThird},
        { field: 'SumThird', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumThird},
      ]
    )
  }

  function socMuColumns () {
    return(
      [
        { field: 'id', headerName: 'gameId', width: 100, editable: false, sortable: false, filterable: false, hide:true},
        { 
          field: 'GameDateTime', 
          headerName: 'Date', 
          width: 150, 
          editable: false, 
          sortable: false, 
          filterable: false,
          headerAlign: 'center',
          renderCell: getDateTime,
          cellClassName: (params) => {
            if (isBanner(params.row)) {
              return 'BannerCell';
            }else{} 
          },
        },
        { field: 'RotationNumbers', align: 'center',headerAlign: 'center', headerName: 'Rotation', width: 150, editable: false, sortable: false, filterable: false,  renderCell: getTeamRotations},
        { field: 'TeamNames', headerName: 'Match', headerAlign: 'center', width: 250, editable: false, sortable: false, filterable: false,renderCell: getTeamNames},
        { field: 'Money Line', headerName: '$-Ln',align: 'right', headerAlign: 'center', width: 120, editable: false, sortable: false, filterable: false,renderCell: getTeamMoneyLines},
        { field: 'ActionFirst', headerName: 'Action', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionFirst},
        { field: 'SumFirst', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumFirst},
        { field: 'Total', headerName: 'Total', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTotalUnderOversOdds},
        { field: 'OddsFirst', headerName: 'Odds', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false,renderCell:getUnderOverOdds},
        { field: 'ActionSecond', headerName: 'Action', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionSecond},
        { field: 'SumSecond', headerName: 'Sum ', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumSecond},
        { field: 'Goals', headerName: 'Goals', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpread},
        { field: 'OddsSecond', headerName: 'Odds', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpreadOdds},        
        { field: 'ActionThird', headerName: 'Action ', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionThird},
        { field: 'SumThird', headerName: 'Sum', align: 'right',headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumThird},
      ]
    )
  }

  function tntPropsColumns () {
    return(
      [
        { field: 'id', headerName: 'gameId', width: 100, editable: false, sortable: false, filterable: false, hide:true},
        { 
          field: 'GameDateTime', 
          headerName: 'Cutt Off', 
          width: 150, 
          editable: false, 
          sortable: false, 
          filterable: false,     
          headerAlign: 'center',     
          renderCell: getDateTime,
          cellClassName: (params) => {
            if (isBanner(params.row)) {
              return 'BannerCell';
            }else{} 
          },
        },
        { field: 'RotationNumbers', align: 'center',align: 'right',headerAlign: 'center', headerName: 'Rotation', width: 150, editable: false, sortable: false, filterable: false,  renderCell: getTeamRotations},
        { field: 'TeamNames', headerName: props.IdSport == 'TNT' ? 'Description' : 'Prop Type', headerAlign: 'center', width: props.IdSport == 'TNT' ? 350 : 350, editable: false, sortable: false, filterable: false,renderCell: props.IdSport == 'TNT' ? getTeamNames : getPropType},
        { field: 'Home',headerName: props.IdSport == 'TNT' ? 'Odds' : 'Description', headerAlign: 'center', width: props.IdSport == 'TNT' ? 120 : 350, editable: false, sortable: false, filterable: false,renderCell: props.IdSport == 'TNT' ? getOdds : getPropDescription},
        { field: 'ActionFirst', align: 'right',headerName: props.IdSport == 'TNT' ? 'Action' : 'Odds', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell: props.IdSport == 'TNT' ? getActionTNT : getPropOdds},
        { field: 'SumFirst', align: 'right',headerName: props.IdSport == 'TNT' ? 'Sum' : 'Action', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:props.IdSport == 'TNT' ? getSumTNT : getActionTNT},
        { field: 'EventDate', align: 'right',headerName: props.IdSport == 'TNT' ? 'Event Date' : 'Sum', headerAlign: 'center', width: 150, editable: false, sortable: false, filterable: false,renderCell:props.IdSport == 'TNT' ? getEventDate : getSumTNT},
      ]
    )
  }

  function nhlColumns () {
    return(
      [
        { field: 'id', headerName: 'gameId', width: 100, editable: false, sortable: false, filterable: false, hide: true},
        { 
          field: 'GameDateTime', 
          headerName: 'Date', 
          width: 150, 
          editable: false, 
          sortable: false, 
          filterable: false,
          headerAlign: 'center',
          renderCell: getDateTime,
          cellClassName: (params) => {
            if (isBanner(params.row)) {
              return 'BannerCell';
            }
          },
        
        },
        { field: 'RotationNumbers', align: 'center',headerAlign: 'center', headerName: 'Rotation', width: 150, editable: false, sortable: false, filterable: false,  renderCell: getTeamRotations},
        { field: 'TeamNames', headerName: 'Match', headerAlign: 'center', width: 250, editable: false, sortable: false, filterable: false,renderCell: getTeamNames},
        { field: 'Money Line', headerName: '$-Ln', align: 'right', headerAlign: 'center', width: 120, editable: false, sortable: false, filterable: false,renderCell: getTeamMoneyLines},
        { field: 'ActionFirst', headerName: 'Action', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionFirst},
        { field: 'SumFirst', headerName: 'Sum', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumFirst},
        { field: 'Total', headerName: 'Total', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTotalUnderOversOdds},
        { field: 'Odds', headerName: 'Odds', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false,renderCell:getUnderOverOdds},
        { field: 'ActionSecond', headerName: 'Action', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamActionSecond},
        { field: 'SumSecond', headerName: 'Sum ', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getTeamSumSecond},
        { field: 'CanadianLine', headerName: 'C-L', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpecial},
        { field: 'OddsSecond', headerName: 'Odds', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpecialOdds},        
        { field: 'ActionThird', headerName: 'Action ', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpreadAction},
        { field: 'SumThird', headerName: 'Sum', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpreadSum},
        { field: 'AmericanLines', headerName: 'A-L', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpread},
        { field: 'OddsThird', headerName: 'Odds', align: 'right', headerAlign: 'center', width: 100, editable: false, sortable: false, filterable: false, renderCell:getSpreadOdds}
      ]
    )
  }

  const calculateColumns = () => {
    

    switch (props.IdSport) {
      case 'MLB':
          return mlbColumns();
      case 'TNT':
          return tntPropsColumns();
      case 'PROP':
          return tntPropsColumns();
      case 'SOC':
        return socMuColumns();
      case 'MU':
        return socMuColumns();
      case 'NHL':
        return nhlColumns();
      default:
        return otherSportsColumns(); 
    } 
  }

  const tablePagination = () => {
    return(
      <TablePagination
        rowsPerPageOptions={[10,25,75,100]}
        component="div"
        paginationMode="server"
        rowsPerPage={rowsPerPage}
        page={currentPage}
        classes={{ caption: classes.caption }}
        onChangePage={handleClick}
        onChangeRowsPerPage={handleChange}                  
      /> 
    )
  }

  const isMLBSOCNHL = () => {
    let isMLBSOCNHL = false;
    if (props.IdSport === 'MLB'  || props.IdSport === 'SOC' || props.IdSport === 'NHL')
      isMLBSOCNHL = true;
    return isMLBSOCNHL;
  }  

  const columns = calculateColumns();
  
  return (
   
    <React.Fragment >
      <KeyboardEventHandler
        handleKeys={[
          "alt+d",
          "alt+i",
          "alt+m",
          "alt+b",
          "alt+t",
          "alt+s",
          "alt+n",
        ]}
        onKeyEvent={(key, e) => setEventKey(key, e)}
      />
      <div className="schedule">
        {isNew ? (
          <CreateGame
            IdLinetype={props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype}
            IdSport={props.IdSport}
            onClose={handleCloseCreate}
            searchGameValues={props.searchGameValues}
          />
        ) : (
          ""
        )}
        <AddBoxIcon
          aria-label="newGame"
          size="small"
          className={classes.fab}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsNew(true);
          }}
        />{" "}
        Add Games
        {props.selectedGame.length > 0
          ? console.log("props.selectedGame", props.selectedGame)
          : ""}
        {props.selectedGame && props.selectedGame.length > 0 ? (
          <div
            style={{
              position: "fixed",
              top: 100,
              left: 40,
              padding: 10,
              background: "white",
              zIndex: 1
            }}
          >
            <Tooltip title="Delete Game" aria-label="Delete Game">
              <DeleteIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  //setselectedIdGame(row.IdGame);
                  setIsDelete(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Game Periods" aria-label="Game Periods">
              <AssignmentIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setIsPeriod(true);
                }}
              />
            </Tooltip>{" "}
            <Tooltip title="Templates" aria-label="Templates">
              <AssignmentIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setIsTemplate(true);
                }}
              />
            </Tooltip>{" "}
            <Tooltip title="Add Banner" aria-label="Add Banner">
              <AddBoxIcon
                aria-label="newGame"
                size="small"
                // className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setNewBanner(true);
                }}
              />
            </Tooltip>{" "}
            <Tooltip title="Game Status" aria-label="Game Status">
              <DescriptionIcon
                aria-label="description"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setIsStatus(true);
                }}
              />
            </Tooltip>{" "}
            <Tooltip title="Modify Game" aria-label="Modify Game">
              <EditIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  setIsModify(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Settings Game" aria-label="Settings Game">
              <SettingsIcon
                aria-label="edit"
                size="small"
                className={classes.fab}
                style={{
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  //setselectedIdGame(row.IdGame);
                  setIsSettings(true);
                }}
              />
            </Tooltip>
            {props.selectedGame.length === 1 ? (
              <React.Fragment>
                <Tooltip title="Settings Game" aria-label="Settings Game">
                  <VisibilityOffIcon
                    aria-label="edit"
                    size="small"
                    className={classes.fab}
                    style={{
                      cursor: "pointer",
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={() => {
                      //setselectedIdGame(row.IdGame);
                      setIsHidden(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Add Event" aria-label="Add Event">
                  <VisibilityOffIcon
                    aria-label="edit"
                    size="small"
                    className={classes.fab}
                    style={{
                      cursor: "pointer",
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={() => {
                      //setselectedIdGame(row.IdGame);
                      setIsNewEvent(true);
                    }}
                  />
                </Tooltip>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div>          
          <Box
            sx={{
              height: '100vh',
              width: '100vw',
              
              '& .Banner': {
                backgroundColor: calculateBgColor('B'),
                color: 'white',
                borderBottom: '0.5px solid white',
                borderTop: '0.5px solid white',
                paddingLeft: '0.3%'
              },                
              '& .Banner:hover': {
                backgroundColor: calculateBgColor('B')
              },
              '& .BannerCell': {
                display:'inline-table'
              },
            }}
          >
            {isStatus ? (
              <GameStatus
                IdGames={props.selectedGame}
                IdLinetype={props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype}
                IdSport={props.IdSport}
                rotation={props.selectedGame[0]}
                onClose={handleCloseStatus}
                searchGameValues={props.searchGameValues}
                clearArray={props.ClearArrar}
                IdGameTNT={props.IdGameTNT}
              />
            ) : (
              ""
            )}

            {isDelete ? (
              <DeleteGame
                IdGames={props.selectedGame}
                clearArray={props.ClearArrar}
                searchGameValues={props.searchGameValues}
                onClose={handleDelete}
              />
            ) : (
              ""
            )}

            {isSettings ? (
              <GameSettingsByLt
                IdGame={props.selectedGame}
                IdLinetype={props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype}
                clearArray={props.ClearArrar}
                onClose={handleSettings}
                searchGameValues={props.searchGameValues}
                lineTypes={props.lineTypes}
              />
            ) : (
              ""
            )}

            {isNewEvent ? (
              <CreateEvents
                IdGame={props.selectedGame}
                onClose={handleCloseIsNewEvent}
                IdSport={props.IdSport}
                IdLeague={selectedIdLeague}
                GameDateTime={gameDateTime}
                HomeTeam={homeTeam}
                VisitorTeam={visitorTeam}
                HomeNumber={homeNumber}
                VisitorNumber={visitorNumber}
              />
            ) : (
              ""
            )}

            {newBanner && props.selectedGame ? (
              <CreateBanner
                IdGames={props.selectedGame}
                IdSport={props.IdSport}
                IdLeague={selectedIdLeague}
                GameDateTime={gameDateTime}
                HomeNumber={homeNumber}
                VisitorNumber={visitorNumber}
                onClose={handleCloseNewBanner}
                searchGameValues={props.searchGameValues}
                clearArray={props.ClearArrar}
                selectedGame={selectedGame}
              />
            ) : (
              ""
            )}

            {isModifyBanner ? (
              <ModifyBanner
                IdGame={selectedIdGame}
                IdLinetype={props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype}
                IdSport={props.IdSport}
                onClose={handleCloseBannerModify}
                searchGameValues={props.searchGameValues}
              />
            ) : (
              ""
            )}

            {isHidden ? (
              <HideGames
                IdGame={props.selectedGame}
                onClose={handleCloseHideGames}
                IdSport={props.IdSport}
              />
            ) : (
              ""
            )}

            {isPeriod ? (
              <GamePeriods
                IdSport={props.IdSport}
                IdGames={props.selectedGame}
                clearArray={props.ClearArrar}
                onClose={handlePeriods}
                searchGameValues={props.searchGameValues}
              />
            ) : (
              ""
            )}

            {isCharting ? (
              <ChartingInfo
                IdGame={selectedIdGame}
                IdLinetype={props.IdLinetype.value ? props.IdLinetype.value : props.IdLinetype}
                IdSport={props.IdSport}
                HomeTeam={homeTeam}
                VisitorTeam={visitorTeam}
                ActionType={0}
                Play={selectedPlay}
                onClose={handleCloseCharting}
                Action={props.action}
              />
            ) : (
              ""
            )}

            {isModify &&
            props.IdSport &&
            props.IdSport == "TNT" &&
            visitorNumber &&
            props.selectedGame &&
            rotation &&
            props.IdLinetype ? (
              <GameModify
                IdGames={props.selectedGame}
                IdLinetype={props.IdLinetype}
                IdSport={props.IdSport}
                onClose={handleCloseModify}
                searchGameValues={props.searchGameValues}
                updateML={updateML}
                clearArray={props.ClearArrar}
                rotation={rotation}
                MLVisitorAction={MLVisitorAction}
                MLVisitorCount={MLVisitorCount}
                MLHomeAction={MLHomeAction}
                MLHomeCount={MLHomeCount}
                setLastChangedGamedID={props.setLastChangedGamedID}
                updatePROPTNT={updatePROPTNT}
              />
            ) : (
              isModify &&
              props.IdSport && (
                <GameModify
                  IdGames={props.selectedGame}
                  IdLinetype={props.IdLinetype}
                  IdSport={props.IdSport}
                  onClose={handleCloseModify}
                  searchGameValues={props.searchGameValues}
                  updateML={updateML}
                  clearArray={props.ClearArrar}
                  MLVisitorAction={MLVisitorAction}
                  MLVisitorCount={MLVisitorCount}
                  MLHomeAction={MLHomeAction}
                  MLHomeCount={MLHomeCount}
                  setLastChangedGamedID={()=>{}}
                />
              )
            )}

            {tablePagination()} 

            <DataGrid
              rows={props.schedulePagination.length > 0 ? props.schedulePagination : []}
              rowHeight={props.IdSport == 'SOC' ? 90 : 65}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[5]}            
              disableColumnSelector
              disableColumnMenu
              onSelectionModelChange={()=>{}}                                    
              disableMultipleSelection={true}
              hideFooterPagination={true}
              getRowClassName={(params) => {
                if (isBanner(params.row)) {
                  return 'Banner';
                }else{    
                  if(params.row.GameStat == 'F'){
                    return 'Finished'
                  }
                  if(params.row.GameStat == 'C'){
                    return 'Cancelled'
                  }
                  if(params.row.GameStat == 'D'){
                    return 'OffBoard'
                  }                                      
                }
              }}
              onRowClick={handleOnClickRow} 
              onRowDoubleClick={handleOnDoubleClickRow}  
              className={classes.cell}    
              autoHeight    
              paginationMode="server"                 
            />

            {tablePagination()}
            
          </Box>
         
        </div>
        {/* {props && lineManagerGames && (
          <div>
            <TablePagination
              rowsPerPageOptions={[50, 75, 125, , 250, 500]}
              component="div"
              count={props.scheduleCount}//aca iria el fetcheo del count total
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleClick}//aqui deberia fetchear con el offset actualizado
              onChangeRowsPerPage={handleChange}//aqui basicamente lo mismo
              // rows per page * page should be the offsett
            />
          </div>
        )} */}
      </div>
    </React.Fragment>
  
  );
}
