import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { DelayInput } from 'react-delay-input';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

export default function GameModify(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [flag, setFlag] = useState(false);
  const [idAction, setIdAction] = useState(0);
  const [pitcherSide, setPitcherSide] = useState('');
  const [open, setOpen] = React.useState(true);
  const [pitchers, setPitchers] = React.useState([]);
  const [idSport, setIdSport] = React.useState('');
  const [idLeague, setIdLeague] = React.useState(0);
  const [visitorScore, setVisitorScore] = React.useState(0);
  const [homeScore, setHomeScore] = React.useState(0);
  const [visitorPitcher, setVisitorPitcher] = React.useState('');
  const [visitorPitcherOld, setVisitorPitcherOld] = React.useState('');
  const [homePitcherOld, setHomePitcherOld] = React.useState('');
  const [homePitcher, setHomePitcher] = React.useState('');
  const [gameStat, setGameStat] = React.useState('');
  const [graded, setGraded] = React.useState(false);
  const [gameType, setGameType] = React.useState(0);
  const [hookups, setHookUps] = React.useState(false);
  const [local, setLocal] = React.useState(false);
  const [online, setOnline] = React.useState(false);
  const [shortGame, setShortGame] = React.useState(false);
  const [eventDate, setEventDate] = React.useState('');
  const [dateChanged, setDateChanged] = React.useState(false);
  const [timeChanged, setTimeChanged] = React.useState(false);
  const [pitcherChanged, setPitcherChanged] = React.useState(0);
  const [period, setPeriod] = React.useState(0);
  const [gradedDate, setGradedDate] = React.useState('');
  const [numTeams, setNumTeams] = React.useState(0);
  const [idEvent, setIdEvent] = React.useState(0);
  const [hasChildren, setHasChildren] = React.useState(false);
  const [idTeamVisitor, setIdTeamVisitor] = React.useState(0);
  const [idTeamHome, setIdTeamHome] = React.useState(0);
  const [idBannerType, setIdBannerType] = React.useState(0);
  const [gameDateTime, setGameDateTime] = React.useState('');
  const [league, setLeague] = React.useState([]);
  const [leagueID, setLeagueID] = React.useState(0);
  const [typeID, setTypeID] = React.useState(0);
  const [type, setType] = React.useState([]);
  const [familyGame, setFamilyGame] = React.useState(0);
  const [parentGame, setParentGame] = React.useState(0);
  const [confirm, setConfirm] = React.useState('');
  const [visitorNumber, setVisitorNumber] = React.useState(0);
  const [visitorTeam, setVisitorTeam] = React.useState('');
  const [homeNumber, setHomeNumber] = React.useState(0);
  const [homeTeam, setHomeTeam] = React.useState('');
  const [visitorSpread, setVisitorSpread] = React.useState(0);
  const [visitorSpreadOdds, setVisitorSpreadOdds] = React.useState(0);
  const [overTotal, setOverTotal] = React.useState(0);
  const [overOdds, setOverOdds] = React.useState(0);
  const [visitorMoneyline, setVisitorMoneyline] = React.useState(0);
  const [homeSpread, setHomeSpread] = React.useState(0);
  const [homeSpreadOdds, setHomeSpreadOdds] = React.useState(0);
  const [underTotal, setUnderTotal] = React.useState(0);
  const [underOdds, setUnderOdds] = React.useState(0);
  const [homeMoneyline, setHomeMoneyline] = React.useState(0);
  const [idGame, setIdGame] = React.useState(0);
  const [sportType, setSportType] = React.useState(0);
  const [normalGame, setNormalGame] = React.useState(0);
  const [idGameType, setIdGameType] = React.useState(0);
  const [gameStatus, setGameStatus] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [acceptAutoChanges, setAcceptAutoChanges] = React.useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [result, setResult] = React.useState(0);
  const [valueHasChanged, setValueHasChanged] = useState(1);

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };
  const handleChangeConfirm = (event) => {
    setConfirm(event.target.value);
  };

  const handleOk = () => {
    if (confirm.toUpperCase() == 'YES') {
      props.postChange();
    } else {
      alert('Type YES');
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Pitching Change Warning!
            </Typography>
          </Toolbar>
        </AppBar>
        {loading ? (
          <CircularProgress style={{ margin: '0 auto' }} />
        ) : (
          <React.Fragment>
            <br />
            <form className={classes.center}>
              <FormGroup row></FormGroup>
              <Divider />

              <p>
                A pitching chabge has been detected. All over/under and runline
                wagers on this game will be "no action". All money-line wagers
                on this game will either be adjusted to the new odds shown above
                or be "no action" depending on the type of action. To continue,
                type the word YES in the box below and choose "I Agree".
                Otherwise, choose "I disagree "
              </p>
              <FormGroup row>
                <TextField
                  type="text"
                  label="Confirm"
                  value={confirm}
                  multiline={true}
                  onChange={handleChangeConfirm}
                  variant="outlined"
                />
              </FormGroup>
              <Divider />
            </form>
          </React.Fragment>
        )}
        <DialogActions>
          <Button
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
          >
            I disagree
          </Button>
          <Button startIcon={<SaveIcon />} color="inherit" onClick={handleOk}>
            I Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
