import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { UserContext } from '../contexts/UserContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CreateBanner(props) {
  const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  var today = new Date();
  var date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  var time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  var dateTime = date + ' ' + time;
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [visitorPitcher, setVisitorPitcher] = React.useState('');
  const [homePitcher, setHomePitcher] = React.useState('');
  const [gameType, setGameType] = React.useState(0);
  const [shortGame, setShortGame] = React.useState(false);
  const [dateChanged, setDateChanged] = React.useState(false);
  const [timeChanged, setTimeChanged] = React.useState(false);
  const [pitcherChanged, setPitcherChanged] = React.useState(0);
  const [period, setPeriod] = React.useState(0);
  const [idEvent, setIdEvent] = React.useState(0);
  const [idBannerType, setIdBannerType] = React.useState(0);
  const [league, setLeague] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [visitorTeam, setVisitorTeam] = React.useState('');
  const [homeTeam, setHomeTeam] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [withCapsOn, setWithCapsOn] = useState(true);
  const [permanentFlag, setPermanentFlag] = useState(false);

  const [result, setResult] = React.useState(0);

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };


  const handleChangeVisitorTeam = (event) => {
    setVisitorTeam(event.target.value);
  };


  const handleChangeHomeTeam = (event) => {
    setHomeTeam(event.target.value);
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };
  const handleOk = async () => {
    let responseData = 0;
    for (let i = 0; i < props.IdGames.length; i++) {
      let gameRequest = {
        IdSport: props.IdSport,
        IdLeague: props.IdLeague,
        GameDateTime: props.selectedGame.DateTime.date.replace('T',' '),
        VisitorNumber: props.VisitorNumber,
        HomeNumber: props.HomeNumber,
        VisitorTeam: withCapsOn ? visitorTeam.toUpperCase() : visitorTeam,
        HomeTeam: withCapsOn ? homeTeam.toUpperCase() : homeTeam,
        VisitorScore: -1,
        HomeScore: -1,
        VisitorPitcher: visitorPitcher,
        HomePitcher: homePitcher,
        NormalGame: 0,
        GameStat: 'B',
        Graded: 0,
        IdGameType: 1,
        Hookups: 0,
        Local: 1,
        Online: 1,
        ShortGame: shortGame,
        EventDate: props.selectedGame.DateTime.date.replace('T',' '),
        DateChanged: dateChanged,
        TimeChanged: timeChanged,
        PitcherChanged: pitcherChanged,
        Period: period,
        ParentGame: props.IdGames[i],
        GradedDate: dateTime,
        NumTeams: 2,
        IdEvent: idEvent,
        FamilyGame: props.IdGames[i],
        HasChildren: 0,
        IdTeamVisitor: -1,
        IdTeamHome: -1,
        IdBannerType: idBannerType,
        Description: description,
        AcceptAutoChanges: 1,
        IdUser: 498,
        Result: result,
      }; 
      await axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/Game/InsertGame',
        data: gameRequest,
      }).then((response) => {
        console.log('response addbanner', response);
        responseData = response.data;
        if(permanentFlag)
          modifyBanner(response.data) 
      });
    }
    if (responseData != 0) {
      props.clearArray();
      props.searchGameValues();
      handleClose();
    }
  };

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + '/GAMETYPE';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => { 
        setType(data.records);
      })
      .then(setLoading(false));
  }, [gameType]);

  useEffect(() => {    
    const url =
      process.env.REACT_APP_API_URL +
      '/LEAGUE?filter=IdSport,eq,' +
      props.IdSport;
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => { 
        setLeague(data.records);
      })
      .then(setLoading(false));
  }, []);

  const modifyBanner = (idGame) => {
    let banner = {
      NormalGame: 2,      
    }
    axios({
        method: 'put',
        url: process.env.REACT_APP_API_URL + "/GAME/"+idGame,
        data: banner,
    }).then((response) => {
        console.log("Response",response);
    }).catch((error) => {
        console.log(error);
    });
  }
  
 
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              New Banner
            </Typography>
            <IconButton>
              <FormControlLabel
                style={{ color: '#ffffff' }}
                label="Insert Caps"
                control={
                  <Checkbox
                    checked={withCapsOn}
                    onChange={() => {
                      setWithCapsOn(!withCapsOn);
                    }}
                    name="InsertCaps"
                    color="secondary"
                  />
                }
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading ? (
          <CircularProgress style={{ margin: '0 auto' }} />
        ) : (
          <React.Fragment>
            <br />
            <form className={classes.center}>
              <FormGroup row>
                <TextField
                  style={{ width: '400px' }}
                  type="text"
                  label="Description"
                  value={description}
                  multiline={true}
                  rows="4"
                  onChange={handleChangeDescription}
                  variant="outlined"
                />
              </FormGroup>
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Top Line"
                    value={visitorTeam}
                    multiline={true}
                    style={{ width: '80%' }}
                    onChange={handleChangeVisitorTeam}
                  />
                </FormControl>
              </FormGroup>
              <FormGroup row>
                <FormControl className={classes.formControl}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Bottom Line"
                    value={homeTeam}
                    multiline={true}
                    style={{ width: '80%' }}
                    onChange={handleChangeHomeTeam}
                  />
                </FormControl>
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                  label="Permanent"
                  control={
                    <Checkbox
                      checked={permanentFlag}
                      onChange={() => {
                        setPermanentFlag(!permanentFlag);
                      }}
                      name="permanent"
                    />
                  }
                />
              </FormGroup>
            </form>
          </React.Fragment>
        )}
        <DialogActions>
          <Button
            color="inherit"
            startIcon={<CancelIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button startIcon={<SaveIcon />} color="inherit" onClick={handleOk}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
