import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default function Leagues(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [Leagues, setLeagues] = React.useState([]);
  useEffect(() => {
    // Change for server api address
    GetLeagues();
  }, []);

  const GetLeagues = () => {
    let url = process.env.REACT_APP_API_URL + '/LEAGUE?order=Description';
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      setLeagues(response.data.records);
    });
  };

  /* se quito el ,[] que se puede para no hacer tantos renders*/
  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 16, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    onRowClick: selectedValues,
  };

  function selectedValues(row) {
    props.GetEventsLeagues(row.IdLeague);
    //alert(`You click row id: ${row.IdLeague}`);
  }

  /*Select Functions*/

  /*const selectRowProp = {
    mode: 'checkbox',
  };*/
  /*End Select Functions*/

  return (
    <React.Fragment>
      <br />

      <BootstrapTable
        data={Leagues}
        options={options}
        //selectRow={selectRowProp}
        pagination={true}
        version="4"
        hover
      >
        <TableHeaderColumn
          visible="none"
          isKey={true}
          hidden
          hiddenOnInsert
          autoValue={true}
          dataField="IdLeague"
        >
          ID
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="Description"
          filter={{ type: 'TextFilter' }}
        >
          Description
        </TableHeaderColumn>
        <TableHeaderColumn dataField="IdSport" filter={{ type: 'TextFilter' }}>
          Sport
        </TableHeaderColumn>
      </BootstrapTable>
    </React.Fragment>
  );
}
