import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../../components/common/InputSelect';
import Sports from '../../../components/common/Sports';
import Periods from '../../../components/common/Periods';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import './ProfileStraight.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const priceFormatter = (cell, row) => {
  let isSelected = '';
  let isSelected0 = '';
  let isSelected1 = '';
  let isSelected2 = '';
  let isSelected3 = '';
  let isSelected4 = '';
  console.log('Formatting cell', cell);
  switch (cell) {
    case 0:
      isSelected0 = 'checked';
      break;
    case 1:
      isSelected1 = 'checked';
      break;
    case 2:
      isSelected2 = 'checked';
      break;
    case 3:
      isSelected3 = 'checked';
      break;
    case 4:
      isSelected4 = 'checked';
      break;
    default:
      isSelected = 'checked';
  }
  console.log('Formatting cell isSelected0', isSelected0);
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>

 `;
};
export default function ProfileStraight(props) {
  const classes = useStyles();
  const [oddsDefault, setOddsDefault] = React.useState([]);
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [period, setPeriod] = React.useState(['0']);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  let idProfile = props.Profile.IdProfile;
  let profile = props.Profile;

  //let IdPeriod = '0';
  useEffect(() => {
    // Change for server api address
    console.log('PROPS Profile Settings period======>', props);
    console.log('PROPS Profile Settings period======>', period);
    const url =
      process.env.REACT_APP_API_URL +
      '/ODDSDEFAULT' +
      '?filter=IdProfile,eq,' +
      idProfile;
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS STRAIGHTS====== REST THEN>', response.data.records);
      console.log('PROPS STRAIGHTS====== REST THEN>');
      setOddsDefault(response.data.records);
    });
  }, [idSport]);

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const ProfileTeaserUpdate = (row) => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Profile/OddsDefault_Update?idProfile=' +
        idProfile +
        '&idSport=' +
        row.IdSport +
        '&Odds=' +
        row.Odds +
        '&idUser=' +
        userInfo.UserId,
      //url:'https://localhost:44337/api/Profile/PlayerProfileCustomTeaser_Update',
    }).then((response) => {
      if (response.data === '0') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  };

  function onAfterSaveCell(row, cellName, cellValue) {
    ProfileTeaserUpdate(row);
  }

  const onBeforeSaveCell = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    beforeSaveCell: onBeforeSaveCell,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };  

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };
  return (
    <Table>
      <TableRow>
        <TableCell colSpan="3">
          <Table>
            <TableCell> {/**/}</TableCell>
            <TableCell>
              {' '}
              {/* */}
              <TableHeaderColumn>Football</TableHeaderColumn>
              <TableHeaderColumn>Spread</TableHeaderColumn>
              <BootstrapTable
                data={oddsDefault}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>

                <TableHeaderColumn editable={false} dataField="IdSport">
                  Sport
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Odds">Odds</TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </Table>
        </TableCell>
      </TableRow>
    </Table>
  );
}
