import React, { useState, useEffect, Component } from 'react';
import { Redirect } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import './TimeZoneList.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/common/loader/Loader';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ProfileList(props) {
  const [agents, setAgents] = useState();
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [currency, setCurrencies] = useState();
  const [book, setBooks] = useState();
  const [grouping, setGrouping] = useState();
  const [lineType, setLineType] = useState();
  const [agentPerHead, setAgentPerHead] = useState();
  const [language, setLanguage] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [value, setValue] = React.useState(0);
  const [selectedIdAgent, setSelectedIdAgent] = React.useState(0);
  const [selectedIdCurrency, setSelectedIdCurrency] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedIdProfile, setSelectedIdProfile] = useState(
    props.initialValue
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 230,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  /*const displayForm = async (data, index) => {
    console.log('Data', data);
    console.log('Index', index);
    let agent = agents[index];
    console.log('Agent', agent);
    await setRecord();
    await setRecord(agent);
    setshowInfo(false);
  };*/

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    //Profile Props',props )
    const url =
      process.env.REACT_APP_API_URL + '/TIMEZONE?order=Description';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setAgents(data.records);

        setIsLoading(false);
      });
  }, []);

  function onAfterDeleteRow(rowKeys) {
    let deleteRequest = {
      IdAgent: rowKeys,
      IdUser: userInfo.IdUser,
    };
    console.log(deleteRequest);
    axios({
      method: 'post',
      url: 'http://192.168.3.22:4587/api/Agent/Agent_Delete',
      data: deleteRequest,
    }).then((response) => {
      console.log('response', response);
    });
    alert('The rowkey you drop: ' + rowKeys);
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(',');
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }

  function selectedValues(row) {
    console.log(`You click row id: ${row.IdProfile}`);

    setRedirect(row);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 28, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: '<', // Previous page button text
    nextPage: '>', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false, // > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    // afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    //handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
  };

  const cellEditProp = {
    mode: 'click', //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    //afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: 'checkbox',

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    let rowStr = '';
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }

  const handleIdProfileChange = (event, newValue) => {
    console.log('Linetype', newValue.props.value);
    props.setProfileOnMem(event.target.value, props.row);
    setSelectedIdProfile(newValue.props.value);
  };
  const printInputList = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          TimeZone
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedIdProfile}
            onChange={handleIdProfileChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {agents &&
              agents.map((row) => (
                <MenuItem value={row.IdTimeZone}>{row.Description}</MenuItem>
              ))}
          </Select>
          <FormHelperText>TimeZone</FormHelperText>
        </FormControl>
      </div>
    );
  };
  const printList = () => {
    return (
      <div id="main-div profile-list" class="profile-list">
        <Paper className={classes.root}>
          <h2>TimeZone</h2>
          {options &&
            console.log('ProfileList rendering typeof', typeof agents)}

          {isLoading ? <Loader></Loader> : ''}
          <Table className={classes.table} aria-label="simple table">
            <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
              {agents && (
                <div>
                  <BootstrapTable
                    pagination={true}
                    options={options}
                    cellEdit={cellEditProp} //DBClick daba problemas con el Expand
                    version="4"
                    data={agents}
                    // deleteRow={true}
                    hover
                  >
                    <TableHeaderColumn
                      isKey={true}
                      filter={{ type: 'TextFilter', delay: 1000 }}
                      dataField="ProfileName"
                    >
                      TimeZone
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  };

  if (redirect) {
    let pushURL = '/profile/' + redirect.IdProfile;
    console.log('Profile Info props redirect', redirect);
    props.setProfile(redirect);
    printList();
    setRedirect(null);
    return (
      <div>
        <Redirect
          to={{
            pathname: pushURL,
          }}
        />

        {}
      </div>
    );

    //history.push(pushURL);
  }

  if (props.isInput) {
    return printInputList();
  } else {
    return printList();
  }
}

export default ProfileList;
