import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import '../../profiles/profileComponents/ProfileSettings.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';

export default function AgentNotes(props) {
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
    },
  };
  
  const [agentNotes, setAgentNotes] = useState();

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  function onAfterSaveCell(row, cellName, cellValue) {
    let AgentRequestDTO = {
      IdAgent: row.IdAgent,
      Notes1: row.Notes1,
      Notes2: row.Notes2,
      Notes3: row.Notes3,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Agent/AgentNotesMaintenance',
      data: AgentRequestDTO,
    }).then(() => {
      alert('Data Successfully Updated');      
    });
  }

  const NotesValues = () => {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Agent/Agent_GetNotes?idAgent=' +
        props.IdAgent,
    })
      .then((response) => response)
      .then((data) => {
        setAgentNotes(data.data);
      });
  };

  useEffect(() => {
    NotesValues();
  }, []);

  return (
    <React.Fragment>
      {agentNotes && (
        <Table>
          <TableRow>
            <BootstrapTable
              data={[agentNotes]}
              cellEdit={cellEditProp}
              options={options}
            >
              <TableHeaderColumn isKey={true} hidden dataField="IdAgent">
                ID
              </TableHeaderColumn>
              <TableHeaderColumn dataField="Notes1">Notes 1</TableHeaderColumn>
              <TableHeaderColumn dataField="Notes2">Notes 2</TableHeaderColumn>
              <TableHeaderColumn dataField="Notes3"> Notes 3</TableHeaderColumn>
            </BootstrapTable>
          </TableRow>
        </Table>
      )}
    </React.Fragment>
  );
}
