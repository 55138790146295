import React, { useState, setState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Grid} from '@material-ui/core'
import Paper from "@material-ui/core/Paper"
import "./ProfileParlay.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import axios from "axios";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const priceFormatter = (cell, row) => {
  let isSelected = "";
  let isSelected0 = "";
  let isSelected1 = "";
  let isSelected2 = "";
  let isSelected3 = "";
  let isSelected4 = "";
  switch (cell) {
    case 0:
      isSelected0 = "checked";
      break;
    case 1:
      isSelected1 = "checked";
      break;
    case 2:
      isSelected2 = "checked";
      break;
    case 3:
      isSelected3 = "checked";
      break;
    case 4:
      isSelected4 = "checked";
      break;
    default:
      isSelected = "checked";
  }
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>

 `;
};

const decimalFormatter = (cell, row) => {
  var num = Number(cell).toFixed(2);
  return `<i ></i> ${num}`;
};

const roundedFormatter = (cell, row) => {  
  return Math.round(cell*10)/10;
};

export default function ProfileParlay(props) {
  const classes = useStyles();
  const [profileParlayOptions, setProfileParlayOptions] = React.useState([]);
  const [profileParlayDetailOptions, setProfileParlayDetailOptions] =
    React.useState([]);
  const [profileParlayBasicSportsOptions, setProfileParlayBasicSportsOptions] =
    React.useState([]);
  const [idSport, setIdSport] = React.useState(["CBB"]);
  const [period, setPeriod] = React.useState(["0"]);
  const [tempParArr, settempParArr] = React.useState([]);
  const [selectedNumTeams, setSelectedNumTeams] = React.useState([2]);
  const [selectedProfile, setSelectedProfile] = useState("");

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );

  let idProfile = props.Profile.IdProfile;
  let profile = props.Profile;

  const ProfileParlayLimitUpdate = (row) => {
    let Request = {
      IdProfile: idProfile,
      NumTeams: row.NumTeams,
      IdWagerType: row.IdWagerType,
      Odds: row.Odds,
      MaxRisk: row.MaxRisk,
      MaxPayout: row.MaxPayout,
      ExtraJuice: row.ExtraJuice,
      MaxOpenSpots: row.MaxOpenSpots,
      MaxDogLines: row.MaxDogLines,
      MaxMoneyLines: row.MaxMoneyLines,
      MaxTotalLines: row.MaxTotalLines,
      IdUser: userInfo.UserId,
    };
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/Profile/PlayerProfileParlayLimit_Update",
      data: Request,
    }).then((response) => {
      if (response.data === "0") {
        alert("Data Successfully Updated");
      }
      console.log("response", response);
    });
  };

  const ProfileParlayLimitDetailUpdate = (row) => {
    let Request = {
      IdProfile: idProfile,
      IdSport: row.IdSport,
      NumTeams: row.NumTeams,
      MaxGames: row.MaxGames,
      MaxDogs: row.MaxDogs,
      UseFormula: row.UseFormula,
      MaxMoneyLines: row.MaxMoneyLines,
      MaxTotalLines: row.MaxTotalLines,
      IdUser: userInfo.UserId,
    };
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/Profile/PlayerProfileParlayLimitDetail_Update",
      data: Request,
    }).then((response) => {
      if (response.data === "0") {
        alert("Data Successfully Updated");
      }
      console.log("response", response);
    });
  };

  const ProfileParlayBasicSportsUpdate = (row) => {
    let Request = {
      IdProfile: idProfile,
      IdSport: row.IdSport,
      BasicSport: row.BasicSport,
      IdUser: userInfo.UserId,
    };
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/Profile/PlayerProfileParlayBasicSports_Update",
      data: Request,
    }).then((response) => {
      if (response.data === "0") {
        alert("Data Successfully Updated");
      }
      console.log("response", response);
    });
  };

  useEffect(() => {
    const url =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILEPARLAYLIMIT" +
      "?filter=IdProfile,eq," +
      idProfile;
    axios({
      method: "get",
      url: url,
    }).then((response) => {
      let tempArr1 = response.data.records;
      tempArr1.forEach(cleanIdSports);
      setProfileParlayOptions(response.data.records);
    });
    const url2 =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILEPARLAYLIMITDETAIL" +
      "?filter=IdProfile,eq," +
      idProfile +
      "&filter=NumTeams,eq," +
      selectedNumTeams;

    axios({
      method: "get",
      url: url2,
    }).then((response) => {
      let tempArr2 = response.data.records;
      tempArr2.forEach(cleanIdSports);
      setProfileParlayDetailOptions(response.data.records);
    });

    const url3 =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILEPARLAYBASICSPORTS" +
      "?filter=IdProfile,eq," +
      idProfile;
    axios({
      method: "get",
      url: url3,
    }).then((response) => {
      let tempArr2 = response.data.records;
      tempArr2.forEach(cleanIdSports);
      setProfileParlayBasicSportsOptions(response.data.records);
    });

    profileParlayDetailOptions.forEach(cleanIdSports);
    setProfileParlayDetailOptions(tempParArr);
  }, [idSport, period, selectedNumTeams]);

  function cleanIdSports(item, index, arr) {
    let vartemp = arr[index]["IdSport"];

    vartemp && (vartemp = vartemp.trim());
    arr[index]["IdSport"] = vartemp;

    tempParArr.push(arr[index]);
  }

  const selectRowProp = {
    mode: "radio",
    onSelect: onRowSelect,
    clickToSelect: true, 
    clickToExpand: true, 
  };

  function onRowSelect(row, isSelected, e) {
    if (isSelected) {
      setSelectedProfile(row)
    } 
  }

  const options = {   
    afterDeleteRow: onAfterDeleteRow, 
    handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
    afterInsertRow: onAfterInsertRow,
  };

  function selectedValues(row) {
    setSelectedNumTeams(row.NumTeams);
  }
  

  const insertParlayLimit = (row) => {
    let Request = {
      IdProfile: idProfile,
      NumTeams: parseInt(row.NumTeams),
      IdWagerType: 0,
      Odds: parseInt(row.Odds),
      MaxRisk: parseInt(row.MaxRisk),
      MaxPayout: parseInt(row.MaxPayout),
      ExtraJuice: parseInt(row.ExtraJuice),
      MaxOpenSpots: parseInt(row.MaxOpenSpots),
      MaxDogLines: parseInt(row.MaxDogLines),
      MaxMoneyLines: 0,
      MaxTotalLines: 0,
      IdUser: userInfo.UserId,
      Result:0,
    };

    console.log("Request", Request)
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + '/PlayerProfileLimitInsert.php',
      data: Request,
    }).then((response) => {      
      console.log("response", response);
    }).catch( (e) => {
      console.log('objMultisend error', e.response.data);
    });
  }
 
  function onAfterInsertRow (row) {
    console.log("row",row)
    insertParlayLimit(row);
  }

  function onAfterDeleteRow(row) {    
    console.log("rowKeys",row)
    let req = {
      IdProfile: selectedProfile.IdProfile,
      NumTeams: selectedProfile.NumTeams,
      IdWagerType: selectedProfile.IdWagerType,
      IdUser: userInfo.UserId,
      Result: 0,
    };    
    console.log("req", req)
    axios({
      method: "post",
      url: process.env.REACT_APP_DEF_PHP_URL + '/PlayerProfileLimitDelete.php',
      data: req,
    }).then((response) => {      
      console.log("response", response);
    }).catch( (e) => {
      console.log('objMultisend error', e.response.data);
    });
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      next();
    }
  }

  const onBeforeSaveCellParlayLimitProp = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const onAfterSaveCellParlayLimitProp = (row, cellName, cellValue) => {
    ProfileParlayLimitUpdate(row);
  }

  const cellEditParlayLimitProp = {
    mode: "click",
    blurToSave: true,
    beforeSaveCell: onBeforeSaveCellParlayLimitProp,
    afterSaveCell: onAfterSaveCellParlayLimitProp
  };

  const onBeforeSaveCellParlayLimitDetailProp = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const onAfterSaveCellParlayLimitDetailProp = (row, cellName, cellValue) => {
    ProfileParlayLimitDetailUpdate(row); 
  }

  const cellEditParlayLimitDetailProp = {
    mode: "click",
    blurToSave: true,
    beforeSaveCell:onBeforeSaveCellParlayLimitDetailProp,
    afterSaveCell: onAfterSaveCellParlayLimitDetailProp,
  };

  const onBeforeSaveCellParlayBasicSportProp = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  };

  const onAfterSaveCellParlayBasicSportProp = (row, cellName, cellValue) => {
    ProfileParlayBasicSportsUpdate(row);
  }

  const cellEditParlayBasicSportProp = {
    mode: "click",
    blurToSave: true,
    beforeSaveCell: onBeforeSaveCellParlayBasicSportProp,
    afterSaveCell: onAfterSaveCellParlayBasicSportProp,
  };

  const onBeforeSaveCell = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const onAfterSaveCell = (row, cellName, cellValue) => {
    props.UpdateProfile(row); 
  }

  const cellEditProp = {
    mode: "click",
    blurToSave: true,
    beforeSaveCell: onBeforeSaveCell,
    afterSaveCell: onAfterSaveCell,
  };
   

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };

  const setPeriodOnMem = (period) => {
    setPeriod(period);
  };

  const priceFormatter = (cell, row) => {
    let isSelected = "";
    let isSelected0 = "";
    let isSelected1 = "";
    let isSelected2 = "";
    let isSelected3 = "";
    let isSelected4 = "";
    switch (cell) {
      case 0:
        isSelected0 = "checked";
        break;
      case 1:
        isSelected1 = "checked";
        break;
      case 2:
        isSelected2 = "checked";
        break;
      case 3:
        isSelected3 = "checked";
        break;
      case 4:
        isSelected4 = "checked";
        break;
      default:
        isSelected = "checked";
    }
    return `
      <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
      <label for="isSelected0">True Odds</label><br>
      <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
      <label for="isSelected1">Ro Mild</label><br>
      <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
      <label for="isSelected2">Ro Total</label><br>
   `;
  };

  const sportsType = {
    NFL: "NFL",
    CBB: "CBB",
    MLB: "MLB",
    NHL: "NHL",
    SOC: "SOC",
    HOC: "HOC",
    TNT: "TNT",
    MU: "MU",
    PROP: "PROP",
  };

  function enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }

  console.log(
    "PROP STRAIGHT====== REST profileParlayOptions>",
    profileParlayDetailOptions
  );
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={5} >
          <Typography variant="h6">
            Odds
          </Typography>
          <Paper style={{overflowX:'auto'}}>
            <BootstrapTable
              data={profileParlayOptions}
              cellEdit={cellEditParlayLimitProp}
              options={options}
              insertRow={true}
              deleteRow={true}
              selectRow={selectRowProp}
              version="4"
              containerStyle={{width:'220%'}}
            >
              <TableHeaderColumn
                visible="none"
                hidden
                dataField="IdProfile"
                hiddenOnInsert
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                visible="none"
                isKey={true}
                dataField="NumTeams"
                thStyle={{whiteSpace:'nowrap'}}
              >
                #Teams
              </TableHeaderColumn>

              <TableHeaderColumn
                visible="none"
                dataField="Odds"
                dataFormat={roundedFormatter}
              >
                Odds
              </TableHeaderColumn>
              <TableHeaderColumn 
                visible="none" 
                dataField="ExtraJuice"
              >
                XtraJuice
              </TableHeaderColumn>
              <TableHeaderColumn 
                visible="none" 
                dataField="MaxRisk"
              >
                MaxRisk
              </TableHeaderColumn>
              <TableHeaderColumn
                visible="none"
                dataField="MaxPayout"
                dataFormat={roundedFormatter}
              >
                MaxPayout
              </TableHeaderColumn>              
              <TableHeaderColumn 
                visible="none" 
                dataField="MaxOpenSpots"
              >
                Open Spots
              </TableHeaderColumn>
              <TableHeaderColumn 
                visible="none" 
                dataField="MaxDogLines"
              >
                Max Dogs
              </TableHeaderColumn>
              <TableHeaderColumn 
                visible="none" 
                dataField="MaxMoneyLines"
              >
                Max $-Lines
              </TableHeaderColumn>
              <TableHeaderColumn 
                visible="none" 
                dataField="MaxTotalLines"
              >
                Max Totals
              </TableHeaderColumn>
            </BootstrapTable>
          </Paper>
        </Grid>

        <Grid item xs={5}>
          <Typography variant="h6">
            Max Totals
          </Typography>
          <Paper style={{overflowX:'auto'}}>
            <BootstrapTable
              data={profileParlayDetailOptions}
              cellEdit={cellEditParlayLimitDetailProp}
              options={options}              
              containerStyle={{width:'155%'}}
            >
              <TableHeaderColumn
                dataField="IdSport"
                isKey={true}
              >
                Sport
              </TableHeaderColumn>

              <TableHeaderColumn visible="none" dataField="MaxGames">
                Games
              </TableHeaderColumn>
              <TableHeaderColumn visible="none" dataField="MaxDogs">
                Dogs
              </TableHeaderColumn>
              <TableHeaderColumn
                visible="none" 
                dataField="UseFormula"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                UseFormula
              </TableHeaderColumn>
              <TableHeaderColumn visible="none" dataField="MaxMoneyLines">
                $Lines
              </TableHeaderColumn>
              <TableHeaderColumn visible="none" dataField="MaxTotalLines">
                Totals
              </TableHeaderColumn>
            </BootstrapTable>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6">
            Basic Sports
          </Typography>
          <Paper style={{overflowX:'auto'}}>
            <BootstrapTable
              data={profileParlayBasicSportsOptions}
              cellEdit={cellEditParlayBasicSportProp}
              options={options}
              containerStyle={{width:'120%'}}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn visible="none" dataField="IdSport">
                IdSport
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="BasicSport"
                editable={{
                  type: "checkbox",
                  options: {
                    values: "true:false",
                  },
                }}
              >
                Basic
              </TableHeaderColumn>
            </BootstrapTable>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={1} direction={'column'} style={{marginTop:'2%'}}>
        
        <Grid item xs={12}>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}         
            containerStyle={{width:'100%'}}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_TieLoses"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Tie Loses
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_LowerUseDefault"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Use Table Odds Range
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_AllowSpreadTotalHK"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Allow Spread and Total in Hockey
            </TableHeaderColumn>
          </BootstrapTable>
        </Grid>

        <Grid item xs={12}>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
            containerStyle={{width:'100%'}}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_AllowOpenPlays"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Allow Open Plays
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_TrueOddsOption"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              If Money Line then Use True Odds
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_AllowSpreadTotalSOC"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Allow Spread and Total in Soccer
            </TableHeaderColumn>
          </BootstrapTable>
        </Grid>

      </Grid>

      <Grid container spacing={1} style={{marginTop:'2%'}}>

        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Baseball
            </Typography>
            <BootstrapTable
              data={[props.Profile]}
              cellEdit={cellEditProp}
              options={options}      
              containerStyle={{width:'100%'}}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="PL_AlwaysAction"
                editable={{
                  type: "checkbox",
                  options: {
                    values: "true:false",
                  },
                }}
              >
                Always Action
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="PL_AllowRunLineTotal"
                editable={{
                  type: "checkbox",
                  options: {
                    values: "true:false",
                  },
                }}
              >
                Allow Rin Line and Total
              </TableHeaderColumn>
            </BootstrapTable>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} style={{marginTop:'2%'}} direction={'column'}>
              <Grid item xs={12}>
                <BootstrapTable
                  data={[props.Profile]}
                  containerStyle={{width:'100%'}}
                >
                    <TableHeaderColumn
                      isKey={true}
                      dataField="PL_ParlayFormula"
                      dataFormat={priceFormatter}
                      
                    >
                      Parlay Formula
                    </TableHeaderColumn>
                  </BootstrapTable>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Check Max Dogs on
                </Typography>
                <BootstrapTable
                  data={[props.Profile]}
                  cellEdit={cellEditProp}
                  options={options}
                  containerStyle={{width:'100%'}}
                >
                  <TableHeaderColumn
                    visible="none"
                    isKey={true}
                    hidden
                    dataField="IdProfile"
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_MaxDogsSide"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Side
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_MaxDogsTotal"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Total
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_MaxDogsMoney"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Money Line
                  </TableHeaderColumn>
                </BootstrapTable>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography 
                variant="h6"
                style={{color:'#def5f2'}}                
              >
                  placeholder
              </Typography>
              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
                containerStyle={{width:'100%'}}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="PL_MaxGames">
                  Max Sides
                </TableHeaderColumn>
                <TableHeaderColumn dataField="PL_MaxTeamBuyPoints">
                  High Level Buy Points
                </TableHeaderColumn>
              </BootstrapTable> 
            </Grid>
            <Grid item xs={12}>
              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
                containerStyle={{width:'100%'}}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="PL_MaxBuyPoints">
                  Max Buy Points
                </TableHeaderColumn>
                <TableHeaderColumn dataField="PL_OddsDefault">
                  Odds Default
                </TableHeaderColumn>
              </BootstrapTable>
            </Grid>
            <Grid item xs={12}>
              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
                containerStyle={{width:'100%'}}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="PL_MaxPayout"
                  dataFormat={roundedFormatter}
                >
                  Max Payout
                </TableHeaderColumn>
                <TableHeaderColumn dataField="PL_MaxOdds">
                  Odds Limit
                </TableHeaderColumn>
              </BootstrapTable>
            </Grid>
            <Grid item xs={12}>
              <BootstrapTable
                data={[props.Profile]}
                cellEdit={cellEditProp}
                options={options}
                containerStyle={{width:'100%'}}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="PL_UseMaxSumOdds"
                  editable={{
                    type: "checkbox",
                    options: {
                      values: "true:false",
                    },
                  }}
                >
                  Stop combinations with odds sum higher than
                </TableHeaderColumn>
                <TableHeaderColumn dataField="PL_MaxSumOdds">
                  Sum Odds
                </TableHeaderColumn>
              </BootstrapTable>
            </Grid>
          </Grid>          
        </Grid>
      </Grid>      
    </React.Fragment>
  );
}
