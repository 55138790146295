import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Axios from "axios";
import {
  BootstrapTable,
  TableHeaderColumn,  
} from 'react-bootstrap-table';
import { Button } from "@material-ui/core";
import './OpenBets.css';
import TextField from '@mui/material/TextField';
import NotificationSound from './notification.wav'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
    maxHeight: 1100
  },
  container: {
    display: "flex",
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

export default function HoldBets(props) {
  const [holdBets, setHoldBets] = useState([]) 
  const [lastHoldWagerId, setLastHoldWagerId] = useState(0) 
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(1000000)
  const audioPlayer = useRef(null);

  function playAudio() {
    audioPlayer.current.play();
  }


  useEffect(() => { 
    const interval = setInterval(() => {      
      getLastHoldWagerId();
      if(lastHoldWagerId !== 0)
        getBetsOnHold();
    }, 5000);
    return () => clearTimeout(interval);


  }, [lastHoldWagerId]);

  useEffect(() => { 
    console.log("holdBets", addHeadersToHeldBets(holdBets))
    if(holdBets.length > 0){
      playAudio();
    }
  }, [holdBets]);

  const getBetsOnHold = () => {   
    const url = process.env.REACT_APP_DEF_PHP_URL + '/BetTickerGetHoldedBet.php'
      Axios({
        method: 'post',
        url: url,
        data: {lastIdWager : lastHoldWagerId},
      }).then((response) => {
        if(response.data.data.length > 0){
          response.data.data.map(
            (x)=>{
              setHoldBets(current => [x , ...current])
            }
          )
        } 
        
        });
  }

  const getLastHoldWagerId = () => {
    const url = process.env.REACT_APP_DEF_PHP_URL + '/BetTickerGetLastIdWager.php'
      Axios({
        method: 'post',
        url: url,
      }).then((response) => {         
          setLastHoldWagerId((response.data.data[0][1]));
        });
  }

  const addHeadersToHeldBets = (aux) => {
    let ObjectifiedArray = aux.map(x => ({ 
      IdHoldWagerHeader: x[0], 
      Player: x[1],
      Description: x[2],
      WagerAmount: Math.round(parseInt(x[3])),
      IdBook: x[4],
      DetailDescription: x[5]
    }));
    return ObjectifiedArray
  }

  function rowClassNameFormat(row, rowIdx) { 
    if (row.WagerAmount > 0 && row.WagerAmount <= 499) {
      return 'row-100-500';
    } else if (row.WagerAmount > 499 && row.WagerAmount <= 999) {
      return 'row-500-1000';
    } else if (row.WagerAmount > 999 && row.WagerAmount <= 2999) {
      return 'row-1000-3000';
    } else if (row.WagerAmount > 2999 && row.WagerAmount <= 5000) {
      return 'row-3000-5000';
    } else if (row.WagerAmount > 5000) {
      return 'row-5000';
    }
  } 
  
  const handleChangeMinValue = (event) => {
    setMinValue(event.target.value);
  }

  const handleChangeMaxValue = (event) => {
    setMaxValue(event.target.value);
  }

  const options = {
    page: 1, 
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], 
    sizePerPage: 20, 
    pageStartIndex: 1, 
    paginationSize: 1, 
    prePage: 'Prev', 
    nextPage: 'Next', 
    firstPage: 'First',
    lastPage: 'Last', 
    paginationShowsTotal: true,
    paginationPosition: 'top',
    withFirstAndLast: false,
  };

  return (
      <React.Fragment >      
        <audio ref={audioPlayer} src={NotificationSound} />
        <div className="open-bets">
          <p>
            Color Codes :<span className='row-100-500'>0-99</span>
            &nbsp;&nbsp;| &nbsp;&nbsp;
            <span className='row-500-1000'>100-499</span>&nbsp;&nbsp;|
            &nbsp;&nbsp;
            <span className='row-1000-3000'>500-999</span>&nbsp;&nbsp;|
            &nbsp;&nbsp;
            <span className='row-3000-5000'>1000-2999</span>&nbsp;&nbsp;|
            &nbsp;&nbsp;
            <span className='row-5000'>{'>=3000'}</span>
          </p>

          <h4 style={{marginTop:16}}>Hold Bets:</h4>
          
          <Grid
            container
            style={{ fontSize: 18 }}
            spacing={2}
          >
            <div style={{display: "flex", marginLeft:"20px", width:"40%"}}>
              <Button 
                style={{width:"-webkit-fill-available"}}
                variant="outlined" 
                onClick={()=>{
                  setHoldBets([])
                }}
              >
                Clear Bets on Hold
              </Button>
              <TextField      
                style={{marginLeft:"20px"}}                          
                variant="outlined"                            
                label="Min"
                type="number"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                value={minValue}                
                onChange={handleChangeMinValue}
                fullWidth                                                                       
              /> 
              <TextField          
                style={{marginLeft:"20px"}}                        
                variant="outlined"                            
                label="Max"
                type="number"
                InputProps={{
                  inputProps: { min: 0 }
                }}
                value={maxValue}
                onChange={handleChangeMaxValue}
                fullWidth                                                                       
              /> 
            </div>
              <BootstrapTable
              data={holdBets.length > 0 ? 
                addHeadersToHeldBets(holdBets).filter(x => x.WagerAmount >= minValue && x.WagerAmount <= maxValue) : []} 
              pagination={true} 
              trClassName={rowClassNameFormat}
              version="4" 
              hover
              options={options}
              >
              <TableHeaderColumn
                dataField="Player"
                filter={{
                  type: 'TextFilter',
                  delay: 1000,
                }}
              >
                Player
              </TableHeaderColumn>
            
              <TableHeaderColumn
                isKey
                dataField="Description"
              >
                Wager Type
              </TableHeaderColumn>
              <TableHeaderColumn dataField="DetailDescription">
                Description
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="WagerAmount"  
              >
                Amount
              </TableHeaderColumn> 
            </BootstrapTable>            
          </Grid>
        </div>
      </React.Fragment>     
  );
}
