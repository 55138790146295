import React, { useState, setState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputSelect from "../../common/InputSelect";
import Sports from "../../common/Sports";
import Periods from "../../common/Periods";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import "./WagerLimitsParlay.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import axios from "axios";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const priceFormatter = (cell, row) => {
  let isSelected = "";
  let isSelected0 = "";
  let isSelected1 = "";
  let isSelected2 = "";
  let isSelected3 = "";
  let isSelected4 = "";
  console.log("Formatting cell", cell);
  switch (cell) {
    case 0:
      isSelected0 = "checked";
      break;
    case 1:
      isSelected1 = "checked";
      break;
    case 2:
      isSelected2 = "checked";
      break;
    case 3:
      isSelected3 = "checked";
      break;
    case 4:
      isSelected4 = "checked";
      break;
    default:
      isSelected = "checked";
  }
  console.log("Formatting cell isSelected0", isSelected0);
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>

 `;
};
export default function ProfileParlay(props) {
  const classes = useStyles();
  const [profileParlayOptions, setProfileParlayOptions] = React.useState([]);
  const [profileParlayDetailOptions, setProfileParlayDetailOptions] =
    React.useState([]);
  const [profileParlayBasicSportsOptions, setProfileParlayBasicSportsOptions] =
    React.useState([]);
  const [idSport, setIdSport] = React.useState(["CBB"]);
  const [period, setPeriod] = React.useState(["0"]);
  const [tempParArr, settempParArr] = React.useState([]);
  const [selectedNumTeams, setSelectedNumTeams] = React.useState([2]);

  let idProfile = props.Profile.IdProfile;
  let profile = props.Profile;

  //let IdPeriod = '0';
  useEffect(() => {
    // Change for server api address
    console.log("PROPS PARLAY Profile Settings period======>", props);
    console.log("PROPS PARLAY Profile Settings period======>", period);
    const url =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILEPARLAYLIMIT" +
      "?filter=IdProfile,eq," +
      idProfile;
    axios({
      method: "get",
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log("PROPS PARLAY====== REST THEN>", response.data.records);
      console.log("PROPS PARLAY====== setProfileParlayOptions REST THEN>");

      let tempArr1 = response.data.records;
      tempArr1.forEach(cleanIdSports);
      setProfileParlayOptions(response.data.records);
    });
    console.log("PROPS Profile Settings period======>", props);
    console.log("PROPS Profile Settings period======>", period);
    const url2 =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILEPARLAYLIMITDETAIL" +
      "?filter=IdProfile,eq," +
      idProfile +
      "&filter=NumTeams,eq," +
      selectedNumTeams;

    axios({
      method: "get",
      url: url2,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log("PROPS PARLAY====== REST THEN>", response.data.records);
      console.log(
        "PROPS PARLAY setProfileParlayDetailOptions====== REST THEN>"
      );
      let tempArr2 = response.data.records;
      tempArr2.forEach(cleanIdSports);
      setProfileParlayDetailOptions(response.data.records);
    });

    const url3 =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILEPARLAYBASICSPORTS" +
      "?filter=IdProfile,eq," +
      idProfile;
    axios({
      method: "get",
      url: url3,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log("PROPS PARLAY====== REST THEN>", response.data.records);
      console.log(
        "PROPS PARLAY setProfileParlayBasicSportsOptions====== REST THEN>"
      );
      let tempArr2 = response.data.records;
      tempArr2.forEach(cleanIdSports);
      setProfileParlayBasicSportsOptions(response.data.records);
    });

    /*Clean Results */
    console.log(
      "PROPS Parlay Settings Clean Results======>",
      profileParlayOptions
    );
    console.log(
      "PROPS Parlay Settings Clean Results======>",
      profileParlayDetailOptions
    );
    console.log(
      "PROPS Parlay Settings Clean Results======>",
      profileParlayBasicSportsOptions
    );

    // tempProfileParlayOptions.forEach(cleanIdSports);
    profileParlayDetailOptions.forEach(cleanIdSports);

    console.log("Parlay tempParArr", tempParArr);
    setProfileParlayDetailOptions(tempParArr);
    /*/Clean Results */
  }, [idSport, period, selectedNumTeams]);

  function cleanIdSports(item, index, arr) {
    let vartemp = arr[index]["IdSport"];

    vartemp && (vartemp = vartemp.trim());
    arr[index]["IdSport"] = vartemp;

    tempParArr.push(arr[index]);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 28, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: false, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
  };

  function selectedValues(row) {
    console.log(`Parlay You click row id: ${row}`);
    console.log(`Parlay You click row id: ${row.NumTeams}`);
    setSelectedNumTeams(row.NumTeams);
    //setRedirect(row);
  }

  function onAfterDeleteRow(rowKeys) {
    /*
    let deleteRequest = {
      IdAgent: rowKeys,
      IdUser: userInfo.IdUser,
    };
    console.log(deleteRequest);
    axios({
      method: 'post',
      url:  process.env.REACT_APP_API2_URL + '/Agent/Agent_Delete',
      data: deleteRequest,
    }).then((response) => {
      console.log('response', response);
    });
    alert('The rowkey you drop: ' + rowKeys);
    */
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }
  // const [selectedIdGame, setselectedIdGame] = useState();

  /*useEffect(() => props.showOverlay(false));
  var returnObj = {};
  const genObjects = function () {
    props &&
      props.data &&
      props.data.map((s, i) => {
        //stateObject(s.IdGame, s.HomeTeam);
        returnObj = {};
        returnObj[s.IdGame] = s.HomeTeam;
      });
  };*/

  /*const handleCloseModify = function () {
    setIsModify(false);
  };*/

  const cellEditProp = {
    mode: "click",
    blurToSave: true,
  };

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };
  const setPeriodOnMem = (period) => {
    setPeriod(period);
    console.log("PROPS====== REST setPeriodOnMem>", period);
  };

  const priceFormatter = (cell, row) => {
    let isSelected = "";
    let isSelected0 = "";
    let isSelected1 = "";
    let isSelected2 = "";
    let isSelected3 = "";
    let isSelected4 = "";
    console.log("Formatting cell", cell);
    switch (cell) {
      case 0:
        isSelected0 = "checked";
        break;
      case 1:
        isSelected1 = "checked";
        break;
      case 2:
        isSelected2 = "checked";
        break;
      case 3:
        isSelected3 = "checked";
        break;
      case 4:
        isSelected4 = "checked";
        break;
      default:
        isSelected = "checked";
    }
    console.log("Formatting cell isSelected0", isSelected0);
    return `
      <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
      <label for="isSelected0">True Odds</label><br>
      <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
      <label for="isSelected1">Ro Mild</label><br>
      <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
      <label for="isSelected2">Ro Total</label><br>
   `;
  };

  const sportsType = {
    NFL: "NFL",
    CBB: "CBB",
    MLB: "MLB",
    NHL: "NHL",
    SOC: "SOC",
    HOC: "HOC",
    TNT: "TNT",
    MU: "MU",
    PROP: "PROP",
  };
  function enumFormatter(cell, row, enumObject) {
    return enumObject[cell];
  }

  console.log(
    "PROP STRAIGHT====== REST profileParlayOptions>",
    profileParlayDetailOptions
  );
  return (
    <Table>
      <TableRow>
        <TableCell colSpan="3">
          <Table>
            <TableRow>
              <TableCell colSpan="2">
                {" "}
                {/* */}
                <TableHeaderColumn>Odds</TableHeaderColumn>
                <BootstrapTable
                  data={profileParlayOptions}
                  cellEdit={cellEditProp}
                  options={options}
                  insertRow={true}
                  deleteRow={true}
                >
                  <TableHeaderColumn
                    visible="none"
                    hidden
                    dataField="IdProfile"
                  >
                    ID
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    visible="none"
                    isKey={true}
                    dataField="NumTeams"
                  >
                    #Teams
                  </TableHeaderColumn>

                  <TableHeaderColumn visible="none" dataField="Odds">
                    Odds
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="MaxRisk">
                    MaxRisk
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="MaxPayout">
                    MaxPayout
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="ExtraJuice">
                    XtraJuice
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="MaxOpenSpots">
                    MaxOP
                  </TableHeaderColumn>

                  <TableHeaderColumn visible="none" dataField="MaxDogLines">
                    MaxDL
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Max Totals
                <BootstrapTable
                  data={profileParlayDetailOptions}
                  cellEdit={cellEditProp}
                  options={options}
                >
                  {/*
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  dataField="IdSport"
                  formatExtraData={ sportsType } 
                  filter={ 
                    { type: 'SelectFilter', 
                      delay: 1000,
                      defaultValue: 'NFL',
                       options: sportsType,  } }>
                >
                IdSport
                </TableHeaderColumn>
     */}
                  {/*profileParlayDetailOptions && console.log('profileParlayDetailOptions ',profileParlayDetailOptions)}*/}
                  <TableHeaderColumn
                    dataField="IdSport"
                    filterFormatted
                    isKey={true}
                    //dataFormat={ enumFormatter }
                    //formatExtraData={ sportsType }
                  >
                    Sport
                  </TableHeaderColumn>

                  <TableHeaderColumn visible="none" dataField="MaxGames">
                    Games
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="MaxDogs">
                    Dogs
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="UseFormula">
                    UseFormula
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="MaxMoneyLines">
                    $Lines
                  </TableHeaderColumn>
                  <TableHeaderColumn visible="none" dataField="MaxTotalLines">
                    Totals
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>
              <TableCell>
                Basic Sports
                <BootstrapTable
                  data={profileParlayBasicSportsOptions}
                  cellEdit={cellEditProp}
                  options={options}
                >
                  <TableHeaderColumn
                    visible="none"
                    isKey={true}
                    hidden
                    dataField="IdProfile"
                  >
                    ID
                  </TableHeaderColumn>

                  <TableHeaderColumn visible="none" dataField="IdSport">
                    IdSport
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="BasicSport"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    BasicSport
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>
            </TableRow>
          </Table>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_TieLoses"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Tie Loses
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_LowerUseDefault"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Use Table Odds Range
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_AllowSpreadTotalHK"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Allow Spread and Total in Hockey
            </TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_AllowOpenPlays"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Allow Open Plays
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_TrueOddsOption"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              If Money Line then Use True Odds
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_AllowSpreadTotalSOC"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Allow Spread and Total in Soccer
            </TableHeaderColumn>
          </BootstrapTable>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Table>
            <TableRow>
              <TableCell>
                <BootstrapTable
                  data={[props.Profile]}
                  cellEdit={cellEditProp}
                  options={options}
                >
                  <TableHeaderColumn
                    visible="none"
                    isKey={true}
                    hidden
                    dataField="IdProfile"
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_AlwaysAction"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Always Action
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_AllowRunLineTotal"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Allow Rin Line and Total
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <BootstrapTable data={[props.Profile]}>
                  <TableHeaderColumn
                    isKey={true}
                    dataField="PL_ParlayFormula"
                    dataFormat={priceFormatter}
                  >
                    Parlay Formula
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>
              <TableCell>
                <BootstrapTable
                  data={[props.Profile]}
                  cellEdit={cellEditProp}
                  options={options}
                >
                  <TableHeaderColumn
                    visible="none"
                    isKey={true}
                    hidden
                    dataField="IdProfile"
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_MaxDogsSide"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Side
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_MaxDogsTotal"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Total
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="PL_MaxDogsMoney"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    Money Line
                  </TableHeaderColumn>
                </BootstrapTable>
              </TableCell>
            </TableRow>
          </Table>
        </TableCell>
        <TableCell>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PL_MaxGames">
              Max Sides
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PL_MaxTeamBuyPoints">
              High Level Buy Points
            </TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PL_MaxBuyPoints">
              Max Buy Points
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PL_OddsDefault">
              Odds Default
            </TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PL_MaxPayout">
              Max Payout
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PL_MaxOdds">
              Odds Limit
            </TableHeaderColumn>
          </BootstrapTable>
          <BootstrapTable
            data={[props.Profile]}
            cellEdit={cellEditProp}
            options={options}
          >
            <TableHeaderColumn
              visible="none"
              isKey={true}
              hidden
              dataField="IdProfile"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="PL_UseMaxSumOdds"
              editable={{
                type: "checkbox",
                options: {
                  values: "true:false",
                },
              }}
            >
              Stop combinations with odds sum higher than
            </TableHeaderColumn>
            <TableHeaderColumn dataField="PL_MaxSumOdds">
              Sum Odds
            </TableHeaderColumn>
          </BootstrapTable>
        </TableCell>
      </TableRow>
    </Table>
  );
}
