import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import TablePagination from '@material-ui/core/TablePagination';
/*BootstrapTable anbd table Related*/
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './ChangesByGame.css';
import { TableBody } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FinalStatsByAgent(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [stats, setStats] = React.useState([]);
  const [grouping, setGrouping] = useState('');
  const [lineType, setLineType] = useState('');
  const [office, setOffice] = useState('');
  const [agentFrom, setAgentFrom] = useState('');
  const [agentTo, setAgentTo] = useState('');
  const [distributor, setDistributor] = useState('');
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [playerType, setPlayerType] = useState(0);
  const [startDate, setStartDate] = React.useState(week[0]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [groupByAgent, setGroupByAgent] = useState(false);
  const [count, setCount] = useState(0);
  // let IdPeriod = '0';

  const handleShow = () => {};

  const HistoryValues = () => {
    setIsLoading(true);
    let request = {
      LogIdUser: userInfo.UserId,
      DateWeek: startDate,
      LineType: lineType,
      Office: office,
      Group: grouping,
      AgentFrom: agentFrom,
      AgentTo: agentTo,
      Distributor: distributor,
      PlayerSelect: playerType,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Reports/Report_Weekly_Balance',

      //url: 'https://localhost:44337/api' + '/Reports/Report_Weekly_Balance',
      data: request,
    }).then((response) => {
      console.log('response', response.data);
      setCount(response.data[0].Count);
      setStats(response.data);
      setIsLoading(false);
    });
  };
  // const [selectedIdGame, setselectedIdGame] = useState();

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeAgentFrom = (event) => {
    setAgentFrom(event.target.value);
  };
  const handleChangeAgentTo = (event) => {
    setAgentTo(event.target.value);
  };
  const handleChangeLineType = (event) => {
    setLineType(event.target.value);
  };
  const handleChangeDistributor = (event) => {
    setDistributor(event.target.value);
  };
  const handleChangeOffice = (event) => {
    setOffice(event.target.value);
  };

  const handleChangeGroup = (event) => {
    setGrouping(event.target.value);
  };

  const handlePlayerTypeChange = (event) => {
    setPlayerType(event.target.value);
  };

  const handleChangeGroupByAgent = () => {
    setGroupByAgent(!groupByAgent);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  function rowClassNameFormat(row, rowIdx) {
    // row is whole row object
    // rowIdx is index of row
    return rowIdx % 2 === 0
      ? 'tr-function-example'
      : 'td-column-function-odd-example';
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <Table>
              <TableRow>
                <TableCell>
                  <TextField
                    id="startDate"
                    label="Week Date"
                    type="date"
                    defaultValue={startDate}
                    className={classes.textField}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Line Type"
                    value={lineType}
                    multiline={true}
                    onChange={handleChangeLineType}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <br></br>
              <TableRow>
                <TableCell>
                  <TextField
                    type="text"
                    label="Office"
                    value={office}
                    multiline={true}
                    onChange={handleChangeOffice}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Group"
                    value={grouping}
                    multiline={true}
                    onChange={handleChangeGroup}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <br></br>
              <TableRow>
                <TableCell>
                  <TextField
                    type="text"
                    label="Player"
                    value={agentFrom}
                    multiline={true}
                    onChange={handleChangeAgentFrom}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    label="Player"
                    value={agentTo}
                    multiline={true}
                    onChange={handleChangeAgentTo}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <br></br>
              <TableRow>
                <TableCell>
                  <TextField
                    type="text"
                    label="Distributor"
                    value={distributor}
                    multiline={true}
                    onChange={handleChangeDistributor}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>
              <br></br>
              <TableRow>
                <TableCell>
                  <h6>Players Selection</h6>
                  <RadioGroup row>
                    <FormControlLabel
                      // value="None"
                      control={
                        <Radio
                          checked={playerType == '0'}
                          onChange={handlePlayerTypeChange}
                          value="0"
                          label="Non Zero Ball"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'None' }}
                          //disabled
                        />
                      }
                      label="Non Zero Ball"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={playerType == '1'}
                          onChange={handlePlayerTypeChange}
                          value="1"
                          label="All Players"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="All Players"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //   value="Home"
                      control={
                        <Radio
                          checked={playerType == '2'}
                          onChange={handlePlayerTypeChange}
                          value="1"
                          label="Credit"
                          name="radio-button-time"
                          inputProps={{ 'aria-label': 'Home' }}
                          // disabled
                        />
                      }
                      label="Active Players"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Button color="inherit" onClick={HistoryValues}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            </Table>
          </form>

          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell style={{ width: '15%' }}>
                  <strong>Player</strong>
                </TableCell>
                <TableCell style={{ width: '5%' }}>
                  <strong>Bal Fwd</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>Mon</strong>
                </TableCell>

                <TableCell style={{ width: '7%' }}>
                  <strong>Tue</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>Wed</strong>
                </TableCell>

                <TableCell style={{ width: '7%' }}>
                  <strong>Thu</strong>
                </TableCell>

                <TableCell style={{ width: '7%' }}>
                  <strong>Fri</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>Sat</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>Sun</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>Week</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>Pmts</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>End Bal</strong>
                </TableCell>
                <TableCell style={{ width: '7%' }}>
                  <strong>Settled</strong>
                </TableCell>
              </TableRow>
            </TableHead>

            {stats &&
              stats.map((row) => (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={'14'}>
                      {row.AgentList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((row2) => (
                        <Table>
                          <div style={{ width: '60%' }}>
                            <strong>{row2.Agent}</strong>
                          </div>
                          <br></br>

                          <TableRow rowSpan={14}>
                            <TableCell colSpan={14}>
                              {row2.PlayesList.map((row3) => (
                                <Table>
                                  <TableRow>
                                    <TableCell style={{ width: '12%' }}>
                                      {row3.Player}
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                      {row3.BalFwd}
                                    </TableCell>
                                    <TableCell style={{ width: '6%' }}>
                                      {row3.Day1}
                                    </TableCell>
                                    <TableCell style={{ width: '7%' }}>
                                      {row3.Day2}
                                    </TableCell>
                                    <TableCell style={{ width: '6.5%' }}>
                                      {row3.Day3}
                                    </TableCell>
                                    <TableCell style={{ width: '6.5%' }}>
                                      {row3.Day4}
                                    </TableCell>
                                    <TableCell style={{ width: '7%' }}>
                                      {row3.Day5}
                                    </TableCell>
                                    <TableCell style={{ width: '7%' }}>
                                      {row3.Day6}
                                    </TableCell>
                                    <TableCell style={{ width: '7%' }}>
                                      {row3.Day7}
                                    </TableCell>
                                    <TableCell style={{ width: '7%' }}>
                                      {row3.SptWeek}
                                    </TableCell>
                                    <TableCell style={{ width: '6%' }}>
                                      {row3.Pmts}
                                    </TableCell>
                                    <TableCell style={{ width: '9%' }}>
                                      {row3.EndBal}
                                    </TableCell>
                                    <TableCell>{row3.SettledFigure}</TableCell>
                                  </TableRow>
                                </Table>
                              ))}
                            </TableCell>
                          </TableRow>
                          <br></br>
                          <TableRow>
                            <TableCell style={{ width: '12%' }}>
                              <strong>SubTotals</strong>
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>
                              <strong>{row2.BalFwdSub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '6%' }}>
                              <strong>{row2.Day1Sub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '7%' }}>
                              <strong>{row2.Day2Sub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '6.5%' }}>
                              <strong>{row2.Day3Sub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '6.5%' }}>
                              <strong>{row2.Day4Sub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '7%' }}>
                              <strong>{row2.Day5Sub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '7%' }}>
                              <strong>{row2.Day6Sub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '7%' }}>
                              <strong>{row2.Day7Sub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '7%' }}>
                              <strong>{row2.SptWeekSub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '6%' }}>
                              <strong>{row2.PmtsSub}</strong>
                            </TableCell>
                            <TableCell style={{ width: '9%' }}>
                              <strong>{row2.EndBalSub}</strong>
                            </TableCell>
                            <TableCell>
                              <strong>{row2.SettledFigureSub}</strong>
                            </TableCell>
                          </TableRow>
                          <br></br>
                        </Table>
                      ))}
                    </TableCell>
                  </TableRow>
                  <br></br>
                  <TableRow>
                    <TableCell style={{ verticalAlign: 'top' }}>
                      <strong>Totals:</strong>
                    </TableCell>
                    <TableCell>
                      <Table>
                        <TableRow>
                          <TableCell>
                            <strong>Balance Fwd:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.BalFwdTot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Mon:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.Day1Tot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Tue:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.Day2Tot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Wed:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.Day3Tot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Thu:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.Day4Tot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Fri:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.Day5Tot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Week:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.SptWeekTot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>PMTS:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.PmtsTot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>End Balance:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.EndBalTot}</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Settled Figure:</strong>
                          </TableCell>
                          <TableCell>
                            <strong>{row.SettledFigureTot}</strong>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </Table>
          {stats && (
            <TablePagination
              rowsPerPageOptions={[25, 50, 75]}
              colSpan={3}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
