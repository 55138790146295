import React, { useState, useEffect, useContext } from "react";
import { forwardRef } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AgentInfo from "./agentComponents/AgentInfo";
import AgentTabs from "./agentComponents/AgentTabs";
import AgentList from "../entities/agents/AgentList";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";

const EntityContainer = forwardRef((props) => {
  const [trigger, setTrigger] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      useState,
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 200,
      },
      "& .MuiFormGroup-row": {
        justifyContent: "center",
      },
      "& .MuiFormControl-root": {
        justifyContent: "center",
      },
    },
  }));
  const classes = useStyles();
  let history = useHistory();
  const [agentInfo, setAgentInfo] = useState();

  useEffect(() => {
    const timeout = setTimeout(() => {
      axios({
        method: "post",
        url:
          process.env.REACT_APP_API2_URL +
          "/Agent/Agent_GetInfoByID?idAgent=" +
          props.match.params.id,
      })
        .then((response) => response)
        .then((data) => {
          setAgentInfo(data.data);
        });
      return () => clearTimeout(timeout);
    });
  }, [setAgentInfo,trigger]);

  const setCurrentAgent = (profile) => {
    setAgentInfo(profile);
    let anothervar = profile.IdAgent.toString();
    //let anothervar = 'profile.IdProfile';
    history.push(anothervar);
  };

  //subscribeToTimer((err, timestamp) => settimestamp(timestamp));

  return (
    <React.Fragment>
      {}

      {agentInfo && (
        <div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="profile-list" id="profilesCell">
                  <div className="profiles-left-div">
                    <AgentList setAgentInfo={setCurrentAgent}></AgentList>
                  </div>
                </TableCell>
                <TableCell className="profiles-right-div">
                  <div className="profiles-right-div">
                    <Paper className={classes.root}>
                      <div
                        id="inputs"
                        //style={{ display: 'flex', maxHeight: 45 }}
                      >
                        <AgentInfo AgentInfo={agentInfo}></AgentInfo>
                      </div>

                      <AgentTabs 
                        AgentInfo={agentInfo} 
                        setTrigger={setTrigger}
                        trigger={trigger}
                      ></AgentTabs>
                    </Paper>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
});

export default EntityContainer;
