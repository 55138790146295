import React, { useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Loader from "../../common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Leagues from '../../common/Leagues';
import Sports from '../../common/Sports'

function WebConfiguratorRowDetailNew (props) {
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "350px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
              //justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [idLeague, setIdLeague] = useState(0);
    const [order, setOrder] = useState('');
    const [isInserted, setIsInserted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [idSport, setIdSport] = React.useState(['CBB']);
 
    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
      ); 

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
    }

    const handleChangeOrder =(event) => {
        setOrder(event.target.value);
    };

    const setLeagueOnMem = (period) => {
        setIdLeague(period);
        console.log('PROPS====== REST setPeriodOnMem>', period);
    };

    const setIdSportOnMem = (IdSport) => {
        setIdSport(IdSport);
        console.log("idSport",idSport)
    };

    const insertWebRowDetail = () => {
        setIsLoading(true);
        try{
            let RequestDTO = {
                idWebRow: props.IdWebRow,
                idLeague: idLeague,
                rowOrder: order,
                IdUser: userInfo.UserId,
              };
              Axios({
                method: 'post',
                url:
                  process.env.REACT_APP_API2_URL + '/WebConfigurator/WebRowDetail_Insert',
                data: RequestDTO,
              }).then((response) => {
                props.GetWebRowDetails();
                alert('Data Successfully Inserted');      
                setIsLoading(false);
                setIsInserted(true);
                setOrder('')
                setIdLeague(0)
              });
                    
          }catch(error){
            console.error("error.response.data",error.response.data)
            setIsLoading(false);
        }
    }

    const handleOk = () => {
        if(order === '' || idLeague === 0){
            setIsRed(true);
            alert("Please provide the required fields");
        }else{
            insertWebRowDetail();            
        }
    }


    return(        
        <React.Fragment>
            <Dialog 
                style={{width: "50%", height: "68%", margin: "0 auto", marginTop: "100px"}}
                open={open} 
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            New Web Column Detail
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.center}>
                    <Divider />                     
                    <FormGroup row style={{margin:"2%"}}>                                
                        <Sports
                            setIdSportOnMem={setIdSportOnMem}
                        ></Sports>                 
                    </FormGroup> 
                    <FormGroup row style={{margin:"2%"}}>                                
                        <Leagues
                            setLeagueOnMem={setLeagueOnMem}
                            filterBy={idSport} 
                            key={idSport}                 
                        ></Leagues>                
                    </FormGroup>                                     
                    <FormGroup row>                                
                        <TextField style={{width:"30%",margin:"2%"}}                               
                            variant="outlined"                            
                            label="order"
                            type="text"
                            value={order}
                            {...isRed && order===''?(
                                {error:true}
                            ):("")}
                            onChange={handleChangeOrder}
                            fullWidth                                                                       
                        />                        
                    </FormGroup>
                </form>
                <React.Fragment>
                    <DialogActions>
                        {isInserted ? (                                
                            <FormControl className={classes.formControl}>
                                <InputBase
                                style={{
                                    color: "red",
                                    fontSize: 35,
                                    fontWeight: "bold",
                                }}
                                className={classes.margin}
                                defaultValue="Done!"
                                inputProps={{ "aria-label": "naked" }}
                                />
                            </FormControl>                                
                        ) : (
                            ""
                        )}
                        {isLoading ? (
                        <Loader height={100} width={100}></Loader>
                        ) : (
                        <div>                                
                            <Button
                                startIcon={<SaveIcon />}
                                color="inherit"
                                onClick={handleOk}
                            >
                            Ok
                            </Button>
                        </div>
                        )}                            
                    </DialogActions>   
                                            
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );    
} 
export default WebConfiguratorRowDetailNew;