import React, { useState, useEffect, Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import './LanguageList.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { useHistory } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BookList(props) {
  const [departments, setDepartments] = useState();

  const [record, setRecord] = useState();

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [selectedPitcher, setSelectedPitcher] = useState(props.initialValue);
  const [initialValue, setInitialValue] = useState(props.initialValue);
  const [isLoading, setIsLoading] = useState();

  const handleIdBookChange = (event, newValue) => {
    console.log('Linetype', newValue.props.value);
    props.setIdLanguageOnMem(event.target.value, props.row);

    setSelectedPitcher(newValue.props.value);
  };

  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 230,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    //Profile Props',props )
    const url = process.env.REACT_APP_API_URL + '/Language?order=IdLanguage';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setDepartments(data.records);

        setIsLoading(false);
      });
  }, []);

  const printInputList = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Language
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedPitcher}
            onChange={handleIdBookChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {departments &&
              departments.map((row) => (
                <MenuItem value={row.IdLanguage}>{row.LangDesc}</MenuItem>
              ))}
          </Select>
          <FormHelperText>Languages</FormHelperText>
        </FormControl>
      </div>
    );
  };

  if (props.isInput) {
    return printInputList();
  }
}

export default BookList;
