import React, { useState, useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    drawer: {
      "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper ": {
        top:"0 !important"     
      }
    },
}));

export default function PaymentDrawer(props) {

  const [payments, setPayments] = useState(props.cryptoPayments);
  const classes = useStyles();

  const handleDismiss = (dismissedPayment) => {
    const updatedPayments = payments.filter(payment => payment !== dismissedPayment);
    setPayments(updatedPayments);
    props.setCryptoPayments(updatedPayments)
  };

  const toggleDrawer = (newOpen) => () => {
    props.setOpen(newOpen);
  };

  const CryptoCard = ({ payment }) => (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="subtitle1">
          New Crypto Payment
        </Typography>
        <Typography variant="subtitle2">
          {"Description: " + payment[3]}
        </Typography>
        <Typography variant="subtitle2">
          {"Amount: " + Math.round(payment[4])}
        </Typography>        
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => handleDismiss(payment)}>Dismiss</Button>
      </CardActions>
    </Card>
  );

  return (
    <Drawer className={classes.drawer} anchor={'right'} open={props.open} onClose={toggleDrawer(false)}>
      <div>
      <Card sx={{ minWidth: 275 }}>
      </Card>
        {payments.map((payment, index) => (
          <CryptoCard key={index} payment={payment} />
        ))}
      </div>
    </Drawer>
  );
}
