import React, { useState, useEffect, Component } from "react";
import { Redirect } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import "./AgentList.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { useHistory } from "react-router-dom";
import Loader from "../../common/loader/Loader";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function AgentList(props) {
  const [agents, setAgents] = useState();
  const [record, setRecord] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [value, setValue] = React.useState(0);
  const [selectedAgent, setSelectedAgent] = useState(props.initialValue);
  const [isLoading, setIsLoading] = useState();

 
  const handleIdLineTypeChange = (event, newValue) => {
    props.setIdAgentOnMem(event.target.value, props.row);

    setSelectedAgent(newValue.props.value);
  };

  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 230,
    },
    tableHead: {
      background: "#3f51b5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkcyan !important",
      },
    },

    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },
  });

  const classes = useStyles();

  useEffect(() => {
    GetAgentInfo();
  }, []);

  const GetAgentInfo = () => {
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + "/AGENT?order=Agent";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setAgents(data.records);

        setIsLoading(false);
      });
  };


  function selectedValues(row) {
    setRedirect(row);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 28, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: "<", // Previous page button text
    nextPage: ">", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false, // > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    // afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    //handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
  };

  const cellEditProp = {
    mode: "click", //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    //afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }
  const printInputList = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Agent
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedAgent}
            onChange={handleIdLineTypeChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {agents &&
              agents.map((row) => (
                <MenuItem value={row.IdAgent}>{row.Agent}</MenuItem>
              ))}
          </Select>
          <FormHelperText>Agents</FormHelperText>
        </FormControl>
      </div>
    );
  };
  const printList = () => {
    return (
      <div id="main-div profile-list" class="profile-list">
        <Paper className={classes.root}>
          <h2>Line Types</h2>

          {isLoading ? <Loader></Loader> : ""}
          <Table className={classes.table} aria-label="simple table">
            <TableBody className={showInfo ? "show-table" : "hide-table"}>
              {agents && (
                <div>
                  <BootstrapTable
                    pagination={true}
                    options={options}
                    cellEdit={cellEditProp} //DBClick daba problemas con el Expand
                    version="4"
                    data={agents}
                    // deleteRow={true}
                    hover
                  >
                    <TableHeaderColumn
                      isKey={true}
                      filter={{ type: "TextFilter", delay: 1000 }}
                      dataField="Agent"
                    >
                      Agent
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  };

  if (redirect) {
    let pushURL = "/lineType/" + redirect.IdLineType;

    props.setLineType(redirect);
    printList();
    setRedirect(null);
    return (
      <div>
        <Redirect
          to={{
            pathname: pushURL,
          }}
        />

        {}
      </div>
    );

    //history.push(pushURL);
  }

  if (props.isInput) {
    return printInputList();
  } else {
    return printList();
  }
}

export default AgentList;
