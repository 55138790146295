import React, {
  useContext,
  useState,
  setState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import searchGameValues from "./containers/LinesManager";
import ChatIcon from "@material-ui/icons/Chat";
import Home from "./components/Home";
import PaymentDrawer from "./components/PaymenysDrawer.js";
//import Games from './components/Games';
//import Users from './components/Users';

import Data from "./components/agents/Data";
import ExpandRow from "./components/ExpandRow";
import Badge from '@mui/material/Badge';
import MailIcon from '@material-ui/icons/Mail';

import Templates from "./components/templates/Templates";
import Profiles from "./components/profiles/Profiles";
import Profile from "./components/profiles/Profile";

import IconButton from "@material-ui/core/IconButton";
import LineTypes from "./components/lineTypes/LineTypes";
import LineType from "./components/lineTypes/LineType";

import WagerLimits from "./components/wagerLimits/WagerLimits";
import WagerLimit from "./components/wagerLimits/WagerLimit";

import Players from "./components/players/Players";
import Player from "./components/players/Player";
import PlayerRating from "./components/players/PlayerRating";

import GameTypes from "./components/common/GameTypes";
import Leagues from "./components/common/Leagues";
import PitcherList from "./components/common/PitcherList";
import OddsBySpport from "./components/common/OddsBySport"
import JuiceRebate from "./components/common/JuiceRebate";
import OddsDefaultBySport from "./components/common/OddsDefaultBySport";
import Events from "./components/events/events";

import Games from "./components/games/Games";
import Game from "./components/games/Game";

import Teams from "./components/teams/Teams";

import Users from "./components/users/Users";
import User from "./components/users/User";

import InfiniteList from "./components/InfiniteList";

import WebConfigurator from "./components/WebConfigurator/WebConfigurator";

import LinesManager from "./containers/LinesManager";
import LManager from "./containers/LManager";

import Agents from "./components/agents/Agents";
import Login from "./components/Login.js";

import Reports from "./components/reports/ReportsMenu";
import BetTicker from "./components/betTicker/BetTicker";
import LeagueMapping from "./components/leagueMapping/LeagueMapping";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useLocation } from "react-router-dom";

import { UserContext } from "./contexts/UserContext";
import openSocket from "socket.io-client";
import { forwardRef, useRef, useImperativeHandle } from "react";
import {
  subscribeToTimer,
  subscribeToAnnounceChange,
  subscribeToChanges,
} from "./socket/Subscriber";
/*Loader */
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReactLoading from "react-loading";

/*animation*/
import * as legoData from "./components/common/animations/legoLoader.json";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./styles/theme";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import Sports from "./components/common/Sports";

const announce = (idGame, idLinetype) => {
  //console.log("subscribeToChanges announce Function called", idGame);
  let gameInfo = { idGame: idGame, idLinetype: idLinetype };
  subscribeToAnnounceChange(gameInfo);
};

export default function App() {
  const dispatch = useDispatch();
  const [message, setMessage] = useState([]);
  const [socketResponse, setSocketResponse] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [recentGame, setRecentGame] = useState({ idGame: 0, idLinetype: 0 });
  const lineManagerGames = useSelector((state) => state.lineManagerGames);
  const selectedLinetype = useSelector((state) => state.selectedLinetype);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpen1, setMenuOpen1] = useState(false);
  const [menuOpen2, setMenuOpen2] = useState(false);
  const [menuOpen3, setMenuOpen3] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [anchorEl1, setAnchorEl1] = useState();
  const [anchorEl2, setAnchorEl2] = useState();
  const [anchorEl3, setAnchorEl3] = useState();
  const [anchorEl4, setAnchorEl4] = useState();
  const [menuOpen4, setMenuOpen4] = useState(false);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [cryptoPayments, setCryptoPayments] = useState([]);    
  const [lastcryptoPaymentId, setLastcryptoPaymentId] = useState(0) 


  const getLastHoldWagerId = () => {
    const url = process.env.REACT_APP_DEF_PHP_URL + '/CryptoPaymentGetLastIdPayment.php'
      Axios({
        method: 'post',
        url: url,
      }).then((response) => {         
            setLastcryptoPaymentId((response.data.data[0][0]));
            console.log("response",response)
            console.log("responseCC",response.data.data[0][0])
        });
  }

  useEffect(() => {
    if(cryptoPayments.length > 0) 
      setNotifications(cryptoPayments.length)
  }, [cryptoPayments]);

  useEffect(() => { 
    const interval = setInterval(() => {      
      getLastHoldWagerId();
      if(lastcryptoPaymentId !== 0)
        getCryptoPayments();
    }, 5000);
    return () => clearTimeout(interval);
  }, [lastcryptoPaymentId]);

  const getCryptoPayments = () => {   
    const url = process.env.REACT_APP_DEF_PHP_URL + '/GetCryptoPayments.php'
      Axios({
        method: 'post',
        url: url,
        data: {lastIdCryptoPayment : lastcryptoPaymentId},
      }).then((response) => {
        if(response.data.data.length > 0){
          response.data.data.map(
            (x)=>{
                setCryptoPayments(current => [x , ...current])                
            }
          )
        } 
        
        });
  }
  

  const recordButtonPosition = (event, anchor) => {
    console.log("recordButtonPosition", event);

    switch (anchor) {
      case "lines":
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
        break;
      case "players":
        setAnchorEl1(event.currentTarget);
        setMenuOpen1(true);
        break;
      case "agents":
        setAnchorEl2(event.currentTarget);
        setMenuOpen2(true);
        break;
      case "settings":
        setAnchorEl3(event.currentTarget);
        setMenuOpen3(true);
        break;
      case "maintenance":
        setAnchorEl4(event.currentTarget);
        setMenuOpen4(true);
        break;
    }
  };

  let closeMenu = () => {
    setMenuOpen(false);
    setMenuOpen1(false);
    setMenuOpen2(false);
    setMenuOpen3(false);
    setMenuOpen4(false);
  };
  const childRef = useRef();

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_NOTIFICATION_SRV_URL);
    socket.on("anounceChange", function (d) {
      setSocketResponse(d);
    });
    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    if (
      socketResponse &&
      socketResponse.idLinetype &&
      socketResponse.idGame &&
      selectedLinetype
    ) {
      let gameToUpdate = lineManagerGames.filter(
        (v) => v.IdGame == socketResponse.idGame
      )[0];
      console.log("msg announced socket   gameToUpdate", gameToUpdate);
      Axios.get(
        process.env.REACT_APP_API_URL +
          `/GAMEVALUES?filter=IdGame,eq,${socketResponse.idGame}&filter=IdLineType,eq,${selectedLinetype.value}`
      )
        .then((res) => {
          let newRecords = res.records[0];
          var i;
          if (gameToUpdate && newRecords) {
            let mappedGames = lineManagerGames.map((game) => {
              if (game.IdGame == socketResponse.idGame) {
                game.VisitorOdds = newRecords.VisitorOdds;
                game.HomeOdds = newRecords.HomeOdds;
                game.TotalOver = newRecords.TotalOver;
                game.OverOdds = newRecords.OverOdds;
                game.TotalUnder = newRecords.TotalUnder;
                game.UnderOdds = newRecords.UnderOdds;
                game.VisitorSpread = newRecords.VisitorSpread;
                game.VisitorSpreadOdds = newRecords.VisitorSpreadOdds;
                game.HomeSpread = newRecords.HomeSpread;
                game.HomeSpreadOdds = newRecords.HomeSpreadOdds;
                game.VisitorSpecial = newRecords.VisitorSpecial;
                game.VisitorSpecialOdds = newRecords.VisitorSpecialOdds;
                game.HomeSpecial = newRecords.HomeSpecial;
                game.HomeSpecialOdds = newRecords.HomeSpecialOdds;
              }
              return game;
            });
            dispatch({
              type: "SET_LINEMANAGER_GAMES",
              value: mappedGames,
            });
          }
        })
        .catch((e) => {
          console.log("ERROR WHILE GETTING GAMEVALUES", e);
        });
    }

    if (
      socketResponse &&
      socketResponse.msg &&
      socketResponse.msg.records &&
      selectedLinetype
    ) {
      let foundLinetype = false;
      const uniqueIdGamesIncoming = [
        ...new Set(socketResponse.msg.records.map((item) => item.IdGame)),
      ];
      const uniqueIdGames = [
        ...new Set(lineManagerGames.map((item) => item.IdGame)),
      ];
      const uniqueIdLinetypes = [
        ...new Set(socketResponse.msg.records.map((item) => item.IdLineType)),
      ];
      for (let i = 0; i < uniqueIdLinetypes.length; i++) {
        const el = uniqueIdLinetypes[i];
        let linetypeval = selectedLinetype.value
          ? parseInt(selectedLinetype.value)
          : parseInt(selectedLinetype);
        if (el == linetypeval) {
          foundLinetype = true;
          break;
        }
      }
      let foundUniqueIdGames = uniqueIdGames.filter((e) =>
        uniqueIdGamesIncoming.includes(e)
      );
      //Check if incoming linetypes have selected linetype
      if (foundLinetype) {
        // let filteredIncomingGamesPerLineType = socketResponse.msg.records.map((item) => item.IdLineType == selectedLinetype)
        let foundGames = [];
        for (let i = 0; i < uniqueIdGames.length; i++) {
          const idGame = uniqueIdGames[i];
          let hasIdGame = uniqueIdGamesIncoming.filter((v) => v == idGame);

          if (hasIdGame) {
            foundGames.push(idGame);
          }
        }
        let mappedGames = lineManagerGames;
        foundGames.forEach((incomingIdGame) => {
          let gameToUpdate = lineManagerGames.filter(
            (v) => v.IdGame == incomingIdGame
          )[0];
          let newRecords = socketResponse.msg.records.find(
            (v) =>
              v.IdGame == incomingIdGame &&
              v.IdLineType ==
                (selectedLinetype.value
                  ? selectedLinetype.value
                  : selectedLinetype)
          );
          if (gameToUpdate && newRecords) {            
            mappedGames = mappedGames.map((game) => {
              if (game && game.IdGame == incomingIdGame) {
                game.VisitorOdds = newRecords.VisitorOdds;
                game.HomeOdds = newRecords.HomeOdds;
                game.TotalOver = newRecords.TotalOver;
                game.OverOdds = newRecords.OverOdds;
                game.TotalUnder = newRecords.TotalUnder;
                game.UnderOdds = newRecords.UnderOdds;
                game.VisitorSpread = newRecords.VisitorSpread;
                game.VisitorSpreadOdds = newRecords.VisitorSpreadOdds;
                game.HomeSpread = newRecords.HomeSpread;
                game.HomeSpreadOdds = newRecords.HomeSpreadOdds;
                game.VisitorSpecial = newRecords.VisitorSpecial;
                game.VisitorSpecialOdds = newRecords.VisitorSpecialOdds;
                game.HomeSpecial = newRecords.HomeSpecial;
                game.HomeSpecialOdds = newRecords.HomeSpecialOdds;
                game.isHighlight = true;
              }
              return game;
            });
          }
        });
        dispatch({
          type: "SET_LINEMANAGER_GAMES",
          value: mappedGames,
        });
      }
    }
  }, [socketResponse]);
  const getRecentGame = () => {
    return recentGame.idGame;
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const toggleLoader = () => {
    if (showLoader) {
      return (
        <FadeIn>
          <div class="d-flex justify-content-center align-items-center">
            <Lottie options={defaultOptions} height={360} width={360} />
          </div>
        </FadeIn>
      );
    }
  };

  const showLoading = (value) => {
    setShowLoader(value);
  };

  return (
    <div>
    <BrowserRouter>
      <Route
        render={({ location }) => {
          return (
            <UserContext.Provider value="Hellos">
              <ThemeProvider theme={theme}>
                <div>
                  {location.pathname != "/login" ? (
                    location.pathname != "/" ? (
                      <div>
                        <AppBar title="My App">
                          <Tabs scrollButtons="auto" variant="scrollable">
                            <Tab to="/home" component={Link} label="Home" />
                            <Tab
                              to="/"
                              label="LInesManager Tools"
                              onClick={(e) => recordButtonPosition(e, "lines")}
                            />

                            <Menu
                              anchorEl={anchorEl}
                              open={menuOpen}
                              onClose={closeMenu}
                            >
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/linesmanager"
                              >
                                {" "}
                                Lines Manager{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/betticker"
                              >
                                {" "}
                                BetTicker{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/linetypes"
                              >
                                {" "}
                                Line Types{" "}
                              </MenuItem>

                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/games"
                              >
                                {" "}
                                Games{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/gametypes"
                              >
                                {" "}
                                Game Types{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/reports"
                              >
                                {" "}
                                Reports{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/leagues"
                              >
                                {" "}
                                Leagues{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/teams"
                              >
                                {" "}
                                Teams{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/events"
                              >
                                {" "}
                                Events{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/leagueMapping"
                              >
                                {" "}
                                League Mapping{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/pitcherList"
                              >
                                {" "}
                                Pitchers{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/sport"
                              >
                                {" "}
                                Sports{" "}
                              </MenuItem>
                            </Menu>
                            <Tab
                              to="/"
                              label="Player Tools"
                              onClick={(e) =>
                                recordButtonPosition(e, "players")
                              }
                            />

                            <Menu
                              anchorEl={anchorEl1}
                              open={menuOpen1}
                              onClose={closeMenu}
                            >
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/players"
                              >
                                {" "}
                                Players{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/profiles"
                              >
                                {" "}
                                Profiles{" "}
                              </MenuItem>
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/playerRating"
                              >
                                {" "}
                                Player Rating{" "}
                              </MenuItem>
                            </Menu>

                            <Tab
                              to="/"
                              label="Agents Tools"
                              onClick={(e) => recordButtonPosition(e, "agents")}
                            />

                            <Menu
                              anchorEl={anchorEl2}
                              open={menuOpen2}
                              onClose={closeMenu}
                            >
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/data"
                              >
                                {" "}
                                Agents{" "}
                              </MenuItem>
                            </Menu>

                            <Tab
                              to="/"
                              label="System"
                              onClick={(e) =>
                                recordButtonPosition(e, "settings")
                              }
                            />

                            <Menu
                              anchorEl={anchorEl3}
                              open={menuOpen3}
                              onClose={closeMenu}
                            >
                       
                    
                              <MenuItem component={Link}  onClick={closeMenu}  to="/users"> Users </MenuItem>  
                              
                              <MenuItem
                                component={Link}
                                onClick={closeMenu}
                                to="/templates"
                              >Templates</MenuItem>     
                              <MenuItem component={Link}  onClick={closeMenu}  to="/wagerLimits"> Wager Limits </MenuItem> 
                              <MenuItem component={Link}  onClick={closeMenu}  to="/webConfigurator"> Web </MenuItem> 
                             
                            </Menu>                            
                            <Tab
                              to="/"
                              label="Maintenance"
                              onClick={(e) =>
                                recordButtonPosition(e, "maintenance")
                              }
                            />
                              <Menu
                                anchorEl={anchorEl4}
                                open={menuOpen4}
                                onClose={closeMenu}
                              >
                                <MenuItem
                                  component={Link}
                                  onClick={closeMenu}
                                  to="/oddsBySport"
                                >
                                  {" "}
                                  Odds By Sport{" "}
                                </MenuItem>
                                <MenuItem
                                  component={Link}
                                  onClick={closeMenu}
                                  to="/juiceRebate"
                                >
                                  {" "}
                                  Juice Rebate{" "}
                                </MenuItem>
                                <MenuItem
                                  component={Link}
                                  onClick={closeMenu}
                                  to="/oddsDefaultBySport"
                                >
                                  {" "}
                                  Odds Default By Sport{" "}
                                </MenuItem>
                              </Menu>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={()=>(setOpen(true))}
                                aria-label="close"
                              >
                                <Badge badgeContent={notifications} color="primary">
                                  <MailIcon color="action" />
                                </Badge>
                              </IconButton>
                          </Tabs>

                        </AppBar>
                        <div style={{ height: "90px" }}></div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {/* A <Switch> looks through its children <Route>s and
                  renders the first one that matches the current URL. */}
                  <Switch>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/users">
                      <Users />
                    </Route>
                    <Route path="/templates">
                      <Templates ref={childRef} showLoading={showLoading} />
                    </Route>
                    <Route path="/games">
                      <Games />
                    </Route>

                    <Route path="/gametypes">
                      <GameTypes isInput={false} />
                    </Route>
                    <Route path="/betticker">
                      <BetTicker isInput={false} message={message} />
                    </Route>

                    <Route path="/leagues">
                      <Leagues showDataTable={true} />
                    </Route>

                    <Route path="/events">
                      <Events showDataTable={true} />
                    </Route>

                    <Route path="/linesmanager">
                      <LinesManager
                        getRecentGame={getRecentGame}
                        setRecentGame={setRecentGame}
                        announce={announce}
                        ref={childRef}
                      />
                    </Route>
                    <Route path="/lmanager">
                      <LManager announce={announce} ref={childRef} />
                    </Route>

                    <Route path="/agents">
                      <Agents ref={childRef} />
                    </Route>

                    <Route path="/expandRow">
                      <ExpandRow ref={childRef} />
                    </Route>
                    <Route path="/data">
                      <Data ref={childRef} />
                    </Route>
                    <Route path="/InfiniteList">
                      <InfiniteList ref={childRef} />
                    </Route>
                    <Route path="/agentContainer/:id" component={Agents} />

                    <Route
                      path="/profile/:id"
                      component={Profile}
                      showLoading={showLoading}
                    />
                    <Route path="/profiles">
                      <Profiles ref={childRef} showLoading={showLoading} />
                    </Route>

                    <Route path="/users">
                      <Users ref={childRef} showLoading={showLoading} />
                    </Route>
                    <Route
                      path="/user/:id"
                      component={User}
                      showLoading={showLoading}
                    />

                    <Route path="/webConfigurator">
                      <WebConfigurator showLoading={showLoading} />
                    </Route>

                    <Route
                      path="/wagerLimit/:id"
                      component={WagerLimit}
                      showLoading={showLoading}
                    />
                    <Route path="/wagerLimits">
                      <WagerLimits ref={childRef} showLoading={showLoading} />
                    </Route>

                    <Route
                      path="/lineType/:id"
                      component={LineType}
                      showLoading={showLoading}
                    />
                    <Route path="/lineTypes">
                      <LineTypes ref={childRef} showLoading={showLoading} />
                    </Route>

                    <Route
                      path="/player/:id"
                      component={Player}
                      showLoading={showLoading}
                    />

                    <Route path="/players">
                      <Players ref={childRef} showLoading={showLoading} />
                    </Route>
                    <Route
                      path="/game/:id"
                      component={Game}
                      showLoading={showLoading}
                    />

                    <Route path="/games">
                      <Games ref={childRef} showLoading={showLoading} />
                    </Route>

                    <Route path="/teams">
                      <Teams ref={childRef} showLoading={showLoading} />
                    </Route>
                    <Route path="/templates">
                      <Templates ref={childRef} showLoading={showLoading} />
                    </Route>
                    <Route path="/reports">
                      <Reports ref={childRef} showLoading={showLoading} />
                    </Route>
                    <Route path="/home">
                      <Home />
                    </Route>
                    <Route path="/leagueMapping">
                      <LeagueMapping />
                    </Route>
                    
                    <Route path="/oddsBySport">
                      <OddsBySpport/>
                    </Route>

                    <Route path="/juiceRebate">
                      <JuiceRebate/>
                    </Route>

                    <Route path="/oddsDefaultBySport">
                      <OddsDefaultBySport/>
                    </Route>

                    <Route path="/playerRating">
                      <PlayerRating/>
                    </Route>
                    
                    <Route path="/pitcherList">
                      <PitcherList 
                        table={true}
                      />
                    </Route>
                    <Route path="/sport">
                      <Sports
                        showDataTable={true}
                      />
                    </Route>
                    {/*<Route path="/">
                      <Login />
                </Route>*/}

                    <Route path="/">
                      <Login />
                    </Route>
                  </Switch>

                  <div> Version 1.3.13.52</div>
                </div>
              </ThemeProvider>
            </UserContext.Provider>
          );
        }}
      />
    </BrowserRouter>
    {open ? 
      (
        <PaymentDrawer 
          cryptoPayments={cryptoPayments} 
          setOpen={setOpen} 
          setNotifications={setNotifications} 
          open={open}
          setCryptoPayments={setCryptoPayments}
        />
      ) : 
      (""
      )
    }
    </div>
  );
}
