import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../contexts/UserContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Loader from '../components/common/loader/Loader';

export default function CreateGame(props) {
  const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  const handleOk = async () => {
    console.log("props",props)
    setIsLoading(true);
    let deletedGames = 0;
    for (let i = 0; i < props.IdGames.length; i++) {
      let deleteRequest = {
        IdGame: props.IdGames[i],
        IdUser: 498,
      };
      console.log(deleteRequest);
      await axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/Game/DeleteGame',
        data: deleteRequest,
      }).then((response) => {
        if (response.data == '0') {
          deletedGames++;
        } else if (response.data == '1') {
          alert('Cannot delete the game ' + props.IdGames[i] + 'due to its status.');
        } else if (response.data == '2') {
          alert('Cannot delete the game ' + props.IdGames[i] + ', as it has family.');
        } else if (response.data == '3') {
          alert('Cannot delete the game '+ props.IdGames[i] +', as it has periods.');
        } else if (response.data == '4') {
          alert('Cannot delete the game ' + props.IdGames[i] + ', as it has bets.');
        } else if (response.data == '5') {
          alert('Cannot delete the game ' + props.IdGames[i] + ', as it has graded bets.');
        } else if (response.data == '6') {
          alert('Cannot delete the game ' + props.IdGames[i] + ', as it has pending bets.');
        }
        console.log('response', response);
      });
    }
    if(deletedGames>0){
      alert(deletedGames + ' game(s) were successfully deleted')
    }
    props.clearArray();
    props.searchGameValues();
    setIsLoading(false);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Delete Game
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogActions>
          {isLoading ? (
            <Loader height={100} width={100}></Loader>
          ) : (
            <div>
              <Button
                color="inherit"
                startIcon={<CancelIcon />}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                startIcon={<SaveIcon />}
                color="inherit"
                onClick={handleOk}
              >
                Ok
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
