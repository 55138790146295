import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../common/InputSelect';
import LineTypeList from '../../lineTypes/lineTypesComponents/LineTypeList';
import AgentList from '../../agents/agentComponents/AgentList';

import OfficeList from '../../offices/officeComponents/OfficeList';
import BookList from '../../books/bookComponents/BookList';
import WagerLimitsList from '../../wagerLimits/wagerLimitsComponents/WagerLimitsList';
import ProfileList from '../../profiles/profileComponents/ProfileList';
import TimeZoneList from '../../timeZones/TimeZoneList'
/*Other Components*/
import Sports from '../../common/Sports';
import Periods from '../../common/Periods';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './PlayerSettings.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const numberFormatter = (cell, row) => {
  return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(
    cell
  );
};
const linetypeFormatter2 = (cell, row) => {
  let isSelected = '';
  let isSelected0 = '';
  let isSelected1 = '';
  let isSelected2 = '';
  let isSelected3 = '';
  let isSelected4 = '';

  switch (cell) {
    case 0:
      isSelected0 = 'checked';
      break;
    case 1:
      isSelected1 = 'checked';
      break;
    case 2:
      isSelected2 = 'checked';
      break;
    case 3:
      isSelected3 = 'checked';
      break;
    case 4:
      isSelected4 = 'checked';
      break;
    default:
      isSelected = 'checked';
  }

  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>`;
};
export default function PlayerSettings(props) {
  const classes = useStyles();
  const [gameRelatedOptions, setGameRelatedOptions] = React.useState([]);
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [period, setPeriod] = React.useState(['0']);
  const [timeZone, setTimeZone] = React.useState(0);
  const [idLineType, setIdLineType] = React.useState(0);
  const [idAgent, setIdAgent] = React.useState(0);
  const [idBook, setIdBook] = useState(0);
  const [idOffice, setIdOffice] = React.useState(0);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  let idPlayer = props.Player.IdPlayer;
  const bookFormatter = (cell, row) => {
    return (
      <BookList
        isInput={true}
        initialValue={cell}
        row={row}
        setIdBookOnMem={setIdBookOnMem}
      ></BookList>
    );
  };
  const linetypeFormatter = (cell, row) => {
    return (
      <LineTypeList
        isInput={true}
        initialValue={cell}
        row={row}
        setIdLineTypeOnMem={setIdLineTypeOnMem}
      ></LineTypeList>
    );
  };
  const agentFormatter = (cell, row) => {
    return (
      <AgentList
        isInput={true}
        initialValue={cell}
        row={row}
        setIdAgentOnMem={setIdAgentOnMem}
      ></AgentList>
    );
  };
  const officeFormatter = (cell, row) => {
    return (
      <OfficeList
        isInput={true}
        initialValue={cell}
        row={row}
        setIdOfficeOnMem={setIdOfficeOnMem}
      ></OfficeList>
    );
  };

  const profileLimitFormatter = (cell, row) => {
    return (
      <WagerLimitsList
        isInput={true}
        initialValue={cell}
        row={row}
        setProfileLimitOnMem={setProfileLimitOnMem}
      ></WagerLimitsList>
    );
  };
  const profileFormatter = (cell, row) => {
    return (
      <ProfileList
        isInput={true}
        initialValue={cell}
        row={row}
        setProfileOnMem={setProfileOnMem}
      ></ProfileList>
    );
  };

  
  const timeZoneFormatter = (cell, row) => {
    return (
      <TimeZoneList
        isInput={true}
        initialValue={cell}
        row={row}
        setProfileOnMem={setTimeZoneOnMem}
      ></TimeZoneList>
    );
  };
  // let IdPeriod = '0';
  useEffect(() => {
    // Change for server api address

    const url =
      process.env.REACT_APP_API_URL +
      '/GAMERELATEDOPTIONS' +
      '?filter=IdPlayer,eq,' +
      idPlayer +
      '&filter=IdSport,eq,' +
      idSport +
      '&filter=IdPeriod,eq,' +
      period;
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      setGameRelatedOptions(response.data.records[0]);
    });

    setIsLoading(false);
  }, [idSport, period, idPlayer]);
  // const [selectedIdGame, setselectedIdGame] = useState();

  function onAfterSaveCell(row, cellName, cellValue) {
    console.log("row",row)
    props.UpdatePlayer(row);
    props.setStatisticsToReload();
  }
  function onAfterSaveCellRelated(row, cellName, cellValue) {
    UpdateRelatedGame(row);
  }
  function UpdateRelatedGame(row) {
    let gameRelatedOptionsRequest = {
      IdPlayer: row.IdPlayer,
      IdSport: row.IdSport,
      IdPeriod: row.IdPeriod,
      MLFav_Over: row.MLFav_Over,
      MLFav_Under: row.MLFav_Under,
      MLDog_Over: row.MLDog_Over,
      MLDog_Under: row.MLDog_Under,
      SpreadFav_Over: row.SprFav_Over,
      SpreadFav_Under: row.SprFav_Under,
      SpreadDog_Over: row.SprDog_Over,
      SpreadDog_Under: row.SprDog_Under,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Game/GameRelatedOptions_Update',
      // url: 'https://localhost:44337/api/Game/GameRelatedOptions_Update',
      data: gameRelatedOptionsRequest,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
    });
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });

      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
    searchPanel: (props) => <MySearchPanel {...props} />,
  };

  const onBeforeSaveCell = (row, cellName, cellValue, done) => {
    if(window.confirm("Are you sure you want to edit the selected item?")){
      done(true);
    }else{
      done(false);
    }  
    return 1
  } 

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
    beforeSaveCell: onBeforeSaveCell,
  };
  const cellEditRelatedGameProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCellRelated, // a hook for after saving cell
  };

  const setIdBookOnMem = (IdBook, row) => {
    setIdBook(IdBook);
    row.IdBook = IdBook;
    props.UpdatePlayer(row);
  };

  const setIdLineTypeOnMem = (IdLineType, row) => {
    setIdLineType(IdLineType);
    row.IdLineType = IdLineType;
    props.UpdatePlayer(row);
  };
  const setIdAgentOnMem = (IdAgent, row) => {
    setIdAgent(IdAgent);
    row.IdAgent = IdAgent;
    props.UpdatePlayer(row);
  };

  const setIdOfficeOnMem = (IdOffice, row) => {
    setIdOffice(IdOffice);
    row.IdOffice = IdOffice;
    props.UpdatePlayer(row);
  };
  const setProfileLimitOnMem = (IdProfileLimit, row) => {
    setIdOffice(IdProfileLimit);
    row.IdOffice = IdProfileLimit;
    props.UpdatePlayer(row);
  };
  const setProfileOnMem = (IdProfile, row) => {
    setIdOffice(IdProfile);
    row.IdOffice = IdProfile;
    props.UpdatePlayer(row);
  };

  const setTimeZoneOnMem = (IdTimeZone, row) => {
    setTimeZone(IdTimeZone);
    row.IdTimeZone = IdTimeZone;
    props.UpdatePlayer(row);
  };
  const setPeriodOnMem = (period) => {
    setPeriod(period);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  class MySearchPanel extends React.Component {
    render() {
      return (
        <div>
          <div className="input-group">
            <span className="input-group-btn">
              <button className="btn btn-primary" type="button">
                CustomButton1
              </button>

              {this.props.clearBtn}
            </span>
          </div>
          {this.props.searchField}
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <Table>
          <TableRow>
            <TableCell colSpan="2">
            <h2>Player Info</h2>
              {/**/}
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn dataField="Player">Player</TableHeaderColumn>

                <TableHeaderColumn dataField="Password">
                  Password
                </TableHeaderColumn>

                <TableHeaderColumn dataField="OnlinePassword">
                  OnlinePassword
                </TableHeaderColumn>

                <TableHeaderColumn dataField="Name">Name</TableHeaderColumn>

                <TableHeaderColumn dataField="LastName">
                  LastName
                </TableHeaderColumn>

                <TableHeaderColumn dataField="LastName2">
                  LastName2
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Email">
                  Email Address
                </TableHeaderColumn>
              </BootstrapTable>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn dataField="Address1">
                  Address1
                </TableHeaderColumn>

                <TableHeaderColumn dataField="Address2">
                  Address2
                </TableHeaderColumn>

                <TableHeaderColumn dataField="City">City</TableHeaderColumn>

                <TableHeaderColumn dataField="State">State</TableHeaderColumn>

                <TableHeaderColumn dataField="Country">
                  Country
                </TableHeaderColumn>

                <TableHeaderColumn dataField="Zip">Zip</TableHeaderColumn>
                <TableHeaderColumn dataField="Phone">Phone</TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
            <h2>Limits</h2>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={numberFormatter}
                  dataField="CreditLimit"
                >
                  CreditLimit
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={numberFormatter}
                  dataField="TempCredit"
                >
                  TempCredit
                </TableHeaderColumn>
                <TableHeaderColumn dataField="TempCreditExpire">
                  Temp Credit Expire
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
            
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={numberFormatter}
                  dataField="OnlineMaxWager"
                >
                  OnlineMaxWager
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={numberFormatter}
                  dataField="OnlineMinWager"
                >
                  OnlineMinWager
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={numberFormatter}
                  dataField="MaxWager"
                >
                  MaxWager
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={numberFormatter}
                  dataField="MinWager"
                >
                  MinWager
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={numberFormatter}
                  dataField="CapPerGame"
                >
                  CapPerGame
                </TableHeaderColumn>

                <TableHeaderColumn dataField="ChartPercent">
                  ChartPercent
                </TableHeaderColumn>

                <TableHeaderColumn dataField="MasterChart">
                  MasterChart
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
            <h2>Profile</h2>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={linetypeFormatter}
                  dataField="IdLineType"
                  editable={false}
                >
                  IdLineType
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={officeFormatter}
                  dataField="IdOffice"
                  editable={false}
                >
                  IdOffice
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={agentFormatter}
                  dataField="IdAgent"
                  editable={false}
                >
                  IdAgent
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={profileFormatter}
                  dataField="IdProfile"
                  editable={false}
                >
                  IdProfile
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={bookFormatter}
                  dataField="IdBook"
                  editable={false}
                >
                  IdBook
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={profileLimitFormatter}
                  dataField="IdProfileLimits"
                  editable={false}
                >
                  IdProfileLimits
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
            <h2>Permissions and Preferences</h2>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Status">Status</TableHeaderColumn>
                <TableHeaderColumn
                  dataField="EnableSports"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  EnableSports
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="EnableCasino"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  EnableCasino
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="EnableHorses"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  EnableHorses
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="ShowInTicker"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  ShowInTicker
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn 
                dataField="IdTimeZone"
                          dataFormat={timeZoneFormatter}
                >
                  TimeZone
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdCurrency">
                  IdCurrency
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdGrouping">
                  IdGrouping
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdSource">
                  IdSource
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdPlayerRate">
                  IdPlayerRate
                </TableHeaderColumn>
                <TableHeaderColumn dataField="IdSource">
                  IdSource
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdCurrency">
                  IdCurrency
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdGrouping">
                  IdGrouping
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
            <h2>Misc</h2>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn dataField="PitcherDefault">
                  PitcherDefault
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdLanguage">
                  IdLanguage
                </TableHeaderColumn>

                <TableHeaderColumn dataField="SignUpIP">
                  SignUpIP
                </TableHeaderColumn>

                <TableHeaderColumn 
                  dataField="AllowNegTrans"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  AllowNegTrans
                </TableHeaderColumn>
                <TableHeaderColumn 
                  dataField="HoldBets"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  HoldBets
                </TableHeaderColumn>

                <TableHeaderColumn dataField="HoldDelay">
                  HoldDelay
                </TableHeaderColumn>

                <TableHeaderColumn dataField="IdAffiliate">
                  IdAffiliate
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>

                <TableHeaderColumn dataField="ScheduleStyle">
                  ScheduleStyle
                </TableHeaderColumn>

                <TableHeaderColumn dataField="LineStyle">
                  LineStyle
                </TableHeaderColumn>

                <TableHeaderColumn dataField="NHLLine">
                  NHLLine
                </TableHeaderColumn>

                <TableHeaderColumn dataField="MLBLine">
                  MLBLine
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="ScheduleFB"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  ScheduleFB
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="ScheduleBB"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  ScheduleBB
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="ScheduleHK"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  ScheduleHK
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="ScheduleBS"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  ScheduleBS
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <BootstrapTable
                data={[props.Player]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="IdPlayer">
                  IdPlayer
                </TableHeaderColumn>
                <TableHeaderColumn dataField="SecQuestion">
                  SecQuestion
                </TableHeaderColumn>
                <TableHeaderColumn dataField="SecAnswer">
                  SecAnswer
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}
