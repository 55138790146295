import React, { useState, useEffect, Component } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import $ from "jquery";
import AgentList from "../agents/agentComponents/AgentList";
import "./Players.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import PlayerList from "../PlayerList";
import ClonePlayer from "./playerComponents/ClonePlayer"
import Loader from "../common/loader/Loader";
import { Button, CircularProgress, Input, TableHead } from "@material-ui/core";
import { DelayInput } from "react-delay-input";
import TextField from "@material-ui/core/TextField";
import { Grid } from "react-virtualized";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Players(props) {
  const [players, setPlayers] = useState();
  const [selectedPlayer,setSelectedPlayer] = useState({});
  const [playersUnfiltered, setPlayersUnfiltered] = useState();
  const [books, setBooks] = useState([]);
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [duplicatePlayer, setDuplicatePlayer] = useState();
  const [duplicatePlayerPwd, setDuplicatePlayerPwd] = useState();
  const [duplicatePlayerPhone, setDuplicatePlayerPhone] = useState();
  const [duplicatePlayerEmail, setDuplicatePlayerEmail] = useState();
  const [idPlayerToDuplicate, setIdPlayerToDuplicate] = useState();
  const [playerToDuplicate, setPlayerToDuplicate] = useState();
  const [showCloneButton, setShowCloneButton] = useState(false);
  const [isCloningPlayer, setCloningPlayer] = useState(false)
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [value, setValue] = React.useState(0);
  const [searchProgress, setSearchProgress] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [agents, setAgents] = useState();
  const [lineType, setLineType] = useState();
  const [currency, setCurrency] = useState();
  const [office, setOffice] = useState();
  const [grouping, setGrouping] = useState();
  const [playerRate, setPlayerRate] = useState();
  const [playerProfile, setPlayerProfile] = useState();
  const [source,setSource] = useState();
  const [profileLimits, setProfileLimits] = useState();
  const [flagMessageType, setFlagMessageType] = useState();
  const [timeZone, setTimeZone] = useState();
  const [language, setLanguage] = useState();
  const [playerNotes, setPlayerNotes] = useState();
  const [showSearchfilters, setShowSearchfilters] = useState(false);

  const [currentPlayerFilter, setCurrentPlayerFilter] = useState("");

  const [searchPlayerOnly, setSearchPlayerOnly] = useState(true);
  const [searchPasswordOnly, setSearchPasswordOnly] = useState(true);
  const [searchNameOnly, setSearchNameOnly] = useState(true);
  const [searchLastNameOnly, setSearchLastNameOnly] = useState(true);
  const [searchPhoneOnly, setSearchPhoneOnly] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const baseURL = process.env.REACT_APP_API_URL + "/PLAYER?";

  const filterPlayer = "&filter4=Player,cs," + currentPlayerFilter;
  const filterPassword = "&filter1=Password,cs," + currentPlayerFilter;
  const filterName = "&filter2=Name,cs," + currentPlayerFilter;
  const filterLastName = "&filter3=LastName,cs," + currentPlayerFilter;

  const filterPhone = "&filter5=Phone,cs," + currentPlayerFilter;

  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#3f51b5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkcyan !important",
      },
    },

    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },
  });

  const classes = useStyles();

  useEffect(() => {
    //Change for server api address
    if (currentPlayerFilter == "") {
      setPlayers([]);
      setRecord({});
    } else {
      LoadProfile();
    }
  }, [currentPlayerFilter]);

  const LoadProfile = () => {
    let config = {
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setSearchProgress(percentCompleted);
        // do whatever you like with the percentage complete
        // maybe dispatch an action that will update a progress bar or something
      },
    };

    setIsLoading(true);
    //const url = process.env.REACT_APP_API_URL + '/PLAYERS/';

    let url =
      `${baseURL}` +
      `${searchPlayerOnly ? filterPlayer : ""}` +
      `${searchPasswordOnly ? filterPassword : ""}` +
      `${searchNameOnly ? filterName : ""}` +
      `${searchLastNameOnly ? filterLastName : ""}` +
      `${searchPhoneOnly ? filterPhone : ""}` +
      `&filter=IdBook,in,${userInfo.commaSeparatedBooks}&size=2500&satisfy=any`;

    console.log(baseURL);
    console.log(searchPasswordOnly);
    console.log(url);
    axios
      .get(url, config)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setPlayers(data.records);

        setIsLoading(false);
      });
  };
  /* function filterPlayers() {
    let filteredplayers = [];
    if (currentPlayerFilter == '') {
      setPlayers(playersUnfiltered);
    } else {
      playersUnfiltered.forEach((player) => {
        let found = false;

        for (const prop in player) {
          if (
            typeof player[prop] === 'string' &&
            player[prop].includes(currentPlayerFilter)
          ) {
            found = true;
            break;
          } else if (player[prop] == currentPlayerFilter) {
            found = true;
            break;
          }
        }

        if (found) {
          filteredplayers.push(player);
        }
      });
      setPlayers(filteredplayers);
    }
  }*/

  useEffect(() => {
    GetBookInfo();
    GetAgentInfo();
    GetLineTypefo();
    GetCurrencyInfo();
    GetOfficeInfo();
    GetGroupingInfo();
    GetPlayerRateInfo();
    GetPlayerProfileInfo();
    GetProfileLimitInfo();
    GetFlagMessageTypeInfo();
    GetTimeZoneInfo();
    GetLanguageInfo();
    GetSourceInfo();
    GetPlayerNotes();
    //LoadProfile();
  }, []);

  const GetTimeZoneInfo = () => {
    const url = process.env.REACT_APP_API_URL + "/TIMEZONE?order=IdTimeZone";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let timeZoneInfo = data.records.map((v) => {
          v.value = v.IdTimeZone;
          v.text = v.Description;
          return v;
        });
        setTimeZone(timeZoneInfo);
      });
  };

  const GetLanguageInfo = () => {
    const url = process.env.REACT_APP_API_URL + "/Language?order=IdLanguage";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let languageInfo = data.records.map((v) => {
          v.value = v.IdLanguage;
          v.text = v.LangDesc;
          return v;
        });
        setLanguage(languageInfo);
      });
  };

  const GetFlagMessageTypeInfo = () => {
    const url =
      process.env.REACT_APP_API_URL +
      "/FLAGMESSAGETYPE?order=IdFlagMessageType";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let messageTypeInfo = data.records.map((v) => {
          v.value = v.IdFlagMessageType;
          v.text = v.Description;
          return v;
        });
        setFlagMessageType(messageTypeInfo);
      });
  };

  const GetProfileLimitInfo = () => {
    const url =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILELIMITS?order=IdProfileLimits";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let limitsInfo = data.records.map((v) => {
          v.value = v.IdProfileLimits;
          v.text = v.ProfileName;
          return v;
        });
        setProfileLimits(limitsInfo);
      });
  };

  const GetPlayerRateInfo = () => {
    const url =
      process.env.REACT_APP_API_URL + "/PLAYERRATE?order=IdPlayerRate";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let rateInfo = data.records.map((v) => {
          v.value = v.IdPlayerRate;
          v.text = v.Description;
          return v;
        });
        setPlayerRate(rateInfo);
      });
  };
  const GetPlayerProfileInfo = () => {
    const url =
      process.env.REACT_APP_API_URL + "/PLAYERPROFILE?order=IdProfile";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let profileInfo = data.records.map((v) => {
          v.value = v.IdProfile;
          v.text = v.ProfileName;
          return v;
        });
        setPlayerProfile(profileInfo);
      });
  };

  const GetBookInfo = () => {
    const url = process.env.REACT_APP_API_URL + "/BOOK?order=IdBook";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfoInfo = data.records.map((v) => {
          v.value = v.IdBook;
          v.text = v.Description;
          return v;
        });
        setBooks(bookInfoInfo);
      });
  };

  const GetAgentInfo = () => {
    //Profile Props',props )
    const url = process.env.REACT_APP_API_URL + "/AGENT?order=IdAgent";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let agentsInfo = data.records.map((v) => {
          v.value = v.IdAgent;
          v.text = v.Agent;
          return v;
        });
        setAgents(agentsInfo);
      });
  };
  const GetLineTypefo = () => {
    //Profile Props',props )
    const url = process.env.REACT_APP_API_URL + "/LINETYPE?order=IdLineType";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let lineTypenfo = data.records.map((v) => {
          v.value = v.IdLineType;
          v.text = v.Description;
          return v;
        });
        setLineType(lineTypenfo);
      });
  };
  const GetCurrencyInfo = () => {
    //Profile Props',props )
    const url = process.env.REACT_APP_API_URL + "/CURRENCY?order=IdCurrency";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let currencyInfo = data.records.map((v) => {
          v.value = v.IdCurrency;
          v.text = v.Currency;
          return v;
        });
        setCurrency(currencyInfo);
      });
  };

  const GetOfficeInfo = () => {
    //Profile Props',props )
    const url = process.env.REACT_APP_API_URL + "/OFFICE?order=IdOffice";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let officeInfo = data.records.map((v) => {
          v.value = v.IdOffice;
          v.text = v.OfficeName;
          return v;
        });
        setOffice(officeInfo);
      });
  };

  const GetGroupingInfo = () => {
    //Profile Props',props )
    const url = process.env.REACT_APP_API_URL + "/GROUPING?order=IdGrouping";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let groupingInfo = data.records.map((v) => {
          v.value = v.IdGrouping;
          v.text = v.GroupName;
          return v;
        });
        setGrouping(groupingInfo);
      });
  };

  const GetSourceInfo = () => {
    //Profile Props',props )
    const url = process.env.REACT_APP_API_URL + "/SOURCE?order=IdSource";

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let sourceInfo = data.records.map((v) => {
          v.value = v.IdSource;
          v.text = v.SourceName;
          return v;
        });
        setSource(sourceInfo);
      });
  }; 

  const GetPlayerNotes = async () => {
    const url = process.env.REACT_APP_API_URL + "/PLAYERNOTES";      
    await axios
    .get(url)
    .then((response) => response.data)
    .then((data) => {
      setPlayerNotes(data.records);
    });
  }

  function onAfterDeleteRow(rowKeys) {
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/Player/DeletePlayer?idPlayer=" +
        rowKeys +
        "&idUser=" +
        userInfo.UserId,
      //url:'https://localhost:44337/api/Player/DeletePlayer?idPlayer=' + rowKeys +'&idUser=' +userInfo.UserId,
    }).then((response) => {
      console.log("response", response);
    });
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }

  function selectedValues(row) {
    setRedirect(row);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  const cellEditProp = {
    mode: "click", //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: "radio",
    onSelect: onRowSelect,
    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
  };

  function onAfterInsertRow(row) {
    let RequestDTO = {
      IdPlayer: row.IdPlayer,
      IdLineType: row.IdLineType,
      IdOffice: row.IdOffice,
      IdAgent: row.IdAgent,
      IdCurrency: row.IdCurrency,
      IdGrouping: row.IdGrouping,
      IdSource: 64,
      IdProfile: row.IdProfile,
      IdPlayerRate: row.IdPlayerRate,
      IdBook: row.IdBook,
      IdTimeZone: row.IdTimeZone,
      IdLanguage: row.IdLanguage,
      Player1: row.Player,
      Password: row.Password,
      Name: row.Name,
      LastName: row.LastName,
      LastName2: row.LastName2,
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      Country: "",
      Zip: "",
      Phone: "",
      Fax: "",
      Email: "",
      CreditLimit: row.CreditLimit,
      TempCredit: row.TempCredit,
      OnlineAccess: row.OnlineAccess,
      OnlinePassword: row.OnlinePassword,
      OnlineMessage: row.OnlineMessage,
      OnlineMaxWager: row.OnlineMaxWager,
      OnlineMinWager: row.OnlineMinWager,
      MaxWager: row.MaxWager,
      MinWager: row.MinWager,
      ChartPercent: row.ChartPercent,
      Master: row.Master,
      FlagMessage: row.FlagMessage,
      IdFlagMessageType: row.IdFlagMessageType,
      DuplicatedBets: row.DuplicatedBets,
      DuplicatedBetsOnline: row.DuplicatedBetsOnline,
      SettledFigure: row.SettledFigure,
      ShowInTicker: row.ShowInTicker,
      EPOSPlayer: row.EPOSPlayer,
      EnableHorses: row.EnableHorses,
      EnableCasino: row.EnableCasino,
      EnableSports: row.EnableSports,
      DateOfBirth: row.DateOfBirth,
      SecQuestion: row.SecQuestion,
      SecAnswer: row.SecAnswer,
      SignUpIP: "192.168.3.20",
      HoldBets: row.HoldBets,
      HoldDelay: row.HoldDelay,
      IdProfileLimits: row.IdProfileLimits,
      EnableCards: row.EnableCards,
      IdAffiliate: row.IdAffiliate,
    };
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/Player/Player_Insert?IdUser=" +
        userInfo.UserId,
      //url: 'https://localhost:44337/api/Player/Player_Insert?IdUser=' +userInfo.UserId,
      data: RequestDTO,
    }).then((response) => {
      if (response.data === "-1") {
        alert("Data Successfully Updated");
      }
    });
    LoadProfile();
  }

  function onRowSelect(row, isSelected, e) {
    console.log("selected Player", row)
    if (isSelected) {
      setShowCloneButton(true)
      setSelectedPlayer(row)
    } else {
      setShowCloneButton(false)
    }
  }

  function onAfterSaveCell(row, cellName, cellValue) {
    let RequestDTO = {
      IdProfile: row.IdProfile,
      ProfileName: row.ProfileName,
      Notes: row.Notes,
      AlternateProfile: row.AlternateProfile,
      AlternateEnable: row.AlternateEnable,
      PromotionPoints: row.PromotionPoints,
      SL_IfBets: row.SL_IfBets,
      SL_AlwaysActionMLBTotals: row.SL_AlwaysActionMLBTotals,
      PL_MaxGames: row.PL_MaxGames,
      PL_AllowProps: row.PL_AllowProps,
      PL_AllowGolf: row.PL_AllowGolf,
      PL_AllowMatchUps: row.PL_AllowMatchUps,
      PL_NoListedPitchers: row.PL_NoListedPitchers,
      PL_Juice2TeamParlay: row.PL_Juice2TeamParlay,
      PL_TrueOddsOption: row.PL_TrueOddsOption,
      PL_MaxOdds: row.PL_MaxOdds,
      PL_MaxPayout: row.PL_MaxPayout,
      PL_MaxTeamBuyPoints: row.PL_MaxTeamBuyPoints,
      PL_MaxBuyPoints: row.PL_MaxBuyPoints,
      PL_AlwaysAction: row.PL_AlwaysAction,
      PL_AllowRunLineTotal: row.PL_AllowRunLineTotal,
      PL_AllowSpreadTotalHK: row.PL_AllowSpreadTotalHK,
      PL_AllowSpreadTotalSOC: row.PL_AllowSpreadTotalSOC,
      PL_TieLoses: row.PL_TieLoses,
      PL_ParlayFormula: row.PL_ParlayFormula,
      PL_AllowOpenPlays: row.PL_AllowOpenPlays,
      PL_OddsDefault: row.PL_OddsDefault,
      PL_LowerUseDefault: row.PL_LowerUseDefault,
      PL_MaxDogsSide: row.PL_MaxDogsSide,
      PL_MaxDogsTotal: row.PL_MaxDogsTotal,
      PL_MaxDogsMoney: row.PL_MaxDogsMoney,
      PL_MaxSumOdds: row.PL_MaxSumOdds,
      PL_UseMaxSumOdds: row.PL_UseMaxSumOdds,
      IL_MaxParlays: row.IL_MaxParlays,
      IL_MaxLevelParlays: row.IL_MaxLevelParlays,
      IL_MaxTeasers: row.IL_MaxTeasers,
      IL_MaxLevelTeasers: row.IL_MaxLevelTeasers,
      IL_MaxNumberSB: row.IL_MaxNumberSB,
      IL_AskAmount: row.IL_AskAmount,
      IL_AllowChildHigher: row.IL_AllowChildHigher,
      PC_PitcherChangeType: row.PC_PitcherChangeType,
      TL_OpenLose: row.TL_OpenLose,
      TL_TeaserCancel: row.TL_TeaserCancel,
      SG_CancelSide: row.SG_CancelSide,
      SG_CancelTotal: row.SG_CancelTotal,
      SG_CancelRunLine: row.SG_CancelRunLine,
      PC_SkipSide: row.PC_SkipSide,
      PC_SkipTotal: row.PC_SkipTotal,
      PC_SkipRunLine: row.PC_SkipRunLine,
      Soc_Hookups: row.Soc_Hookups,
      Rev_AllowOpen: row.Rev_AllowOpen,
      DuplicateBetsCheckLineChange: row.DuplicateBetsCheckLineChange,
      DuplicateBetsCheckParlays: row.DuplicateBetsCheckParlays,
      DuplicateBetsCheckTeasers: row.DuplicateBetsCheckTeasers,
      DuplicateBetsCheckReverses: row.DuplicateBetsCheckReverses,
      DuplicateBetsCheckIfbets: row.DuplicateBetsCheckIfbets,
      DuplicateBetsCheckIfSBRev: row.DuplicateBetsCheckIfSBRev,
      DuplicateBetsCheckParlaysSB: row.DuplicateBetsCheckParlaysSB,
      DuplicateBetsCheckTeasersSB: row.DuplicateBetsCheckTeasersSB,
      DuplicateBetsCheckSameParlay: row.DuplicateBetsCheckSameParlay,
      DuplicateBetsCheckSameTeaser: row.DuplicateBetsCheckSameTeaser,
      FPMaxFav: row.FPMaxFav,
      FPMaxPayout: row.FPMaxPayout,
      FPMaxTeams: row.FPMaxTeams,
      FPOddsLimit: row.FPOddsLimit,
      FPAllowDuplicatedBets: row.FPAllowDuplicatedBets,
      FPAllowBothSides: row.FPAllowBothSides,
      FPCheckOfficeFilters: row.FPCheckOfficeFilters,
      LastModification: row.LastModification,
      LastModificationUser: row.LastModificationUser,
      CLMaxWager: row.CLMaxWager,
      ILOddslimit: row.ILOddslimit,
    };
    axios({
      method: "post",
      url: process.env.REACT_APP_API2_URL + "/api/Profile/PlayerProfile_Update",
      //url: 'https://localhost:44337/api/Profile/PlayerProfile_Update',
      data: RequestDTO,
    }).then((response) => {
      if (response.data === "-1") {
        alert("Data Successfully Updated");
      }
    });
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }

  const toggleTable = () => {
    if (showInfo) {
      setshowInfo(false);
    } else {
      setshowInfo(true);
    }
  };

  const duplicatePlayerOn = () => {
    setCloningPlayer(true)
  };

  const handleCloseCreate = function () {
    setCloningPlayer(false)
  };
  const handleDuplicatePlayer = (e) => {
    //http://wager.dm/addons/spapiCP.php
    console.log(" idPlayerToDuplicate", idPlayerToDuplicate);
    if (idPlayerToDuplicate || idPlayerToDuplicate != "") {
      var mydat = {};
      mydat.player = duplicatePlayer;
      mydat.pwd = duplicatePlayerPwd;
      mydat.idp = idPlayerToDuplicate;
      mydat.phone = duplicatePlayerPhone;
      mydat.email = duplicatePlayerEmail;

      $.post(process.env.REACT_APP_DEF_PHP_URL + "/spapiCP.php", mydat).done(
        (data) => {
          if (data == "0") {
            alert("Player Created");
          } else {
            alert("Error creating player, please check player name and email");
          }
        }
      );

      setDuplicatePlayer("");
      //setIsDuplicatingPlayer(false);
    } else {
      alert("Select a Player to duplicate");
    }
  };
  const handleDuplicatePlayerChange = (e) => {
    setDuplicatePlayer(e.target.value);
  };
  const handlePlayerToDuplicateEmailChange = (e) => {
    setDuplicatePlayerEmail(e.target.value);
  };
  const handlePlayerToDuplicatePhoneChange = (e) => {
    setDuplicatePlayerPhone(e.target.value);
  };
  const handlePlayerToDuplicatePwdChange = (e) => {
    setDuplicatePlayerPwd(e.target.value);
  };
  const handleChangePlayerFilter = (e) => {
    setCurrentPlayerFilter(e.target.value);
  };
  /*useEffect(() => {
    if (playersUnfiltered) filterPlayers();
  }, [currentPlayerFilter]);*/
  if (redirect) {
    let pushURL = "/player/" + redirect.IdPlayer;

    return (
      <React.Fragment>
        {/* //this.props.location.state. asi se agarran los datos en el otro componente */}

        <div>{history.push(pushURL)} </div>
      </React.Fragment>
    );
  }
  return (
    <div id="main-div">
      <Paper className={classes.root}>
        <br />
        <br /> <h2>Players</h2>
        {showCloneButton ? (
          <Button
            style={{ margin: 8 }}
            variant="contained"
            color="primary"
            onClick={duplicatePlayerOn}
          >
            Clone Player
          </Button>
        ) : (
          ""
        )}
        {isCloningPlayer ? (
          <ClonePlayer
            books={books}
            office={office}
            agents={agents}
            currency={currency}
            grouping={grouping}
            playerProfile={playerProfile}
            playerRate={playerRate}
            timeZone={timeZone}
            language={language}
            source={source}
            profileLimits={profileLimits}
            flagMessageType={flagMessageType}
            onClose={handleCloseCreate}
            selectedPlayer={selectedPlayer}
            playerNotes={playerNotes}
          ></ClonePlayer>
        ) : (
          ""
        )}
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {players && (
              <div>
                <div
                  style={{ float: "right" }}
                  onMouseOver={(e) =>
                    alert(` Search Panel
                  You can search by Search by Player, Name, Email,
                  Phone,Password by Default, you can also filter 
                  the criteria by clicking on the 
                  More filters Options`)
                  }
                >
                  <span className="search-include"> Help</span>
                </div>
                {searchProgress != 0 && searchProgress != 100 && (
                  <CircularProgress
                    style={{ height: 20, marginLeft: 16 }}
                    variant="determinate"
                    value={searchProgress}
                  />
                )}

                <div>
                  <span className="search-include"> Search Panel</span> <br />
                  <FormControlLabel
                    control={
                      <DelayInput
                        minLength={1}
                        delayTimeout={500}
                        className="select-input-general"
                        value={currentPlayerFilter}
                        onChange={handleChangePlayerFilter}
                        placeHolder="Search by Player, Name, Email, Phone,Password "
                      />
                    }
                    label=""
                  />
                  <span
                    onClick={(e) => {
                      setShowSearchfilters(!showSearchfilters);
                      if (showSearchfilters) {
                        setSearchPlayerOnly(true);
                        setSearchPasswordOnly(true);
                        setSearchNameOnly(true);
                        setSearchLastNameOnly(true);
                        setSearchPhoneOnly(true);
                      }
                    }}
                    className="search-include"
                  >
                    {`${showSearchfilters ? "- Less" : "+ More"}`} Filter
                    Options
                  </span>
                  <div
                    style={{
                      display: `${showSearchfilters ? "flex" : "none"}`,
                    }}
                  >
                    <span className="search-include">Filter Only By</span>
                    <br />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setSearchPlayerOnly(!searchPlayerOnly);
                          }}
                          checked={searchPlayerOnly}
                        />
                      }
                      label="By Player"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setSearchPasswordOnly(!searchPasswordOnly);
                          }}
                          checked={searchPasswordOnly}
                        />
                      }
                      label="By Password"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setSearchNameOnly(!searchNameOnly);
                          }}
                          checked={searchNameOnly}
                        />
                      }
                      label="By Name"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setSearchLastNameOnly(!searchLastNameOnly);
                          }}
                          checked={searchLastNameOnly}
                        />
                      }
                      label="By LastName"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setSearchPhoneOnly(!searchPhoneOnly);
                          }}
                          checked={searchPhoneOnly}
                        />
                      }
                      label="By Phone"
                    />
                  </div>
                </div>
                <hr />
              </div>
            )}
          </TableHead>
          <TableBody className={showInfo ? "show-table" : "hide-table"}>
            {players && (
              <div>
                <BootstrapTable
                  pagination={true}
                  options={options}
                  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
                  selectRow={selectRowProp} //Da problemas con el Expand
                  //expandableRow={isExpandableRow}
                  //expandComponent={expandComponent}
                  insertRow={true}
                  /*expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: expandColumnComponent,
                  columnWidth: 50,
                }}*/
                  version="4"
                  data={players}
                  deleteRow={true}
                  hover
                >
                  <TableHeaderColumn
                    dataField="IdPlayer"
                    hiddenOnInsert
                    hidden
                    isKey={true}
                    autoValue={true}
                  >
                    Player ID
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="Player">
                    Player
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="Password">
                    Password
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="Name">Name</TableHeaderColumn>
                  <TableHeaderColumn dataField="LastName">
                    LastName
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="LastName2">
                    LastName2
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdBook"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: books },
                    }}
                  >
                    IdBook
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdLineType"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: lineType },
                    }}
                  >
                    IdLineType
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdOffice"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: office },
                    }}
                  >
                    IdOffice
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="IdAgent"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: agents },
                    }}
                    hidden
                  >
                    IdAgent
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    hidden
                    dataField="IdCurrency"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: currency },
                    }}
                  >
                    IdCurrency
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdGrouping"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: grouping },
                    }}
                  >
                    IdGrouping
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdProfile"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: playerProfile },
                    }}
                  >
                    IdProfile
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdPlayerRate"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: playerRate },
                    }}
                  >
                    IdPlayerRate
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdTimeZone"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: timeZone },
                    }}
                  >
                    IdTimeZone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdLanguage"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: language },
                    }}
                  >
                    IdLanguage
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="CreditLimit">
                    CreditLimit
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="TempCredit">
                    TempCredit
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="OnlinePassword">
                    OnlinePassword
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="OnlineMessage">
                    OnlineMessage
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="OnlineMaxWager">
                    OnlineMaxWager
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="OnlineMinWager">
                    OnlineMinWager
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="MaxWager">
                    MaxWager
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="MinWager">
                    MinWager
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="MasterChart">
                    MasterChart
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                    dataField="Master"
                  >
                    Master
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdFlagMessageType"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: flagMessageType },
                    }}
                  >
                    IdFlagMessageType
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="DuplicatedBets"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    DuplicatedBets
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="DuplicatedBetsOnline"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    DuplicatedBetsOnline
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="SettledFigure">
                    SettledFigure
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                    dataField="ShowInTicker"
                  >
                    ShowInTicker
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                    dataField="EPOSPlayer"
                  >
                    EPOSPlayer
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                    dataField="EnableHorses"
                  >
                    EnableHorses
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                    dataField="EnableCasino"
                  >
                    EnableCasino
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                    dataField="EnableSports"
                  >
                    EnableSports
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{ type: "datetime" }}
                    dataField="DateOfBirth"
                  >
                    DateOfBirth
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="SecQuestion">
                    SecQuestion
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="SecAnswer">
                    SecAnswer
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="HoldBets"
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                  >
                    HoldBets
                  </TableHeaderColumn>
                  <TableHeaderColumn hidden dataField="HoldDelay">
                    HoldDelay
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    dataField="IdProfileLimits"
                    editable={{
                      type: "select",
                      readOnly: true,
                      options: { values: profileLimits },
                    }}
                  >
                    IdProfileLimits
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    hidden
                    editable={{
                      type: "checkbox",
                      options: {
                        values: "true:false",
                      },
                    }}
                    dataField="EnableCards"
                  >
                    EnableCards
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default Players;
