import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from '../../components/common/loader/Loader';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
export default function GameStatus(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [events, setEvents] = React.useState([]);
  const [leagueToInfo, setLeagueToInfo] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [flag, setFlag] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const [
    selectedValueAutoChanges,
    setSelectedValueAutoChanges,
  ] = React.useState('Visitor');

  useEffect(() => {
    //Change for server api address
    //Profile Props',props )
    const url =
      process.env.REACT_APP_API_URL +
      '/EVENT?filter=IdLeague,eq,' +
      props.IdLeague;
    let IdLeagueTo = '';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setEvents(data.records);
        IdLeagueTo = data.records.IdTargetLeage;
        const url =
          process.env.REACT_APP_API_URL +
          '/LEAGUE?filter=IdLeague,eq,' +
          IdLeagueTo;
        axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setLeagueToInfo(data.records);
          });
        setLoading(false);
      });
  }, []);

  const InsertGame = (
    IdEventPrinc,
    IdGameType,
    IdLeagueTo,
    Description,
    GameDescription,
    RotCalcMethod,
    RotCalcArg1
  ) => {
    let VisitorNum =
      RotCalcMethod == 0
        ? RotCalcArg1.ToString().Concat(props.VisitorNumber.ToString())
        : 1;
    let HomeNum =
      RotCalcMethod == 0
        ? RotCalcArg1.ToString().Concat(props.HomeNumber.ToString())
        : 2;
    let gameRequest = {
      IdSport: leagueToInfo.IdSport,
      IdLeague: IdLeagueTo,
      IdGameType: IdGameType,
      GameDateTime: props.GameDateTime
        ? props.GameDateTime.replace(' ', 'T').split('.')[0]
        : '',
      VisitorNumber: leagueToInfo.IdSport == 'TNT' ? 1 : VisitorNum,
      HomeNumber: leagueToInfo.IdSport == 'TNT' ? 2 : HomeNum,
      VisitorTeam:
        leagueToInfo.IdSport == 'TNT'
          ? Description
          : GameDescription.replace('<VT>/<HT>', props.VisitorTeam),
      HomeTeam:
        leagueToInfo.IdSport == 'TNT'
          ? Description
          : GameDescription.replace('<VT>/<HT>', props.HomeTeam),
      VisitorScore: 0,
      HomeScore: 0,
      VisitorPitcher: '',
      HomePitcher: '',
      NormalGame: 1,
      GameStat: 'D',
      Graded: false,
      Hookups: false,
      Local: false,
      Online: false,
      ShortGame: false,
      EventDate: props.GameDateTime
        ? props.GameDateTime.replace(' ', 'T').split('.')[0]
        : '',
      DateChanged: false,
      YimeChanged: false,
      PitcherChanged: false,
      Period: 0,
      ParentGame: props.IdGame,
      GradedDate: null,
      NumTeams: 2,
      IdEvent: IdEventPrinc,
      FamilyGame: props.IdGame,
      HasChildren: false,
      IdTeamVisitor: 0,
      IdTeamHome: 0,
      IdBannerType: 0,
      Description: Description,
      AcceptAutoChanges: false,
      IdUser: userInfo.UserId,
      Result: 0,
    };
    setIsLoading(true);
    axios({
      method: 'post',
      //url: 'https://localhost:44337/api/Game/InsertEventValues',
      url: process.env.REACT_APP_API2_URL + '/Game/InsertGame',
      data: gameRequest,
    }).then((response) => {
      let IdGame = response.data;
      if (leagueToInfo.IdSport == 'TNT') {
        axios({
          method: 'post',
          // url: 'https://localhost:44337/api/Game/InsertEventValues?idGame=' + IdGame,
          url:
            process.env.REACT_APP_API2_URL +
            '/Game/InsertEventValues?idGame=' +
            IdGame +
            '&idEvent=' +
            IdEventPrinc +
            '&idGameOrigin' +
            props.selectedGame,
        }).then((response) => {
          //setArray([]);
          //props.searchGameValues();
        });
      }
      setIsLoading(false);
    });
  };

  function onRowSelect(row, isSelected, e) {
    let rowStr = '';
    if (isSelected) {
      InsertGame(
        row.IdEvent,
        row.IdGameType,
        row.IdTargetLeague,
        row.Description,
        row.GameDescription,
        row.RotCalcMethod,
        row.RotCalcArg1
      );
    }
  }

  function onSelectAll(isSelected, rows) {
    alert(`is select all: ${isSelected}`);
    if (isSelected) {
      for (let i = 0; i < rows.length; i++) {
        InsertGame(
          rows[i].IdEvent,
          rows[i].IdGameType,
          rows[i].IdTargetLeague,
          rows[i].Description,
          rows[i].GameDescription,
          rows[i].RotCalcMethod,
          rows[i].RotCalcArg1
        );
      }
    }
  }
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };
  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: true, // click to select, default is false
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  const handleOk = () => {};
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add Event
          </Typography>
        </Toolbar>
      </AppBar>
      {loading ? (
        <CircularProgress style={{ margin: '0 auto' }} />
      ) : (
        <BootstrapTable
          data={events}
          selectRow={selectRowProp}
          options={options}
        >
          <TableHeaderColumn isKey={true} hidden dataField="IdEvent">
            TicketNumber
          </TableHeaderColumn>

          <TableHeaderColumn width="200 " hidden dataField="IdTargetLeague">
            type
          </TableHeaderColumn>
          <TableHeaderColumn dataField="Description">
            Description
          </TableHeaderColumn>
        </BootstrapTable>
      )}
      <DialogActions>
        {isLoading ? (
          <Loader height={100} width={100}></Loader>
        ) : (
          <div>
            <Button
              color="inherit"
              startIcon={<CancelIcon />}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button startIcon={<SaveIcon />} color="inherit" onClick={handleOk}>
              Ok
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
