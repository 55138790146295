import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../common/InputSelect';
import Sports from '../../common/Sports';
import Periods from '../../common/Periods';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import './WagerLimitsStraight.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const priceFormatter = (cell, row) => {
  let isSelected = '';
  let isSelected0 = '';
  let isSelected1 = '';
  let isSelected2 = '';
  let isSelected3 = '';
  let isSelected4 = '';
  console.log('Formatting cell', cell);
  switch (cell) {
    case 0:
      isSelected0 = 'checked';
      break;
    case 1:
      isSelected1 = 'checked';
      break;
    case 2:
      isSelected2 = 'checked';
      break;
    case 3:
      isSelected3 = 'checked';
      break;
    case 4:
      isSelected4 = 'checked';
      break;
    default:
      isSelected = 'checked';
  }
  console.log('Formatting cell isSelected0', isSelected0);
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>

 `;
};
export default function ProfileStraight(props) {
  const classes = useStyles();
  const [profileStraightOptions, setProfileStraightOptions] = React.useState([]);
  const [idSport, setIdSport] = React.useState(['CBB']);
  const [period, setPeriod] = React.useState(['0']);
  let idProfile = props.Profile.IdProfile;
  let profile = props.Profile;

  //let IdPeriod = '0';
  useEffect(() => {
    // Change for server api address
    console.log('PROPS Profile Settings period======>', props);
    console.log('PROPS Profile Settings period======>', period);
    const url =
      process.env.REACT_APP_API_URL +
      '/PLAYERPROFILESTRAIGHTLIMIT' +
      '?filter=IdProfile,eq,' +
      idProfile ;
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log('PROPS STRAIGHTS====== REST THEN>', response.data.records);
      console.log('PROPS STRAIGHTS====== REST THEN>', );
      setProfileStraightOptions(response.data.records);
    });
  }, [idSport, period]);
  // const [selectedIdGame, setselectedIdGame] = useState();

  /*useEffect(() => props.showOverlay(false));
  var returnObj = {};
  const genObjects = function () {
    props &&
      props.data &&
      props.data.map((s, i) => {
        //stateObject(s.IdGame, s.HomeTeam);
        returnObj = {};
        returnObj[s.IdGame] = s.HomeTeam;
      });
  };*/

  /*const handleCloseModify = function () {
    setIsModify(false);
  };*/
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
  };

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };
  const setPeriodOnMem = (period) => {
    setPeriod(period);
    console.log('PROPS====== REST setPeriodOnMem>', period);
  };
  console.log('PROP STRAIGHT====== REST profileStraightOptions>', profileStraightOptions);
  return (
    <Table>
      <TableRow>
        <TableCell colSpan="3">
          <Table>
            <TableCell>
              {' '}
              {/**/}
         
            </TableCell>
            <TableCell>
              {' '}
              {/* */}
              <TableHeaderColumn>
              Football
              </TableHeaderColumn> 
              <TableHeaderColumn>
              Spread
              </TableHeaderColumn> 
              <BootstrapTable
                data={profileStraightOptions}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                

               
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IdSport"
                >
                  IdSport
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="SpreadPointsPurchaseMax"
                >
                  SpreadPointsPurchaseMax
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="SpreadPriceHalfPoints"
                >
                  SpreadPriceHalfPoints
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="SpreadJuiceDefault"
                >
                  SpreadJuiceDefault
                </TableHeaderColumn>
              </BootstrapTable>
              <TableHeaderColumn>
              Total
              </TableHeaderColumn> 
              <BootstrapTable
                data={profileStraightOptions}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IdSport"
                >
                  IdSport
                </TableHeaderColumn>
               
                <TableHeaderColumn
                  visible="none"
              
                  dataField="TotalPointsPurchaseMax"
                >
                  TotalPointsPurchaseMax
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="TotalPriceHalfPoints"
                >
                  TotalPriceHalfPoints
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="TotalJuiceDefault"
                >
                  TotalJuiceDefault
                </TableHeaderColumn>

                </BootstrapTable>
               
                <TableHeaderColumn>
              Surcharge
              </TableHeaderColumn> 
               <BootstrapTable
                data={profileStraightOptions}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
               
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IdSport"
                >
                  IdSport
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="On3Surcharge"
                >
                  On3Surcharge
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="Off3Surcharge"
                >
                  Off3Surcharge
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="On7Surcharge"
                >
                  On7Surcharge
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="Off7Surcharge"
                >
                  Off7Surcharge
                </TableHeaderColumn>

                
                
</BootstrapTable>
<TableHeaderColumn>
              Skip Half a point
              </TableHeaderColumn> 
<BootstrapTable
                data={profileStraightOptions}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
               
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IdSport"
                >
                  IdSport
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="SurchargeTwice_3pts"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  SurchargeTwice_3pts
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="SurchargeTwice_7pts"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  SurchargeTwice_7pts
                </TableHeaderColumn>
                </BootstrapTable>
                <TableHeaderColumn>
              Surcharge twice when
              </TableHeaderColumn> 
                <BootstrapTable
                data={profileStraightOptions}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
               
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IdSport"
                >
                  IdSport
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="SkipHalfPoint"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  SkipHalfPoint
                </TableHeaderColumn>
                </BootstrapTable>
                <TableHeaderColumn>
              Can Buy Points
              </TableHeaderColumn> 
                <BootstrapTable
                data={profileStraightOptions}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
               
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IdSport"
                >
                  IdSport
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="CanBuyOn3"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  CanBuyOn3
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="CanBuyOff3"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  CanBuyOff3
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="CanBuyOn7"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  CanBuyOn7
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="CanBuyOff7"
                  editable={{
                    type: 'checkbox',
                    options: {
                      values: 'true:false',
                    },
                  }}
                >
                  CanBuyOff7
                </TableHeaderColumn>


           
              </BootstrapTable>
             
             
            
            </TableCell>
          </Table>
        </TableCell>
      </TableRow>

     
      <TableRow>
        <TableCell colSpan="2">
          <hr></hr>
          <b>If Bets Options</b>
          {console.log('straight props.Profile',props.Profile)}
          <BootstrapTable
                data={[profile]}
                cellEdit={cellEditProp}
                options={options}
              >
                <TableHeaderColumn
                  visible="none"
                  isKey={true}
                  hidden
                  dataField="IdProfile"
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IL_MaxParlays"
                >
                  IL_MaxParlays
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IL_MaxLevelParlays"
                >
                  IL_MaxLevelParlays
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IL_MaxTeasers"
                >
                  IL_MaxTeasers
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IL_MaxLevelTeasers"
                >
                  IL_MaxLevelTeasers
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IL_MaxNumberSB"
                >
                  IL_MaxNumberSB
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IL_AskAmount"
                >
                  IL_AskAmount
                </TableHeaderColumn>
                <TableHeaderColumn
                  visible="none"
              
                  dataField="IL_AllowChildHigher"
                >
                 IL_AllowChildHigher
                </TableHeaderColumn>
                </BootstrapTable>
      
        </TableCell>
      </TableRow>
      



    </Table>
  );
}
