import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import '../../profiles/profileComponents/ProfileSettings.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import axios from 'axios';


export default function AgentPersonalInfo(props) {
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }

  function onAfterSaveCell(row, cellName, cellValue) {
    let AgentRequestDTO = {
      IdAgent: row.IdAgent,
      Enable: row.Enable,
      DontXferPlayerActivity: row.DontXferPlayerActivity,
      IsDistributor: row.IsDistributor,
      Distributor: row.Distributor,
      CommSports: row.CommSports,
      CommCasino: row.CommCasino,
      CommHorses: row.CommHorses,
      IdAgentType: row.IdAgentType,
      IdCurrency: row.IdCurrency,
      IdBook: row.IdBook,
      IdGrouping: row.IdGrouping,
      Agent1: row.Agent,
      Name: row.Name,
      Password: row.Password,
      City: row.City,
      State: row.State,
      Country: row.Country,
      Address1: row.Address1,
      Address2: row.Address2,
      Email: row.Email,
      Phone: row.Phone,
      Fax: row.Fax,
      Zip: row.Zip,
      OnlineAccess: row.OnlineAccess,
      OnlineMessage: row.OnlineMessage,
      IdLineType: row.IdLineType,
      IdAgentPerHead: row.IdAgentPerHead,
      IdLanguage: row.IdLanguage,
      LastModificationUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: 'http://192.168.3.22:4587/api/Agent/Agent_Update',
      data: AgentRequestDTO,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  return (
    <Table>
      <TableRow>
        Agent Information
        <BootstrapTable
          data={[props.AgentInfo]}
          cellEdit={cellEditProp}
          options={options}
        >
          <TableHeaderColumn isKey={true} hidden dataField="IdAgent">
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="Address1">Address</TableHeaderColumn>
          <TableHeaderColumn dataField="Address2"> Address2</TableHeaderColumn>
        </BootstrapTable>
      </TableRow>
      <TableRow>
        <BootstrapTable
          data={[props.AgentInfo]}
          cellEdit={cellEditProp}
          options={options}
        >
          <TableHeaderColumn isKey={true} hidden dataField="IdAgent">
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="City">City</TableHeaderColumn>
          <TableHeaderColumn dataField="State">State</TableHeaderColumn>
          <TableHeaderColumn dataField="Zip"> Zip</TableHeaderColumn>
        </BootstrapTable>
      </TableRow>
      <TableRow>
        <BootstrapTable
          data={[props.AgentInfo]}
          cellEdit={cellEditProp}
          options={options}
        >
          <TableHeaderColumn isKey={true} hidden dataField="IdAgent">
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="Country">Country</TableHeaderColumn>
          <TableHeaderColumn dataField="Phone">Phone</TableHeaderColumn>
          <TableHeaderColumn dataField="Fax"> Fax</TableHeaderColumn>
        </BootstrapTable>
      </TableRow>
      <TableRow>
        <BootstrapTable
          data={[props.AgentInfo]}
          cellEdit={cellEditProp}
          options={options}
        >
          <TableHeaderColumn isKey={true} hidden dataField="IdAgent">
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="Email">E-mail</TableHeaderColumn>
        </BootstrapTable>
      </TableRow>
      <TableRow>
        <BootstrapTable
          data={[props.AgentInfo]}
          cellEdit={cellEditProp}
          options={options}
        >
          <TableHeaderColumn isKey={true} hidden dataField="IdAgent">
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="OnlineMessage">
            Online Message
          </TableHeaderColumn>
        </BootstrapTable>
      </TableRow>
    </Table>
  );
}
