import React, { useState, setState, useEffect, useContext } from "react";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';
import { Dialog } from '@mui/material';
import { DialogTitle } from '@mui/material'
import Box from '@mui/material/Box';

export default function GameIndexed(props) {

    //call example <GameIndexed games = {gamesToInsert}/>

    const [gamesToProcess, setGamesToProcess] = useState(10);
    const [processedGames, setProcessedGames] = useState(0);
    const [open, setOpen] = useState(true);

    useEffect(() => {
        //insertedGames()
        //setGamesToProcess(props.games.length);
    },[]);
    
    function timeOut(ms){
      return new Promise((resolve) => setTimeout(resolve,ms));
    }
    const test = async () => {
      await timeOut (1000)
      setProcessedGames(processedGames + 1);
    }

    useEffect(() => {
      if(processedGames < gamesToProcess){
        test();
      }else{
        handleClose();
      }              
    },[processedGames]);

    const handleClose = () => {
      if (props.onClose) {
        props.onClose();
      }
      setOpen(false);
    };

    const  insertGames = async () => {

        for (let i = 0; i < props.games.length; i++) {
            await axios({
                method: "post",                
                url: process.env.REACT_APP_API2_URL + "/Game/InsertGame",
                data: props.games[i],
            }).then((response) => {
                console.log("response", response);
                setProcessedGames(processedGames + 1);               
            });
        } 
    }
        
    return (    
      <Dialog open={open}>
        <DialogTitle>Inserting: {processedGames+" Processed of "+gamesToProcess + " (" + processedGames/gamesToProcess*100 + "%)"}</DialogTitle>
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </Dialog>
    );
  }