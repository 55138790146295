import React, { useState, setState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputSelect from "../../common/InputSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

/*Other Components*/
import Sports from "../../common/Sports";
import Periods from "../../common/Periods";

/*Loader */
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReactLoading from "react-loading";

/*animation*/
import * as legoData from "../../common/animations/legoLoader.json";
/*Inputs*/
/*Styles*/
import "./WagerLimitsSettings.css";

import WagerTypes from "../../common/WagerTypes";
import GameTypes from "../../common/GameTypes";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const priceFormatter = (cell, row) => {
  let isSelected = "";
  let isSelected0 = "";
  let isSelected1 = "";
  let isSelected2 = "";
  let isSelected3 = "";
  let isSelected4 = "";
  console.log("Formatting cell", cell);
  switch (cell) {
    case 0:
      isSelected0 = "checked";
      break;
    case 1:
      isSelected1 = "checked";
      break;
    case 2:
      isSelected2 = "checked";
      break;
    case 3:
      isSelected3 = "checked";
      break;
    case 4:
      isSelected4 = "checked";
      break;
    default:
      isSelected = "checked";
  }
  console.log("Formatting cell isSelected0", isSelected0);
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>

 `;
};
export default function ProfileSettings(props) {
  const classes = useStyles();
  const [wagerLimitDetails, setWagerLimitDetails] = React.useState([]);
  const [idSport, setIdSport] = React.useState(["CBB"]);
  const [period, setPeriod] = React.useState(["0"]);
  const [gameType, setGameType] = React.useState(1);
  const [online, setOnline] = React.useState(0);

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  let idProfile = props.WagerLimit.IdProfileLimits; //props.match.params.id,

  //let IdPeriod = '0';
  useEffect(() => {
    getProfileLimit();
  }, [idProfile]);
  //idProfile const [selectedIdGame, setselectedIdGame] = useState();

  const handleTypeChanged = (event) => {
    setOnline(event.target.value);
    getProfileLimit();
  };
  const getProfileLimit = () => {
    const url =
      process.env.REACT_APP_API_URL +
      "/PLAYERPROFILELIMITSDETAILS" +
      "?filter=IdProfileLimits,eq," +
      idProfile +
      "&filter=IdGameType,eq," +
      gameType +
      "&filter=Online,eq," +
      online;
    axios({
      method: "get",
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      setWagerLimitDetails(response.data.records);
    });

    setIsLoading(false);
  };

  const setGameTypeOnMem = (idGameType) => { 
    setGameType(idGameType);
     
  };
useEffect(()=>{getProfileLimit();},[gameType])
  function onAfterSaveCell(row, cellName, cellValue) {
    props.UpdateProfile(row);
  }

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const cellEditProp = {
    mode: "click",
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };
  const setPeriodOnMem = (period) => {
    setPeriod(period);
    console.log("PROPS====== REST setPeriodOnMem>", period);
  };
  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  /**/

  const cellEdit = {
    mode: "click",
    blurToSave: true,
    afterSaveCell: onAfterSaveCellEdit,
  };
  function onAfterSaveCellEdit(row, cellName, cellValue) {
    UpdateEditPlayerProfileLimits(row);
  }

  function UpdateEditPlayerProfileLimits(row) {
    let request = {
      IdProfileLimits: row.IdProfileLimits,
      IdGameType: row.IdGameType,
      Online: row.Online,
      IdSport: row.IdSport,
      Side: row.Side,
      Total: row.Total,
      MoneyLine: row.MoneyLine,
      Parlays: row.Parlays,
      Teasers: row.Teasers,
      IfBets: row.IfBets,
      Reverses: row.Reverses,
      Related: row.Related,
      IdUser: userInfo.UserId,
    };
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API2_URL +
        "/Profile/PlayerProfileLimitsDetails_Update",
      //url:'https://localhost:44337/api/Profile/PlayerProfileLimitsDetails_Update',
      data: request,
    }).then((response) => {
      console.log("response", response);
    });
  }
  const numberFormatter = (cell, row) => {
    return new Intl.NumberFormat("en-US", {
      maximumSignificantDigits: 3,
    }).format(cell);
  };
  console.log("PROPS====== Settings setPeriodOnMem>", isLoading);
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <GameTypes
            style={{ marginTop: -250 }}
            setGameTypeOnMem={setGameTypeOnMem}
          ></GameTypes>
        </Grid>
        <Grid item xs={6}>
          <React.Fragment>
            <Table>
              <TableRow>
                <TableCell className="cell-top-align">
                  <Table>
                    <TableRow className="cell-top-align">
                      <RadioGroup row>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={online == "0"}
                              onChange={handleTypeChanged}
                              value="0"
                              label="Local"
                              name="radio-button-time"
                              inputProps={{ "aria-label": "Home" }}
                              // disabled
                            />
                          }
                          label="Local"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={online == "1"}
                              onChange={handleTypeChanged}
                              value="1"
                              label="Online"
                              name="radio-button-time"
                              inputProps={{ "aria-label": "Visitor" }}
                              // disabled
                            />
                          }
                          label="Online"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <BootstrapTable
                          data={wagerLimitDetails}
                          cellEdit={cellEdit}
                          options={options}
                        >
                          <TableHeaderColumn
                            visible="none"
                            isKey={true}
                            hidden
                            dataField="IdProfileLImit"
                          >
                            ID
                          </TableHeaderColumn>

                          <TableHeaderColumn dataField="IdSport">
                            Sport
                          </TableHeaderColumn>

                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="Side"
                          >
                            Side
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="Total"
                          >
                            Total
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="MoneyLine"
                          >
                            ML
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="Parlays"
                          >
                            Parlays
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="Teasers"
                          >
                            Teasers
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="IfBets"
                          >
                            IfBets
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="Reverses"
                          >
                            Reverses
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataFormat={numberFormatter}
                            dataField="Related"
                          >
                            Related
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableCell>
              </TableRow>
            </Table>
          </React.Fragment>
        </Grid>
      </Grid>
    </>
    //***** SEPARATIION */
  );
}
