import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './ScheduleTbl.css';
import Time from 'react-time';
import { DelayInput } from 'react-delay-input';

import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddBoxIcon from '@material-ui/icons/AddBox';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GameModify from '../GameModify';
import GameStatus from '../GameStatus';
import CreateGame from '../CreateGame';
import CreateBanner from '../CreateBanner';
import DeleteGame from '../DeleteGame';
import ChartingInfo from '../ChartingInfo';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigationIcon from '@material-ui/icons/Navigation';
import DescriptionIcon from '@material-ui/icons/Description';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from './InputSelect';



const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Schedule(props) {
  const classes = useStyles();

  const [selectedIdGame, setselectedIdGame] = useState();
  const [selectedPlay, setselectedPlay] = useState();
  const [selectedIdLeague, setselectedIdLeague] = useState();
  const [isModify, setIsModify] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isCharting, setIsCharting] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [newBanner, setNewBanner] = useState(false);
  const [parentGame, setParentGame] = useState();
  const [homeNumber, setHomeNumber] = useState();
  const [visitorNumber, setVisitorNumber] = useState();
  const [homeTeam, setHomeTeam] = useState();
  const [visitorTeam, setVisitorTeam] = useState();
  const [gameDateTime, setGameDateTime] = useState();
  const [dateGame, setDateGame] = useState('');
  const [recentUpdatedGame, setRecentUpdatedGame] = useState('');
  
  const [flag, setFlag] = useState(false);

  const updateML = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay
  ) => {
    let z = newValue < 0 ? newValue * -1 : newValue;
    setRecentUpdatedGame(idGame);
    props.setLastChangedGamedID(idGame);
    const url =
      process.env.REACT_APP_API_URL +
      '/ODDSBYSPORT?filter=IdLineType,eq,' +
      idLinetype +
      '&filter=IdSport,eq,' +
      idSport +
      '&filter=IdGameType,eq,' +
      IdGameType +
      '&filter=Line,eq,' +
      3 +
      '&filter=Range,ge,' +
      Math.round(z);
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        //setoddBySport(data.records.odds);
        let moneyLineObject = {
          idGame: idGame,
          odds: newValue,
          idLinetype: idLinetype,
          idSport: idSport,
          newValue: newValue,
          play: play,
          teamNumber: teamNumber,
          column: column,
          changeLine: changeLine,
          changeOdds: changeOdds,
        };

        //if (totalObject.flag == true) {
        let storedOdd = -moneyLineObject.odds;
        let oddToLower = 0;
        let found = false;
        data.records.forEach((item, index) => {
          let valCompare = parseInt(moneyLineObject.odds);
          valCompare = valCompare < 0 ? -valCompare : valCompare;
          if (valCompare < item.Range && !found) {
            if (index == 0) {
              oddToLower = data.records[index].OddsValue;
            } else {
              oddToLower = data.records[index - 1].OddsValue;
            }
            found = true;
          }
        });
        if (storedOdd === 110 || storedOdd === 100 || storedOdd === -100) {
          oddToLower = 0;
        } else if (storedOdd === 115) {
          oddToLower = -10;
        } else if (storedOdd === 105) {
          oddToLower = 10;
        } else {
          oddToLower = oddToLower === 0 ? 20 : oddToLower;
        }

        if (storedOdd <= 115) {
          storedOdd += oddToLower;
          storedOdd = storedOdd * -1;
        } else {
          storedOdd += -oddToLower;
        }

        axios({
          method: 'post',
          url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
          data: moneyLineObject,
        }).then((response) => {
          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
            data: {
              idGame: idGame,
              odds: storedOdd,
              idLinetype: idLinetype,
              idSport: idSport,
              newValue: newValue,
              play: otherPlay,
              teamNumber: otherTeam,
              column: otherColumn,
              changeLine: changeLine,
              changeOdds: changeOdds,
            },
          }).then((response) => {
            props.announce(idGame, idLinetype);
            const url =
              process.env.REACT_APP_API_URL +
              '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
              idLinetype +
              '&filter=IdSport,eq,' +
              idSport +
              '&filter=MoneyLineSync,eq,' +
              1;
            axios
              .get(url)
              .then((response) => response.data)
              .then((data) => {
                if (data.records.Length !== null && data.records.Length > 0) {
                  data.data.forEach((x) => {
                    let idLT = x[0];
                    axios({
                      method: 'post',
                      url:
                        process.env.REACT_APP_DEF_PHP_URL + 'updateTotal.php',
                      data: {
                        idGame: idGame,
                        odds: odds,
                        idLinetype: idLT,
                        idSport: idSport,
                        newValue: newValue,
                        play: play,
                        teamNumber: teamNumber,
                        column: column,
                        changeLine: changeLine,
                        changeOdds: changeOdds,
                      },
                    }).then((response) => {});
                  });
                }
              });

            props.searchGameValues();
          });
        });
      });
  };

  const updateSP = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay
  ) => {
    let z = newValue < 0 ? newValue * -1 : newValue;
    setRecentUpdatedGame(idGame);
    props.setLastChangedGamedID(idGame);
    const url =
      process.env.REACT_APP_API_URL +
      '/ODDSBYSPORT?filter=IdLineType,eq,' +
      idLinetype +
      '&filter=IdSport,eq,' +
      idSport +
      '&filter=IdGameType,eq,' +
      IdGameType +
      '&filter=Line,eq,' +
      1 +
      '&filter=Range,ge,' +
      Math.round(z);
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        //setoddBySport(data.records.odds);
        let spreadObject = {
          idGame: idGame,
          odds: odds,
          idLinetype: idLinetype,
          idSport: idSport,
          newValue: parseFloat(newValue),
          play: play,
          teamNumber: teamNumber,
          column: column,
          changeLine: changeLine,
          changeOdds: changeOdds,
        };
        let storedOdd = -spreadObject.odds;
        let storedLine = -spreadObject.newValue;
        let oddToLower = 0;
        let found = false;
        data.records.forEach((item, index) => {
          let valCompare = parseInt(spreadObject.odds);
          valCompare = valCompare < 0 ? -valCompare : valCompare;
          if (valCompare < item.Range && !found) {
            if (index == 0) {
              oddToLower = data.records[index].OddsValue;
            } else {
              oddToLower = data.records[index - 1].OddsValue;
            }
            found = true;
          }
        });
        if (storedOdd === 110 || storedOdd === 100 || storedOdd === -100) {
          oddToLower = 0;
        } else if (storedOdd === 115) {
          oddToLower = -10;
        } else if (storedOdd === 105) {
          oddToLower = 10;
        } else {
          oddToLower = oddToLower === 0 ? 20 : oddToLower;
        }

        if (storedOdd <= 115) {
          storedOdd += oddToLower;
          storedOdd = storedOdd * -1;
        } else {
          storedOdd += -oddToLower;
        }

        axios({
          method: 'post',
          url: process.env.REACT_APP_DEF_PHP_URL + '/updateSpread.php',
          data: spreadObject,
        }).then((response) => {
          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateSpread.php',
            data: {
              idGame: idGame,
              odds: storedOdd,
              idLinetype: idLinetype,
              idSport: idSport,
              newValue: storedLine,
              play: otherPlay,
              teamNumber: otherTeam,
              column: otherColumn,
              changeLine: changeLine,
              changeOdds: changeOdds,
            },
          }).then((response) => {
            const url =
              process.env.REACT_APP_API_URL +
              '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
              idLinetype +
              '&filter=IdSport,eq,' +
              idSport +
              '&filter=SpreadOddsSync,eq,' +
              1;
            axios
              .get(url)
              .then((response) => response.data)
              .then((data) => {
                if (data.records.Length !== null && data.records.Length > 0) {
                  data.data.forEach((x) => {
                    let idLT = x[0];

                    axios({
                      method: 'post',
                      url:
                        process.env.REACT_APP_DEF_PHP_URL + '/updateTotal.php',
                      data: {
                        idGame: idGame,
                        odds: odds,
                        idLinetype: idLT,
                        idSport: idSport,
                        newValue: newValue,
                        play: play,
                        teamNumber: teamNumber,
                        column: column,
                        changeLine: changeLine,
                        changeOdds: changeOdds,
                      },
                    }).then((response) => {});
                  });
                }
              });

            props.searchGameValues();
          });
        });
      });
  };

  const updateTO = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay
  ) => {
    let z = newValue < 0 ? newValue * -1 : newValue;
    setRecentUpdatedGame(idGame);
    props.setLastChangedGamedID(idGame);
    const url =
      process.env.REACT_APP_API_URL +
      '/ODDSBYSPORT?filter=IdLineType,eq,' +
      idLinetype +
      '&filter=IdSport,eq,' +
      idSport +
      '&filter=IdGameType,eq,' +
      IdGameType +
      '&filter=Line,eq,' +
      2 +
      '&filter=Range,ge,' +
      Math.round(z);
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        // setoddBySport(data.records.odds);

        let totalObject = {
          idGame: idGame,
          odds: odds,
          idLinetype: idLinetype,
          idSport: idSport,
          newValue: newValue,
          play: play,
          teamNumber: teamNumber,
          column: column,
          changeLine: changeLine,
          changeOdds: changeOdds,
        };
        let x = totalObject.odds;
        //if (totalObject.flag == true) {
        let storedOdd = -totalObject.odds;
        let oddToLower = 0;
        let found = false;
        data.records.forEach((item, index) => {
          let valCompare = parseInt(totalObject.odds);
          valCompare = valCompare < 0 ? -valCompare : valCompare;
          if (valCompare <= item.Range && !found) {
            if (index == 0) {
              oddToLower = data.records[index].OddsValue;
            } else {
              oddToLower = data.records[index - 1].OddsValue;
            }
            found = true;
          }
        });
        if (storedOdd === 110 || storedOdd === 100 || storedOdd === -100) {
          oddToLower = 0;
        } else if (storedOdd === 115) {
          oddToLower = -10;
        } else if (storedOdd === 105) {
          oddToLower = 10;
        } else {
          oddToLower = oddToLower === 0 ? 20 : oddToLower;
        }

        if (storedOdd <= 115) {
          storedOdd += oddToLower;
          storedOdd = storedOdd * -1;
        } else {
          storedOdd += -oddToLower;
        }

        //}
        axios({
          method: 'post',
          url: process.env.REACT_APP_DEF_PHP_URL + '/updateTotal.php',
          data: totalObject,
        }).then((response) => {
          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateTotal.php',
            data: {
              idGame: idGame,
              odds: storedOdd,
              idLinetype: idLinetype,
              idSport: idSport,
              newValue: newValue,
              play: otherPlay,
              teamNumber: otherTeam,
              column: otherColumn,
              changeLine: changeLine,
              changeOdds: changeOdds,
            },
          }).then((response) => {
            const url =
              process.env.REACT_APP_API_URL +
              '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
              idLinetype +
              '&filter=IdSport,eq,' +
              idSport +
              '&filter=TotalOddsSync,eq,' +
              1;
            axios
              .get(url)
              .then((response) => response.data)
              .then((data) => {
                if (data.records.Length !== null && data.records.Length > 0) {
                  data.data.forEach((x) => {
                    let idLT = x[0];

                    axios({
                      method: 'post',
                      url:
                        process.env.REACT_APP_DEF_PHP_URL + '/updateTotal.php',
                      data: {
                        idGame: idGame,
                        odds: odds,
                        idLinetype: idLT,
                        idSport: idSport,
                        newValue: newValue,
                        play: play,
                        teamNumber: teamNumber,
                        column: column,
                        changeLine: changeLine,
                        changeOdds: changeOdds,
                      },
                    }).then((response) => {});
                  });
                }
              });

            props.searchGameValues();
          });
        });
      });
  };

  useEffect(() => props.showOverlay(false));
  var returnObj = {};
 /* const genObjects = function () {
    props &&
      props.data &&
      props.data.map((s, i) => {
        //stateObject(s.IdGame, s.HomeTeam);
        returnObj = {};
        returnObj[s.IdGame] = s.HomeTeam;
      });
   
   };
 */
  const stateObject = function (id, value) {
    var returnObj = {};
    returnObj[id] = value;
    setState(returnObj);
  };
  const handleCloseModify = function () {
    setIsModify(false);
  };
  const handleCloseCharting = function () {
    setIsCharting(false);
  };
  const handleCloseStatus = function () {
    setIsStatus(false);
  };
  const handleCloseCreate = function () {
    setIsNew(false);
  };
  const handleDelete = function () {
    setIsDelete(false);
  };
 // genObjects();

  useEffect(() => console.log('Schedule props',props));

  /*DataTable Functions*/
  const optionsBSDT ={


  }
  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 28, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: '<', // Previous page button text
    nextPage: '>', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false, // > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    expandRowBgColor: '#d3e4e0',
  
    // afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    //handleConfirmDeleteRow: customConfirm,
    //onRowClick: selectedValues,
  };
  function rowClassNameFormat(row, rowIdx) {
    // row is whole row object
    // rowIdx is index of row
    
    if (row.GameStat === 'B' ) {
      console.log('isExpandableRow banner');
      return 'schedule-banner';
    } else {
      console.log('isExpandableRow game');
      //return true;
    }

  }
  const cellEditProp = {
    mode: 'click', //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    //afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: 'checkbox',

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    let rowStr = '';
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }
  function expandComponent(row) {
    console.log('expandComponent ', row);
    return <BSTable data={row} />;
  }
  function isExpandableRow(row) {
    console.log('isExpandableRow ', row);
    if (row.GameStat === 'B' ) {
      console.log('isExpandableRow banner');
      return false;
    } else {
      console.log('isExpandableRow game');
      return true;
    }
  }
  class BSTable extends React.Component {
    render() {
      if (this.props.data) {
        console.log('this.props.data', this.props.data);
        return(
<div>
   
<BootstrapTable
  //pagination={true}
  options={optionsBSDT}
  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
  version="4"
  data={[this.props.data]}
  // deleteRow={true}
  hover
>
<TableHeaderColumn
    isKey={true}
    dataField="HomeNumber"
  >
   HomeNumber
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="HomeOdds"   
  >
  HomeOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="HomeSpread"   
  >
  HomeSpread
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="HomeSpreadOdds"   
  >
  HomeSpreadOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="TotalOver"   
  >
  TotalOver
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="OverOdds"   
  >
  OverOdds
  </TableHeaderColumn>
  </BootstrapTable>
  {/* Visitor Table*/}
  <BootstrapTable
  //pagination={true}
  options={optionsBSDT}
  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
  version="4"
  data={[this.props.data]}
  // deleteRow={true}
  hover
>
<TableHeaderColumn
    isKey={true}
    dataField="VisitorNumber"
    className="col-hidden"
  >
  VisitorNumber
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="VisitorOdds"  
    className="col-hidden" 
  >
  VisitorOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="VisitorSpread"   
    className="col-hidden"
  >
 VisitorSpread
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="VisitorSpreadOdds"   
    className="col-hidden"
  >
  VisitorSpreadOdds
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="TotalUnder"   
    className="col-hidden"
  >
  TotalOver
  </TableHeaderColumn>
  <TableHeaderColumn
    dataField="UnderOdds" 
    className="col-hidden"  
  >
  OverOdds
  </TableHeaderColumn>
  </BootstrapTable>
</div>

        ) 
      } else {
        return <p>?</p>;
      }
    }
  }
  
  function dateFormatter(cell, row) {
    //return `${('0' + cell.getDate()).slice(-2)}/${('0' + (cell.getMonth() + 1)).slice(-2)}/${cell.getFullYear()}`;
    const date = new Date(cell.date);
    console.log('Date Foramtting cell', date)
    console.log('Date Foramtting row', row)
    var d = new Date(cell.date);
    console.log('Date Foramtting row', row)
    return cell.date

  }
  /* /DataTable Functions */
  

  return (
    <React.Fragment>

      <h1>Test! Localhost</h1>
      {isNew ? (
        <CreateGame
          IdLinetype={props.IdLinetype.value}
          IdSport={props.IdSport.value}
          onClose={handleCloseCreate}
          searchGameValues={props.searchGameValues}
        />
      ) : (
        ''
      )}
      <AddBoxIcon
        aria-label="newGame"
        size="small"
        className={classes.fab}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsNew(true);
        }}
      />{' '}
      Add Games

{console.log('rendering ',props.data)}
     



      <Table
        className={classes.table}
        component={Paper}
        aria-label="a dense table"
      >
        {newBanner ? (
          <CreateBanner
            IdGame={selectedIdGame}
            IdSport={props.IdSport.value}
            IdLeague={selectedIdLeague}
            ParentGame={parentGame}
            GameDateTime={gameDateTime}
            HomeNumber={homeNumber}
            VisitorNumber={visitorNumber}
            onClose={handleCloseModify}
            searchGameValues={props.searchGameValues}
          />
        ) : (
          ''
        )}
        {isModify ? (
          <GameModify
            IdGame={selectedIdGame}
            IdLinetype={props.IdLinetype.value}
            IdSport={props.IdSport.value}
            onClose={handleCloseModify}
          />
        ) : (
          ''
        )}
        {isStatus ? (
          <GameStatus
            IdGame={selectedIdGame}
            IdLinetype={props.IdLinetype.value}
            onClose={handleCloseStatus}
          />
        ) : (
          ''
        )}

        {isDelete ? (
          <DeleteGame
            IdGame={selectedIdGame}
            searchGameValues={props.searchGameValues}
            onClose={handleCloseStatus}
          />
        ) : (
          ''
        )}

        {isCharting ? (
          <ChartingInfo
            IdGame={selectedIdGame}
            IdLinetype={props.IdLinetype.value}
            IdSport={props.IdSport}
            HomeTeam={homeTeam}
            VisitorTeam={visitorTeam}
            ActionType={0}
            Play={selectedPlay}
            onClose={handleCloseCharting}
          />
        ) : (
          ''
        )}

        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.headerCell}>
              <strong>Actions</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Rotation</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Match</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>ML</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Action</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Sum</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Spread</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Action</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Sum</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Totals</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Action</strong>
            </TableCell>
            <TableCell className={classes.headerCell}>
              <strong>Sum</strong>
            </TableCell>

            <TableCell className={classes.headerCell}>
              <strong>Special</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
        </TableBody>
      </Table>
      {props && props.data && console.log('schedule rendering',props.data)}
      {props && props.data && console.log('schedule rendering',props.data[0])}
      {props && props.data && console.log('schedule rendering typeof',typeof props.data)}
      {props && props.data && console.log('schedule rendering typeof 0',typeof props.data[0])}
      {props && props.data && 
  
  <BootstrapTable
  pagination={true}
  options={options}
  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
  trClassName={rowClassNameFormat}
  version="4"
  data={props.data}
  // deleteRow={true}
  expandableRow={isExpandableRow}
  expandComponent={expandComponent}
  hover
>
  <TableHeaderColumn
   
    hidden
    dataField="IdGame"
  >
    IdGame
  </TableHeaderColumn>
  <TableHeaderColumn
     isKey={true}
    dataField="GameDateTime"
    dataFormat={ dateFormatter }
  >
    GameDateTime
  </TableHeaderColumn>
  
  <TableHeaderColumn
  
    filter={{ type: 'TextFilter', delay: 1000 }}
    dataField="LeagueDescription"

    
  >
 LeagueDescription
  </TableHeaderColumn>
  <TableHeaderColumn
  
    filter={{ type: 'TextFilter', delay: 1000 }}
    dataField="VisitorTeam"

    
  >
 Visitor Team
  </TableHeaderColumn>
  <TableHeaderColumn
  
    filter={{ type: 'TextFilter', delay: 1000 }}
    dataField="HomeTeam"
 >
 Home Team
  </TableHeaderColumn>

</BootstrapTable>
} 
    </React.Fragment>
  );
}


