import React, { useState, useEffect, Component } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import AddBoxIcon from "@material-ui/icons/AddBox";

import "./Games.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import "../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import Loader from "../common/loader/Loader";

import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CreateGame from "../CreateGame";
import { create } from "@mui/material/styles/createTransitions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Games(props) {
  const [games, setgames] = useState();
  const [selectedGame, setSelectedGame] = useState();
  const [idSport, setIdSport] = useState();
  const [idGame, setIdGame] = useState();

  const [books, setBooks] = useState([]);
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [language, setLanguage] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [value, setValue] = React.useState(0);
  const [isNew, setIsNew] = React.useState(false);
  const [isModify, setIsModify] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGameStat, setSelectedGameStat] = React.useState("O");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleGameStatChange = (event, newValue) => {
    console.log("setSelectedGameStat", newValue.props.value);
    setSelectedGameStat(newValue.props.value);
  };

  const handleCloseCreate = function () {
    setIsNew(false);
    setIsModify(false);
  };
  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#3f51b5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkcyan !important",
      },
    },

    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },
  });

  const classes = useStyles();

  /*const displayForm = async (data, index) => {
    console.log('Data', data);
    console.log('Index', index);
    let agent = games[index];
    console.log('Agent', agent);
    await setRecord();
    await setRecord(agent);
    setshowInfo(false);
  };*/

  useEffect(() => {
    //Change for server api address
    LoadGame();
  }, [selectedGameStat]);

  const LoadGame = () => {
    setIsLoading(true);
    const url =
      process.env.REACT_APP_API_URL +
      "/GAME?filter=GameStat,eq," +
      selectedGameStat;

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setgames(data.records);

        console.log("data", data.records);
        console.log("sschema", data.records[0]);
        console.log("sschema", schema);
        console.log("games", games);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    //Change for server api address
    const url =
      process.env.REACT_APP_API_URL +
      "/GAME?filter=GameState=" +
      selectedGameStat;

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfoInfo = data.records.map((v) => {
          v.value = v.IdBook;
          v.text = v.Description;
          return v;
        });
        setBooks(bookInfoInfo);
      });
  }, []);

  function onAfterDeleteRow(rowKeys) {
    let deleteRequest = {
      IdGame: rowKeys,
      IdUser: userInfo.IdUser,
    };
    console.log(deleteRequest);
    axios({
      method: "post",
      url: process.env.REACT_APP_API2_URL + "/Game/DeleteGame",
      data: deleteRequest,
    }).then((response) => {
      console.log("response", response);
    });
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(",");
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }

  function selectedValues(row) {
    console.log(`find parent You click row id Games: ${row.IdGame}`, props);
    console.log(`find parent You click row id Games: ${row.IdGame}`);
    props.callBackFn &&
      console.log(`!!!!!find parent You click row id Games: ${row.IdGame}`);

    props.callBackFn ? props.callBackFn(row.IdGame) : setSelectedGame(row);
    
    setIdSport(row.IdSport);    
    setIdGame(row.IdGame);       
    setIsModify(true);   
    
    //setRedirect(row);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "500",
        value: 500,
      },
      {
        text: "1000",
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    cellEdit: false,
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: "top", // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
    beforeInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  const cellEditProp = {
    mode: "click", //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: "checkbox",

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };

  function onAfterInsertRow() {
    setIsNew(true);
  }

  function onRowSelect(row, isSelected, e) {
    let rowStr = "";
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  function onAfterSaveCell(row, cellName, cellValue) {
    let RequestDTO = {
      IdGame: row.IdGame,
      GameName: row.GameName,
      Notes: row.Notes,
      AlternateGame: row.AlternateGame,
      AlternateEnable: row.AlternateEnable,
      PromotionPoints: row.PromotionPoints,
      SL_IfBets: row.SL_IfBets,
      SL_AlwaysActionMLBTotals: row.SL_AlwaysActionMLBTotals,
      PL_MaxGames: row.PL_MaxGames,
      PL_AllowProps: row.PL_AllowProps,
      PL_AllowGolf: row.PL_AllowGolf,
      PL_AllowMatchUps: row.PL_AllowMatchUps,
      PL_NoListedPitchers: row.PL_NoListedPitchers,
      PL_Juice2TeamParlay: row.PL_Juice2TeamParlay,
      PL_TrueOddsOption: row.PL_TrueOddsOption,
      PL_MaxOdds: row.PL_MaxOdds,
      PL_MaxPayout: row.PL_MaxPayout,
      PL_MaxTeamBuyPoints: row.PL_MaxTeamBuyPoints,
      PL_MaxBuyPoints: row.PL_MaxBuyPoints,
      PL_AlwaysAction: row.PL_AlwaysAction,
      PL_AllowRunLineTotal: row.PL_AllowRunLineTotal,
      PL_AllowSpreadTotalHK: row.PL_AllowSpreadTotalHK,
      PL_AllowSpreadTotalSOC: row.PL_AllowSpreadTotalSOC,
      PL_TieLoses: row.PL_TieLoses,
      PL_ParlayFormula: row.PL_ParlayFormula,
      PL_AllowOpenPlays: row.PL_AllowOpenPlays,
      PL_OddsDefault: row.PL_OddsDefault,
      PL_LowerUseDefault: row.PL_LowerUseDefault,
      PL_MaxDogsSide: row.PL_MaxDogsSide,
      PL_MaxDogsTotal: row.PL_MaxDogsTotal,
      PL_MaxDogsMoney: row.PL_MaxDogsMoney,
      PL_MaxSumOdds: row.PL_MaxSumOdds,
      PL_UseMaxSumOdds: row.PL_UseMaxSumOdds,
      IL_MaxParlays: row.IL_MaxParlays,
      IL_MaxLevelParlays: row.IL_MaxLevelParlays,
      IL_MaxTeasers: row.IL_MaxTeasers,
      IL_MaxLevelTeasers: row.IL_MaxLevelTeasers,
      IL_MaxNumberSB: row.IL_MaxNumberSB,
      IL_AskAmount: row.IL_AskAmount,
      IL_AllowChildHigher: row.IL_AllowChildHigher,
      PC_PitcherChangeType: row.PC_PitcherChangeType,
      TL_OpenLose: row.TL_OpenLose,
      TL_TeaserCancel: row.TL_TeaserCancel,
      SG_CancelSide: row.SG_CancelSide,
      SG_CancelTotal: row.SG_CancelTotal,
      SG_CancelRunLine: row.SG_CancelRunLine,
      PC_SkipSide: row.PC_SkipSide,
      PC_SkipTotal: row.PC_SkipTotal,
      PC_SkipRunLine: row.PC_SkipRunLine,
      Soc_Hookups: row.Soc_Hookups,
      Rev_AllowOpen: row.Rev_AllowOpen,
      DuplicateBetsCheckLineChange: row.DuplicateBetsCheckLineChange,
      DuplicateBetsCheckParlays: row.DuplicateBetsCheckParlays,
      DuplicateBetsCheckTeasers: row.DuplicateBetsCheckTeasers,
      DuplicateBetsCheckReverses: row.DuplicateBetsCheckReverses,
      DuplicateBetsCheckIfbets: row.DuplicateBetsCheckIfbets,
      DuplicateBetsCheckIfSBRev: row.DuplicateBetsCheckIfSBRev,
      DuplicateBetsCheckParlaysSB: row.DuplicateBetsCheckParlaysSB,
      DuplicateBetsCheckTeasersSB: row.DuplicateBetsCheckTeasersSB,
      DuplicateBetsCheckSameParlay: row.DuplicateBetsCheckSameParlay,
      DuplicateBetsCheckSameTeaser: row.DuplicateBetsCheckSameTeaser,
      FPMaxFav: row.FPMaxFav,
      FPMaxPayout: row.FPMaxPayout,
      FPMaxTeams: row.FPMaxTeams,
      FPOddsLimit: row.FPOddsLimit,
      FPAllowDuplicatedBets: row.FPAllowDuplicatedBets,
      FPAllowBothSides: row.FPAllowBothSides,
      FPCheckOfficeFilters: row.FPCheckOfficeFilters,
      LastModification: row.LastModification,
      LastModificationUser: row.LastModificationUser,
      CLMaxWager: row.CLMaxWager,
      ILOddslimit: row.ILOddslimit,
    };
    axios({
      method: "post",
      url: process.env.REACT_APP_API2_URL + "/Game/PlayerGame_Update",
      //url: 'https://localhost:44337/api/Game/PlayerGame_Update',
      data: RequestDTO,
    }).then((response) => {
      if (response.data === "-1") {
        alert("Data Successfully Updated");
      }
      console.log("response", response);
    });
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }

  const toggleTable = () => {
    if (showInfo) {
      setshowInfo(false);
    } else {
      setshowInfo(true);
    }
  };

  if (redirect) {
    let pushURL = "/Game/" + redirect.IdGame;

    return (
      <React.Fragment>
        {/* //this.props.location.state. asi se agarran los datos en el otro componente */}

        {console.log("Game Info props", redirect)}
        <div>{history.push(pushURL)}</div>
      </React.Fragment>
      /*<Redirect
        to={{
          pathname: '/entityContainer',
          state: { row: redirect },
        }}
      />

      */
    );
  }
  return (
    <React.Fragment>
      {isNew ? (
        <CreateGame
          onClose={handleCloseCreate}
          searchGameValues={LoadGame}
          IdSport={""}
          selectedGame={selectedGame}
        />
      ) : (
        isModify?(          
          <CreateGame
          onClose={handleCloseCreate}
          searchGameValues={LoadGame}
          IdSport={idSport}
          selectedGame={selectedGame}
          isModify={isModify}
          idGame={idGame}
          //udpate when drop downs are ready
          IdLinetype={55}
          />
        ) :("")
      )}

      <div id="main-div">
        <Paper className={classes.root}>
          {!props.callBackFn && (
            <div>
              <h2>Games</h2>
              <FormControl className={classes.formControl}>
                <InputLabel
                  shrink
                  id="demo-simple-select-placeholder-label-label"
                >
                  Game
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={selectedGameStat}
                  onChange={handleGameStatChange}
                  displayEmpty
                  className={classes.selectEmpty}
                >
                  <MenuItem value={"O"}>Open</MenuItem>
                  <MenuItem value={"G"}>Graded</MenuItem>
                  <MenuItem value={"C"}>Closed</MenuItem>
                  <MenuItem value={"D"}>Disabled</MenuItem>
                </Select>
                <FormHelperText>Status</FormHelperText>
              </FormControl>
              <br />
              <br />
              <FormControl className={classes.formControl}>
                <AddBoxIcon
                  aria-label="newGame"
                  size="small"
                  className={classes.fab}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsNew(true);
                  }}
                />{" "}
                Add Games
              </FormControl>
            </div>
          )}
          {isLoading ? <Loader></Loader> : ""}
          <Table className={classes.table} aria-label="simple table">
            <TableBody className={showInfo ? "show-table" : "hide-table"}>
              {games && (
                <div>
                  <BootstrapTable
                    pagination={true}
                    options={options}
                    editable={false}
                    //cellEdit={cellEditProp} //DBClick daba problemas con el Expand
                    //selectRow={selectRowProp} //Da problemas con el Expand
                    onRowClick={() => props.handleChange(653676)}
                    version="4"
                    data={games}
                    //deleteRow={props.callBackFn ? false : true}
                    hover
                  >
                    <TableHeaderColumn
                      dataField="IdGame"
                      hiddenOnInsert
                      isKey={true}
                      autoValue={true}
                      hidden
                    >
                      Game ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      filter={{ type: "TextFilter", delay: 1000 }}
                      dataField="HomeNumber"
                    >
                      HomeNumber
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      filter={{ type: "TextFilter", delay: 1000 }}
                      dataField="HomeTeam"
                      hiddenOnInsert
                    >
                      HomeTeam
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      filter={{ type: "TextFilter", delay: 1000 }}
                      dataField="VisitorNumber"
                    >
                      VisitorNumber
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      filter={{ type: "TextFilter", delay: 1000 }}
                      dataField="VisitorTeam"
                      hiddenOnInsert
                    >
                      VisitorTeam
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </React.Fragment>
  );
}

export default Games;
