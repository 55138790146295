import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Form from "@rjsf/core";
import EditableForm from "../components/common/EditableForm";
import {
  subscribeToTimer,
  subscribeToAnnounceChange,
  subscribeToChanges,
} from "../socket/Subscriber";
function Home(props) {
  const [linetypes, setLinetypes] = useState();
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [userLogonInfo, setUserLogonInfo] = useState("");
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );

  const [timestamp, settimestamp] = useState();
  console.log("userInfo", userInfo);
  console.log("userInfo localStorage", sessionStorage);

  //comentario

  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "grey",
    },
    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },
  });

  const classes = useStyles();

  useEffect(() => {
    subscribeToTimer((err, timestamp) => settimestamp(timestamp));
  }, []);
  return (
    <Paper className={classes.root}>
      <br />
      <br /> <h2>Home</h2>
      <br /> <h3>Lobby</h3>
    </Paper>
  );
}

export default Home;
