import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import Select from "@material-ui/core/Select";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { Error } from "@material-ui/icons";



export default function ControlledOpenSelect(props) {  
  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: props.fixedWidth ? 250 : ""
    },
  }));
  const classes = useStyles();
  const [age, setAge] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {    
    setAge(event.target.value);
    props.setSelectValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (props.startValue) {
      setAge(props.startValue);      
    }    
  }, []);

  useEffect(() => {
    if(props.placeholder==="Master Agent"){
      console.log("age",age)
      console.log("age", typeof age)
    }  
   }, [age]);

  useEffect(() => {
    if(props.placeholder==="LineTypes"){
      setAge(props.idLineType);
    }
  }, [props.idLineType]);

  useEffect(() => {
    if(props.placeholder==="LineTypes"){
      setAge(13);
    }
  }, []);

  
  if (props.placeholder === "Sports") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          <Select
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={age}
            onChange={handleChange}
          >
            {props.data &&
              props.data.map((row) => (
                <MenuItem value={row.IdSport.toString().trim()}>
                  {/*console.log(row.SportName)*/}
                  <em>{row.SportName}</em>
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  } else if (props.placeholder === "League") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {/*  <InputLabel id="demo-controlled-open-select-label">
            {props.placeholder}
          </InputLabel>
          */}
          <Select
            /* labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            */
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={age}
            onChange={handleChange}
          >
            <MenuItem value="-1">
              <em>All</em>
            </MenuItem>
            {props.data &&
              props.data.map((row) => (
                <MenuItem value={row.IdLeague}>
                  <em>{row.Description}</em>
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  } else if (props.placeholder === "Type") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          <Select
            key={age}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={age}
            onChange={handleChange}
          >            
            {props.data &&
              props.data.map((row) => (
                <MenuItem value={row.IdGameType}>
                  <em>{row.Description}</em>
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  } else if (props.placeholder === "LineTypes") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          <Select
            key={age}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={age}
            onChange={handleChange}
          >
            {console.log("age", age)}
            {props.data &&
              props.data.sort(
                function(a, b){
                  if(a.Description < b.Description) { return -1; }
                  if(a.Description > b.Description) { return 1; }
                  return 0;
                }
              ).map((row,index) => (
                <MenuItem value={index}>                  
                  <em>{row.Description}</em>
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>Line Types</FormHelperText>
        </FormControl>
      </div>
    );
  }else if (props.placeholder === "Id Office") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.OfficeName}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }else if (props.placeholder === "Id Agent") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.Agent}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }else if (props.placeholder === "Id Profile" || props.placeholder === "Id Profile Limits") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.sort(
                  function(a, b){
                    if(a.Description < b.Description) { return -1; }
                    if(a.Description > b.Description) { return 1; }
                    return 0;
                  }
                ).map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.ProfileName}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }else if (props.placeholder === "Id Language") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.LangDesc}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }else if (props.placeholder === "Id Source") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.SourceName}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }else if (props.placeholder === "Id Grouping") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.GroupName}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }else if (props.placeholder === "Master Agent") {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.Name}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  else {
    return (
      <div>
        <FormControl className={classes.formControl} error={props.isError}>
          {props.data ? (
            <Select
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={age}
              onChange={handleChange}
            >
              {props.data &&
                props.data.map((row, index) => (
                  <MenuItem value={index}>
                    <em>{row.Description}</em>
                  </MenuItem>
                ))}
            </Select>
          ) : (
            ""
          )}
          <FormHelperText>{props.placeholder}</FormHelperText>
        </FormControl>
      </div>
    );
  }
}
