import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import './WebConfigurationColumnHead.css';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid' 
import Loader from '../../common/loader/Loader';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "@material-ui/core/Button";
import WebConfiguratorColumnHeadNew from '../webConfiguratorComponents/WebConfiguratorColumnHeadNew'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function WebList(props) {
  const [webColumns, setWebColumns] = useState();
  const [record, setRecord] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedWebColumn,setSelectedWebColumn] = useState(false);
  const [selectedIdWeb, setSelectedIdWeb] = useState(props.initialValue);
  const [isNewWebColumn, setIsNewWebColumn] = useState(false);
  const [filter, setFilter] = useState("");

  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 230,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  useEffect(() => {
    GetColumn();
  }, []);

  const GetColumn = () => {
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/WEBCOLUMN?order=IdWebColumn';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setWebColumns(data.records);
        setIsLoading(false);
      });
  };


  const handleIdWebChange = (event, newValue) => {
    console.log('Linetype', newValue.props.value);
    props.setWebOnMem(event.target.value, props.row);
    setSelectedIdWeb(newValue.props.value);
  };

  function onRowSelect(row, isSelected, e) {
    setSelectedWebColumn(webColumns.filter(x => x.IdWebColumn == row[0])[0])    
  }

  function renderManage(params) { 
    return( 
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          props.loadWebColumn(params.row)
        }}
      >
        Manage
      </Button>
    )
  }

  const handleCloseCreate = function () {
    setIsNewWebColumn(false); 
  };

  const deleteWebColumn = () => {
    if(window.confirm("Are you sure you want to delete the selected item? ("+selectedWebColumn.IdWebColumn+")")){
      let RequestDTO = {
        idWebColumn: selectedWebColumn.IdWebColumn,
        idUser: userInfo.UserId,
      };
      axios({
        method: 'post',
        url: process.env.REACT_APP_API2_URL + '/WebConfigurator/WebColumn_Delete',
        data: RequestDTO,
      }).then((response) => {
        if (response.data === true) {
          alert('Data Successfully Deleted');
        }
        GetColumn();
        setSelectedWebColumn(false)
      });
    }
  }

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  }

  const printInputList = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Web
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedIdWeb}
            onChange={handleIdWebChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {webColumns &&
              webColumns.map((row) => (
                <MenuItem value={row.IdWeb}>{row.Description}</MenuItem>
              ))}
          </Select>
          <FormHelperText>Web Row</FormHelperText>
        </FormControl>
      </div>
    );
  };

  const printList = () => {
    return (
      <div id="main-div Web-list" class="Web-list">
        <Paper className={classes.root}>
          <h2>Web columns</h2>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>{setIsNewWebColumn(true)}}
          >
            New Web Column
          </Button>
          {selectedWebColumn ? (
            <Button
                style={{ margin: 8 }}
                variant="contained"
                color="secondary"
                onClick={deleteWebColumn}
              >
                Delete Web Column
              </Button>
            ) : (
              ""
            )}
          {isLoading ? <Loader></Loader> : ''}
          <Table className={classes.table} aria-label="simple table">
            <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
              {webColumns && (
                <div>
                  <TextField      
                    style={{padding:"8px"}}                          
                    variant="outlined"                            
                    label="Search by Description"
                    type="text"
                    value={filter}
                    onChange={handleChangeFilter}                                                             
                  />
                  <Box sx={{ width: '750px' }}>
                    <DataGrid
                        rows={webColumns.length > 0 ? filter != "" ? webColumns.filter(x => x.Description.toUpperCase().includes(filter.toUpperCase())) : webColumns : [] }
                        getRowId={(row) => Object.values(row)[0]}
                        columns={[ 
                          {
                            field: "",
                            headerName: "",
                            align: "right",
                            headerAlign: "center",
                            width: 100,
                            editable: false,
                            sortable: false,
                            filterable: false,
                            renderCell: renderManage,
                          },                    
                          {field: 'Description' , headerName: 'Description', width: 350, editable: false, sortable: false, filterable: false},
                        ]}
                        pageSize={100}
                        rowsPerPageOptions={[5]}            
                        disableColumnSelector
                        disableColumnMenu
                        disableMultipleSelection={true}  
                        onSelectionModelChange={onRowSelect}   
                        autoHeight                               
                    />
                </Box>                 
                </div>
              )}
            </TableBody>
          </Table>
        </Paper>
        {isNewWebColumn?(        
            <WebConfiguratorColumnHeadNew
              onClose={handleCloseCreate}
              GetColumn={GetColumn}
            />          
        ):(
          "" 
        )} 
      </div>
    );
  };

  if (redirect) {
    let pushURL = '/Web/' + redirect.IdWeb;
    console.log('Web Info props redirect', redirect);
    props.setWeb(redirect);
    printList();
    setRedirect(null);
    return (
      <div>
        <Redirect
          to={{
            pathname: pushURL,
          }}
        />

        {}
      </div>
    );
  }

  if (props.isInput) {
    return printInputList();
  } else {
    return printList();
  }
}

export default WebList;
