import React, { useState, setState, useEffect, useContext } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import Axios from "axios";
import FormGroup from "@material-ui/core/FormGroup";
import InputSelect from '../../common/InputSelect';
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@mui/material/Box';
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { set } from 'date-fns';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

function ClonePlayer (props) {

    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            //width: "600px",
            height: "600px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              //width: 500,
            },
            "& .MuiFormGroup-row": {
              justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();
    const [open, setOpen] = useState(true)
    const [lineTypes, setLineTypes] = useState();
    const [lineTypeIndex, setLineTypeIndex] = useState(13);
    const [isRed, setIsRed] = useState(false);
    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
    );
    

    //Player States below
    
    const [idLineType, setIdLineType] = useState(props.selectedPlayer.IdLineType)
    const [idOffice, setIdOffice] = useState(props.selectedPlayer.IdOffice)
    const [idAgent, setIdAgent] = useState(props.selectedPlayer.IdAgent)
    const [idCurrency, setIdCurrency] = useState(props.selectedPlayer.IdCurrency)
    const [idGrouping, setIdGrouping] = useState(props.selectedPlayer.IdGrouping)
    const [idSource, setIdSource] = useState(props.selectedPlayer.IdSource)
    const [idProfile, setIdProfile] = useState(props.selectedPlayer.IdProfile)
    const [idPlayerRate, setIdPlayerRate] = useState(props.selectedPlayer.IdPlayerRate)
    const [idBook, setIdBook] = useState(props.selectedPlayer.IdBook)
    const [idTimeZone, setIdTimeZone] = useState(props.selectedPlayer.IdTimeZone)
    const [idLanguage, setIdLanguage] = useState(props.selectedPlayer.IdLanguage)
    const [scheduleStyle, setScheduleStyle] = useState(props.selectedPlayer.ScheduleStyle)
    const [player, setPlayer] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [lastName2, setLastName2] = useState("")
    const [title, setTitle] = useState("")
    const [address1, setAddress1] = useState(props.selectedPlayer.Address1)
    const [address2, setAddress2] = useState(props.selectedPlayer.Address2)
    const [city, setCity] = useState(props.selectedPlayer.City)
    const [state, setState] = useState(props.selectedPlayer.State)
    const [country, setCountry] = useState(props.selectedPlayer.Country)
    const [zip, setZip] = useState(props.selectedPlayer.Zip)
    const [phone, setPhone] = useState(props.selectedPlayer.Phone)
    const [fax, setFax] = useState(props.selectedPlayer.Fax)
    const [email, setEmail] = useState(props.selectedPlayer.Email)
    const [status, setStatus] = useState(props.selectedPlayer.Status)
    const [autoPay, setAutoPay] = useState(props.selectedPlayer.AutoPay)
    const [balanceChecked, setBalanceChecked] = useState(props.selectedPlayer.BalanceChecked)
    const [creditLimit, setCreditLimit] = useState(props.selectedPlayer.CreditLimit)
    const [noLimit, setNoLimit] = useState(props.selectedPlayer.NoLimit)
    const [tempCredit, setTempCredit] = useState(props.selectedPlayer.TempCredit)
    const [softLimitPercent, setSoftLimitPercent] = useState(props.selectedPlayer.SoftLimitPercent)
    const [tempCreditExpire, setTempCreditExpire] = useState(props.selectedPlayer.TempCreditExpire)
    const [onlineAccess, setOnlineAccess] = useState(props.selectedPlayer.OnlineAccess)
    const [onlinePassword, setOnlinePassword] = useState("")
    const [onlineMessage, setOnlineMessage] = useState(props.selectedPlayer.OnlineMessage)
    const [onlineMaxWager, setOnlineMaxWager] = useState(props.selectedPlayer.OnlineMaxWager)
    const [onlineMinWager, setOnlineMinWager] = useState(props.selectedPlayer.OnlineMinWager)
    const [maxWager, setMaxWager] = useState(props.selectedPlayer.MaxWager)
    const [minWager, setMinWager] = useState(props.selectedPlayer.MinWager)
    const [capPerGame, setCapPerGame] = useState(props.selectedPlayer.CapPerGame)
    const [chartPercent, setChartPercent] = useState(props.selectedPlayer.ChartPercent)
    const [masterChart, setMasterChart] = useState(props.selectedPlayer.MasterChart)
    const [master, setMaster] = useState(props.selectedPlayer.Master)
    const [flagMessage, setFlagMessage] = useState(props.selectedPlayer.FlagMessage)
    const [idFlagMessageType, setIdFlagMessageType] = useState(props.selectedPlayer.IdFlagMessageType)
    const [maxActionPoints, setMaxActionPoints] = useState(props.selectedPlayer.MaxActionPoints)
    const [bonusPointsStatus, setBonusPointsStatus] = useState(props.selectedPlayer.BonusPointsStatus)
    const [bonusPointsExpire, setBonusPointsExpire] = useState(props.selectedPlayer.BonusPointsExpire)
    const [bonusPointsStart, setBonusPointsStart] = useState(props.selectedPlayer.BonusPointsStart)
    const [lineStyle, setLineStyle] = useState(props.selectedPlayer.LineStyle)
    const [nHLLine, setNHLLine] = useState(props.selectedPlayer.NHLLine)
    const [mLBLine, setMLBLine] = useState(props.selectedPlayer.MLBLine)
    const [pitcherDefault, setPitcherDefault] = useState(props.selectedPlayer.PitcherDefault)
    const [duplicatedBets, setDuplicatedBets] = useState(props.selectedPlayer.DuplicatedBets)
    const [duplicatedBetsOnline, setDuplicatedBetsOnline] = useState(props.selectedPlayer.DuplicatedBetsOnline)
    const [freePlayAmount, setFreePlayAmount] = useState(props.selectedPlayer.FreePlayAmount)
    const [freePlayMessage, setFreePlayMessage] = useState(props.selectedPlayer.FreePlayMessage)
    const [scheduleFB, setScheduleFB] = useState(props.selectedPlayer.ScheduleFB)
    const [scheduleBB, setScheduleBB] = useState(props.selectedPlayer.ScheduleBB)
    const [scheduleHK, setScheduleHK] = useState(props.selectedPlayer.ScheduleHK)
    const [scheduleBS, setScheduleBS] = useState(props.selectedPlayer.ScheduleBS)
    const [settledFigure, setSettledFigure] = useState(props.selectedPlayer.SettledFigure)
    const [showInTicker, setShowInTicker] = useState(props.selectedPlayer.ShowInTicker)
    const [EPOSPlayer, setEPOSPlayer] = useState(props.selectedPlayer.EPOSPlayer)
    const [enableHorses, setEnableHorses] = useState(props.selectedPlayer.EnableHorses)
    const [enableCasino, setEnableCasino] = useState(props.selectedPlayer.EnableCasino)
    const [enableSports, setEnableSports] = useState(props.selectedPlayer.EnableSports)
    const [dateOfBirth, setDateOfBirth] = useState(props.selectedPlayer.DateOfBirth)
    const [secQuestion, setSecQuestion] = useState(props.selectedPlayer.SecQuestion)
    const [secAnswer, setSecAnswer] = useState(props.selectedPlayer.SecAnswer)
    const [signUpIP, setSignUpIP] = useState(props.selectedPlayer.SignUpIP)
    const [allowNegTrans, setAllowNegTrans] = useState(props.selectedPlayer.AllowNegTrans)
    const [lastModificationUser, setLastModificationUser] = useState(userInfo.UserId)
    const [holdBets, setHoldBets] = useState(props.selectedPlayer.HoldBets)
    const [holdDelay, setHoldDelay] = useState(props.selectedPlayer.HoldDelay)
    const [idProfileLimits, setIdProfileLimits  ] = useState(props.selectedPlayer.IdProfileLimits)
    const [enableCards, setEnableCards] = useState(props.selectedPlayer.EnableCards)
    const [idAffiliate, setIdAffiliate] = useState(props.selectedPlayer.IdAffiliate)
    const [reset_Password, setReset_Password] = useState(props.selectedPlayer.Reset_Password)
    
    
    const getLineTypes = () => {
        const url = process.env.REACT_APP_API_URL + "/LINETYPE";
        Axios.get(url)
        .then((response) => response.data)
        .then((data) => {
            setLineTypes(data.records);
            setLineTypeIndex(data.records.findIndex(x => x.IdLineType == idLineType))
        });
    }

    const handleChangePlayer = (event) => {
        setPlayer(event.target.value);
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    }
    
    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleChangeName = (event) => {
        setName(event.target.value);
    }

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    }

    const handleChangeLastName2 = (event) => {
        setLastName2(event.target.value);
    }

    const handleChangeMaster = () => {
        setMaster(!master);
    }

    const handleChangeStatus = () =>{
        if(status=='E')
            setStatus('D')
        else    
            setStatus('E')
    }

    const handleChangeOnlineAccess = () => {
        setOnlineAccess(!onlineAccess);
    }

    const handleChangeEPOSPlayer = () => {
        setEPOSPlayer(!EPOSPlayer);
    }

    const changeIdBook = (value) => {
        setIdBook(props.books[value].IdBook);
    }
    const changeLineTypeID = (value) => {    
        let linetype = lineTypes[value];
        setLineTypeIndex(value);    
        setIdLineType(linetype.IdLineType);
    }

    const changeIdOffice = (value) => {
        setIdOffice(props.office[value].IdOffice);
    }

    const changeIdAgent = (value) => {
        setIdAgent(props.agents[value].IdAgent);
    }

    const changeIdCurrency = (value) => {
        setIdCurrency(props.currency[value].IdCurrency);
    }
    
    const changeIdGrouping = (value) => {
        setIdGrouping(props.grouping[value].IdGrouping);
    }

    const changeIdProfile = (value) => {
        setIdProfile(props.playerProfile[value].IdPlayer);
    }

    const changeIdPlayerRate = (value) => {
        setIdPlayerRate(props.playerRate[value].IdPlayerRate);
    }

    const changeIdTimeZone = (value) => {
        setIdTimeZone(props.timeZone[value].IdTimeZone);
    }

    const changeIdLanguage = (value) => {
        setIdLanguage(props.language[value].IdLanguage);
    } 

    const handleChangeSecQuestion = (event) => {
        setSecQuestion(event.target.value);
    }

    const handleChangeSecAnswer = (event) => {
        setSecAnswer(event.target.values);
    }

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    }

    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    }

    const handleChangeFax = (event) => {
        setFax(event.target.value);
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const handleChangeDateOfBirth = (event) => {
        setDateOfBirth(event.target.value);
    }

    const handleChangeState = (event) => {
        setState(event.target.value);
    }

    const handleChangeZip = (event) => {
        setZip(event.target.value);
    }

    const handleChangeAddress1 = (event) => {
        setAddress1(event.target.value);
    }

    const handleChangeAddress2 = (event) => {
        setAddress2(event.target.value);
    }

    const handleChangeCity = (event) => {
        setCity(event.target.value);
    }

    const changeIdSource = (value) => {
        setIdSource(props.source[value].IdSource);
    }

    const handleChangeSignUpIp = (event) => {
        setSignUpIP(event.target.value);
    }

    const handleChangeFreePlayMessage = (event) => {
        setFreePlayMessage(event.target.value);
    }

    const handleChangeScheduleFB = () => {
        setScheduleFB(!scheduleFB);
    }

    const handleChangeScheduleBS = () => {
        setScheduleBS(!scheduleBS)
    }

    const handleChangeScheduleBB = () => {
        setScheduleBB(!scheduleBB);
    }

    const handleChangeScheduleHK = () => {
        set(!scheduleHK)
    }

    const handleChangeBonusPointsStart = (event) => {
        setBonusPointsStart(event.target.value);
    }

    const handleChangeBonusPointsExpire = (event) => {
        setBonusPointsExpire(event.target.value);
    }

    const changeIdProfileLimits = (value) => {
        setIdProfileLimits(props.profileLimits[value].IdProfileLimits);
    }

    const handleChangeCreditLimit = (event) => {
        setCreditLimit(event.target.value);
    }

    const handleChangeNoLimit = () => {
        setNoLimit(!noLimit)
    }

    const handleChangeTempCredit = (event) => {
        setTempCredit(event.target.value);
    }

    const handleChangeTempCreditExpire = (event) => {
        setTempCreditExpire(event.target.value);
    }

    const handleChangeSoftLimitPercent = (event) => {
        setSoftLimitPercent(event.target.value);
    }

    const handleChangeSettleFigure = (event) => {
        setSettledFigure(event.target.value);
    }

    const handleChangeChartPercent = (event) => {
        setChartPercent(event.target.value);
    }

    const handleChangeMasterChart = (event) => {
        setMasterChart(event.target.value);
    }

    const handleChangeMaxActionPoints = (event) => {
        setMaxActionPoints(event.target.value);
    }

    const handleChangeMaxWager = (event) => {
        setMaxWager(event.target.value);
    }

    const handleChangeMinWager = (event) => {
        setMinWager(event.target.value);
    }

    const handleChangeDuplicateBets = () => {
        setDuplicatedBets(!duplicatedBets)
    }

    const changeIdFlagMessageType = () => {
        setIdFlagMessageType(props.flagMessageType[value].IdFlagMessageType);
    }

    const handleChangeFlagMessage = (event) => {
        setFlagMessage(event.target.value);
    }

    const handleChangeOnlineMaxWager = (event) => {
        setOnlineMaxWager(event.target.value);
    }

    const handleChangeOnlineMinWager = (event) => {
        setOnlineMinWager(event.target.value);
    }

    const handleChangeDuplicateBetsOnline = () => {
        setDuplicatedBetsOnline(!duplicatedBetsOnline);
    }

    const handleChangeCapPerGame = (event) => {
        setCapPerGame(event.target.value);
    }

    const handleChangeFreePlayAmount = (event) => {
        setFreePlayAmount(event.target.value);
    }

    const handleChangeOnlineMessage = (event) => {
        setOnlineMessage(event.target.value);
    }

    const handleChangeHoldDelay = (event) => {
        setHoldDelay(event.target.value);
    }

    const handleChangeHoldBets = () => {
        setHoldBets(!holdBets);
    }

    const handleChangeEnableSports = () => {
        setEnableSports(!enableSports);
    }

    const handleChangeEnableCasino = () => {
        setEnableCasino(!enableCasino)
    }

    const handleChangeEnableHorses = () => {
        setEnableHorses(!enableHorses);
    }

    const handleChangeEnableCards = () => {
        setEnableCards(!enableCards);
    }

    const handleChangeShowInTicker = () => {
        setShowInTicker(!showInTicker);
    }

    const handleChangeLineType = (event) => {
        let calculatedLineStyle;
        switch(event.target.value){
            case "Eastern" :
                calculatedLineStyle = "E" 
                break
            case "Decimal" :
                calculatedLineStyle = "D"
                break
            case "Fractional":
                calculatedLineStyle = "F"
                break
        }
        setLineStyle(calculatedLineStyle);
    }
    
    const handleChangeNHLLine = (event) => {
        let calculatedLineStyle;
        switch(event.target.value){
            case "American" :
                calculatedLineStyle = "A" 
                break
            case "Canadian" :
                calculatedLineStyle = "C"
                break
        }
        setNHLLine(calculatedLineStyle);
    }

    const handleChangeMLBLine = (event) => {
        let calculatedLineStyle;
        switch(event.target.value){
            case "Normal" :
                calculatedLineStyle = "N"
                break
            case "Wide" :
                calculatedLineStyle = "W"
                break
            case "Eastern" :
                calculatedLineStyle = "Y"
                break
        }
        setLineStyle(calculatedLineStyle);
    }

    const handleChangePitcherDefault = (event) => {
        let calculatedLineStyle;
        switch(event.target.value){
            case "Action" :
                calculatedLineStyle = 0
                break
            case "Visitor" :
                calculatedLineStyle = 1
                break
            case "Home" :
                calculatedLineStyle = 2
                break
            case "Listed" :
                calculatedLineStyle = 3
                break
            case "Same" :
                calculatedLineStyle = 4
                break
        }
        setPitcherDefault(calculatedLineStyle);
    }

    const handleChangeScheduleStyle = (event) => {
        let calculatedLineStyle;
        switch(event.target.value){
            case "American" :
                calculatedLineStyle = "A"
                break
            case "European" :
                calculatedLineStyle = "E"
                break
        }
        return calculatedLineStyle;
    }

    const handleChangeBonusPoints = (event) => {
        let CalculatedBonusPointsStatus;
        switch(event.target.value){
            case "Enabled" :
                CalculatedBonusPointsStatus = "E"
                break
            case "Disabled" :
                CalculatedBonusPointsStatus = "D"
                break
            case "Banned" :
            CalculatedBonusPointsStatus = "E"
            break
        }
        return CalculatedBonusPointsStatus;
    }

    const CalculateLineStyle = () => {
        let calculatedLineStyle;
        switch(lineStyle){
            case "E" :
                calculatedLineStyle = "Eastern"
                break
            case "D" :
                calculatedLineStyle = "Decimal"
                break
            case "F" :
                calculatedLineStyle = "Fractional"
                break
        }
        return calculatedLineStyle;
    }
    
    const CalculateNHLLine = () => {
        let calculatedLineStyle;
        switch(nHLLine){
            case "A" :
                calculatedLineStyle = "American"
                break
            case "C" :
                calculatedLineStyle = "Canadian"
                break
        }
        return calculatedLineStyle;
    }

    const CalculateMLBLine = () => {
        let calculatedLineStyle;
        switch(mLBLine){
            case "N" :
                calculatedLineStyle = "Normal"
                break
            case "W" :
                calculatedLineStyle = "Wide"
                break
            case "Y" :
                calculatedLineStyle = "Eastern"
                break
        }
        return calculatedLineStyle;
    }

    const CalculatePitcherDefault = () => {
        let calculatedLineStyle;
        switch(pitcherDefault){
            case 0 :
                calculatedLineStyle = "Action"
                break
            case 1 :
                calculatedLineStyle = "Visitor"
                break
            case 2 :
                calculatedLineStyle = "Home"
                break
            case 3 :
                calculatedLineStyle = "Listed"
                break
            case 4 :
                calculatedLineStyle = "Same"
                break
        }
        return calculatedLineStyle;
    }

    const CalculateScheduleStyle = () => {
        let calculatedLineStyle;
        switch(scheduleStyle){
            case "A" :
                calculatedLineStyle = "American"
                break
            case "E" :
                calculatedLineStyle = "European"
                break
        }
        return calculatedLineStyle;
    }

    const CalculateBonusPointStatus = () => {
        let calculatedBonusPointStatus;
        switch(bonusPointsStatus){
            case "E" :
                calculatedBonusPointStatus = "Enabled"
                break
            case "D" :
                calculatedBonusPointStatus = "Disabled"
                break
            case "B" :
            calculatedBonusPointStatus = "Banned"
            break
        }
        return calculatedBonusPointStatus; 
    }    

    const handleChangeOnlinePassword = (event) => {
        setOnlinePassword(event.target.value);
    }

    const handleOk = () => {
        if(player == "" || password == "" || name =="" || onlinePassword == ""){
            alert("Please provide the required fields");     
            setIsRed(true);                        
        }else{
            clonePlayer();   
            setPlayer("")
            setPassword("")
            setName("")
            setOnlinePassword("")
            setLastName("")
            setLastName2("")
            setTitle("")       
        }
    }

    const clonePlayer = () => {
        let RequestDTO = {
        IdLineType: idLineType,
        IdOffice: idOffice,
        IdAgent: idAgent,
        IdCurrency: idCurrency,
        IdGrouping: idGrouping,
        IdSource: idSource,
        IdProfile: idProfile,
        IdPlayerRate: idPlayerRate,
        IdBook: idBook,
        IdTimeZone: idTimeZone,
        IdLanguage: idLanguage,
        Player1: player,
        Password: password,
        Name: name,
        LastName: lastName,
        LastName2: lastName2,
        Address1: address1,
        Address2: address2,
        City: city,
        State: state,
        Country: country,
        Zip: zip,
        Phone: phone,
        Fax: fax,
        Email: email,
        CreditLimit: creditLimit,
        TempCredit: tempCredit,
        OnlineAccess: onlineAccess,
        OnlinePassword: onlinePassword,
        OnlineMessage: onlineMessage,
        OnlineMaxWager: onlineMaxWager,
        OnlineMinWager: onlineMinWager,
        MaxWager: maxWager,
        MinWager: minWager,
        ChartPercent: chartPercent,
        Master: master,
        FlagMessage: flagMessage,
        IdFlagMessageType: idFlagMessageType,
        DuplicatedBets: duplicatedBets,
        DuplicatedBetsOnline: duplicatedBetsOnline,
        SettledFigure: settledFigure,
        ShowInTicker: showInTicker,
        EPOSPlayer: EPOSPlayer,
        EnableHorses: enableHorses,
        EnableCasino: enableCasino,
        EnableSports: enableSports,
        DateOfBirth: dateOfBirth,
        SecQuestion: secQuestion,
        SecAnswer: secAnswer,
        SignUpIP: signUpIP,
        HoldBets: holdBets,
        HoldDelay: holdDelay,
        IdProfileLimits: idProfileLimits,
        EnableCards: enableCards,
        IdAffiliate: idAffiliate,
        };
        Axios({
            method: "post",
            url:
                process.env.REACT_APP_API2_URL +
                "/Player/Player_Insert?IdUser=" +
                userInfo.UserId,
            data: RequestDTO,
        }).then((response) => {
            console.log("response", response);
            if (response.data ===-1){
                alert("The Player: " + player + " already exists on the system")
            }else{
                alert("Player Successfuly cloned")
            }
                  
        });        
    }

    useEffect(() => {
        console.log("props",props)   
        console.log("idLineType", idLineType)
        getLineTypes()
    }, [idLineType]);

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
          }
        setOpen(false);
    }

    const [value, setValue] = React.useState(0);

    function wagering () {
        return(
            <React.Fragment>
                <div style={{display:"flex", justifyContent:"center", alignItems:"baseline", placeContent:"space-evenly"}}>
                    <FormGroup style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"fit-content"}}>
                        <TextField                                
                            variant="outlined"                            
                            label="Credit Limit"
                            type="number"
                            value={Math.round(creditLimit)}
                            onChange={handleChangeCreditLimit}                                              
                        /> 
                        <FormControlLabel
                            style={{padding:"8px"}}
                            control={
                            <Checkbox
                                checked={noLimit}
                                onChange={handleChangeNoLimit}
                                color={"primary"}
                            />
                            }
                            label="No Limit"                               
                        />
                        <TextField                                
                            variant="outlined"                            
                            label="Temp Credit"
                            type="number"
                            value={Math.round(tempCredit)}
                            onChange={handleChangeTempCredit}                                              
                        /> 
                        <TextField
                            required
                            variant="outlined"
                            label="Temp Expires"
                            type="datetime-local"
                            value={
                                tempCreditExpire
                            }
                            onChange={handleChangeTempCreditExpire}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField                                
                            variant="outlined"                            
                            label="Soft Limit %"
                            type="number"
                            value={Math.round(softLimitPercent)}
                            onChange={handleChangeSoftLimitPercent}                                              
                        /> 
                        <TextField                                
                            variant="outlined"                            
                            label="Settle"
                            type="number"
                            value={Math.round(settledFigure)}
                            onChange={handleChangeSettleFigure}                                              
                        /> 
                        <TextField                                
                            variant="outlined"                            
                            label="Chart %"
                            type="number"
                            value={Math.round(chartPercent)}
                            onChange={handleChangeChartPercent}                                              
                        /> 
                        <TextField                                
                            variant="outlined"                            
                            label="Master Chart"
                            type="number"
                            value={Math.round(masterChart)}
                            onChange={handleChangeMasterChart}                                              
                        /> 
                        <TextField                                
                            variant="outlined"                            
                            label="Action Pts Max"
                            type="number"
                            value={Math.round(maxActionPoints)}
                            onChange={handleChangeMaxActionPoints}                                              
                        />                                    
                    </FormGroup>
                    <FormGroup style={{display:"flex",flexDirection:"column",justifyContent:"center",width:"fit-content"}}>    
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={lineTypes}
                            placeholder="LineTypes"
                            setSelectValue={changeLineTypeID}  
                            idLineType={lineTypeIndex}                         
                        /> 
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.currency}
                            placeholder="Id Currency"
                            startValue={props.currency.findIndex(x => x.IdCurrency === idCurrency)}
                            setSelectValue={changeIdCurrency}                         
                        />      
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.office}
                            placeholder="Id Office"
                            startValue={props.office.findIndex(x => x.IdOffice === idOffice)}
                            setSelectValue={changeIdOffice}                         
                        />
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.grouping}
                            placeholder="Id Grouping"
                            startValue={props.grouping.findIndex(x => x.IdGrouping === idGrouping)}
                            setSelectValue={changeIdGrouping}                         
                        />   
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.playerRate}
                            placeholder="Id Player Rate"
                            startValue={props.playerRate.findIndex(x => x.IdPlayerRate === idPlayerRate)}
                            setSelectValue={changeIdPlayerRate}                         
                        />
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.books}
                            placeholder="Id Book"
                            startValue={props.books.findIndex(x => x.IdBook === idBook)}
                            setSelectValue={changeIdBook}                         
                        />
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.playerProfile}
                            placeholder="Id Profile"
                            startValue={props.playerProfile.findIndex(x => x.IdProfile === idProfile)}
                            setSelectValue={changeIdProfile}                         
                        />   
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.profileLimits}
                            placeholder="Id Profile Limits"
                            startValue={props.profileLimits.findIndex(x => x.IdProfileLimits === idProfileLimits)}
                            setSelectValue={changeIdProfileLimits}                         
                        />  
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.agents}
                            placeholder="Id Agent"
                            startValue={props.agents.findIndex(x => x.IdAgent === idAgent)}
                            setSelectValue={changeIdAgent}                         
                        />                                            
                    </FormGroup>
                </div>
                <Divider />
                <div style={{display:"flex",flexFlow:"column"}}>
                    <FormControl
                        style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}}
                        >
                        <FormLabel 
                            id="demo-radio-buttons-group-label"
                        >
                            Local                                    
                        </FormLabel>
                        <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <TextField                                
                                variant="outlined"                            
                                label="Max Wager"
                                type="number"
                                value={Math.round(maxWager)}
                                onChange={handleChangeMaxWager}                                              
                            /> 
                            <TextField                                
                                variant="outlined"                            
                                label="Min Wager"
                                type="number"
                                value={Math.round(minWager)}
                                onChange={handleChangeMinWager}                                              
                            /> 
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={duplicatedBets}
                                    onChange={handleChangeDuplicateBets}
                                    color={"primary"}
                                />
                                }
                                label="Allow Duplicate Bets"                               
                            />
                        </FormGroup>
                        <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center", placeContent:"space-around"}}>
                            <InputSelect                             
                                variant="outlined"
                                name="name"
                                data={props.flagMessageType}
                                placeholder="Flag Type"
                                startValue={props.source.findIndex(x => x.IdFlagMessageType === idFlagMessageType)}
                                setSelectValue={changeIdFlagMessageType}                         
                            />
                            <TextField                                
                                variant="outlined"                            
                                label="Flag"
                                type="text"
                                value={flagMessage}
                                onChange={handleChangeFlagMessage}                                              
                            /> 
                        </FormGroup>
                    </FormControl>
                    <FormControl
                        style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}}
                        >
                        <FormLabel 
                            id="demo-radio-buttons-group-label"
                        >
                            Online                                    
                        </FormLabel>
                        <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <TextField                                
                                variant="outlined"                            
                                label="Online Max Wager"
                                type="number"
                                value={Math.round(onlineMaxWager)}
                                onChange={handleChangeOnlineMaxWager}                                              
                            /> 
                            <TextField                                
                                variant="outlined"                            
                                label="Online Min Wager"
                                type="number"
                                value={Math.round(onlineMinWager)}
                                onChange={handleChangeOnlineMinWager}                                              
                            /> 
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={duplicatedBetsOnline}
                                    onChange={handleChangeDuplicateBetsOnline}
                                    color={"primary"}
                                />
                                }
                                label="Allow Duplicate Bets"                               
                            />
                        </FormGroup>
                        <FormGroup style={{display:"flex",flexDirection:"row", placeContent:"flex-start"}}>
                            <TextField                                
                                variant="outlined"                            
                                label="Flag"
                                type="text"
                                value={onlineMessage}
                                onChange={handleChangeOnlineMessage}                                              
                            />
                        </FormGroup>
                    </FormControl>
                    <FormControl
                        style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}}
                        >
                        <FormLabel 
                            id="demo-radio-buttons-group-label"
                        >
                            Hold Bets                                    
                        </FormLabel>
                        <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <TextField                                
                                variant="outlined"                            
                                label="Delay"
                                type="number"
                                value={holdDelay}
                                onChange={handleChangeHoldDelay}                                              
                            />
                            <FormLabel 
                                style={{alignSelf:"center"}}
                                id="demo-radio-buttons-group-label"
                            >
                                Secs.                                    
                            </FormLabel>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={holdBets}
                                    onChange={handleChangeHoldBets}
                                    color={"primary"}
                                />
                                }
                                label="Enable"                               
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </React.Fragment>
        )
    }

    function settingNotes () {
        return (
            <div>
                <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={enableSports}
                            onChange={handleChangeEnableSports}
                            color={"primary"}
                        />
                        }
                        label="Enable Sports"                               
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={enableCasino}
                            onChange={handleChangeEnableCasino}
                            color={"primary"}
                        />
                        }
                        label="Enable Casino"                               
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={enableHorses}
                            onChange={handleChangeEnableHorses}
                            color={"primary"}
                        />
                        }
                        label="Enable Horses"                               
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={enableCards}
                            onChange={handleChangeEnableCards}
                            color={"primary"}
                        />
                        }
                        label="Enable Cards"                               
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={showInTicker}
                            onChange={handleChangeShowInTicker}
                            color={"primary"}
                        />
                        }
                        label="Show Plays in Ticker"                               
                    />
                </FormGroup>
                <FormControl
                    style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"
                    >
                        Line Style                                    
                    </FormLabel>
                    <RadioGroup 
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={CalculateLineStyle}
                        name="radio-buttons-group"                        
                        onChange={handleChangeLineType}
                    >
                        <FormControlLabel value="Eastern" control={<Radio />} label="Eastern" />
                        <FormControlLabel value="Decimal" control={<Radio />} label="Decimal" />
                        <FormControlLabel value="Fractional" control={<Radio />} label="Fractional" />
                    </RadioGroup>
                </FormControl>
                <FormControl
                    style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"
                    >
                        Hockey Line                                 
                    </FormLabel>
                    <RadioGroup 
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={CalculateNHLLine}
                        name="radio-buttons-group"                        
                        onChange={handleChangeNHLLine}
                    >
                        <FormControlLabel value="American" control={<Radio />} label="American" />
                        <FormControlLabel value="Canadian" control={<Radio />} label="Canadian" />
                    </RadioGroup>
                </FormControl>
                <FormControl
                    style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"
                    >
                        Baseball Line                                 
                    </FormLabel>
                    <RadioGroup 
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={CalculateMLBLine}
                        name="radio-buttons-group"                        
                        onChange={handleChangeMLBLine}
                    >
                        <FormControlLabel value="Normal" control={<Radio />} label="Normal" />
                        <FormControlLabel value="Wide" control={<Radio />} label="Wide" />
                        <FormControlLabel value="Eastern" control={<Radio />} label="Eastern" />                                        
                    </RadioGroup>
                </FormControl>
                <FormControl
                    style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"
                    >
                        Pitcher Default                                 
                    </FormLabel>
                    <RadioGroup 
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={CalculatePitcherDefault}
                        name="radio-buttons-group"                        
                        onChange={handleChangePitcherDefault}
                    >
                        <FormControlLabel value="Action" control={<Radio />} label="Action" />
                        <FormControlLabel value="Visitor" control={<Radio />} label="Visitor" />
                        <FormControlLabel value="Home" control={<Radio />} label="Home" /> 
                        <FormControlLabel value="Listed" control={<Radio />} label="Listed" /> 
                        <FormControlLabel value="Same" control={<Radio />} label="Same" />                                        
                    </RadioGroup>
                </FormControl>
                <FormControl
                    style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"
                    >
                        Schedule Style                                 
                    </FormLabel>
                    <RadioGroup 
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={CalculateScheduleStyle}
                        name="radio-buttons-group"                        
                        onChange={handleChangeScheduleStyle}
                    >
                        <FormControlLabel value="European" control={<Radio />} label="European" />
                        <FormControlLabel value="American" control={<Radio />} label="American" />                                                                         
                    </RadioGroup>
                </FormControl>
                <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center", placeContent:"space-evenly"}}>
                    <InputSelect                             
                        variant="outlined"
                        name="name"
                        data={props.timeZone}
                        placeholder="Id Time Zone"
                        startValue={props.timeZone.findIndex(x => x.IdTimeZone === idTimeZone)}
                        setSelectValue={changeIdTimeZone}                         
                    />   
                    <InputSelect                             
                        variant="outlined"
                        name="name"
                        data={props.language}
                        placeholder="Id Language"
                        startValue={props.language.findIndex(x => x.IdLanguage === idLanguage)}
                        setSelectValue={changeIdLanguage}                         
                    /> 
                </FormGroup>
                <FormControl
                    style={{border:"1px solid grey",borderRadius:"6px", margin:"5px"}}
                    fullWidth
                    >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"
                    >
                        Secret                                 
                    </FormLabel>
                    <TextField                                
                        variant="outlined"                            
                        label="Question"
                        type="text"
                        value={secQuestion}
                        onChange={handleChangeSecQuestion}                                              
                    />
                    <TextField                                
                        variant="outlined"                            
                        label="Answer"
                        type="text"
                        value={secAnswer}
                        onChange={handleChangeSecAnswer}                                              
                    />
                </FormControl>
            </div>
        )
    }

    function marketing () {
        return(
            <div style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                <FormControl
                    fullWidth
                    style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"   
                    >
                        Personal Info                                 
                    </FormLabel>                                    
                    <TextField                                
                        variant="outlined"                            
                        label="Address"
                        type="text"
                        value={address1}
                        onChange={handleChangeAddress1}                                              
                    />
                    <TextField                                
                        variant="outlined"                            
                        label="Address"
                        type="text"
                        value={address2}
                        onChange={handleChangeAddress2}                                              
                    />
                    <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center", placeContent:"space-evenly"}}>
                        <TextField                                
                            variant="outlined"                            
                            label="City"
                            type="text"
                            value={city}
                            onChange={handleChangeCity}                                              
                        />
                        <TextField                                
                            variant="outlined"                            
                            label="State"
                            type="text"
                            value={state}
                            onChange={handleChangeState}                                              
                        />
                        <TextField                                
                            variant="outlined"                            
                            label="Zip"
                            type="text"
                            value={zip}
                            onChange={handleChangeZip}                                              
                        />
                    </FormGroup>
                    <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center", placeContent:"space-evenly"}}>
                        <TextField                                
                            variant="outlined"                            
                            label="Country"
                            type="text"
                            value={country}
                            onChange={handleChangeCountry}                                              
                        />
                        <TextField                                
                            variant="outlined"                            
                            label="Phone"
                            type="text"
                            value={phone}
                            onChange={handleChangePhone}                                              
                        />
                        <TextField                                
                            variant="outlined"                            
                            label="Fax"
                            type="text"
                            value={fax}
                            onChange={handleChangeFax}                                              
                        />                                        
                    </FormGroup> 
                    <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center", placeContent:"space-between"}}>
                        <TextField                                
                            variant="outlined"                            
                            label="Email"
                            type="Email"
                            value={email}
                            onChange={handleChangeEmail}                                              
                        />
                        <TextField
                            required
                            variant="outlined"
                            label="Date of Birth"
                            type="datetime-local"
                            value={
                                dateOfBirth
                            }
                            onChange={handleChangeDateOfBirth}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormGroup> 
                    <FormGroup style={{display:"flex",flexDirection:"row",justifyContent:"center", placeContent:"space-between"}}>
                        <InputSelect                             
                            variant="outlined"
                            name="name"
                            data={props.source}
                            placeholder="Id Source"
                            startValue={props.source.findIndex(x => x.IdSource === idSource)}
                            setSelectValue={changeIdSource}                         
                        />
                        <TextField                                
                            variant="outlined"                            
                            label="Sign Up IP"
                            type="text"
                            value={signUpIP}
                            onChange={handleChangeSignUpIp}                                              
                        />
                    </FormGroup>                                                        
                </FormControl>
                <FormControl
                    fullWidth
                    style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                >
                    <FormLabel 
                        id="demo-radio-buttons-group-label"   
                    >
                        Free Play - Unavailable                                 
                    </FormLabel>
                    <TextField                                
                        variant="outlined"                            
                        label="Fax"
                        type="text"
                        value={freePlayMessage}
                        onChange={handleChangeFreePlayMessage}                                              
                    />
                </FormControl> 
                <div style={{display: "flex", flexDirection: "row"}}>
                    <FormControl
                        style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                        <FormLabel 
                            id="demo-radio-buttons-group-label"   
                        >
                            Schedule                                
                        </FormLabel>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={scheduleFB}
                                onChange={handleChangeScheduleFB}
                                color={"primary"}
                            />
                            }
                            label="Football"                               
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={scheduleBS}
                                onChange={handleChangeScheduleBS}
                                color={"primary"}
                            />
                            }
                            label="Basetball"                               
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={scheduleBB}
                                onChange={handleChangeScheduleBB}
                                color={"primary"}
                            />
                            }
                            label="Basketball"                               
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={scheduleHK}
                                onChange={handleChangeScheduleHK}
                                color={"primary"}
                            />
                            }
                            label="Hockey"                               
                        />
                    </FormControl>
                    <FormControl
                        style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                        <FormLabel 
                            id="demo-radio-buttons-group-label"   
                        >
                            Bonus Points                                
                        </FormLabel>
                        <TextField
                            required
                            variant="outlined"
                            label="Bonus Start On"
                            type="datetime-local"
                            value={
                                bonusPointsStart
                            }
                            onChange={handleChangeBonusPointsStart}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            required
                            variant="outlined"
                            label="Bonus Expires"
                            type="datetime-local"
                            value={
                                bonusPointsExpire
                            }
                            onChange={handleChangeBonusPointsExpire}
                            InputLabelProps={{ shrink: true }}
                        />
                        <FormControl
                            style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                        >
                            <FormLabel 
                                id="demo-radio-buttons-group-label"   
                            >
                                Status                                
                            </FormLabel>
                            <RadioGroup 
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={CalculateBonusPointStatus}
                                name="radio-buttons-group"                        
                                onChange={handleChangeBonusPoints}
                            >
                                <FormControlLabel value="Enabled" control={<Radio />} label="Enabled" />
                                <FormControlLabel value="Disabled" control={<Radio />} label="Disabled" />
                                <FormControlLabel value="Banned" control={<Radio />} label="Banned" />                                                                         
                            </RadioGroup>
                        </FormControl>
                    </FormControl>
                    <FormControl
                        style={{border:"1px solid grey",borderRadius:"6px", padding:"8px", margin:"5px"}}
                    >
                        <FormLabel 
                            id="demo-radio-buttons-group-label"   
                        >
                            Bet Tracker                                
                        </FormLabel>
                        <TextField                                
                            variant="outlined"                            
                            label="Promo Code"
                            type="text"
                            value={""}
                            onChange={()=>{}}                                              
                        />
                    </FormControl>
                </div>
            </div>
        )
    }

    function navigationButtons () {
        return(
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>                            
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                    setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Wagering" />
                    <BottomNavigationAction label="Setting/Notes"/>
                    <BottomNavigationAction label="Marketing" />
                </BottomNavigation>
            </Box>
        )
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>            
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        Clone Player
                        </Typography>
                    </Toolbar>
                </AppBar>  
                <form className={classes.center}>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"baseline"}}>
                        <FormGroup style={{display:"flex",flexDirection:"column",justifyContent:"center", width:"fit-content"}}>  
                            <TextField                                
                                variant="outlined"                            
                                label="Player"
                                type="text"
                                value={player}
                                onChange={handleChangePlayer}
                                {...isRed&&player===""?(
                                    {error:true}
                                ):("")}                                                
                            /> 
                            <TextField                                
                                variant="outlined"                            
                                label="Password"
                                type="text"
                                value={password}
                                onChange={handleChangePassword}
                                {...isRed&&password===""?(
                                    {error:true}
                                ):("")}                                                
                            />   
                            
                            <TextField                                
                                variant="outlined"                            
                                label="Online Password"
                                type="text"
                                value={onlinePassword}
                                onChange={handleChangeOnlinePassword}
                                {...isRed&&onlinePassword===""?(
                                    {error:true}
                                ):("")}                                               
                            />  
                        </FormGroup>
                        <FormGroup style={{display:"flex",flexDirection:"column",justifyContent:"center", width:"fit-content"}}>
                            <TextField                                
                                variant="outlined"                            
                                label="Title"
                                type="text"
                                value={title}
                                onChange={handleChangeTitle} 
                            />                                                                                                      
                            <TextField                                
                                variant="outlined"                            
                                label="Name"
                                type="text"
                                value={name}
                                onChange={handleChangeName}
                                {...isRed&&name===""?(
                                    {error:true}
                                ):("")}                                                
                            />                         
                            <TextField                                
                                variant="outlined"                            
                                label="Last Name"
                                type="text"
                                value={lastName}
                                onChange={handleChangeLastName}                                               
                            />
                            <TextField                                
                                variant="outlined"                            
                                label="Last Name 2"
                                type="text"
                                value={lastName2}
                                onChange={handleChangeLastName2}                                              
                            />    
                        </FormGroup>
                        <FormGroup style={{margin:"8px",padding:"8px",border:"1px solid lightgrey", alignSelf:"flex-start"}}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={master}
                                    onChange={handleChangeMaster}
                                    color={"primary"}
                                />
                                }
                                label="Master"                               
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={status=='E'? true : false}
                                    onChange={handleChangeStatus}
                                    color={"primary"}
                                />
                                }
                                label="Enabled"                               
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={onlineAccess}
                                    onChange={handleChangeOnlineAccess}
                                    color={"primary"}
                                />
                                }
                                label="Online Access"                               
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={EPOSPlayer}
                                    onChange={handleChangeEPOSPlayer}
                                    color={"primary"}
                                />
                                }
                                label="EPOS Player"                               
                            />
                        </FormGroup>
                    </div>
                    <Divider />
                    <Box sx={{ width: '100%' }}>
                        {navigationButtons()}
                        {value === 0 ? wagering() : ""}
                        {value === 1 ? settingNotes() : ""}
                        {value === 2 ? marketing() : ""}
                        {navigationButtons()}
                    </Box>     
                    <React.Fragment>
                        <DialogActions>                            
                            <div>   
                                <Button
                                    startIcon={<SaveIcon />}
                                    color="inherit"
                                    onClick={handleClose}
                                >
                                Cancel
                                </Button>                             
                                <Button
                                    startIcon={<SaveIcon />}
                                    color="inherit"
                                    onClick={handleOk}
                                >
                                Ok
                                </Button>
                            </div>                                                       
                        </DialogActions>                                            
                    </React.Fragment>                
                </form>                  
            </Dialog>
        </React.Fragment>
    );
}
export default ClonePlayer;

