import React, { useState, useEffect, Component } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import './LineTypes.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import Loader from '../common/loader/Loader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Profiles(props) {
  const [agents, setAgents] = useState();
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  const FillLineTypes = () => {
    //Change for server api address
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/LINETYPE';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setAgents(data.records);
        setIsLoading(false);
        console.log("response",data.records)
      });
  };

  useEffect(() => {
    FillLineTypes();
  }, []);



  function onAfterDeleteRow(rowKeys) { 
    console.log("rowKeys",rowKeys.length) 
    let deletedLineTypes = 0
    if(rowKeys.length>1){
      for(let i = 0 ; i < rowKeys.length ; i++ ){
        axios({
          method: 'post',
          url:
            process.env.REACT_APP_API2_URL+'/LineType/LineType_Delete?idLineType=' +
            rowKeys[i] +
            '&idUser=' +
            userInfo.UserId,      
        }).then((response) => {
          console.log('response', response);
          deletedLineTypes++
        });
      } 
      if(deletedLineTypes == rowKeys.length) 
        alert('The rowkey you drop: ' + rowKeys)   
    }else{
      axios({
        method: 'post',
        url:
          process.env.REACT_APP_API2_URL+'/LineType/LineType_Delete?idLineType=' +
          rowKeys +
          '&idUser=' +
          userInfo.UserId,      
      }).then((response) => {
        console.log('response', response);
        alert('The rowkey you drop: ' + rowKeys);
      });  
    }        
  }

  function customConfirm(next, row) { 
    let dropRowKeysStr =''
    for(let i = 0 ; i < row.length ; i++){
      dropRowKeysStr = dropRowKeysStr + "\n" + agents.filter(lineType => lineType.IdLineType === row[i])[0].Description
    } 
    console.log("row",row)
    if (
      window.confirm(
        `Are you sure you want to delete the below Line Type(s): \n${dropRowKeysStr}`
      )
    ) {
      next();
    }
  }

  function selectedValues(row) {
    console.log(`You click row id Profiles: ${row.IdLineType}`);

    setRedirect(row);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  function onAfterInsertRow(row) { 
    try{
      axios({
        method: 'post',        
        url:
        process.env.REACT_APP_API2_URL+'/LineType/LineType_Insert?name=' +
        row.Description +
        '&idUser=' +
        userInfo.UserId,
      }).then((response) => {
        alert("The Line Type has been successfully created")      
        console.log('response', response);
        FillLineTypes();        
      });
    }catch(error){
      console.error("error.response.data",error.response.data)
    }
  }
  const cellEditProp = {
    mode: 'click', //DBClick daba problemas con el Expand se cambio a click
    beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };
  const selectRowProp = {
    mode: 'checkbox',

    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  }; 
  function onRowSelect(row, isSelected, e) {
    let rowStr = '';
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  function onAfterSaveCell(row, cellName, cellValue) {
    let AgentRequestDTO = {
      IdAgent: row.IdAgent,
      Enable: row.Enable,
      DontXferPlayerActivity: row.DontXferPlayerActivity,
      IsDistributor: row.IsDistributor,
      Distributor: row.Distributor,
      CommSports: row.CommSports,
      CommCasino: row.CommCasino,
      CommHorses: row.CommHorses,
      IdAgentType: row.IdAgentType,
      IdCurrency: row.IdCurrency,
      IdBook: row.IdBook,
      IdGrouping: row.IdGrouping,
      Agent1: row.Agent,
      Name: row.Name,
      Password: row.Password,
      City: row.City,
      State: row.State,
      Country: row.Country,
      Address1: row.Address1,
      Address2: row.Address2,
      Email: row.Email,
      Phone: row.Phone,
      Fax: row.Fax,
      Zip: row.Zip,
      OnlineAccess: row.OnlineAccess,
      OnlineMessage: row.OnlineMessage,
      IdLineType: row.IdLineType,
      IdAgentPerHead: row.IdAgentPerHead,
      IdLanguage: row.IdLanguage,
      LastModificationUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: 'http://192.168.3.22:4587/api/Profile/PlayerProfile_Update',
      data: AgentRequestDTO,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
  }

  function onBeforeSaveCell(row, cellName, cellValue) {
    // You can do any validation on here for editing value,
    // return false for reject the editing
    return true;
  }

  const toggleTable = () => {
    if (showInfo) {
      setshowInfo(false);
    } else {
      setshowInfo(true);
    }
  };

  if (redirect) {
    let pushURL = '/lineType/' + redirect.IdLineType;

    return (
      <React.Fragment>
        {/* //this.props.location.state. asi se agarran los datos en el otro componente */}

        {console.log('Profile Info props', redirect)}
        <div>
          {history.push(pushURL)}         
        </div>
      </React.Fragment>
      /*<Redirect
        to={{
          pathname: '/entityContainer',
          state: { row: redirect },
        }}
      />

      */
    );
  }
  return (
    <div id="main-div">
      <Paper className={classes.root}>
        <br />
        <br /> <h2>Line Types</h2>
        {isLoading ? <Loader></Loader> : ''}
        <Table className={classes.table} aria-label="simple table">
          <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
            {agents && (
              <div>
                <BootstrapTable
                  pagination={true}
                  options={options}
                  cellEdit={cellEditProp} //DBClick daba problemas con el Expand
                  selectRow={selectRowProp} //Da problemas con el Expand
                  insertRow={true}
                  /*expandColumnOptions={{
                  expandColumnVisible: true,
                  expandColumnComponent: expandColumnComponent,
                  columnWidth: 50,
                }}*/
                  version="4"
                  data={agents}
                  deleteRow={true}
                  hover                  
                >
                  <TableHeaderColumn
                    dataField="IdLineType"
                    hiddenOnInsert
                    autoValue={true}
                    isKey={true}
                  >
                    IdLineType
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataField="Description"
                  >
                    Description
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default Profiles;
