import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { makeStyles, useStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Form from "@rjsf/core";
import   "./EditableForm.css";
export default function Schedule(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
          width: 10,
          height: 10,
        },
        button: {
          display: 'block',
          marginTop: theme.spacing(2),
        },
        formControl: {
          margin: theme.spacing(1),
          minWidth: 120,
        },
      }));
      
    const classes = useStyles();

    const [properties, setProperties] = useState();
    useEffect(() => {
       // setProperties(props.record);

        //console.log("Props")   ;
        //console.log('Props',props);  
   
        let tempRecordsArr = [];

        for (let key in props.record) {

          let keyValue = props.record[key];
            let tempRecordKey 
          //  //console.log('tempObj key ',key);
            //console.log('tempObj keyValue ',keyValue);
            let typeofVal;
            typeofVal= typeof keyValue; 
            //console.log('tempObj typeofVal ',typeofVal);
            if(typeofVal !== 'boolean'){
              tempRecordKey = { type: "string", title: key, default: keyValue  };
              //console.log('if');

            }else{
              //console.log('else');
              tempRecordKey = { type: "boolean", title: key, default: keyValue,
              
            };
            }
           
            tempRecordsArr.push(tempRecordKey);
            //console.log('tempObj key ',key);
           // tempObj = Object.assign({}, tempkey);
          }

        //console.log('Key tempRow',tempRecordsArr);
        const tempRecordSchema = {
            title: "Todo",
            type: "object",
            required: [],
            properties: tempRecordsArr
        }
   
          setProperties(tempRecordSchema);

    }, []);

    const submitForm=()=>{

      //console.log('submited ');
    }
    return (
        <React.Fragment>
            <h1>Editing : {props.title}</h1>
      {properties && 
      <Form schema={properties}
      onChange={console.log("changed")}
      onSubmit={submitForm}
      onError={console.log("errors")} />
      }   

        </React.Fragment>
    )

}
