import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import './AgentSettings.css';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function AgentPersonalInfo(props) {
  const classes = useStyles();

  
  return (
    <div className={classes.root}>
      <TextField
        type="number"
        label="Family Game"
        //value={familyGame}
        multiline={true}
        //onChange={handleChangeFamilyGame}
        variant="outlined"
      />
    </div>
  );
}
