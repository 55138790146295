import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputSelect from '../../common/InputSelect';
import LineTypeList from '../../lineTypes/lineTypesComponents/LineTypeList';
import AgentList from '../../agents/agentComponents/AgentList';

import OfficeList from '../../offices/officeComponents/OfficeList';
import BookList from '../../books/bookComponents/BookList';
import WagerLimitsList from '../../wagerLimits/wagerLimitsComponents/WagerLimitsList';
import ProfileList from '../../profiles/profileComponents/ProfileList';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import ReactLoading from 'react-loading';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './PlayerOpenBets.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const linetypeFormatter = (cell, row) => {
  return <LineTypeList isInput={true} initialValue={cell}></LineTypeList>;
};
const agentFormatter = (cell, row) => {
  return <AgentList isInput={true} initialValue={cell}></AgentList>;
};
const officeFormatter = (cell, row) => {
  return <OfficeList isInput={true} initialValue={cell}></OfficeList>;
};
const bookFormatter = (cell, row) => {
  return <BookList isInput={true} initialValue={cell}></BookList>;
};
const profileLimitFormatter = (cell, row) => {
  return <WagerLimitsList isInput={true} initialValue={cell}></WagerLimitsList>;
};
const profileFormatter = (cell, row) => {
  return <ProfileList isInput={true} initialValue={cell}></ProfileList>;
};
const numberFormatter = (cell, row) => {
  return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(
    cell
  );
};
const linetypeFormatter2 = (cell, row) => {
  let isSelected = '';
  let isSelected0 = '';
  let isSelected1 = '';
  let isSelected2 = '';
  let isSelected3 = '';
  let isSelected4 = '';
  switch (cell) {
    case 0:
      isSelected0 = 'checked';
      break;
    case 1:
      isSelected1 = 'checked';
      break;
    case 2:
      isSelected2 = 'checked';
      break;
    case 3:
      isSelected3 = 'checked';
      break;
    case 4:
      isSelected4 = 'checked';
      break;
    default:
      isSelected = 'checked';
  }
  return `
    <input type="radio" id="isSelected0" name="age" value="0" ${isSelected0}>
    <label for="isSelected0">Keep Price</label><br>
    <input type="radio" id="isSelected1" name="age" value="1" ${isSelected1}>
    <label for="isSelected1">Credit use line, Cash Adjust win amount</label><br>
    <input type="radio" id="isSelected2" name="age" value="2" ${isSelected2}>
    <label for="isSelected2">Adjust Risk Amount</label><br>
    <input type="radio" id="isSelected3" name="age" value="3" ${isSelected3}>
    <label for="isSelected3">Adjust Win Amount</label><br>
    <input type="radio" id="isSelected4" name="age" value="4" ${isSelected4}>
    <label for="isSelected4">UseLine</label><br>`;
};
export default function OpenBets(props) {
  const classes = useStyles();
  const [openBets, setOpenBets] = React.useState([]);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  let idPlayer = props.Player.IdPlayer;

  useEffect(() => {
    let IdPlayer = props.Player.IdPlayer;
    const url =
      process.env.REACT_APP_API2_URL +
      '/Player/GetPlayerOpenBets?idPlayer=' +
      IdPlayer;
    axios({
      method: 'post',
      url: url,
      // data: scheduleRequest,
    }).then((response) => {
      setOpenBets(response.data);
      setIsLoading(false);
      let lastIdWager = 0;
      let openBetList = [];
      let openBet = {};
      response.data.map(function (keyMain) {
        if (keyMain['IdWager'] != lastIdWager) {
          keyMain.Leg = [];
          openBetList.push(keyMain);
          lastIdWager = keyMain['IdWager'];
        } else {
          let tempGame = {};
          tempGame = openBetList[openBetList.length - 1];
          lastIdWager = keyMain['IdWager'];
          tempGame.Leg.push(keyMain);
          delete openBetList[openBetList.length - 1];
          openBetList.push(tempGame);
        }
      });

      setOpenBets(openBetList);
    });
  }, []);

  const onAfterDeleteRow = async (rowKeys) => {
    let bet = {
      idWager: rowKeys[0],
      idUser: userInfo.UserId
    }
    const url = process.env.REACT_APP_DEF_PHP_URL + '/PrepareDeleteBet.php'
    axios({
      method: 'post',
      url: url,
      data: bet,
    }).then((response) => {
        console.log("bet delete response",response)        
    });
  };

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
    },
    afterDeleteRow: onAfterDeleteRow,
  };
  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: true, 
    clickToExpand: true, 
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/
  const isExpandableRow = (row) => {
    return true;
  };

  class BSTable extends React.Component {
    render() {
      if (this.props.data) {
        return (
          <Table className="table-open-bets">
            <TableRow>
              <TableCell>
                <p>
                  <b> {this.props.data.HeaderDescription}</b>
                  <br />
                  {this.props.data.IdWager}
                  <br />
                  <small>
                    <b>Event Date: {this.props.data.GameDateTime} </b>
                  </small>
                </p>
              </TableCell>
              <TableCell>
                <p>
                  <b> {this.props.data.LoginName} </b>
                  <br />
                  {this.props.data.IP}
                  <br />
                  <small>
                    <b>Placed Date: {this.props.data.PlacedDate} </b>
                  </small>
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan="2">
                <p>
                  <b>Ticket Description</b>
                </p>
                <li key={this.props.data.IdWager}>
                  {this.props.data.DetailDescription}{' '}
                </li>

                {this.props.data.Leg &&
                  this.props.data.Leg.map(function (item, i) {
                    return <li key={i}>{item.DetailDescription} </li>;
                  })}
                <br />

                <hr></hr>
                <p style={{ textAlign: 'center' }}>
                  <b>Risk/Win : </b>
                  {this.props.data.RiskAmount}/ {this.props.data.WinAmount}{' '}
                </p>
                <hr></hr>
              </TableCell>
            </TableRow>
          </Table>
        );
      } else {
        return <p>?</p>;
      }
    }
  }

  const expandComponent = (row) => {
    return <BSTable data={row} />;
  };
  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <Table>
          <TableRow>
            <TableCell colSpan="2">
              {' '}
              {/**/}
              <BootstrapTable
                data={openBets.filter(element => {
                  if (Object.keys(element).length !== 0) {
                    return true;
                  }
                
                  return false;
                })}
                //   cellEdit={cellEditProp}
                expandableRow={isExpandableRow}
                expandComponent={expandComponent}
                selectRow={selectRowProp}
                deleteRow={true}
                options={options}
              >
                <TableHeaderColumn isKey={true} hidden dataField="TicketNumber">
                  TicketNumber
                </TableHeaderColumn>
                <TableHeaderColumn width="200" dataField="HeaderDescription">
                  Type
                </TableHeaderColumn>
                <TableHeaderColumn dataField="DetailDescription">
                  Description
                </TableHeaderColumn>
                <TableHeaderColumn width="80" dataField="RiskAmount">
                  RiskAmount
                </TableHeaderColumn>
                <TableHeaderColumn width="80" dataField="WinAmount">
                  Win
                </TableHeaderColumn>
              </BootstrapTable>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}
