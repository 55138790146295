import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';

export default function ProfileInfo(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );


  return (
    <React.Fragment>
       {console.log('in INFO props',props ) }
       {console.log('in INFO props match',props.Profile  ) }
       
      <br />
      <form className={classes.center}>
        <FormGroup row>
  <h5>{props.Profile.ProfileName}</h5>
        </FormGroup>
       {/* <FormGroup row>
          <TextField
            type="text"
            label="ProfileName"
            value={props.Profile.ProfileName}
            multiline={false}
            //onChange={handleChangeFamilyGame}
            variant="outlined"
            className="select-input-general"
          />
          <TextField
            type="text"
            label="AlternateProfile"
            value={props.Profile.AlternateProfile}
            multiline={false}
            //onChange={handleChangeFamilyGame}
            variant="outlined"
            className="select-input-general"
          />

<TextField
            type="text"
            label="Notes"
            value={props.Profile.Notes}
            multiline={true}
            // onChange={handleChangeFamilyGame}
            variant="outlined"
            className="select-input-general"
          />
       
       <Button
            
            className="select-input-general "
            variant="contained"
            //onClick={handleFindParent}
            //startIcon={<SearchIcon />}
          >
            Save
          </Button>
        </FormGroup>
     
      */}
        
      </form>
    </React.Fragment>
  );
}
