import axios from 'axios';
import { useEffect } from 'react';

 export const updateML = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    valueHasChanged,
    setRecentUpdatedGame,
    setValueHasChanged,
    props
  ) => {
    updateMLxp(
      idGame,
      odds,
      idLinetype,
      newValue, //linea
      idSport,
      teamNumber,
      play,
      column,
      changeLine,
      changeOdds,
      otherTeam,
      IdGameType,
      otherColumn,
      otherPlay,
      valueHasChanged,
      setRecentUpdatedGame,
      setValueHasChanged,
      props
    );
  };
  export const updateML22 = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    valueHasChanged,
    setRecentUpdatedGame,
    setValueHasChanged,
    props

  ) => {
    if (valueHasChanged) {
      let line = idSport != 'MLB' ? 3 : 1;
      let flag = true;
      let z = newValue < 0 ? newValue * -1 : newValue;
      setRecentUpdatedGame(idGame);
      props.setLastChangedGamedID(idGame);
      const url =
        process.env.REACT_APP_API_URL +
        '/ODDSBYSPORT?filter=IdLineType,eq,' +
        idLinetype +
        '&filter=IdSport,eq,' +
        idSport +
        '&filter=IdGameType,eq,' +
        IdGameType +
        '&filter=Line,eq,' +
        line +
        '&filter=Range,ge,' +
        Math.round(z);
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          //setoddBySport(data.records.odds);
          let moneyLineObject = {
            idGame: idGame,
            odds: newValue,
            idLinetype: idLinetype,
            idSport: idSport,
            newValue: newValue,
            play: play,
            teamNumber: teamNumber,
            column: column,
            changeLine: changeLine,
            changeOdds: changeOdds,
          };
          let storedOdd = -moneyLineObject.odds;

          if (newValue != '') {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(moneyLineObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare < item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }
            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
          } else {
            storedOdd = null;
            newValue = null;
            odds = null;
            moneyLineObject.odds = null;
            moneyLineObject.newValue = null;
          }

          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
            data: moneyLineObject,
          }).then((response) => {
            axios({
              method: 'post',
              url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
              data: {
                idGame: idGame,
                odds: storedOdd,
                idLinetype: idLinetype,
                idSport: idSport,
                newValue: newValue,
                play: otherPlay,
                teamNumber: otherTeam,
                column: otherColumn,
                changeLine: changeLine,
                changeOdds: changeOdds,
              },
            }).then((response) => {
              //props.announce(idGame, idLinetype);
              const url =
                process.env.REACT_APP_API_URL +
                '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
                idLinetype +
                '&filter=IdSport,eq,' +
                idSport +
                '&filter=MoneyLineSync,eq,' +
                1;
              axios
                .get(url)
                .then((response) => response.data)
                .then((data) => {
                  if (data.records !== null && data.records.length > 0) {
                    data.records.forEach(async (x) => {
                      let idLT = x.IdLineTypeOut;
                      await axios({
                        method: 'post',
                        url:
                          process.env.REACT_APP_DEF_PHP_URL +
                          '/updateMoneyline.php',
                        data: {
                          idGame: idGame,
                          odds: newValue,
                          idLinetype: idLT,
                          idSport: idSport,
                          newValue: newValue,
                          play: play,
                          teamNumber: teamNumber,
                          column: column,
                          changeLine: changeLine,
                          changeOdds: changeOdds,
                        },
                      }).then((response) => {
                        axios({
                          method: 'post',
                          url:
                            process.env.REACT_APP_DEF_PHP_URL +
                            '/updateMoneyline.php',
                          data: {
                            idGame: idGame,
                            odds: storedOdd,
                            idLinetype: idLT,
                            idSport: idSport,
                            newValue: newValue,
                            play: otherPlay,
                            teamNumber: otherTeam,
                            column: otherColumn,
                            changeLine: changeLine,
                            changeOdds: changeOdds,
                          },
                        }).then((response) => {});
                      });
                    });
                  }
                });

              props.searchGameValues();
            });
          });
        });
    }
    setValueHasChanged(true);
  };

  export const updateDraw = (idGame, odds, idLinetype,valueHasChanged,setValueHasChanged) => {
    if (valueHasChanged) {
      //setoddBySport(data.records.odds);
      let drawObject = {
        idGame: idGame,
        odds: odds,
        idLinetype: idLinetype,
      };
      axios({
        method: 'post',
        url: process.env.REACT_APP_DEF_PHP_URL + '/updateDraw.php',
        data: drawObject,
      }).then((response) => {});
    }
    setValueHasChanged(true);
  };

  export const updatePROPTNT = (idGame, idLinetype, odds, teamNumber, teamName, result, graded, valueHasChanged, setValueHasChanged,idSport,columnNumber,play,lineTypes) => {
    console.log('lineTypes',lineTypes)
    console.log('lineType',idLinetype)
    if(idSport.trim() === 'TNT'){
      let gameTNT = {
        idGame: idGame,
        teamNumber: teamNumber,
        teamName: teamName,
        result: result,
        graded: graded,
      };
      axios({
        method: 'post',
        url: process.env.REACT_APP_DEF_PHP_URL + '/GameTNTSetValues.php',
        data: gameTNT,
      }).then((response) => {
        if(idLinetype === 55){
          for(let i = 0 ; i < lineTypes.length ; i++){
            
              let drawObject = {
                idGame: idGame,
                odds: odds,
                teamNumber: teamNumber,
                idLinetype: lineTypes[i].IdLineType,
              };
              axios({
                method: 'post',
                url: process.env.REACT_APP_DEF_PHP_URL + '/changeTNTPROPValues.php',
                data: drawObject,
              }).then((response) => {
                let ChangedLinesObj = {
                  idLinetype: lineTypes[i].IdLineType,
                  idSport: idSport,
                  idUser: 498,
                  idGame: idGame,
                  teamNumber: teamNumber,
                  columnNumber: columnNumber,
                  changeValue: odds,
                  phoneLine: '1234',
                  play: 4,
                };
                axios({
                  method: 'post',
                  url: process.env.REACT_APP_DEF_PHP_URL + '/SystemChangedLinesInsert.php',
                  data: ChangedLinesObj,
                }).then((response) => {
                  let ChangedLinesSetValues  = {
                    idLinetype: lineTypes[i].IdLineType,
                    idGame: idGame,
                    teamNumber: teamNumber,              
                    play: 4,
                  };
                  axios({
                    method: 'post',
                    url: process.env.REACT_APP_DEF_PHP_URL + '/ClientChangedLinesSetValue.php',
                    data: ChangedLinesSetValues,
                  }).then((response) => {
                    
                  });
                }); 
              }); 
            
          }
        }else{
            let drawObject = {
              idGame: idGame,
              odds: odds,
              teamNumber: teamNumber,
              idLinetype: idLinetype,
            };
            axios({
              method: 'post',
              url: process.env.REACT_APP_DEF_PHP_URL + '/changeTNTPROPValues.php',
              data: drawObject,
            }).then((response) => {
              let ChangedLinesObj = {
                idLinetype: idLinetype,
                idSport: idSport,
                idUser: 498,
                idGame: idGame,
                teamNumber: teamNumber,
                columnNumber: columnNumber,
                changeValue: odds,
                phoneLine: '1234',
                play: 4,
              };
              axios({
                method: 'post',
                url: process.env.REACT_APP_DEF_PHP_URL + '/SystemChangedLinesInsert.php',
                data: ChangedLinesObj,
              }).then((response) => {
                let ChangedLinesSetValues  = {
                  idLinetype: idLinetype,
                  idGame: idGame,
                  teamNumber: teamNumber,              
                  play: 4,
                };
                axios({
                  method: 'post',
                  url: process.env.REACT_APP_DEF_PHP_URL + '/ClientChangedLinesSetValue.php',
                  data: ChangedLinesSetValues,
                }).then((response) => {
                  
                });
              }); 
            });
        }
      })
      
    }else{
      let gameTNT = {
        idGame: idGame,
        teamNumber: teamNumber,
        teamName: teamName,
        result: result,
        graded: graded,
      };
      axios({
        method: 'post',
        url: process.env.REACT_APP_DEF_PHP_URL + '/GameTNTSetValues.php',
        data: gameTNT,
      }).then((response) => {
        let drawObject = {
          idGame: idGame,
          odds: odds,
          teamNumber: teamNumber,
          idLinetype: idLinetype,
        };
        axios({
          method: 'post',
          url: process.env.REACT_APP_DEF_PHP_URL + '/changeTNTPROPValues.php',
          data: drawObject,
        }).then((response) => {}); 
      });
    }
    
       
       
  };

  
  export const GameTNT_SetValues = (idGame, teamNumber, teamName, result, graded) => { 
    let drawObject = {
      idGame: idGame,
      teamNumber: teamNumber,
      teamName: teamName,
      result: result,
      graded: graded,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_DEF_PHP_URL + '/GameTNTSetValues.php',
      data: drawObject,
    }).then((response) => {});        
  };

  export const updateSP = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    isOdd, // 1  is just the spread, 2 is spread odd
    valueHasChanged,
    setValueHasChanged,
    setRecentUpdatedGame,
    props

  ) => {
    let queryString = '';
    if (isOdd == 1) {
      queryString = '&filter=SpreadSync,eq,1';
    } else {
      queryString = '&filter=SpreadOddsSync,eq,1';
    }

    if (valueHasChanged) {
      let flag = true;
      let line = idSport != 'MLB' ? 1 : 3;
      let z = newValue < 0 ? newValue * -1 : newValue;
      setRecentUpdatedGame(idGame);
      props.setLastChangedGamedID(idGame);
      const url =
        process.env.REACT_APP_API_URL +
        '/ODDSBYSPORT?filter=IdLineType,eq,' +
        idLinetype +
        '&filter=IdSport,eq,' +
        idSport +
        '&filter=IdGameType,eq,' +
        IdGameType +
        '&filter=Line,eq,' +
        line +
        '&filter=Range,ge,' +
        Math.round(z);
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          //setoddBySport(data.records.odds);
          let spreadObject = {
            idGame: idGame,
            odds: odds,
            idLinetype: idLinetype,
            idSport: idSport,
            newValue: parseFloat(newValue),
            play: play,
            teamNumber: teamNumber,
            column: column,
            changeLine: changeLine,
            changeOdds: changeOdds,
          };
          let storedOdd = -spreadObject.odds;
          let storedLine = -spreadObject.newValue;
          if (newValue != '' && odds != '') {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(spreadObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare < item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }

            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
          } else {
            storedOdd = null;
            newValue = null;
            odds = null;
            spreadObject.odds = null;
            spreadObject.newValue = null;
          }

          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateSpread.php',
            data: spreadObject,
          }).then((response) => {
            axios({
              method: 'post',
              url: process.env.REACT_APP_DEF_PHP_URL + '/updateSpread.php',
              data: {
                idGame: idGame,
                odds: storedOdd,
                idLinetype: idLinetype,
                idSport: idSport,
                newValue: storedLine,
                play: otherPlay,
                teamNumber: otherTeam,
                column: otherColumn,
                changeLine: changeLine,
                changeOdds: changeOdds,
              },
            }).then((response) => {
              const url =
                process.env.REACT_APP_API_URL +
                '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
                idLinetype +
                '&filter=IdSport,eq,' +
                idSport +
                queryString;
              axios
                .get(url)
                .then((response) => response.data)
                .then((data) => {
                  if (data.records !== null && data.records.length > 0) {
                    data.records.forEach(async (x) => {
                      let idLT = x.IdLineTypeOut;

                      await axios({
                        method: 'post',
                        url:
                          process.env.REACT_APP_DEF_PHP_URL +
                          '/updateSpread.php',
                        data: {
                          idGame: idGame,
                          odds: odds,
                          idLinetype: idLT,
                          idSport: idSport,
                          newValue: parseFloat(newValue),
                          play: play,
                          teamNumber: teamNumber,
                          column: column,
                          changeLine: changeLine,
                          changeOdds: changeOdds,
                        },
                      }).then((response) => {
                        axios({
                          method: 'post',
                          url:
                            process.env.REACT_APP_DEF_PHP_URL +
                            '/updateSpread.php',
                          data: {
                            idGame: idGame,
                            odds: storedOdd,
                            idLinetype: idLT,
                            idSport: idSport,
                            newValue: storedLine,
                            play: otherPlay,
                            teamNumber: otherTeam,
                            column: otherColumn,
                            changeLine: changeLine,
                            changeOdds: changeOdds,
                          },
                        }).then((response) => {});
                      });
                    });
                  }
                });

              props.searchGameValues();
            });
          });
        });
    }
    setValueHasChanged(true);
  };

  export const updateTO = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    isOdd,
    valueHasChanged,
    setValueHasChanged,
    setRecentUpdatedGame,
    props
  ) => {
    let queryString = '';
    if ((isOdd = 1)) {
      queryString = '&filter=TotalSync,eq,1';
    } else {
    }
    if (valueHasChanged) {
      let flag = true;
      let z = newValue < 0 ? newValue * -1 : newValue;
      setRecentUpdatedGame(idGame);
      props.setLastChangedGamedID(idGame);
      const url =
        process.env.REACT_APP_API_URL +
        '/ODDSBYSPORT?filter=IdLineType,eq,' +
        idLinetype +
        '&filter=IdSport,eq,' +
        idSport +
        '&filter=IdGameType,eq,' +
        IdGameType +
        '&filter=Line,eq,' +
        2 +
        '&filter=Range,ge,' +
        Math.round(z);
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          // setoddBySport(data.records.odds);

          let totalObject = {
            idGame: idGame,
            odds: odds,
            idLinetype: idLinetype,
            idSport: idSport,
            newValue: newValue,
            play: play,
            teamNumber: teamNumber,
            column: column,
            changeLine: changeLine,
            changeOdds: changeOdds,
          };
          //if (totalObject.flag == true) {
          let storedOdd = -totalObject.odds;
          if (newValue != '' && odds != '') {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(totalObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare <= item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }

            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
          } else {
            storedOdd = null;
            newValue = null;
            odds = null;
            totalObject.odds = null;
            totalObject.newValue = null;
          }

          //}
          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateTotal.php',
            data: totalObject,
          }).then((response) => {
            axios({
              method: 'post',
              url: process.env.REACT_APP_DEF_PHP_URL + '/updateTotal.php',
              data: {
                idGame: idGame,
                odds: storedOdd,
                idLinetype: idLinetype,
                idSport: idSport,
                newValue: newValue,
                play: otherPlay,
                teamNumber: otherTeam,
                column: otherColumn,
                changeLine: changeLine,
                changeOdds: changeOdds,
              },
            }).then((response) => {
              const url =
                process.env.REACT_APP_API_URL +
                '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
                idLinetype +
                '&filter=IdSport,eq,' +
                idSport +
                queryString;
              axios
                .get(url)
                .then((response) => response.data)
                .then((data) => {
                  if (data.records !== null && data.records.length > 0) {
                    data.records.forEach(async (x) => {
                      let idLT = x.IdLineTypeOut;

                      await axios({
                        method: 'post',
                        url:
                          process.env.REACT_APP_DEF_PHP_URL +
                          '/updateTotal.php',
                        data: {
                          idGame: idGame,
                          odds: odds,
                          idLinetype: idLT,
                          idSport: idSport,
                          newValue: newValue,
                          play: play,
                          teamNumber: teamNumber,
                          column: column,
                          changeLine: changeLine,
                          changeOdds: changeOdds,
                        },
                      }).then((response) => {
                        axios({
                          method: 'post',
                          url:
                            process.env.REACT_APP_DEF_PHP_URL +
                            '/updateTotal.php',
                          data: {
                            idGame: idGame,
                            odds: storedOdd,
                            idLinetype: idLT,
                            idSport: idSport,
                            newValue: newValue,
                            play: otherPlay,
                            teamNumber: otherTeam,
                            column: otherColumn,
                            changeLine: changeLine,
                            changeOdds: changeOdds,
                          },
                        }).then((response) => {});
                      });
                    });
                  }
                });

              props.searchGameValues();
            });
          });
        });
    }
    setValueHasChanged(true);
  };
  
  export const updateMLxp = (
    idGame,
    odds,
    idLinetype,
    newValue, //linea
    idSport,
    teamNumber,
    play,
    column,
    changeLine,
    changeOdds,
    otherTeam,
    IdGameType,
    otherColumn,
    otherPlay,
    valueHasChanged,
    setRecentUpdatedGame,
    setValueHasChanged,
    props,
  ) => {   
    if (valueHasChanged) {
      let line = idSport != 'MLB' ? 3 : 1;
      let flag = true;
      let z = newValue < 0 ? newValue * -1 : newValue;
      setRecentUpdatedGame(idGame);
      props.setLastChangedGamedID(idGame);
      const url =
        process.env.REACT_APP_API_URL +
        '/ODDSBYSPORT?filter=IdLineType,eq,' +
        idLinetype +
        '&filter=IdSport,eq,' +
        idSport +
        '&filter=IdGameType,eq,' +
        IdGameType +
        '&filter=Line,eq,' +
        line +
        '&filter=Range,ge,' +
        Math.round(z);
      console.log("url",url)
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          //setoddBySport(data.records.odds);
          console.log("data", data)
          let moneyLineObject = {
            idGame: idGame,
            odds: newValue,
            idLinetype: idLinetype,
            idSport: idSport,
            newValue: newValue,
            play: play,
            teamNumber: teamNumber,
            column: column,
            changeLine: changeLine,
            changeOdds: changeOdds,
          };
          let storedOdd = -moneyLineObject.odds;

          if (newValue != '') {
            let oddToLower = 0;
            let found = false;
            data.records.forEach((item, index) => {
              let valCompare = parseInt(moneyLineObject.odds);
              valCompare = valCompare < 0 ? -valCompare : valCompare;
              if (valCompare < item.Range && !found) {
                if (index == 0) {
                  oddToLower = data.records[index].OddsValue;
                } else {
                  oddToLower = data.records[index - 1].OddsValue;
                }
                found = true;
              }
            });
            oddToLower = oddToLower === 0 ? 20 : oddToLower;
            if (
              storedOdd === 100 ||
              storedOdd === -100 ||
              oddToLower / 2 + 100 === storedOdd
            ) {
              oddToLower = 0;
            } else if (storedOdd > 0 && storedOdd - oddToLower < 100) {
              oddToLower = 100 + (100 + oddToLower - storedOdd) - storedOdd;
              flag = false;
            }
            if (storedOdd === 100 || storedOdd === -100) {
              storedOdd = null;
            } else if (oddToLower === 0 || !flag) {
              storedOdd = storedOdd + oddToLower;
              storedOdd = storedOdd * -1;
            } else {
              storedOdd += -oddToLower;
            }
          } else {
            storedOdd = null;
            newValue = null;
            odds = null;
            moneyLineObject.odds = null;
            moneyLineObject.newValue = null;
          }

          axios({
            method: 'post',
            url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
            data: moneyLineObject,
          }).then((response) => {
            axios({
              method: 'post',
              url: process.env.REACT_APP_DEF_PHP_URL + '/updateMoneyline.php',
              data: {
                idGame: idGame,
                odds: storedOdd,
                idLinetype: idLinetype,
                idSport: idSport,
                newValue: newValue,
                play: otherPlay,
                teamNumber: otherTeam,
                column: otherColumn,
                changeLine: changeLine,
                changeOdds: changeOdds,
              },
            }).then((response) => {
              //props.announce(idGame, idLinetype);
              const url =
                process.env.REACT_APP_API_URL +
                '/LINETYPELINKS?filter=IdLineTypeIn,eq,' +
                idLinetype +
                '&filter=IdSport,eq,' +
                idSport +
                '&filter=MoneyLineSync,eq,' +
                1;
              axios
                .get(url)
                .then((response) => response.data)
                .then((data) => {
                  if (data.records !== null && data.records.length > 0) {
                    data.records.forEach(async (x) => {
                      let idLT = x.IdLineTypeOut;
                      await axios({
                        method: 'post',
                        url:
                          process.env.REACT_APP_DEF_PHP_URL +
                          '/updateMoneyline.php',
                        data: {
                          idGame: idGame,
                          odds: newValue,
                          idLinetype: idLT,
                          idSport: idSport,
                          newValue: newValue,
                          play: play,
                          teamNumber: teamNumber,
                          column: column,
                          changeLine: changeLine,
                          changeOdds: changeOdds,
                        },
                      }).then((response) => {
                        axios({
                          method: 'post',
                          url:
                            process.env.REACT_APP_DEF_PHP_URL +
                            '/updateMoneyline.php',
                          data: {
                            idGame: idGame,
                            odds: storedOdd,
                            idLinetype: idLT,
                            idSport: idSport,
                            newValue: newValue,
                            play: otherPlay,
                            teamNumber: otherTeam,
                            column: otherColumn,
                            changeLine: changeLine,
                            changeOdds: changeOdds,
                          },
                        }).then((response) => {});
                      });
                    });
                  }
                });

                props.searchGameValues();
            });
          });
        });
    }
    setValueHasChanged(true);
  };
