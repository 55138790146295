import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import Select from '@material-ui/core/Select';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ColorPicker from './ColorPicker';

function GameTypes(props) {
  //const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [selectedGametype, setSelectedGametype] = React.useState();
  const [gameGameTypes, setGameGameTypes] = React.useState([]);
  const [selectedGameTypes, setSelectedGameTypes] = React.useState(1);

  const handleIdGameTypeChange = (event, newValue) => { 
    props.setGameTypeOnMem(event.target.value);

    setSelectedGameTypes(newValue.props.value);
  };
  useEffect(() => {
    GetGameTypes();
  }, []);

  const GetGameTypes = () => {
    // Change for server api address 
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/GAMETYPE',
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => { 
      setGameGameTypes(response.data.records);
    });
  };

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 22, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 1, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: true, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
    //onRowClick: selectedValues,
  };

  function selectedValues(row) { 
    props.setGameTypeOnMem(row.IdGameType);
    // setRedirect(row);
  }
  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCell, // a hook for after saving cell
  };

  function onAfterInsertRow(row) {
    let RequestDTO = {
      Short: row.Short,
      Description: row.Description,
      AllowHookUps: row.AllowHookUps,
      AllowBuyPoints: row.AllowBuyPoints,
      FkColor: row.FkColor,
      BkColor: row.BkColor,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/GameType/GameType_Insert',
      //url: 'https://localhost:44337/api/GameType/GameType_Insert',
      data: RequestDTO,
    }).then((response) => {
      if (response.data === '0') {
        alert('Data Successfully Inserted');
        GetGameTypes();
      } 
    });
  }

  function onAfterSaveCell(row) {
    let RequestDTO = {
      Short: row.Short,
      Description: row.Description,
      AllowHookUps: row.AllowHookUps,
      AllowBuyPoints: row.AllowBuyPoints,
      FkColor: row.FkColor,
      BkColor: row.BkColor,
      IdUser: 498,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/GameType/GameType_Update?idGameType=' +
        row.IdGameType,
      //url:'https://localhost:44337/api/GameType/GameType_Update?idGameType=' + row.IdGameType,
      data: RequestDTO,
    }).then((response) => {
      if (response.data === '0') {
        alert('Data Successfully Updated');
      } 
    });
  }

  function onAfterDeleteRow(rowKeys) {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/GameType/GameType_Delete?idGameType=' +
        rowKeys +
        '&idUser=' +
        userInfo.UserId,
      //url: 'https://localhost:44337/api/GameType/GameType_Delete?idGameType=' +rowKeys +'&idUser=' + userInfo.UserId,
    }).then((response) => {
      console.log('response', response);
    });
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(',');
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }

  const selectRowProp = {
    mode: 'radio',    onSelect: onRowSelect,
  };

  function onRowSelect(row, isSelected, e) {
    props.setGameTypeOnMem && props.setGameTypeOnMem(row.IdGameType); 
    setSelectedGametype(row);
  }

  const selectRowProp2 = {
    mode: 'radio',
    hideSelectColumn: true, // enable hide selection column.
    clickToSelect: true, // you should enable clickToSelect, otherwise, you can't select column.
    onSelect: onRowSelect,
  };

  const printInputList = () => {
    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Game Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={selectedGameTypes}
            onChange={handleIdGameTypeChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {gameGameTypes &&
              gameGameTypes.map((row) => (
                <MenuItem value={row.IdGameType}>{row.Description}</MenuItem>
              ))}
          </Select>
          <FormHelperText>Game Types</FormHelperText>
        </FormControl>
      </div>
    );
  };

  const printList = () => {
    return (
      <React.Fragment> 
        {selectedGametype && <ColorPicker saveData={onAfterSaveCell} setSelectedGametype={setSelectedGametype} selectedGametype={selectedGametype}  />}
        <br />

        <BootstrapTable
          data={gameGameTypes}
          cellEdit={cellEditProp}
          options={options}
          selectRow={selectRowProp}
          deleteRow={true}
          insertRow={true}
          pagination={true}
          version="4"
          hover
        >
          <TableHeaderColumn
            visible="none"
            hiddenOnInsert
            autoValue={true}
            hidden
            isKey
            dataField="IdGameType"
          >
            IdGameType
          </TableHeaderColumn>
          <TableHeaderColumn dataField="Short">Short</TableHeaderColumn>
          <TableHeaderColumn
            dataField="Description"
            filter={{ type: 'TextFilter', delay: 1000 }}
          >
            Description
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={{
              type: 'checkbox',
              options: {
                values: 'true:false',
              },
            }}
            dataField="AllowHookUps"
          >
            Hooks Ups
          </TableHeaderColumn>
          <TableHeaderColumn
            editable={{
              type: 'checkbox',
              options: {
                values: 'true:false',
              },
            }}
            dataField="AllowBuyPoints"
          >
            Buy Points
          </TableHeaderColumn>
          <TableHeaderColumn dataField="BkColor">BkColor</TableHeaderColumn>
          <TableHeaderColumn dataField="FkColor">FkColor</TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    );
  };

  const printListEasy = () => {
    return (
      <React.Fragment> 

        <br />

        <BootstrapTable
          data={gameGameTypes}
          options={options}
          selectRow={selectRowProp2}
          pagination={true}
          version="4"
          hover
        >
          <TableHeaderColumn
            visible="none"
            hiddenOnInsert
            autoValue={true}
            hidden
            isKey
            dataField="IdGameType"
          >
            IdGameType
          </TableHeaderColumn>
          <TableHeaderColumn dataField="Short">Short</TableHeaderColumn>
          <TableHeaderColumn
            dataField="Description"
            filter={{ type: 'TextFilter', delay: 1000 }}
          >
            Description
          </TableHeaderColumn>
        </BootstrapTable>
      </React.Fragment>
    );
  };
  if (props.isInput) {
    return printInputList();
  } else if (props.isEasy) {
    return printListEasy();
  } else {
    return printList();
  }
}

export default GameTypes;
