import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import GameTypes from '../../common/GameTypes';
import Sports from '../../common/Sports';
import WagerTypes from '../../common/WagerTypes';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

export default function ProfileGameType(props) {
  // const user = useContext(UserContext);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '75%',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  console.log('PROPS====== PLAYERPROFILEGAMETYPESPORT url props', props);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [
    playerProfileGameTypeSport,
    setPlayerProfileGameTypeSport,
  ] = React.useState([]);
  const [playerProfileGameType, setPlayerProfileGameType] = React.useState([]);

  const [idSport, setIdSport] = React.useState(['CBB']);
  const [wagerType, setWagerType] = React.useState(['1']);
  const [gameType, setGameType] = React.useState(['1']);

  useEffect(() => {
    // Change for server api address
    console.log('PROPS PLAYERPROFILEGAMETYPESPORT======>', props);
    // const url = process.env.REACT_APP_API_URL + '/PLAYERPROFILEGAMETYPESPORT';

    let IdProfile = props.Profile.IdProfile;

    let IdGameType = 1;
    const url =
      process.env.REACT_APP_API_URL +
      '/PLAYERPROFILEGAMETYPESPORT?filter=IdProfile,eq,' +
      IdProfile +
      '&filter=IdGameType,eq,' +
      IdGameType +
      '&filter=IdSport,eq,' +
      idSport +
      '&filter=WagerType,eq,' +
      wagerType;
    console.log('PROPS====== PLAYERPROFILEGAMETYPESPORT url', url);
    axios({
      method: 'get',
      url: url,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log(
        'PROPS====== PLAYERPROFILEGAMETYPESPORT THEN',
        response.data.records[0]
      );
      setPlayerProfileGameTypeSport(response.data.records[0]);
    });

    const urlGT =
      process.env.REACT_APP_API_URL +
      '/PLAYERPROFILEGAMETYPE?filter=IdProfile,eq,' +
      IdProfile +
      '&filter=IdGameType,eq,' +
      gameType;
    console.log('PROPS====== PLAYERPROFILEGAMETYPE urlGT', urlGT);
    axios({
      method: 'get',
      url: urlGT,
      /*  REPLACE WITH SELECT DATA */
    }).then((response) => {
      console.log(
        'PROPS====== PLAYERPROFILEGAMETYPE THEN',
        response.data.records[0]
      );
      setPlayerProfileGameType(response.data.records[0]);
    });
  }, [props, idSport, wagerType, gameType]);
  console.log('PROPS====== PLAYERPROFILEGAMETYPESPORT after useEffect');
  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCellEditPlayerProfileGameTypeSports,
  };

  const cellEditPlayerProfileGameTypeProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: onAfterSaveCellEditPlayerProfileGameType,
  };

  const setIdSportOnMem = (IdSport) => {
    setIdSport(IdSport);
  };
  const setIdWagerTypeOnMem = (idWagerType) => {
    setWagerType(idWagerType);
  };
  const setGameTypeOnMem = (idGameType) => {
    console.log('setGameTypeOnMem', gameType);
    setGameType(idGameType);
    console.log('setGameTypeOnMem', gameType);
  };
  function onAfterSaveCellEditPlayerProfileGameType(row, cellName, cellValue) {
    UpdateEditPlayerProfileGameType(row);
  }
  function onAfterSaveCellEditPlayerProfileGameTypeSports(
    row,
    cellName,
    cellValue
  ) {
    UpdateEditPlayerProfileGameTypeSports(row);
  }

  function UpdateEditPlayerProfileGameType(row) {
    let request = {
      IdProfile: row.IdProfile,
      IdGameType: row.IdGameType,
      FreeHalfPoints: row.FreeHalfPoints,
      AllowON3NFL: row.AllowON3NFL,
      AllowOFF3NFL: row.AllowOFF3NFL,
      AllowON3CFB: row.AllowON3CFB,
      AllowOFF3CFB: row.AllowOFF3CFB,
      AllowON7NFL: row.AllowON7NFL,
      AllowOFF7NFL: row.AllowOFF7NFL,
      AllowON7CFB: row.AllowON7CFB,
      AllowOFF7CFB: row.AllowOFF7CFB,
      PL_CheckSameTeam: row.PL_CheckSameTeam,
      PL_MaxSameTeam: row.PL_MaxSameTeam,
      TL_CheckSameTeam: row.TL_CheckSameTeam,
      TL_MaxSameTeam: row.TL_MaxSameTeam,
      IL_CheckSameTeam: row.IL_CheckSameTeam,
      IL_MaxSameTeam: row.IL_MaxSameTeam,
      PL_UseWideLine: row.PL_UseWideLine,
      UseWideLine: row.UseWideLine,
      SL_CheckMLnSpread: row.SL_CheckMLnSpread,
      PL_CheckMLnSpread: row.PL_CheckMLnSpread,
      PL_CheckTOnTU: row.PL_CheckTOnTU,
      TL_CheckTOnTU: row.TL_CheckTOnTU,
      RL_CheckMLnSpread: row.RL_CheckMLnSpread,
      RL_CheckTOnTU: row.RL_CheckTOnTU,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Profile/PlayerProfileGameType_Update',
      //url: 'https://localhost:44337/api/Profile/PlayerProfileGameType_Update',
      data: request,
    }).then((response) => {
      console.log('response', response);
    });
  }

  function UpdateEditPlayerProfileGameTypeSports(row) {
    let request = {
      IdProfile: row.IdProfile,
      IdGameType: row.IdGameType,
      IdSport: row.IdSport,
      WagerType: row.WagerType,
      CheckDogOver: row.ChangeAllowON3NFL,
      CheckDogUnder: row.AllowON3NFL,
      CheckFavOver: row.CheckFavOver,
      CheckFavUnder: row.CheckFavUnder,
      MaxPoints: row.MaxPoints,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/Profile/PlayerProfileGameTypeSport_Update',
      //url:'https://localhost:44337/api/Profile/PlayerProfileGameTypeSport_Update',
      data: request,
    }).then((response) => {
      console.log('response', response);
    });
  }

  return (
    <React.Fragment>
      <Table>
        <TableRow>
          <TableCell>
            Game Type
            <GameTypes
              style={{ marginTop: -250 }}
              setGameTypeOnMem={setGameTypeOnMem}
            ></GameTypes>
          </TableCell>
          <TableCell>
            <p>
              <b>Free Play Options</b>
            </p>
            <Table>
              <TableRow>
                <TableCell>
                  <b>NFL</b>
                  <BootstrapTable
                    data={[playerProfileGameType]}
                    cellEdit={cellEditPlayerProfileGameTypeProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdProfile"
                    >
                      ID
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="AllowON3NFL"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowON3NFL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="AllowOFF3NFL"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowOFF3NFL
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="AllowON7NFL"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowON7NFL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="AllowOFF7NFL"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowOFF7NFL
                    </TableHeaderColumn>
                  </BootstrapTable>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>CFB</b>
                  <BootstrapTable
                    data={[playerProfileGameType]}
                    cellEdit={cellEditPlayerProfileGameTypeProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdProfile"
                    >
                      ID
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="AllowON3CFB"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowON3CFB
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="AllowOFF3CFB"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowOFF3CFB
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="AllowON7CFB"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowON7CFB
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="AllowOFF7CFB"
                      editable={{
                        type: 'checkbox',
                        options: {
                          values: 'true:false',
                        },
                      }}
                    >
                      AllowOFF7CFB
                    </TableHeaderColumn>
                  </BootstrapTable>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <BootstrapTable
                    data={[playerProfileGameType]}
                    cellEdit={cellEditProp}
                    options={options}
                  >
                    <TableHeaderColumn
                      visible="none"
                      isKey={true}
                      hidden
                      dataField="IdProfile"
                    >
                      ID
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="FreeHalfPoints">
                      FreeHalfPoints
                    </TableHeaderColumn>
                  </BootstrapTable>
                </TableCell>
              </TableRow>
            </Table>

            <p>
              <b>Max Times same team can be involved in bets</b>
            </p>
            <BootstrapTable
              data={[playerProfileGameType]}
              cellEdit={cellEditPlayerProfileGameTypeProp}
              options={options}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="PL_CheckSameTeam"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                Check this limit on Parlay
              </TableHeaderColumn>
              <TableHeaderColumn dataField="PL_MaxSameTeam">
                Allowing Only
              </TableHeaderColumn>
            </BootstrapTable>
            <BootstrapTable
              data={[playerProfileGameType]}
              cellEdit={cellEditPlayerProfileGameTypeProp}
              options={options}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="TL_CheckSameTeam"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                Check this limit on Teaser
              </TableHeaderColumn>
              <TableHeaderColumn dataField="TL_MaxSameTeam">
                Allowing Only
              </TableHeaderColumn>
            </BootstrapTable>

            <BootstrapTable
              data={[playerProfileGameType]}
              cellEdit={cellEditPlayerProfileGameTypeProp}
              options={options}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="IL_CheckSameTeam"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                Check this limit on If Bets and Reverses
              </TableHeaderColumn>
              <TableHeaderColumn dataField="IL_MaxSameTeam">
                Allowing Only
              </TableHeaderColumn>
            </BootstrapTable>
            <p>
              <b>Check Limit Per Team/Total</b>
            </p>

            <BootstrapTable
              data={[playerProfileGameType]}
              cellEdit={cellEditPlayerProfileGameTypeProp}
              options={options}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="SL_CheckMLnSpread"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                SL_CheckMLnSpread
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="PL_CheckMLnSpread"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                PL_CheckMLnSpread
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="PL_CheckTOnTU"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                PL_CheckTOnTU
              </TableHeaderColumn>
            </BootstrapTable>
            <BootstrapTable
              data={[playerProfileGameType]}
              cellEdit={cellEditPlayerProfileGameTypeProp}
              options={options}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="TL_CheckTOnTU"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                TL_CheckTOnTU
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="RL_CheckMLnSpread"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                RL_CheckMLnSpread
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="RL_CheckTOnTU"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                RL_CheckTOnTU
              </TableHeaderColumn>
            </BootstrapTable>
            <p>
              <b>Max Points for side/total combinations</b>
            </p>

            <div>
              <Sports
                showMinimalSelect={true}
                setIdSportOnMem={setIdSportOnMem}
              ></Sports>

              <WagerTypes
                showMinimalSelect={true}
                setIdWagerTypeOnMem={setIdWagerTypeOnMem}
              ></WagerTypes>
            </div>
            <BootstrapTable
              data={[playerProfileGameTypeSport]}
              cellEdit={cellEditProp}
              options={options}
            >
              <TableHeaderColumn
                visible="none"
                isKey={true}
                hidden
                dataField="IdProfile"
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn dataField="MaxPoints">
                MaxPoints
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="CheckDogOver"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                CheckDogOver
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="CheckDogUnder"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                CheckDogUnder
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="CheckFavOver"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                CheckFavOver
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="CheckFavUnder"
                editable={{
                  type: 'checkbox',
                  options: {
                    values: 'true:false',
                  },
                }}
              >
                CheckFavUnder
              </TableHeaderColumn>
            </BootstrapTable>
          </TableCell>
        </TableRow>
      </Table>
    </React.Fragment>
  );
}
