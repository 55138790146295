import React, { useState} from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import HoldBets from '../betTicker/HoldBets';
import OpenBets from '../betTicker/OpenBets';


function BetTicker(props) {
  //const user = useContext(UserContext);

  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [color, setColor] = useState('#fffff');

  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

 


  return (
    <React.Fragment>
      <div>
        <Table>          
          <TableRow>
            <HoldBets color={color} />
          </TableRow> 
          <TableRow>           
            <OpenBets/>
          </TableRow>
        </Table>
      </div>
    </React.Fragment>
  );
}

export default BetTicker;
