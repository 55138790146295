import React, { useState, useEffect, Component } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import './Users.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import Loader from '../common/loader/Loader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Users(props) {
  const [agents, setAgents] = useState();
  const [books, setBooks] = useState([]);
  const [schema, setSchema] = useState();
  const [record, setRecord] = useState();
  const [language, setLanguage] = useState();
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [renderShowsTotal, setrenderShowsTotal] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [value, setValue] = React.useState(0);
  const [selectedIdAgent, setSelectedIdAgent] = React.useState(0);
  const [selectedIdCurrency, setSelectedIdCurrency] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let history = useHistory();
  const useStyles = makeStyles({
    root: {
      width: '100%',
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: '#3f51b5',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'darkcyan !important',
      },
    },

    tableRow: {
      '&:hover': {
        backgroundColor: 'lightgrey !important',
      },
    },
    headerCell: {
      color: 'white',
    },
  });

  const classes = useStyles();

  /*const displayForm = async (data, index) => {
    console.log('Data', data);
    console.log('Index', index);
    let agent = agents[index];
    console.log('Agent', agent);
    await setRecord();
    await setRecord(agent);
    setshowInfo(false);
  };*/

  useEffect(() => {
    //Change for server api address
    LoadUser();
  }, []);

  const LoadUser = () => {
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/USERS';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setRecord(data.records[0]);
        setAgents(data.records);

        console.log('data', data.records);
        console.log('sschema', data.records[0]);
        console.log('sschema', schema);
        console.log('agents', agents);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    //Change for server api address
    const url = process.env.REACT_APP_API_URL + '/BOOK';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let bookInfoInfo = data.records.map((v) => {
          v.value = v.IdBook;
          v.text = v.Description;
          return v;
        });
        setBooks(bookInfoInfo);
      });
  }, []);

  function onAfterDeleteRow(rowKeys) {
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_API2_URL +
        '/User/PlayerUser_Delete?idUser=' +
        rowKeys +
        '&idUser=' +
        userInfo.UserId,
      //url:'https://localhost:44337/api/User/PlayerUser_Delete?idUser=' + rowKeys +'&idUser=' +userInfo.UserId,
    }).then((response) => {
      console.log('response', response);
    });
    alert('The rowkey you drop: ' + rowKeys);
  }

  function customConfirm(next, dropRowKeys) {
    const dropRowKeysStr = dropRowKeys.join(',');
    if (
      window.confirm(
        `(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`
      )
    ) {
      // If the confirmation is true, call the function that
      // continues the deletion of the record.
      next();
    }
  }

  function selectedValues(row) {
    console.log(`You click row id Users: ${row.IdUser}`);

    setRedirect(row);
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPageList: [
      {
        text: '15',
        value: 15,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ], // you can change the dropdown list for size per page
    sizePerPage: 10, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    paginationShowsTotal: renderShowsTotal, // Accept bool or function
    paginationPosition: 'top', // default is bottom, top and both is all available
    // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    // alwaysShowAllBtns: true // Always show next and previous button
    // withFirstAndLast: false > Hide the going to First and Last page button
    //expandRowBgColor: 'rgb(196, 222, 222)',
    afterDeleteRow: onAfterDeleteRow, // A hook for after droping rows.
    handleConfirmDeleteRow: customConfirm,
    onRowClick: selectedValues,
    afterInsertRow: onAfterInsertRow, // A hook for after insert rows
  };

  const selectRowProp = {
    //clickToSelect: false, // enable click to select

    clickToSelect: true, // click to select, default is false
    clickToExpand: true, // click to expand row, default is false
    //onSelect: onRowSelect,
  };

  function onAfterInsertRow(row) {
    let RequestDTO = {
      Name: row.UserName,
      IdBook: row.IdBook,
      IdUser: userInfo.UserId,
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/User/PlayerUser_Insert',
      //url: 'https://localhost:44337/api/User/PlayerUser_Insert',
      data: RequestDTO,
    }).then((response) => {
      if (response.data === '-1') {
        alert('Data Successfully Updated');
      }
      console.log('response', response);
    });
    LoadUser();
  }
 
  function onRowSelect(row, isSelected, e) {
    let rowStr = '';
    for (const prop in row) {
      rowStr += prop + ': "' + row[prop] + '"';
    }
    console.log(e);
    alert(`is selected: ${isSelected}, ${rowStr}`);
  }

  const toggleTable = () => {
    if (showInfo) {
      setshowInfo(false);
    } else {
      setshowInfo(true);
    }
  };

  if (redirect) {
    let pushURL = '/user/' + redirect.IdUser;

    return (
      <React.Fragment>
        {/* //this.props.location.state. asi se agarran los datos en el otro componente */}

        {console.log('User Info props', redirect)}
        <div>
          {history.push(pushURL)}
          ); }
        </div>
      </React.Fragment>
      /*<Redirect
        to={{
          pathname: '/entityContainer',
          state: { row: redirect },
        }}
      />

      */
    );
  }
  return (
    <div id="main-div">
      <Paper className={classes.root}>
        <br />
        <br /> <h2>Users</h2>
        {isLoading ? <Loader></Loader> : ''}
        <Table className={classes.table} aria-label="simple table">
          <TableBody className={showInfo ? 'show-table' : 'hide-table'}>
            {agents && (
              <div>
                <BootstrapTable
                  pagination={true}
                  options={options}
                  selectRow={selectRowProp} //Da problemas con el Expand
                  insertRow={true}
                  version="4"
                  data={agents}
                  deleteRow={true}
                  hover
                >
                  <TableHeaderColumn
                    dataField="IdUser"
                    hiddenOnInsert
                    isKey={true}
                    autoValue={true}
                  >
                    User ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataField="LoginName"
                  >
                    User
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    filter={{ type: 'TextFilter', delay: 1000 }}
                    dataField="Name"
                    hiddenOnInsert
                  >
                    Name
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default Users;
