import React, { useState, setState, useEffect, useContext } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LineTypeSettings from './LineTypeSettings';
import LineTypeLinks from './LineTypeLinks';

import './LineTypeTabs.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProfileTabs(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //const user = useContext(UserContext);
  console.log('In Tabs ===>', props);
  return (
    <React.Fragment>
      <br />
      <Tabs value={value} onChange={handleChange} className="tabs-class">
        <Tab label="General" {...a11yProps(0)} />.
        <Tab label="Linked LineTypes" {...a11yProps(1)} />
      </Tabs>
      <TabPanel className="tab-content" value={value} index={0}>
        <LineTypeSettings
          LineType={props.LineType}
          UpdateProfile={props.UpdateProfile}
        ></LineTypeSettings>
      </TabPanel>

      <TabPanel className="tab-content" value={value} index={1}>
        <LineTypeLinks
          LineType={props.LineType}
          UpdateProfile={props.UpdateProfile}
        ></LineTypeLinks>
      </TabPanel>
    </React.Fragment>
  );
}
