import React, { useContext, useState, setState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import App from '../App';
import Loader from '../components/common/loader/Loader';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { UserContext } from '../contexts/UserContext';

export default function Login() {
  //subscribeToChanges((err, change) => console.log(change));
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    appBar: {
      position: 'relative',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    button: {
      margin: theme.spacing(1),
    },

    center: {
      margin: '0 auto',
      border: '1px grey black',
      textAlign: 'center',
      width: '600px',
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLogged, setIsLogged] = useState(false);
  const [loginType, setLoginType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangUserName = (event) => {
    setUserName(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleTypeChanged = (event) => {
    setLoginType(event.target.value);
  };

  const searchLoginValues = () => {
    setIsLoading(true);
    let tempVar;
    let loginRequest = {
      PhoneLine: '1234',
      LoginName: userName,
      Password: password,
      IP: '192.168.3.22',
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Login/Login',
      /*  REPLACE WITH SELECT DATA */
      data: loginRequest,
    }).then((response) => {
      if (response.data.UserId) {
        /*add IbBooks*/
        const urlresponseBooks =
          process.env.REACT_APP_API_URL +
          '/USERSBOOK' +
          '?filter=IdUser,eq,' +
          response.data.UserId;
        console.log('urlresponseBooks    ', urlresponseBooks);
        console.log('urlresponseBooksData    ', response.data);
        tempVar = {
          ...response.data,
        };
        console.log('urlresponseBooksTemp    ', tempVar);
        axios({
          method: 'get',
          url: urlresponseBooks,
          /*  REPLACE WITH SELECT DATA */
        }).then((responseBooks) => {
          console.log('urlresponseBooksBooks1    ', responseBooks);
          console.log('urlresponseBooksBooks    ', responseBooks.data);
          response.data.responseBooks = responseBooks.data;
          let sessionToStore = response.data;
          console.log('urlresponseBooksToStore    ', responseBooks.data);
          console.log('BOOKS====>', response.data);
          let commaSeparatedBooks = response.data.responseBooks.records.map(
            (v) => {
              return v.IdBook;
            }
          );
          commaSeparatedBooks = commaSeparatedBooks.join();
          sessionToStore.commaSeparatedBooks = commaSeparatedBooks;

          sessionStorage.setItem('userInfo', JSON.stringify(sessionToStore));

          console.log('commaSeparatedBooks', commaSeparatedBooks);

          console.log('response.data login', response.data);
          setIsLogged(true);

          //setGameRelatedOptions(response.data);
        });
        /**/
      } else {
        setIsLoading(false);
        alert('User or Password wrong!');
      }
    });
  };

  return (
    <Router>
      <UserContext.Provider value="Hellos">
        <form className={classes.center}>
          <div
            style={isLogged ? { visibility: 'hidden' } : { marginTop: '50%' }}
          >
            <FormGroup>
              <div id="inputs" style={{ display: 'flex', maxHeight: 45 }}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="User Name"
                  value={userName}
                  multiline={false}
                  onChange={handleChangUserName}
                />
                <TextField
                  variant="outlined"
                  type="password"
                  label="Password"
                  value={password}
                  multiline={false}
                  onChange={handleChangePassword}
                />

                {isLoading ? (
                  <Loader height={100} width={100}></Loader>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={searchLoginValues}
                    >
                      Login
                    </Button>
                  </div>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <RadioGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      checked={loginType == '0'}
                      onChange={handleTypeChanged}
                      value="0"
                      label="User"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Home' }}
                      // disabled
                    />
                  }
                  label="User"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={loginType == '1'}
                      onChange={handleTypeChanged}
                      value="1"
                      label="Agent"
                      name="radio-button-time"
                      inputProps={{ 'aria-label': 'Visitor' }}
                      // disabled
                    />
                  }
                  label="Agent"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormGroup>
          </div>
        </form>
      </UserContext.Provider>
      {isLogged ? window.location.replace('/home') : ''}
    </Router>
  );
}
