import React, { useState, setState, useEffect, useContext } from 'react';
import Loader from "../../components/common/loader/Loader";
import Axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, esES } from '@material-ui/data-grid'
import Divider from "@material-ui/core/Divider";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import SaveIcon from "@material-ui/icons/Save";

function PlayerRating (props) {

    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
    );

    const columns = [
        { field: 'id', headerName: 'Id', width: 0, editable: false, sortable: false, filterable: false, hide:true },  
        { field: 'description', headerName: 'Name', width: 150, editable: true, sortable: false, filterable: false }
    ]

    const [isLoading,setIsLoading] = useState(false);
    const [row, setRow] = useState([]);
    const [selectedRow,setSelectedRow] = useState([]);
    const [open, setOpen] = useState(true);
    const [playerRateName, setPlayerRateName] = useState("");
    const [isNew, setIsNew] = useState(false);
    const [isInserted, setIsInserted] = useState(false);

    var today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
      
    const useStyles = makeStyles((theme) => ({        
        root: {
        width: '100%',
        overflowX: 'auto',
        },
        appBar: {
        position: "relative",
        },
        center: {
        marginTop:"10px",
        display:"flex",
        margin: "0 auto",
        border: "1px grey black",
        textAlign: "center",
        height: "300px",
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: 200,
        },
        "& .MuiFormGroup-row": {
            justifyContent: "center",
        },
        "& .MuiFormControl-root": {
            justifyContent: "center"            
        }
        },
        marginMaker: {
        margin:"5px"
        }
    }));

    const classes = useStyles();  

    useEffect (() => {  
        getPlayerRate();       
    },[])

    useEffect (() => {  
        console.log("row",row)
        console.log("selected row", selectedRow);   
        console.log("id thingy", selectedRow.length > 0 ? selectedRow[0] : "");
    },[selectedRow])

    const getPlayerRate = async () => {
        const url = process.env.REACT_APP_API_URL +
        '/PLAYERRATE' 
      
        await Axios
            .get(url)
            .then((response) => response.data)
            .then((data) => {          
            feedRow(data.records)
            console.log("data.records",data.records)
            setIsLoading(false)
        });
    } 

    const feedRow = (playerRate) => {            
        let rowtemp = []
        for (let i = 0; i < playerRate.length; i++) {
          rowtemp.push({
            id: playerRate[i].IdPlayerRate,
            description: playerRate[i].Description
          })
        }
        setRow(rowtemp);    
    }

    const updatePlayerRate = async (description) => {        
        let playerRateRequest = {
            Description: description.value,
            LastModificationUser: userInfo.UserId,
            LastModification: today,
        };
        setIsLoading(true);
        await Axios({
            method: 'put',
            url: process.env.REACT_APP_API_URL + '/PLAYERRATE/' + selectedRow[0],
            data: playerRateRequest,
        }).then((response) => {
            console.log(response)
            alert("The Player Rate was successfully updated, Reference ID: "+selectedRow[0])            
        })
        setIsLoading(false) 
    }

    const insertPlayerRate = async () => {        
        let playerRateRequest = {
            Description: playerRateName,
            LastModificationUser: userInfo.UserId,
            LastModification: today,
        };
        setIsLoading(true);
        await Axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/PLAYERRATE',
            data: playerRateRequest,
        }).then((response) => {
            console.log(response)
            alert("The Player has been succesfully created")     
            getPlayerRate();         
        })
        setIsLoading(false) 
    }

    const deletePlayerRate = async () => { 
        if(selectedRow.length == 0){
            alert("You must select a Player Rate first")
        }else{
            setIsLoading(true); 
            await Axios({
                method: 'delete',
                url: process.env.REACT_APP_API_URL + '/PLAYERRATE/' + selectedRow[0],            
            }).then((response) => {
                console.log(response)
                alert("The Player Rate: " + row.filter(x => x.id == selectedRow[0])[0].description +" has been deleted")        
                getPlayerRate();    
            })
            setIsLoading(false)
        }
    }

    const handleRowSelection = (e) => {
        setSelectedRow(e)                  
    }

    function handleCell (e){
        console.log("e",e)
        updatePlayerRate(e)
    } 

    const handleClose = () => {        
        setOpen(false);
        setIsNew(false);
    }

    const handlePlayerRateNameChange = (e) => {
        setPlayerRateName(e.target.value)
    }

    const handleOk = () => {
        if(playerRateName==""){
            alert("please input the required field")
        }else{
            insertPlayerRate()
        }        
    }

    const newPlayerRating = () => {
        return(        
            <React.Fragment>
                <Dialog 
                    style={{width: "50%", height: "38%", margin: "0 auto", marginTop: "100px"}}
                    open={open} 
                    onClose={handleClose}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {props.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <form className={classes.center}>
                        <Divider />
                        <FormGroup row>                                
                            <TextField                                
                            variant="outlined"                            
                            label="Player Rate Name"
                            type="text"                            
                            onChange={handlePlayerRateNameChange}
                            fullWidth                                                                       
                            />                        
                        </FormGroup>                        
                    </form>
                    <React.Fragment>
                        <DialogActions>
                            {isInserted ? (                                
                                <FormControl className={classes.formControl}>
                                    <InputBase
                                    style={{
                                        color: "red",
                                        fontSize: 35,
                                        fontWeight: "bold",
                                    }}
                                    className={classes.margin}
                                    defaultValue="Done!"
                                    inputProps={{ "aria-label": "naked" }}
                                    />
                                </FormControl>                                
                            ) : (
                                ""
                            )}
                            {isLoading ? (
                            <Loader height={100} width={100}></Loader>
                            ) : (
                            <div>                                
                                <Button
                                startIcon={<SaveIcon />}
                                color="inherit"
                                onClick={handleOk}
                                >
                                Ok
                                </Button>
                            </div>
                            )}                            
                        </DialogActions>                                               
                    </React.Fragment>
                </Dialog>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            {isLoading ? (                  
                <Loader height={100} width={100}></Loader>
            ):("")}
            {isNew ? (                  
                newPlayerRating()
            ):("")}
            <Paper className={classes.root}>
              <br />
              <br /> 
              <h2 style={{margin:"10px"}}>Player Rating</h2>
            </Paper>            
            <div style={{display:"flex", flexDirection:"row", alignContent:"space-between", alignItems:"center"}}>
                <label style={{padding:"8px",fontSize:"150%"}}>
                  <AddBoxIcon                 
                    aria-label="newGame"
                    size="small"
                    className={classes.fab}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsNew(true)   
                      setOpen(true)                   
                    }}
                  />{" "}
                  Add New
                </label> 
                <label style={{padding:"8px",fontSize:"150%"}}>
                  <DeleteIcon                 
                    aria-label="newGame"
                    size="small"
                    className={classes.fab}
                    style={{ cursor: "pointer" }}
                    onClick={deletePlayerRate}
                  />{" "}
                  Delete
                </label> 
            </div>
            <div style={{ height: 500, width: '100%' }}>
                <DataGrid                  
                rows={row}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[5]}            
                disableColumnSelector
                disableColumnMenu                
                onSelectionModelChange={handleRowSelection}                                    
                disableMultipleSelection={true}    
                onCellEditCommit={handleCell}                               
                />              
            </div>   
            <Divider></Divider>  
        </React.Fragment>
             
    )

}
export default PlayerRating