import React, { useState, setState, useEffect, useContext } from 'react';
import Loader from "../../components/common/loader/Loader";
import Axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputSelect from "../../components/common/InputSelect";
import { DataGrid, esES } from '@material-ui/data-grid'
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";

function OddsDefaultBySport (props) {

    const [lineTypes,setLineTypes] = useState([]);
    const [gameTypes, setGameTypes] = useState([]);
    const [sports, setSports] = useState([]);
    const [isLoading,setIsLoading] = useState(false)
    const [idSport,setIdSport] = useState('');
    const [lineTypeID, setlineTypeID] = useState(55);
    const [gameTypeId, setGameTypeId] = useState(1);
    const [row, setRow] = useState([]);
    const [selectedRow,setSelectedRow] = useState([]);
    const [juiceRebate,setJuiceRebate] = useState([]);
    const [range,setRange] = useState(0)
    const [juiceRebateValue,setJuiceRebateValue] = useState(0)
    const [open, setOpen] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [isInserted, setIsInserted] = useState(false);
    const [lines, setLines] = useState([]);
    const [profileTracks, setProfileTracks] = useState([]);
    const [profileID, setProfileID] = useState(4036)

     
    const [type, setType] = useState("Local");    
    const [line, setLine] = useState("Money Line");
    const [positiveFlag, setPositiveFlag] = useState("Positive");
    
    const columns = [
      { field: 'id', headerName: 'Id', width: 0, editable: false, sortable: false, filterable: false, hide:true },  
      { field: 'idSport', headerName: 'Sport', width: 150, editable: false, sortable: false, filterable: false },
      { field: 'odds', headerName: 'Odds', width: 150, editable: true, sortable: false, filterable: false}
    ]
    const [userInfo, setUserInfo] = useState(
      JSON.parse(sessionStorage.getItem("userInfo"))
    );
    
    const useStyles = makeStyles((theme) => ({        
        root: {
          width: '100%',
          overflowX: 'auto',
        },
        appBar: {
          position: "relative",
        },
        center: {
          marginTop:"10px",
          display:"flex",
          margin: "0 auto",
          border: "1px grey black",
          textAlign: "center",
          height: "300px",
          "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: 200,
          },
          "& .MuiFormGroup-row": {
            justifyContent: "center",
          },
          "& .MuiFormControl-root": {
            justifyContent: "center"            
          }
        },
        marginMaker: {
          margin:"5px"
        }
    }));
    const classes = useStyles();   
  
    const getProfileTracks = async () => {
        const url = process.env.REACT_APP_API_URL + "/ProfileTrack";
        await Axios
          .get(url)
          .then((response) => response.data)
          .then((data) => {
            setProfileTracks(data.records.sort((a, b) => {
              let fa = a.ProfileName.toLowerCase(),
                  fb = b.ProfileName.toLowerCase();          
              if (fa < fb) {
                  return -1;
              }
              if (fa > fb) {
                  return 1;
              }
              return 0;
            }));
          });
    };
    
    const getOddsDefault = async () => {
      const url = process.env.REACT_APP_API_URL +
      '/ODDSDEFAULT?filter=IdProfile,eq,' +
      profileID      
      
      await Axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {          
          feedRow(data.records)
          console.log("data.records",data.records)
          setIsLoading(false)
        });
    }   

    const updateOddsDefault = async (e) => {
      setIsLoading(true)

      let oddsDefault={
        idProfile: profileID, 
        idSport:  idSport,
        odds : e.value,
        idUser: userInfo.UserId        
      }     

      console.log("oddsBySport",oddsDefault)
      
      const url = process.env.REACT_APP_DEF_PHP_URL + '/OddsDefaultUpdate.php'
      Axios({
        method: 'post',
        url: url,
        data: oddsDefault,
      }).then((response) => {
          setIsLoading(false)
          console.log("odd response",response)
        });
    }    

    useEffect (() => {  
        getOddsDefault();      
        getProfileTracks();       
    },[])
    
    useEffect(() => {
        setLoader();
        getOddsDefault();
    }, [profileID]);

    const setLoader = () => {
        if(sports.length === 0 || lineTypes.length === 0 || gameTypes.length === 0) {
            setIsLoading(true);
        }else{
            setIsLoading(false);
        }      
    }
    
    const feedRow = (oddsDefault) => {            
      let rowtemp = []
      for (let i = 0; i < oddsDefault.length; i++) {
        rowtemp.push({
          id:i,
          idSport: oddsDefault[i].IdSport,
          odds: oddsDefault[i].Odds
        })
      }
      setRow(rowtemp);    
    }
    
    const handleRowSelection = (row) => {
      setSelectedRow(row)                  
    }

    function handleCell (e){
      console.log(e)
      updateOddsDefault(e)
    }    
    
    function handleRow (e){
      console.log(e)
      setIdSport(e.row.idSport)
    }  

    return(
        <React.Fragment>
            {isLoading ? (                  
            <Loader height={100} width={100}></Loader>
            ):("")}
            <Paper className={classes.root}>
              <br />
              <br /> 
              <h2 style={{margin:"10px"}}>Odds Default by Sport</h2>
            </Paper>            
            <div style={{display:"flex", gap:"100px"}}>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '50ch' },
                        borderRight:"1px solid",
                        padding:"10px",
                        display:"grid"
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Autocomplete
                      autoSelect
                      id="profiles"
                      options={profileTracks}
                      getOptionLabel={(option) =>
                        option.ProfileName ? option.ProfileName : ""
                      }
                      key={profileTracks}
                      defaultValue={{
                          ProfileName: profileTracks.length > 0 ? profileTracks.filter(x => x.ProfileID === 4036)[0].ProfileName : "",
                          ProfileID:profileTracks.length > 0 ? 4036 : ""
                      }}
                      onChange={(event, newValue) => { 
                        if(newValue!==null && newValue!=="" && newValue!== undefined)                       
                          setProfileID(newValue.ProfileID);                        
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Profiles" fullWidth/>
                      )}
                    />
                </Box>
                <Box sx={{ height: 500, width: '350px', marginBottom:"10px",marginTop:"20px"}}>
                  <DataGrid                  
                    rows={row}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}            
                    disableColumnSelector
                    disableColumnMenu
                    onSelectionModelChange={handleRowSelection}                                    
                    disableMultipleSelection={true}    
                    onCellEditCommit={handleCell}   
                    onRowClick={handleRow}                              
                  />                
                </Box>                
            </div>   
            <Divider></Divider>  
        </React.Fragment>
             
    )
}
export default OddsDefaultBySport