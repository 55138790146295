import React, { useState, setState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as moment from 'moment';
import InputSelect from '../../common/InputSelect';
/*BootstrapTable anbd table Related*/
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LineTypeList from '../../lineTypes/lineTypesComponents/LineTypeList';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

/*Loader */
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';

/*animation*/
import * as legoData from '../../common/animations/legoLoader.json';
/*Inputs*/
/*Styles*/
import './ChangesByGame.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 10,
    height: 10,
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FinalStatsByAgent(props) {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  const classes = useStyles();
  const [gameDeleted, setGameDeleted] = React.useState([]);
  const [leagueId, setLeagueId] = useState();
  const [leagues, setLeagues] = useState();
  const [user, setUser] = useState('');
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo'))
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [idSport, setIdSport] = useState();
  const [startDate, setStartDate] = React.useState(week[0]);
  const [endDate, setEndDate] = React.useState(week[0]);
  const [sports, setSports] = useState();
  // let IdPeriod = '0';

  const handleShow = () => {};

  useEffect(() => {
    console.log('PROPS AGENT INFO=====>', props);
    //Change for server api address
    const url = process.env.REACT_APP_API_URL + '/SPORT';
    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let sportInfo = data.records.map((v) => {
          v.IdLineType = v.IdSport;
          v.Description = v.SportName;
          return v;
        });
        setSports(sportInfo);
      });
  }, [setSports]);

  useEffect(() => {
    //Change for server api address
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + '/LEAGUE';

    axios
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        let leagueInfo = data.records.map((v) => {
          v.IdLineType = v.Description;
          v.Description = v.Description;
          return v;
        });
        setLeagues(leagueInfo);
        setIsLoading(false);
      });
  }, [setLeagues]);

  const HistoryValues = () => {
    setIsLoading(true);
    let request = {
      logIdUser: userInfo.UserId,
      StartDate: startDate,
      EndDate: endDate,
      User: user,
      Sport: idSport ? idSport : '',
      League: leagueId ? leagueId : '',
    };
    axios({
      method: 'post',
      url: process.env.REACT_APP_API2_URL + '/Reports/Report_Game_Deleted',
      data: request,
    }).then((response) => {
      console.log('response', response.data);
      setGameDeleted(response.data);
      setIsLoading(false);
    });
  };
  // const [selectedIdGame, setselectedIdGame] = useState();

  const options = {
    beforeShowError: (type, msg) => {
      this.setState({ errType: type, errMsg: msg });
      // return false or do not return will not trigger the toastr,
      // if you want the toastr popup, you should return true always.
    },
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };
  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };

  /*Loader*/
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: legoData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  /**/

  function changeSportID(value) {
    setIdSport(value);
  }

  function changeLeagueID(value) {
    setLeagueId({ value });
  }

  function rowClassNameFormat(row, rowIdx) {
    // row is whole row object
    // rowIdx is index of row
    return rowIdx % 2 === 0
      ? 'tr-function-example'
      : 'td-column-function-odd-example';
  }

  return (
    <React.Fragment>
      <div class="main-div">
        {isLoading ? (
          <FadeIn>
            <div class="d-flex justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={360} width={360} />
            </div>
          </FadeIn>
        ) : (
          ''
        )}
        <div>
          <form className={classes.container} noValidate>
            <Table>
              <TableRow>
                <TableCell>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    className={classes.textField}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    defaultValue={endDate}
                    className={classes.textField}
                    onChange={handleChangeEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    type="text"
                    label="User"
                    value={user}
                    multiline={true}
                    onChange={handleChangeUser}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={sports}
                    placeholder="Sports"
                    setSelectValue={changeSportID}
                    startValue={idSport}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <InputSelect
                    variant="outlined"
                    name="name"
                    data={leagues}
                    placeholder="Line Types"
                    setSelectValue={changeLeagueID}
                    startValue={leagueId}
                  />
                </TableCell>
                <TableCell>
                  <Button color="inherit" onClick={HistoryValues}>
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            </Table>
          </form>

          {gameDeleted && (
            <BootstrapTable
              data={gameDeleted}
              //   cellEdit={cellEditProp}
              trClassName={rowClassNameFormat}
              pagination
            >
              <TableHeaderColumn isKey={true} hidden dataField="IdGame">
                IdGame
              </TableHeaderColumn>
              <TableHeaderColumn dataField="IdSport">Sport</TableHeaderColumn>
              <TableHeaderColumn dataField="VisitorNumber">#</TableHeaderColumn>
              <TableHeaderColumn dataField="VisitorTeam">
                VisitorTeam
              </TableHeaderColumn>
              <TableHeaderColumn dataField="HomeNumber">#</TableHeaderColumn>
              <TableHeaderColumn dataField="HomeTeam">
                Home Team
              </TableHeaderColumn>
              <TableHeaderColumn dataField="LastModification">
                Deleted Date
              </TableHeaderColumn>
              <TableHeaderColumn dataField="LoginName">
                Deleted by
              </TableHeaderColumn>
            </BootstrapTable>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
