import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import "./Data.css";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Loader from "../common/loader/Loader";
import { DataGrid } from '@material-ui/data-grid'
import TextField from "@material-ui/core/TextField";
import TableRow from "@material-ui/core/TableRow";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button'
import CreateAgent from "./agentComponents/CreateAgent";
import FileCopyIcon from '@material-ui/icons/FileCopy';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function Data(props) {
  const [agents, setAgents] = useState();
  const [agentSelected, setAgentSelected] = useState();
  const [record, setRecord] = useState();
  const [isNew, setIsNew] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [showInfo, setshowInfo] = useState(true);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem("userInfo"))
  );
  const [filter, setFilter] = useState("");

  const handleChangeFilter = (event) => {
    setFilter(event.target.value);
  }

  const handleRowSelection = (row) => {
    setAgentSelected(agents.filter(x => x.IdAgent == row[0])[0])           
  }



  let history = useHistory();

  const useStyles = makeStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#3f51b5",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "darkcyan !important",
      },
    },

    tableRow: {
      "&:hover": {
        backgroundColor: "lightgrey !important",
      },
    },
    headerCell: {
      color: "white",
    },
  });

  const handleCloseCreate = function () {
    setIsNew(false);
    setIsClone(false)   
  };

  const classes = useStyles();

  const loadAgents = () => {
    setIsLoading(true);
    const url =
      process.env.REACT_APP_API_URL +
      "/AGENT?" +
      "filter=IdBook,in," +
      userInfo.commaSeparatedBooks;
    const timeout = setTimeout(() => {
      axios
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setRecord(data.records[0]);
          setAgents(data.records);
          setIsLoading(false);
        }, 2000);

      return () => clearTimeout(timeout);
    });
  }

  useEffect(() => {
    loadAgents()
  }, []);

  function DeleteAgent() {
    if(window.confirm("Are you sure you want to edit the selected item?")){      
      let req = {
        IdAgent: agentSelected.IdAgent,
        IdUser: userInfo.UserId,
        Result: 0,
      };    
      console.log("req", req)
      axios({
        method: "post",
        url: process.env.REACT_APP_DEF_PHP_URL + '/AgentDelete.php',
        data: req,
      }).then((response) => {      
        alert("The Agent: " + agentSelected.IdAgent + " has been succesfully deleted")
        loadAgents()
        setFilter("")
      }).catch( (e) => {
        console.log('objMultisend error', e.response.data);
      });
    }
  }

  

  if (redirect) {
    let pushURL = "/agentContainer/" + redirect.IdAgent;
    return (
      <React.Fragment>
        {console.log("Profile Info props", redirect)}
        <div>
          {history(pushURL)}         
        </div>
      </React.Fragment>
    );
  }

  function renderManage(params) { 
    return( 
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          window.location.href='/agentContainer/'+params.row.IdAgent
        }}
      >
        Manage
      </Button>
    )
  }

  return (
    <Paper className={classes.root}>
      <br />
      <br /> <h2>Agents</h2>
      <div style={{display:"flex", flexDirection:"row", alignContent:"space-between", alignItems:"center"}}>
        <label style={{padding:"8px",fontSize:"150%"}}>
          <AddBoxIcon                 
            aria-label="newGame"
            size="small"
            className={classes.fab}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsNew(true);                      
            }}
          />{" "}
          Add New
        </label> 
        {agentSelected ? (
          <label style={{padding:"8px",fontSize:"150%"}}>
            <FileCopyIcon                 
              aria-label="newGame"
              size="small"
              className={classes.fab}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsClone(true)                     
              }}
            />{" "}
            Clone Agent
          </label>  
        ):("")}
        {agentSelected ? (
          <label style={{padding:"8px",fontSize:"150%"}}>
            <DeleteForeverIcon                 
              aria-label="newGame"
              size="small"
              className={classes.fab}
              style={{ cursor: "pointer" }}
              onClick={() => {
                DeleteAgent()                      
              }}
            />{" "}
            Delete Agent
          </label>  
        ):("")}
      </div> 
      {isLoading ? <Loader></Loader> : ""}
      <Table sx={{minWidth:650}} aria-label="simple table">
        <TableBody className={showInfo ? "show-table" : "hide-table"}>
          <TableRow>
            {agents && (
              <div>
                <TextField      
                  style={{padding:"8px"}}                          
                  variant="outlined"                            
                  label="Search"
                  type="text"
                  value={filter}
                  onChange={handleChangeFilter}                                                             
                />
                <Box sx={{ height: 520, width: '100%' }}>
                  <DataGrid
                      rows={filter != "" ? agents.filter(x => x.Name.toUpperCase().includes(filter.toUpperCase()) || x.Agent.toUpperCase().includes(filter.toUpperCase())) : agents}
                      getRowId={(row) => Object.values(row)[0]}
                      columns={[
                        {
                          field: "",
                          headerName: "",
                          align: "right",
                          headerAlign: "center",
                          width: 100,
                          editable: false,
                          sortable: false,
                          filterable: false,
                          renderCell: renderManage,
                        }, 
                        {field: 'IdAgent' , headerName: 'Id Agent', width: 100, editable: false, sortable: false, filterable: false},
                        {field: 'Agent' , headerName: 'Agent', width: 100, editable: false, sortable: false, filterable: false},
                        {field: 'Name' , headerName: 'Name', width: 200, editable: false, sortable: false, filterable: false},
                        {field: 'Password' , headerName: 'Password', width: 150, editable: false, sortable: false, filterable: false},
                        {field: 'Enable' , headerName: 'Enable', width: 100, editable: false, sortable: false, filterable: false},
                        {field: 'DontXferPlayerActivity' , headerName: 'Dont Xfer PlayerActivity', width: 200, editable: false, sortable: false, filterable: false},
                        {field: 'Distributor' , headerName: 'Distributor', width: 100, editable: false, sortable: false, filterable: false},
                        {field: 'OnlineAccess' , headerName: 'OnlineAccess', width: 150, editable: false, sortable: false, filterable: false},
                      ]}
                      pageSize={100}
                      rowsPerPageOptions={[5]}            
                      disableColumnSelector
                      disableColumnMenu       
                      onSelectionModelChange={handleRowSelection}                             
                      disableMultipleSelection={true}                     
                  />
                </Box>                            
              </div>
            )}
          </TableRow>
        </TableBody>
      </Table>
      {isNew || isClone?(        
          <CreateAgent
            onClose={handleCloseCreate}
            title={isNew ? "New Agent" : "Clone Agent"}
            agentSelected={agentSelected}
            loadAgents={loadAgents}
            isClone={isClone}
          />          
      ):(
        "" 
      )} 
    </Paper>
  );
}

export default Data;

