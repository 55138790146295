import React, { useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import Loader from "../../common/loader/Loader";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";


function WebCongigurationRowNew (props) {

    const useStyles = makeStyles((theme) => ({        
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        center: {
            margin: "0 auto",
            border: "1px grey black",
            textAlign: "center",
            width: "600px",
            height: "250px",
            "& .MuiTextField-root": {
              margin: theme.spacing(1),
              width: 500,
            },
            "& .MuiFormGroup-row": {
              //justifyContent: "center",
            },
            "& .MuiFormControl-root": {
              justifyContent: "center",
            },
            "& .MuiSelect-select": {
                width: 220,
                spacing: theme.spacing(1),
            }
        },        
    }));

    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [webRowName, setWebRowName] = useState('');
    const [isInserted, setIsInserted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRed, setIsRed] = useState(false);
 
    const [userInfo, setUserInfo] = useState(
        JSON.parse(sessionStorage.getItem("userInfo"))
      ); 

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
    }

    const handleChangeColumnName =(event) => {
        setWebRowName(event.target.value);
    };

    const insertWebRow = () => {
        setIsLoading(true);        
        try{
            let RequestDTO = {
                name: webRowName,
                idUser: userInfo.UserId,
              };
              Axios({
                method: 'post',
                url: process.env.REACT_APP_API2_URL + '/WebConfigurator/WebRow_Insert',
                data: RequestDTO,
              }).then((response) => {
                if (response.data === 0) {
                  props.GetWebRows();
                  setIsLoading(false);
                  setIsInserted(true);
                  alert('Data Successfully Inserted');
                  setWebRowName('');
                  setIsRed(false);
                }
                console.log('response', response);
              });                    
          }catch(error){
            console.error("error.response.data",error.response.data)
            setIsLoading(false);
        }
    }

    const handleOk = () => {
        if(webRowName === ''){
            setIsRed(true);
            alert("Please provide the required fields");
        }else{
            insertWebRow();            
        }
    }

    return(        
        <React.Fragment>
            <Dialog 
                style={{width: "50%", height: "38%", margin: "0 auto", marginTop: "100px"}}
                open={open} 
                onClose={handleClose}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            New Web Row
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.center}>
                    <Divider />
                    <FormGroup row>                                
                        <TextField                                
                        variant="outlined"                            
                        label="Web Row Name"
                        type="text"
                        value={webRowName}
                        {...isRed && webRowName===''?(
                            {error:true}
                        ):("")}
                        onChange={handleChangeColumnName}
                        fullWidth                                                                       
                        />                        
                    </FormGroup>
                </form>
                <React.Fragment>
                    <DialogActions>
                        {isInserted ? (                                
                            <FormControl className={classes.formControl}>
                                <InputBase
                                style={{
                                    color: "red",
                                    fontSize: 35,
                                    fontWeight: "bold",
                                }}
                                className={classes.margin}
                                defaultValue="Done!"
                                inputProps={{ "aria-label": "naked" }}
                                />
                            </FormControl>                                
                        ) : (
                            ""
                        )}
                        {isLoading ? (
                        <Loader height={100} width={100}></Loader>
                        ) : (
                        <div>                                
                            <Button
                            startIcon={<SaveIcon />}
                            color="inherit"
                            onClick={handleOk}
                            >
                            Ok
                            </Button>
                        </div>
                        )}                            
                    </DialogActions>   
                                            
                </React.Fragment>
            </Dialog>
        </React.Fragment>
    );    
} 
export default WebCongigurationRowNew;