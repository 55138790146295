import React, { useState, setState, useEffect, useContext } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import { TextField } from '@mui/material';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { StyledTableCell, StyledTableRow } from "../common/Table";
import Button from "@material-ui/core/Button";
import Time from 'react-time';

export default function ActionDetail(props) {

    const [open, setOpen] = React.useState(true);    
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);

    const useStyles = makeStyles((theme) => ({
        root: {
          width: "100%",
        },
        appBar: {
          position: "relative",
        },
        formControl: {
          margin: theme.spacing(1),
          minWidth: "100%",
        },
        selectEmpty: {
          marginTop: theme.spacing(2),
        },
        title: {
          marginLeft: theme.spacing(2),
          flex: 1,
        },
        button: {
          margin: theme.spacing(1),
        },
    
        table: {
          minWidth: "100%",
        },
        center: {
          margin: "0 auto",
          border: "1px grey black",
          textAlign: "center",
          width: "75%",
          paddingTop:"5%",
          "& .MuiTextField-root": {
            margin: theme.spacing(1),
            width: 200,
          },
          "& .MuiFormGroup-row": {
            justifyContent: "center",
          },
          "& .MuiFormControl-root": {
            justifyContent: "center",
          },
        },
    }));

    const classes = useStyles();

    const handleClose = () => {
        if (props.onClose) {
          props.onClose();
        }
        setOpen(false);
    };

    useEffect(() => {
        console.log("props", props)    
    }, []);    

    return(
        <React.Fragment>
            <Dialog style={{ maxWidth: "75%" }} open={open} onClose={handleClose}>
                <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Action Details
                    </Typography>
                </Toolbar>
                </AppBar>
                <form className={classes.center}>
                    
                    <FormGroup row>
                        
                        <TextField
                            label="Line Type"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][10]}
                        />

                        <TextField
                            label="Ticket No."
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][0]}
                        />

                        <TextField
                            label="User"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][13]}
                        />
                            
                    </FormGroup>
                    
                    <FormGroup row>
                        
                        <TextField
                            label="Profile"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][11]}
                        />

                        <TextField
                            label="Risk"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][14] + " " + Math.round(props.actionHeader[0][7]) + " " +  "(" + props.actionHeader[0][15] + ")"}
                        />

                        <TextField
                            label="Call ID"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][3]}
                        />
                            
                    </FormGroup>

                    <FormGroup row>
                        
                        <TextField
                            label="Player"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][12]}
                        />

                        <TextField
                            label="Win"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][14] + " " + Math.round(props.actionHeader[0][8]) + " " +  "(" + props.actionHeader[0][15] + ")"}
                        />

                        <TextField
                            label="Phone"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][4]}
                        />
                            
                    </FormGroup>
                
                    <FormGroup row>
                        
                        <TextField
                            label="Tax"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][14] + " " + Math.round(props.actionHeader[0][9]) + " " +  "(" + props.actionHeader[0][15] + ")"}
                        />

                        <TextField
                            label="Placed"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][5].date}
                        />
                            
                    </FormGroup>

                    <FormGroup row>
                        
                        <TextField
                            label="Description"
                            disabled="true"
                            color="primary"
                            variant='outlined'
                            value={props.actionHeader[0][6]}
                            style={{width:"100%"}}
                        />
                            
                    </FormGroup>
                    
                    <FormGroup row>
                        <Table className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <StyledTableRow>
                                    <StyledTableCell className={classes.headerCell}>
                                    <strong>Detail</strong>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.headerCell}>
                                    <strong>Game Date</strong>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.headerCell}>
                                    <strong>Description</strong>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.headerCell}>
                                    <strong>Result</strong>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                            {props.actionDetail &&
                                props.actionDetail.slice(
                                    currentPage * rowsPerPage,
                                    currentPage * rowsPerPage + rowsPerPage
                                ).map((row) => (
                                    console.log("row",row),                                    
                                    <StyledTableRow 
                                        className={classes.tableRow} 
                                    >
                                        <StyledTableCell className={classes.headerCell}>
                                            <strong>{row[0]}</strong>
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.headerCell}>
                                            <strong>
                                            <Time value={row[2].date} format="DD/MM/YYYY" />
                                            </strong>
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.headerCell}>
                                            <strong>{row[3]}</strong>
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.headerCell}>
                                            <strong>{row[4]}</strong>
                                        </StyledTableCell>                                   
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </FormGroup>
                </form>
                <DialogActions>
                    <Button
                        color="inherit"
                        startIcon={<CancelIcon />}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}