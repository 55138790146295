import React, { useState, setState, useEffect, useContext } from 'react';
import { forwardRef, useRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { UserContext } from '../../contexts/UserContext';
import Checkbox from '@material-ui/core/Checkbox';

import KeyboardEventHandler from 'react-keyboard-event-handler';
import LoadingOverlay from 'react-loading-overlay';
/*WebConfigurator Components*/
import WebConfiguratorsList from './webConfiguratorComponents/WebConfiguratorList';

import WebConfiguratorTabs from './webConfiguratorComponents/WebConfiguratorTabs';
import './WebConfigurator.css';

const WebConfigurator = forwardRef((props) => {
  const user = useContext(UserContext);
  const [WebConfigurator, setWebConfigurator] = React.useState(0);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      useState,
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      },
      '& .MuiFormGroup-row': {
        justifyContent: 'center',
      },
      '& .MuiFormControl-root': {
        justifyContent: 'center',
      },
    },
  }));
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    //Change for server api address
  }, []);
  //subscribeToTimer((err, timestamp) => settimestamp(timestamp));

  const setCurrentWebConfigurator = (WebConfigurator) => {
    console.log('in WebConfigurator props', props);
    console.log('in WebConfigurator props', WebConfigurator);
    setWebConfigurator(WebConfigurator);
    let anothervar = WebConfigurator.IdWebConfigurator.toString();
    //let anothervar = 'WebConfigurator.IdWebConfigurator';
    history.push(anothervar);
    console.log('in WebConfigurator props after anothervar', anothervar);
  };
  return (
    <React.Fragment>
      {console.log('in WebConfigurator props', props)}

      <div>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="WebConfigurators-right-div">
                <div className="WebConfigurators-right-div">
                  <Paper className={classes.root}>
                    <WebConfiguratorTabs></WebConfiguratorTabs>
                  </Paper>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
});

export default WebConfigurator;
